import api from './api';

const getAll = (pagination, filter, sortColumn) => api.get('/guide-image', {
    pageNum: pagination.currentPage - 1, 
    pageLength: pagination.itemsPerPage, 
    active: filter.active,
    search: filter.search,
    sortBy: sortColumn.sortByColumn,
    orderBy: sortColumn.orderBy,
}).then(response => {
    return response.data;
});

const getById = (id) => api.get(`/guide-image/${id}`).then(response => {
    return response.data
});

const create = (data) => api.post('/guide-image', data).then(response => {
    return response.data
});

const update = (id, data) => api.put(`/guide-image/${id}`, data).then(response => {
    return response.data
});

const remove = (id) => api.delete(`/guide-image/${id}`).then(response => {
    return response.data
});

const GuideImagesService = {
    getAll,
    getById,
    create,
    update,
    remove
};

export default GuideImagesService;