import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { t } from "i18next";
import { useState } from "react";
import { Button, Col, Form, FormGroup, Image, Row } from "react-bootstrap";
import { useForm } from "react-hook-form";
import BodyService from "../../services/body.service";

const LanguageForm = ({ onSubmit, language, permissions }) => {

    const { register, handleSubmit, formState} = useForm({
        mode: "all",
    });

    const [base64, setBase64] = useState(null);

    const setImageToBase64 = async (file) => {
        if (file) {
            const base = await BodyService.getBase64(file);
            setBase64(base);
        }
    }

    const onSubmitForm = async (data) => {

        var sendObject = {
            name: language.name,
            iso: language.iso,
            iso2: language.iso2,
            active: language.active
        };

        if (data.icon.length > 0) {
            sendObject.iconBase64 = base64;
            sendObject.iconBase64 = sendObject.iconBase64.split(',')[1];
            sendObject.iconContentType = data.icon[0].type;
        }

        await onSubmit(sendObject);
        setTimeout(function () {
            window.scrollTo(0, 0);
        },0);
    }

    return (
        <Form onSubmit={handleSubmit(onSubmitForm)}>
            <Row>
                <Col md="4">
                    <FormGroup >
                        <Form.Label>{t('languages.list.fields.icon')}</Form.Label>
                        <Form.Control type="file"
                            accept="image/png, image/jpeg"
                            {...register("icon")}
                            onChange={(e) => setImageToBase64(e.target.files[0])} />
                    </FormGroup>

                    {(language?.icon || base64) &&
                        <Row>
                            <Col className="col-8 col-md-6 col-lg-2">
                                <Image fluid thumbnail className="mt-4" src={base64 || language?.icon} alt={language?.name} />
                            </Col>
                        </Row>
                    }
                </Col>
            </Row>
            <Button type="submit" className={"mt-4"} disabled={ permissions.languages?.update === false ? true : (!formState.isValid || (language.icon && !base64)) }>
                <FontAwesomeIcon icon={"save"} />
            </Button>
        </Form>
    );
}

export default LanguageForm;
