import api from './api';

const getHomeHeadline = () => api.get('/home-headline')
.then(response => {
    return response.data;
});

const save = (data) => api.post('/home-headline', data).then(response => {
    return response.data
});

const HomeHeadline = {
    getHomeHeadline,
    save
};

export default HomeHeadline;