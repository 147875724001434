import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect, useState } from 'react'
import { Accordion, Button, Col, Form, FormCheck, FormGroup, Image, Row } from 'react-bootstrap'
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import BodyService from '../../services/body.service';

function StampsForm({ onSubmit, languages, stamp, permissions, details }) {
	const { t, i18n } = useTranslation();

	const { register, handleSubmit, formState, reset } = useForm({
		mode: "all",
	});

	const [langData, setLangData] = useState();
	const [resetFlag, setResetFlag] = useState(false);
	const [formDisabled, setFormDisabled] = useState(true);

	const onSubmitForm = async (data) => {
		if (!formDisabled) {

			let sendData = {
				active: data.active,
				images: [],
				title: []
			}

			Object.values(langData).forEach(langData => {
				sendData.images.push({
					imageBase64: langData?.img?.imageBase64 ? langData?.img?.imageBase64 : langData?.img?.imageBase64 === null ? null : "",
					imageContentType: langData?.img?.imageContentType ? langData?.img?.imageContentType : langData?.img?.imageContentType === null ? null : "",
					language: {
						id: langData.language.id
					},
				})

				sendData.title.push({
					language: {
						id: langData.language.id
					},
					value: langData.title
				})
			})

			if (details && stamp?.id) {
				sendData.id = stamp?.id
			}

			try {
				const createResponse = await onSubmit(sendData)
				if (!details && !createResponse) {
					reset();
					setResetFlag(resetFlag ? false : true)
				}
			} catch (error) {
				console.error(error)
			}

			setTimeout(function () {
				window.scrollTo(0, 0);
			}, 0);
		} else {
			alert(t('stamps.form.warning'))
		}
	}

	const setImageToBase64 = async (file, iso) => {
		if (file) {
			const base = await BodyService.getBase64(file);
			const fileData = {};
			fileData.imageBase64 = base.split(',')[1];
			fileData.imageContentType = file.type;

			langInputUpdate(fileData, iso, "img")
		}
	}

	useEffect(() => {
		if (languages && !details) {
			let configLangObj = {};

			if (languages) {
				languages.forEach(lang => {
					configLangObj[lang.language.iso] = {
						img: null,
						language: lang.language,
						title: null
					}
				});
			}

			setLangData(configLangObj)
		}

		if (details && stamp && !langData && languages) {

			let configLangObj = {}
			languages.forEach(lang => {

				configLangObj[lang.language.iso] = {
					title: stamp?.title[lang.language.iso].value,
					img: {
						url: stamp?.image.find(nameObj => nameObj.language.iso === lang.language.iso).value,
						imageBase64: null,
						imageContentType: null
					},
					language: lang.language
				}
			});

			setLangData(configLangObj)
		}

	}, [languages, stamp, resetFlag])

	const langInputUpdate = (data, iso, target) => {
		setLangData({
			...langData, [iso]: {
				...langData[iso], [target]: data
			}
		});
	}

	const removeImg = (iso) => {
		setLangData({
			...langData, [iso]: {
				...langData[iso], "img": ""
			}
		});

		document.getElementById(`img-${iso}`).value = null
	}

	const getBase64Src = (image) => {
		if (!image?.imageBase64 || !image?.imageContentType) {
			return false;
		}
		return `data:${image.imageContentType};base64,${image.imageBase64}`;
	}

	useEffect(() => {
		reset(stamp);
	}, [details, stamp])

	const checkDisable = () => {
		let blocked = true
		if (langData) {
			Object.values(langData).forEach((data) => {
				if (data.img && data.title) {
					blocked = false
					if (formDisabled) {
						setFormDisabled(false)
					}
					return false
				}
			})

			if (blocked) {
				if (!formDisabled) {
					setFormDisabled(true)
				}
				return true
			}
		}
	}

	return (
		<Form onSubmit={handleSubmit(onSubmitForm)}>
			{((stamp && formDisabled ) || (formDisabled && !details)) && <small className='text-warning fw-bold d-block mb-3'>{t('stamps.form.warning')}</small>}
			{languages && languages?.map((lang, i) => (
				<Accordion key={i} className="mb-3">
					<Accordion.Item eventKey="">
						<Accordion.Header>
							<h6 className={"m-0 fw-bold text-primary"}>{lang?.language?.name}{((stamp && formDisabled ) || (formDisabled && !details)) && <span className="ms-1 text-warning fw-bold">*</span>}</h6>
						</Accordion.Header>
						<Accordion.Body>
							<Row className="mb-3">
								<Col md={4}>
									<FormGroup>
										<Form.Label>{t('stamps.form.name')}</Form.Label>
										<Form.Control type="text" value={langData?.[lang.language.iso].title || ''} onChange={e => langInputUpdate(e.target.value, lang.language.iso, "title")} />
									</FormGroup>
								</Col>
							</Row>
							<Row>
								<Col md={'auto'}>
									<Row>
										<Col md="auto" className={langData?.[lang.language.iso]?.img && "mb-3"}>
											<Form.Label>{t('stamps.form.icon')}</Form.Label>
											<Form.Control id={`img-${lang.language.iso}`} type="file"
												accept="image/png, image/jpeg"
												onChange={(e) => setImageToBase64(e.target.files[0], lang.language.iso)} />
										</Col>
										{(langData?.[lang.language.iso]?.img?.url || langData?.[lang.language.iso]?.img?.imageBase64) && <Col md="auto" className={`align-self-end ${langData?.[lang.language.iso]?.img && "mb-3"}`}>
											<Button variant="link" size="ls" onClick={() => removeImg(lang.language.iso)}
												className="border-primary">
												<FontAwesomeIcon icon="fas fa-trash" />
											</Button>
										</Col>}
										{(langData?.[lang.language.iso]?.img?.url || langData?.[lang.language.iso]?.img?.imageBase64) &&
											<>
												<Col className="col-12">
													<Image fluid thumbnail src={getBase64Src(langData?.[lang.language.iso]?.img) || langData?.[lang.language.iso]?.img?.url}
														alt="test" />
												</Col>
											</>
										}
									</Row>
								</Col>
							</Row>
						</Accordion.Body>
					</Accordion.Item>
				</Accordion>
			))}
			<FormCheck label={t('stamps.form.active')} className='align-self-end form-check-input-md' {...register("active")} />

			{details ? 
			<Button type="submit" className={"mt-3"} disabled={ permissions.stamps?.update === false ? true : checkDisable() }>
				{t('stamps.update.button')}
			</Button>
			: 
			<Button type="submit" className={"mt-3"} disabled={ permissions.stamps?.create === false ? true : checkDisable() }>
				{t('stamps.form.submit')}
			</Button>
			}
		</Form>

	)
}

export default StampsForm