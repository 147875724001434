import { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import Select from "react-select";

import TagService from "../../../services/tag.service";

const ArticleTagForm = ({ onChange, tags }) => {

    const { i18n } = useTranslation();

    const [tagList, setTagList] = useState([]);
    const [selectedTags, setSelectedTags] = useState([]);

    const getTags = async () => {
        try {
            const data = await TagService.getAllList();
            setTagList(data);
        } catch ({ response }) {
            console.error(response);
        }
    }

    const setData = (tags) => {
        setSelectedTags(tags);
        onChange(tags);
    }

    useEffect(() => {
        if (tags) {
            setSelectedTags(tags);
        }
    }, [tags])

    useEffect(() => {
        getTags();
    }, []);

    return (
        <Row>
            <Col md="6" lg="4">
                <Select options={tagList}
                    isMulti
                    classNamePrefix="select"
                    closeMenuOnSelect={false}
                    placeholder=""
                    value={selectedTags}
                    getOptionLabel={(option) => option.name[i18n.language.split('-')[0]].value}
                    getOptionValue={(option) => option.id}
                    onChange={setData}
                />
            </Col>
        </Row>
    );
}

export default ArticleTagForm;