import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { t } from "i18next";
import { Fragment, useEffect, useState } from "react";
import { Button, Col, Form, FormCheck, FormGroup, Image, Row } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { v4 as uuidv4 } from "uuid";
import _ from "lodash";

import BodyService from "../../services/body.service";
import TranslationInput from "./fields/TranslationInput";
import TranslationDescriptionInput from "./fields/TranslationDescriptionInput";

const CategoryForm = ({ onSubmit, details, category, translations, slugs, baseTitleTranslations, baseDescriptionTranslations, permissions }) => {

    const [nameTranslations, setNameTranslations] = useState([]);
    const [slugTranslations, setSlugTranslations] = useState([]);
    const [titleTranslations, setTitleTranslations] = useState([]);
    const [descriptionTranslations, setDescriptionTranslations] = useState([]);
    const [emptyTranslations, setEmptyTranslations] = useState([]);
    const [logo, setLogo] = useState({});
    const [marker, setMarker] = useState({});
    const [complexMarker, setComplexMarker] = useState({});
    const [isSlugEmpty, setIsSlugEmpty] = useState();

    const [imageList, setImageList] = useState([]);

    const { register, handleSubmit, formState, reset } = useForm({
        mode: "all",
        defaultValues: {
            active: !details,
        },
    });

    const setLogoImage = async (file) => {
        if (file) {
            const base = await BodyService.getBase64(file);
            const copyImage = {};
            copyImage.imageBase64 = base.split(',')[1];
            copyImage.imageContentType = file.type;
            setLogo(copyImage);
        }
    }
    const removeLogoImage = () => {
        setLogo({
            imageBase64: ""
        })
    }

    const setMarkerImage = async (file) => {
        if (file) {
            const base = await BodyService.getBase64(file);
            const copyImage = {};
            copyImage.imageBase64 = base.split(',')[1];
            copyImage.imageContentType = file.type;
            setMarker(copyImage);
        }
    }
    const removeMarkerImage = () => {
        setMarker({
            imageBase64: ""
        })
    }

    const setComplexMarkerImage = async (file) => {
        if (file) {
            const base = await BodyService.getBase64(file);
            const copyImage = {};
            copyImage.imageBase64 = base.split(',')[1];
            copyImage.imageContentType = file.type;
            setComplexMarker(copyImage);
        }
    }
    const removeComplexMarkerImage = () => {
        setComplexMarker({
            imageBase64: ""
        })
    }

    const setImageToBase64 = async (file, index) => {
        if (file) {
            const base = await BodyService.getBase64(file);
            const copyImageList = [...imageList];
            const copyImage = { ...copyImageList[index] };
            copyImage.imageBase64 = base.split(',')[1];
            copyImage.imageName = file.name;
            copyImage.imageContentType = file.type;
            copyImageList[index] = copyImage;
            setImageList(copyImageList);
        }
    }

    const removeImage = (index) => {
        const copyImageList = [...imageList];
        copyImageList.splice(index, 1);
        setImageList(copyImageList);

    }

    const getBase64Src = (image) => {
        if (!image?.imageBase64 || !image?.imageContentType) {
            return false;
        }
        return `data:${image.imageContentType};base64,${image.imageBase64}`;
    }

    const addImage = () => {
        setImageList([...imageList, {
            uuid: uuidv4(),
            title1: _.cloneDeep(emptyTranslations),
            title2: _.cloneDeep(emptyTranslations)
        }]);
    }

    const setTitleTranslationValue = (index, value) => {

        setTitleTranslations(titleTranslations.map((titleTranslation, i) => {
            if (i === index) {
                titleTranslation.value = value;
            }
            return titleTranslation;
        }));
    }

    const setDescriptionTranslationValue = (index, value) => {

        setDescriptionTranslations(descriptionTranslations.map((descriptionTranslation, i) => {
            if (i === index) {
                descriptionTranslation.value = value;
            }
            return descriptionTranslation;
        }));
    }

    const setNameTranslationValue = (index, value) => {

        setNameTranslations(nameTranslations.map((nameTranslation, i) => {
            if (i === index) {
                nameTranslation.value = value;
            }
            return nameTranslation;
        }));
    }

    const setSlugTranslationValue = (index, value) => {

        setSlugTranslations(slugTranslations.map((slugTranslation, i) => {
            if (i === index) {
                slugTranslation.value = BodyService.slugifyStr(value);
            }
            return slugTranslation;
        }));
    }

    const setTitle1TranslationValue = (languageIndex, value, index) => {

        const copyImageList = [...imageList];

        copyImageList[index].title1 = imageList[index].title1.map((title1Translation, i) => {
            if (i === languageIndex) {
                title1Translation.value = value;
            }
            return title1Translation;
        });

        setImageList([...copyImageList]);
    }

    const setTitle2TranslationValue = (languageIndex, value, index) => {
        const copyImageList = [...imageList];

        copyImageList[index].title2 = imageList[index].title2.map((title2Translation, i) => {
            if (i === languageIndex) {
                title2Translation.value = value;
            }
            return title2Translation;
        });

        setImageList([...copyImageList]);
    }

    const onSubmitForm = async (data) => {

        data.name = nameTranslations;
        data.slug = slugTranslations;
        data.title = titleTranslations;
        data.description = descriptionTranslations;
        data.images = imageList;
        if (logo) {
            data.logoBase64 = logo.imageBase64
            data.logoContentType = logo.imageContentType
        }
        if (marker) {
            data.markerBase64 = marker.imageBase64
            data.markerContentType = marker.imageContentType
        }

        if (complexMarker) {
            data.multiMarkerBase64 = complexMarker.imageBase64
            data.multiMarkerContentType = complexMarker.imageContentType
        }

        try {
            await onSubmit(data);
            if (!details) {
                const empty = await getImageEmptyTranslations();
                setNameTranslations(_.cloneDeep(empty))
                setSlugTranslations(_.cloneDeep(empty))
                setTitleTranslations(_.cloneDeep(empty))
                setDescriptionTranslations(_.cloneDeep(empty))
                setImageList([{
                    uuid: uuidv4(),
                    title1: _.cloneDeep(empty),
                    title2: _.cloneDeep(empty),
                }]);
                setLogo({})
                setMarker({})
                setComplexMarker({})
                reset();
            }
        } catch (error) {
            console.error(error)
        }

        setTimeout(function () {
            window.scrollTo(0, 0);
        }, 0);
    }

    const getImageEmptyTranslations = async () => {
        const data = await BodyService.getEmptyTranslations();
        setEmptyTranslations(data);

        return data;
    }

    const isImageListValid = () => {
        return !imageList?.some((image) => {
            return !image.imageBase64 && !image.imageUrl;
        });
    }

    useEffect(() => {
        if (details && category !== {}) {
            setNameTranslations(translations);
            setTitleTranslations(baseTitleTranslations);
            setDescriptionTranslations(baseDescriptionTranslations);
            setSlugTranslations(slugs);
            let slugDisable = {};

            slugs?.forEach((slug) => {
                slugDisable[slug?.language?.iso] = slug?.value
            })

            setIsSlugEmpty(slugDisable)
        }
    }, [translations, baseTitleTranslations, baseDescriptionTranslations, category, details, slugs]);

    useEffect(() => {
        const init = async () => {
            const empty = await getImageEmptyTranslations();
            if (details) {
                reset(category);
                setNameTranslations(category?.name)
                setTitleTranslations(category?.title)
                setDescriptionTranslations(category?.description)
                setImageList(category?.images || []);
                let detailLogo = {
                    imageUrl: category?.logoUrl
                }

                setLogo(detailLogo)
                let detailMarker = {
                    imageUrl: category?.markerUrl
                }
                setMarker(detailMarker)

                let detailComplexMarker = {
                    imageUrl: category?.multiMarkerUrl
                }
                setComplexMarker(detailComplexMarker)
            } else {
                setNameTranslations(_.cloneDeep(empty))
                setSlugTranslations(_.cloneDeep(empty))
                setTitleTranslations(_.cloneDeep(empty))
                setDescriptionTranslations(_.cloneDeep(empty))
                setImageList([{
                    uuid: uuidv4(),
                    title1: _.cloneDeep(empty),
                    title2: _.cloneDeep(empty),
                }]);
                setLogo({});
                setMarker({});
                setComplexMarker({});
            }
        }

        init();
    }, [category, details, reset]);

    return (
        <Form onSubmit={handleSubmit(onSubmitForm)}>
            <Row>
                <Col xs={16}>
                    <h5>{t('categories.creation.fields.name')}</h5>
                    {nameTranslations?.length > 0 ?
                        <Row className="mt-3">
                            <TranslationInput translations={nameTranslations} refer="name" onChange={setNameTranslationValue} />
                        </Row>
                        :
                        <></>
                    }
                </Col>
            </Row>    
            <Row>    
                <Col sx={16} className="mt-3">
                    <h5>{t('categories.creation.fields.slug')}</h5>
                    {slugTranslations?.length > 0 ?
                        <Row className="mt-3">
                            <TranslationInput translations={slugTranslations} refer="slug" onChange={setSlugTranslationValue} disable={isSlugEmpty} />
                        </Row>
                        :
                        <></>
                    }
                </Col>
            </Row>    
            <Row>
                <Col sx={12} className="mt-3">
                    <h5>{t('categories.creation.fields.title')}</h5>
                    {titleTranslations?.length > 0 ?
                        <Row className="mt-3">
                            <TranslationDescriptionInput translations={titleTranslations} refer="title" onChange={setTitleTranslationValue} />
                        </Row>
                        :
                        <></>
                    }
                </Col>
            </Row>    
            <Row>
                <Col sx={3} className="mt-3">
                    <h5>{t('categories.creation.fields.description')}</h5>
                    {descriptionTranslations?.length > 0 ?
                        <Row className="mt-3">
                            <TranslationDescriptionInput translations={descriptionTranslations} refer="description" onChange={setDescriptionTranslationValue} />
                        </Row>
                        :
                        <></>
                    }
                </Col>
            </Row>    
            <Row>
                <Row className="mt-3">
                    <Col md={2}>
                        <h5>{t('categories.creation.fields.order')}</h5>
                        <Form.Control type="number" {...register('order')} />
                    </Col>
                    <Col xs={'auto'} className="mt-md-3 d-flex align-items-end">
                        <FormGroup>
                            <FormCheck label={t('categories.creation.fields.active')} {...register("active")} className="form-check-input-md" />
                        </FormGroup>
                    </Col>
                </Row>
                <Col xs="12" className="mt-md-3">
                    <h5>{t('categories.creation.fields.logo')}</h5>
                    <Row>
                        <Col md="auto">
                            <Form.Control type="file"
                                accept="image/png, image/jpeg"
                                onChange={(e) => setLogoImage(e.target.files[0])} />
                        </Col>
                        <Col md="auto">
                            <Button variant="link" size="ls" onClick={() => removeLogoImage()}
                                className="border-primary">
                                <FontAwesomeIcon icon="fas fa-trash" />
                            </Button>
                        </Col>
                        {(logo?.imageUrl || logo?.imageBase64) &&
                            <>
                                <Col className="col-8 col-md-6 col-lg-2">
                                    <Image fluid thumbnail src={getBase64Src(logo) || logo.imageUrl}
                                        alt={category?.name} />
                                </Col>
                            </>
                        }
                    </Row>
                </Col>
                <Col xs="12" className="mt-md-3">
                    <h5>{t('categories.creation.fields.marker')}</h5>
                    <Row>
                        <Col md="auto">
                            <Form.Control type="file"
                                accept="image/png, image/jpeg"
                                onChange={(e) => setMarkerImage(e.target.files[0])} />
                        </Col>
                        <Col md="auto">
                            <Button variant="link" size="ls" onClick={() => removeMarkerImage()}
                                className="border-primary">
                                <FontAwesomeIcon icon="fas fa-trash" />
                            </Button>
                        </Col>
                        {(marker?.imageUrl || marker?.imageBase64) &&
                            <>
                                <Col className="col-8 col-md-6 col-lg-2">
                                    <Image fluid thumbnail src={getBase64Src(marker) || marker.imageUrl}
                                        alt={category?.name} />
                                </Col>
                            </>
                        }
                    </Row>
                </Col>

                <Col xs="12" className="mt-md-3">
                    <h5>{t('categories.creation.fields.complexMarker')}</h5>
                    <Row>
                        <Col md="auto">
                            <Form.Control type="file"
                                accept="image/png, image/jpeg"
                                onChange={(e) => setComplexMarkerImage(e.target.files[0])} />
                        </Col>
                        <Col md="auto">
                            <Button variant="link" size="ls" onClick={() => removeComplexMarkerImage()}
                                className="border-primary">
                                <FontAwesomeIcon icon="fas fa-trash" />
                            </Button>
                        </Col>
                        {(complexMarker?.imageUrl || complexMarker?.imageBase64) &&
                            <>
                                <Col className="col-8 col-md-6 col-lg-2">
                                    <Image fluid thumbnail src={getBase64Src(complexMarker) || complexMarker.imageUrl}
                                        alt={category?.name} />
                                </Col>
                            </>
                        }
                    </Row>
                </Col>

            </Row>

            <h4 className="mt-5">
                {t('categories.creation.fields.images')}
            </h4>

            {imageList?.map((image, index) => (
                <Fragment key={image.uuid || image.id}>

                    <hr />

                    <h5>
                        {t('categories.creation.fields.title1')}
                    </h5>
                    <Row className="my-3">
                        <TranslationInput translations={image.title1} imgTitleIndex={index}
                            onChange={(languageIndex, value) => setTitle1TranslationValue(languageIndex, value, index)} />
                    </Row>
                    <h5>
                        {t('categories.creation.fields.title2')}
                    </h5>
                    <Row className="mt-2">
                        <TranslationInput translations={image.title2} imgTitleIndex={index}
                            onChange={(languageIndex, value) => setTitle2TranslationValue(languageIndex, value, index)} />
                    </Row>

                    <Row className="my-3">
                        {(image?.imageUrl || image?.imageBase64) &&
                            <>
                                <Col className="col-8 col-md-6 col-lg-2">
                                    <Image fluid thumbnail src={getBase64Src(image) || image.imageUrl}
                                        alt={category?.name} />
                                </Col>
                            </>
                        }
                    </Row>
                    <Row>
                        <Col md="auto">
                            <Form.Control type="file"
                                accept="image/png, image/jpeg"
                                onChange={(e) => setImageToBase64(e.target.files[0], index)} />
                        </Col>
                        <Col md="auto">
                            <Button variant="link" size="ls" onClick={() => removeImage(index)}
                                className="border-primary">
                                <FontAwesomeIcon icon="fas fa-trash" />
                            </Button>
                        </Col>
                    </Row>
                    {(index === imageList.length - 1 && (image?.imageUrl || image?.imageBase64)) &&
                        <Row>
                            <Col md="auto">
                                <FormGroup onClick={addImage} className="mt-3">
                                    <Form.Label
                                        style={{ cursor: "pointer" }}>{t('general.addImage')}</Form.Label>
                                    <Button variant="link" size="ls" className={"ms-2 border-primary"}>
                                        <FontAwesomeIcon icon="fas fa-plus" />
                                    </Button>
                                </FormGroup>
                            </Col>
                        </Row>
                    }
                </Fragment>
            ))}

            {imageList.length === 0 &&
                <Row>
                    <Col md="auto">
                        <FormGroup onClick={addImage}>
                            <Form.Label
                                style={{ cursor: "pointer" }}>{t('general.addImage')}</Form.Label>
                            <Button variant="link" size="lg" className={"ms-2"}>
                                <FontAwesomeIcon icon="fa-solid fa-square-plus" />
                            </Button>
                        </FormGroup>
                    </Col>
                </Row>
            }

            {details ? 
            <Button type="submit" className={"mt-4"} disabled={ permissions.categories?.update === false ? true : (!formState.isValid || !isImageListValid()) }>
                <FontAwesomeIcon icon={"save"} />
            </Button>
            : 
            <Button type="submit" className={"mt-4"} disabled={ permissions.categories?.create === false ? true : (!formState.isValid || !isImageListValid()) }>
                {t('categories.creation.button')}
            </Button>
            }

        </Form>
    );
}

export default CategoryForm;
