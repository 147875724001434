import api from './api';

const getAll = (pagination, filter, sortColumn) => api.get('/charging-point/point-type', {
    pageNum: pagination.currentPage - 1, 
    pageLength: pagination.itemsPerPage, 
    active: filter.active,
    search: filter.search,
    sortBy: sortColumn.sortByColumn,
    orderBy: sortColumn.orderBy
}).then(response => response.data);

const create = (data) => api.post('/charging-point/point-type', data).then(response => {
    return response.data
});

const getList = () => api.get('/charging-point/point-type/list').then(response => {
    return response.data
});

const getById = (id) => api.get(`/charging-point/point-type/${id}`).then(response => {
    return response.data
});

const remove = (id) => api.delete(`/charging-point/point-type/${id}`).then(response => {
    return response.data
});

const update = (id, data) => api.put(`/charging-point/point-type/${id}`, data).then(response => {
    return response.data
});

const PointTypeService = {
    getAll,
    getList,
    getById,
    create,
    update,
    remove
}

export default PointTypeService;