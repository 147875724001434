import api from './api';

const getAll = (pagination, filter, sortColumn) => api.get('/tag', {
    pageNum: pagination.currentPage - 1, 
    pageLength: pagination.itemsPerPage, 
    active: filter.active,
    search: filter.search,
    sortBy: sortColumn.sortByColumn,
    orderBy: sortColumn.orderBy
}).then(response => response.data);

const getAllList = () => api.get('/tag/list').then(response => response.data);

const getById = (id) => api.get(`/tag/${id}`).then(response => response.data);

const create = (data) => api.post('/tag', data).then(response => response.data);

const update = (id, data) => api.put(`/tag/${id}`, data).then(response => response.data);

const remove = (id) => api.delete(`/tag/${id}`).then(response => response.data);

const TagService = {
    getAll,
    getAllList,
    getById,
    create,
    update,
    remove
};

export default TagService;