import { t } from "i18next";
import { useState } from "react";
import { Button, Col, Form, FormCheck, FormGroup, Row } from "react-bootstrap"
import TranslationInputLanguage from "../fields/TranslationInputLanguage";
import { useForm } from "react-hook-form";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect } from "react";

const DayOfTheWeekForm = ({ onSubmit, details, translations, dayOfTheWeek, permissions }) => {

    const [dayOfTheWeekNameValues, setDayOfTheWeekNameValues] = useState();

    const [translationValues, setTranslationValues] = useState();

    const { register, handleSubmit, formState, reset } = useForm({
        mode: "all",
        defaultValues: {
            active: !details,
        },
    });

    const setDayOfTheWeekNameValuesForm = (iso, value, id) => {
        setTranslationValues({
            ...translationValues, [iso]: { value: value, langId: id, iso: iso }
        })
    };

    const onSubmitForm = async (data) => {
        let objSend = {
            name:[],
            active: data.active
        }

        
        Object.values(translationValues).forEach((name) => {
            objSend.name.push({
                language: {
                    id: name.langId
                },
                value: name.value
            })
        })

        try {
            await onSubmit(objSend);
            if (!details) {
                setTranslationValues(null);
                reset();
            }
        } catch (error) {
            console.error(error)
        }

        setTimeout(function () {
            window.scrollTo(0, 0);
        }, 0);
    }

    useEffect(() => {
        setDayOfTheWeekNameValues(translations);

        if (details && dayOfTheWeek) {
                       
            let dayOfTheWeekLang = {}

            Object.keys(dayOfTheWeek.dayOfTheWeek).forEach((data) => {
                dayOfTheWeekLang[data] = {
                    value: dayOfTheWeek.dayOfTheWeek[data].value,
                    langId: dayOfTheWeek.dayOfTheWeek[data].language.id,
                    iso: dayOfTheWeek.dayOfTheWeek[data].language.iso,
                }});
            
            setTranslationValues(dayOfTheWeekLang);
        }

    }, [translations,dayOfTheWeek,details])

    useEffect(() => {
        if (details) {
            reset(dayOfTheWeek);
        }
    }, [dayOfTheWeek, details, reset]);

    return (
        <>
            <Form onSubmit={handleSubmit(onSubmitForm)}>
                <Row>
                    <Col xs={12}>
                        <h5>{t('dayOfTheWeek.creation.fields.name')}</h5>
                        {translations?.length > 0 ?
                            <Row className="mt-3">
                                <TranslationInputLanguage translations={dayOfTheWeekNameValues} data={translationValues} onChange={setDayOfTheWeekNameValuesForm} />
                            </Row>
                            : <></>
                        }
                    </Col>
                </Row>
                <Row>
                    <Col xs="12" className="mt-md-3">
                        <FormGroup>
                            <FormCheck label={t('dayOfTheWeek.creation.fields.active')} {...register("active")} />
                        </FormGroup>
                    </Col>
                </Row>
                {details ? 
                <Button type="submit" className={"mt-4"} disabled={ permissions.weekDays?.update === false ? true : !formState.isValid }>
                        <FontAwesomeIcon icon={"save"} />
                </Button>
                :
                <Button type="submit" className={"mt-4"} disabled={ permissions.weekDays?.create === false ? true : !formState.isValid }>
                        <FontAwesomeIcon icon={"save"} /> {t('dayOfTheWeek.creation.button')}
                </Button>
                }

            </Form>
        </>
    );
}
export default DayOfTheWeekForm;