import { useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import ChargingPointService from "../../../services/chargingPoint.service";
import { useCallback } from "react";
import { Alert, Card } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect } from "react";
import BodyService from "../../../services/body.service";
import DayOfTheWeekService from "../../../services/dayOfTheWeek.service";
import ChargerTypeService from "../../../services/chargerTypes.service";
import PointTypeService from "../../../services/pointTypes.service";
import UbicationTypeService from "../../../services/ubicationType.service";
import ProjectService from "../../../services/project.service";
import ProvinceService from "../../../services/province.service";
import RegionService from "../../../services/region.service";
import ChargingPointForm from "../../../components/forms/ChargingPointForm";
import UserPermissionService from "../../../services/userPermission.service";

const ChargingPointDetails = () => {
    
    const params = useParams();

    const navigate = useNavigate();

    const { t } = useTranslation();

    const [chargingPoint, setChargingPoint] = useState();

    const [translations, setTranslations] = useState([]);

    const [title, setTitle] = useState("");

    const [daysOfTheWeek, setDaysOfTheWeek] = useState([]);

    const [chargers, setChargers] = useState([]);

    const [pointTypes, setPointTypes] = useState([]);

    const [ubicationTypes, setUbicationTypes] = useState([]);

    const [projects, setProjects] = useState([]);

    const [provinces, setProvinces] = useState([]);

    const [regions, setRegions] = useState([]);

    const [permissions, setPermissions] = useState({});

    const [success, setSuccess] = useState({
        update: false,
        email: false,
    });

    const [errors, setErrors] = useState({
        update: false,
    });

    const updateChargingPoint = async (data) => {
        setErrors({ ...errors, update: false });
        setSuccess({ ...success, update: false });

        try {
            await ChargingPointService.update(params.id, data);
            getChargingPoint(params.id);
            setSuccess({ ...success, update: true });
        } catch (e) {
            setErrors({ ...errors, update: true });
            throw e;
        }
    }

    const getChargingPoint = useCallback(async (id) => {
        try {
            const data = await ChargingPointService.getById(id);
            setChargingPoint(data);
            setTitle(data.name["es"].value);
        } catch (error) {
            navigate("/charging-point");
        }
    }, [navigate]);

    const getEmptyTranslations = async () => {
        const data = await BodyService.getEmptyTranslations();
        setTranslations(data);
    }

    const getDaysOfTheWeek = async () => {
        const filter = {
            active: true
        }
        const data = await DayOfTheWeekService.getDaysByActive(filter);

        setDaysOfTheWeek(data);
    }

    const getChargerTypes = async () => {
        const data = await ChargerTypeService.getList();
        setChargers(data);
    }

    const getPointTypes = async () => {
        const data = await PointTypeService.getList();
        setPointTypes(data);
    }

    const getUbicationTypes = async () => {
        const data = await UbicationTypeService.getList();
        setUbicationTypes(data);
    }

    const getProjects = async () => {
        const data = await ProjectService.getAllOrderByTitle("es");
        const emptyProject = {
            id:null,
            title:[{value:null}]
        }
        setProjects([emptyProject,...data]);
    }

    const getProvinces = async () => {
        const data = await ProvinceService.getList();
        setProvinces(data);
    }

    const getRegions = async () => {
        const data = await RegionService.getList();
        setRegions(data);
    }

    const getPermissions = async () => {
        try {
            const data = await UserPermissionService.getPermissionsByConnected();
            setPermissions(data);
        } catch (error) {
            console.error(error);
        }
    }

    useEffect(() => {
        getPermissions();
    }, []);

    useEffect(() => {
        getChargingPoint(params.id);
        getEmptyTranslations();
        getDaysOfTheWeek();
        getChargerTypes();
        getPointTypes();
        getUbicationTypes();
        getProjects();
        getProvinces();
    }, [getChargingPoint, params.id]);

    return (
        <>
            <div className={"d-flex justify-content-between my-3"}>
                <Link to="/charging-point" className={"previous round rounded-circle text-decoration-none"}
                    title={t('general.back')}>
                    <FontAwesomeIcon icon={"chevron-left"} className={"mt-2 me-2"} />
                    <span>{t('chargingPoint.title')}</span>
                </Link>
            </div>
            <Alert variant={"success"} show={success.update} dismissible onClose={() => setSuccess({ ...success, update: false })}>
                {t('chargingPoint.success.update')}
            </Alert>
            <Alert variant={"danger"} show={errors.update} dismissible onClose={() => setErrors({ ...errors, update: false })}>
                {t('chargingPoint.error.update')}
            </Alert>
            <Card>
                <Card.Header className={"py-3"}>
                    <h1 className={"h3 mb-0 text-gray-800 card-title text-capitalize"}>{title}</h1>
                </Card.Header>
                <Card.Body>
                    <ChargingPointForm details onSubmit={updateChargingPoint} name={translations} daysOfTheWeek={daysOfTheWeek} chargers={chargers} pointTypes={pointTypes} ubicationTypes={ubicationTypes} projects={projects} provinces={provinces} regions={regions} chargingPoint={chargingPoint} permissions={permissions}/>
                </Card.Body>
            </Card>
        </>
    );
}

export default ChargingPointDetails;