import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

const TableHeaderSortComponent = ({text, scope, column, sortColumn, setSortColumn}) => {

    const changeSortColumn = () => {

        if (sortColumn.sortByColumn !== column) {
            setSortColumn({...sortColumn, sortByColumn: column, orderBy: "asc"})
        } else {
            let newOrderByValue;
            if (sortColumn.orderBy === "asc") {
                newOrderByValue = "desc";
            } else if (sortColumn.orderBy === null || sortColumn.orderBy === undefined) {
                newOrderByValue = "asc";
            }
            setSortColumn({...sortColumn, sortByColumn: column, orderBy: newOrderByValue})
        }
    }

    const getIcon = (sortColumn) => {
        if (sortColumn.sortByColumn === column && sortColumn.orderBy === "asc") {
            return "fa-sort-up"
        } else if (sortColumn.sortByColumn === column && sortColumn.orderBy === "desc") {
            return "fa-sort-down"
        }
        return "fa-sort"
    }

    return (
        <th scope={scope} onClick={changeSortColumn}>{text}&nbsp;
            <FontAwesomeIcon icon={getIcon(sortColumn)}/>
        </th>
    );
}

export default TableHeaderSortComponent;