import { Card, Alert } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import APP_CONF from "../../../scripts/constants";
import BodyService from "../../../services/body.service";
import CategoryService from "../../../services/category.service";
import ProjectService from "../../../services/project.service";
import ArticleService from "../../../services/article.service";
import AuthorService from "../../../services/author.service";

import ArticleForm from "../../../components/forms/article/ArticleForm";
import StampService from "../../../services/stamps.service";
import ProvinceService from "../../../services/province.service";
import UserPermissionService from "../../../services/userPermission.service";

const ArticleDetails = () => {

    const { t } = useTranslation();

    const params = useParams();

    const [languages, setLanguages] = useState();
    const [categories, setCategories] = useState();
    const [projects, setProjects] = useState();
    const [stamps, setStamps] = useState();
    const [article, setArticle] = useState();
    const [author, setAuthor] = useState();
    const [provinces, setProvinces] = useState();
    const [permissions, setPermissions] = useState({});

    const [pagination] = useState({
        currentPage: 1,
        totalItems: 0,
        itemsPerPage: APP_CONF.ITEMS_PER_PAGE,
        numPages: 1
    });

    const [filter] = useState({
        active: ""
    })

    const [success, setSuccess] = useState({
        update: false,
    });

    const [errors, setErrors] = useState({
        update: false,
        config: false,
        slug: false,
        usedSlug: false,
    });

    const getPermissions = async () => {
        try {
            const data = await UserPermissionService.getPermissionsByConnected();
            setPermissions(data);
        } catch (error) {
            console.error(error);
        }
    }

    const getEmptyTranslations = async () => {
        const data = await BodyService.getEmptyTranslations();
        setLanguages(data);
    }

    const getCategories = async () => {
        try {
            const data = await CategoryService.selectAll();
            setCategories(data);
        } catch ({ response }) {
            console.error(response);
        }
    }

    const getAuthor = async () => {
        try {
            const data = await AuthorService.getList();
            setAuthor(data);
        } catch ({ response }) {
            console.error(response);
        }
    }

    const getProjects = async () => {
        try {
            const data = await ProjectService.getAllOrderByTitle("es");
            setProjects(data);
        } catch ({ response }) {
            console.error(response);
        }
    }

    const getArticle = async () => {
        try {
            const data = await ArticleService.getById(params.id)
            setArticle(data)
        } catch ({ response }) {
            console.error(response);
        }
    }

    const getProvinces = async () => {
        try {
            const data = await ProvinceService.getList();
            setProvinces(data);
        } catch ({ response }) {
            console.error(response);
        }
    }

    const updateArticle = async (data) => {
        setErrors({ ...errors, update: false, delete: false, config: false, slug: false, usedSlug: false, });
        setSuccess({ ...success, update: false });

        try {
            await ArticleService.update(params.id, data);
            setSuccess({ ...success, update: true });
            getArticle();
        } catch (e) {
            if (e?.response?.data?.errorCode === 15002) {
                setErrors({ ...errors, update: false, delete: false, config: false, slug: true, usedSlug: false, });
            } else if (e?.response?.data?.errorCode === 15001) {
                setErrors({ ...errors, update: false, delete: false, config: false, slug: false, usedSlug: true });
            } else {
                setErrors({ ...errors, update: true, delete: false, config: false, slug: false, usedSlug: false, });
            }
            return e.response
        }
    }

    const getStamps = async () => {
        try {
            const data = await StampService.lazy();
            setStamps(data);
        } catch ({ response }) {
            console.error(response);
        }
    }

    useEffect(() => {
        const loadData = async () => {
            await getPermissions();
            await getArticle();
            await getCategories();
            await getAuthor(); 
            await getProjects();
            await getStamps();
            await getProvinces();
            await getEmptyTranslations();
        }

        loadData();
    }, []);

    return (
        <>
            <div className={"d-flex justify-content-between my-3"}>
                <Link to="/article" className={"previous round rounded-circle text-decoration-none"}
                    title={t('general.back')}>
                    <FontAwesomeIcon icon={"chevron-left"} className={"mt-2 me-2"} />
                    <span>{t('articles.title')}</span>
                </Link>
            </div>
            <Alert variant={"success"} show={success.update} dismissible onClose={() => setSuccess({ ...success, update: false })}>
                {t('articles.success.update')}
            </Alert>
            <Alert variant={"danger"} show={errors.update} dismissible onClose={() => setErrors({ ...errors, update: false })}>
                {t('articles.error.update')}
            </Alert>
            <Alert variant={"danger"} show={errors.slug} dismissible onClose={() => setErrors({ ...errors, slug: false })}>
                {t('articles.error.emptySlug')}
            </Alert>

            <Alert variant={"danger"} show={errors.usedSlug} dismissible onClose={() => setErrors({ ...errors, usedSlug: false })}>
                {t('articles.error.alreadyUsedSlug')}
            </Alert>
            <Card className={"shadow mb-4"}>
                <Card.Header className={"py-3"}>
                    <h6 className={"m-0 fw-bold text-primary"}>{t('articles.update.title')}</h6>
                </Card.Header>
                <Card.Body>
                    <ArticleForm article={article} categories={categories} projects={projects} stamps={stamps} languages={languages} authors={author} provinces={provinces} permissions={permissions} onSubmit={updateArticle} detail />
                </Card.Body>
            </Card>
        </>
    )
}

export default ArticleDetails