import {Button, Modal} from "react-bootstrap";
import {useContext} from "react";

import {AlertConfirmContext} from "../context/AlertConfirmContext";

const AlertConfirm = () => {

    const {state: {
        show, 
        title, 
        body, 
        onConfirm, 
        confirmButtonContent, 
        confirmButtonVariant, 
        confirmButtonIsDark,
        dismissButtonContent, 
        dismissButtonVariant,
        dismissButtonIsDark,
        onDismiss
    }, update} = useContext(AlertConfirmContext); 

    const handleClose = () => {
        update({
            show: false,
            title: '',
            body: '',
            onConfirm: () => {},
            onDismiss: () => {}
        });
    }

    const handleConfirm = () => {
        onConfirm();
        handleClose();
    }

    const handleDismiss = () => {
        onDismiss();
        handleClose();
    }

    return (
        <Modal show={show} onHide={handleClose} backdrop="static" keyboard={false}>
            <Modal.Header closeButton onHide={handleDismiss}>
                <Modal.Title>
                    {title}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {body}
            </Modal.Body>
            <Modal.Footer>
                <Button variant={dismissButtonVariant} className={dismissButtonIsDark ? "text-white" : ""} onClick={handleDismiss}>
                    {dismissButtonContent}
                </Button>
                <Button variant={confirmButtonVariant} className={confirmButtonIsDark ? "text-white" : ""} onClick={handleConfirm}>
                    {confirmButtonContent}
                </Button>
            </Modal.Footer>
        </Modal>
    );
}

export default AlertConfirm;