import { Accordion, Alert, Button, Card, Col, Form, FormGroup, Image, Row, Table } from "react-bootstrap";
import ChargingPointForm from "../../components/forms/ChargingPointForm";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import BodyService from "../../services/body.service";
import { useEffect } from "react";
import DayOfTheWeekService from "../../services/dayOfTheWeek.service";
import ChargerTypeService from "../../services/chargerTypes.service";
import PointTypeService from "../../services/pointTypes.service";
import UbicationTypeService from "../../services/ubicationType.service";
import ProjectService from "../../services/project.service";
import ProvinceService from "../../services/province.service";
import RegionService from "../../services/region.service";
import ChargingPointService from "../../services/chargingPoint.service";
import UseAlertConfirm from "../../hooks/UseAlertConfirm";
import APP_CONF from "../../scripts/constants";
import { useForm } from "react-hook-form";
import UseFormatDate from "../../hooks/UseFormatDate";
import PaginationComponent from "../../components/PaginationComponent";
import TableHeaderSortComponent from "../../components/TableHeaderSortComponent";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import UserPermissionService from "../../services/userPermission.service";

const ChargingPoint = () => {

    const { t, i18n } = useTranslation();

    const language = i18n.language.split('-')[0];

    const [translations, setTranslations] = useState([]);

    const [daysOfTheWeek, setDaysOfTheWeek] = useState([]);

    const [chargers, setChargers] = useState([]);

    const [pointTypes, setPointTypes] = useState([]);

    const [ubicationTypes, setUbicationTypes] = useState([]);

    const [projects, setProjects] = useState([]);

    const [provinces, setProvinces] = useState([]);

    const [regions, setRegions] = useState([]);

    const [permissions, setPermissions] = useState({});

    const [success, setSuccess] = useState({
        create: false,
        delete: false,
    });

    const [errors, setErrors] = useState({
        create: false,
        delete: false,
    });

    const [sortColumn, setSortColumn] = useState({
        sortByColumn: null,
        orderBy: null
    });

    const [pagination, setPagination] = useState({
        currentPage: 1,
        totalItems: 0,
        itemsPerPage: APP_CONF.ITEMS_PER_PAGE,
        numPages: 1
    });

    const { register, handleSubmit } = useForm({
        mode: "all",
    });

    const [filter, setFilter] = useState({
        active: true,
        search: ""
    });

    const [chargingPoints, setChargingPoints] = useState([]);

    const { showModal } = UseAlertConfirm();

    const getEmptyTranslations = async () => {
        const data = await BodyService.getEmptyTranslations();
        setTranslations(data);
    }

    const getDaysOfTheWeek = async () => {
        const data = await DayOfTheWeekService.getDaysByActive({ active: true });
        setDaysOfTheWeek(data);
    }

    const getChargerTypes = async () => {
        const data = await ChargerTypeService.getList();
        setChargers(data);
    }

    const getPointTypes = async () => {
        const data = await PointTypeService.getList();
        setPointTypes(data);
    }

    const getUbicationTypes = async () => {
        const data = await UbicationTypeService.getList();
        setUbicationTypes(data);
    }

    const getProjects = async () => {
        const data = await ProjectService.getAllOrderByTitle("es");
        const emptyProject = {
            id: null,
            title: [{ value: null }]
        }
        setProjects([emptyProject, ...data]);
    }

    const getProvinces = async () => {
        const data = await ProvinceService.getList();
        setProvinces(data);
    }

    const getRegions = async () => {
        const data = await RegionService.getList();
        setRegions(data);
    }

    const createChargingPoint = async (data) => {
        setErrors({ ...errors, create: false });
        setSuccess({ ...success, create: false });
        try {
            await ChargingPointService.create(data);
            getEmptyTranslations();
            getChargingPoints();
            setSuccess({ ...success, create: true });
        } catch (error) {
            console.error(error.response);
            setErrors({ ...errors, create: true });
            throw error;
        }
    }

    const getPermissions = async () => {
        try {
            const data = await UserPermissionService.getPermissionsByConnected();
            setPermissions(data);
        } catch (error) {
            console.error(error);
        }
    }

    const filterSearch = (data) => {
        let obj = data;
        if (obj.search === "") {
            obj = {
                active: data.active
            }
        }
        setFilter(obj);
    }

    useEffect(() => {
        const loadData = async () => {
            await getPermissions();
            await getDaysOfTheWeek();
            await getChargerTypes();
            await getPointTypes();
            await getUbicationTypes();
            await getProjects();
            await getProvinces();
            await getRegions();
            await getEmptyTranslations();
        }

        loadData();
    }, []);

    useEffect(() => {
        getChargingPoints();
    }, [pagination.currentPage, filter, sortColumn]);

    const deleteChargingPoint = async (id) => {
        showModal({
            title: t("general.delete.title"),
            body: t("general.delete.message"),
            confirmButtonVariant: "danger",
            onConfirm: async () => {
                setErrors({ ...errors, create: false });
                setSuccess({ ...success, create: false });
                try {
                    await ChargingPointService.remove(id);
                    setSuccess({ ...success, delete: true });
                    getChargingPoints();
                } catch (e) {
                    console.error(e.response);
                    setErrors({ ...errors, delete: true });
                }
            }
        });
    }

    const getChargingPoints = async () => {
        try {
            let languageToSearch;

            const sortColumnsByLanguage = ['name', 'province', 'region', 'locality', 'pointType', 'ubicationType']

            if (sortColumnsByLanguage.includes(sortColumn.sortByColumn) &&
                (sortColumn.orderBy !== null || sortColumn.orderBy !== undefined)) {
                languageToSearch = language;
            }

            const data = await ChargingPointService.getAll(pagination, sortColumn, languageToSearch, filter);
            setChargingPoints(data.content);
            setPagination({
                ...pagination,
                totalItems: data.totalElements,
                numPages: data.totalPages
            });
        } catch ({ response }) {
            console.error(response);
        }
    }

    return (
        <>
            <div className={"d-sm-flex align-items-center justify-content-between mb-4"}>
                <h1 className={"h3 mb-0 text-gray-800"}>{t('chargingPoint.title')}</h1>
            </div>

            <Alert variant={"danger"} show={errors.create} dismissible onClose={() => setErrors({ ...errors, create: false })}>
                {t('chargingPoint.error.create')}
            </Alert>

            <Alert variant={"success"} show={success.create} dismissible onClose={() => setSuccess({ ...success, create: false })}>
                {t('chargingPoint.success.create')}
            </Alert>

            <Alert variant={"success"} show={success.delete} dismissible onClose={() => setSuccess({ ...success, delete: false })}>
                {t('chargingPoint.success.delete')}
            </Alert>

            <Alert variant={"danger"} show={errors.delete} dismissible onClose={() => setErrors({ ...errors, delete: false })}>
                {t('chargingPoint.error.delete')}
            </Alert>

            {permissions.chargingPoints?.read && permissions.chargingPoints?.create ? 
            <Card className={"shadow mb-4"}>
                <Accordion>
                    <Accordion.Item eventKey="0">
                        <Accordion.Header><h6 className={"m-0 fw-bold text-primary"}>{t('chargingPoint.creation.title')}</h6>
                        </Accordion.Header>
                        <Accordion.Body>
                            <ChargingPointForm onSubmit={createChargingPoint} name={translations} daysOfTheWeek={daysOfTheWeek} chargers={chargers} pointTypes={pointTypes} ubicationTypes={ubicationTypes} projects={projects} provinces={provinces} regions={regions} permissions={permissions}/>
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>
            </Card>
            
            : null }

            <Card className={"shadow mb-4"}>
                <Card.Header className={"py-3"}>
                    <h6 className={"m-0 fw-bold text-primary"}>{t('chargingPoint.list.title')}</h6>
                </Card.Header>
                <Card.Body>
                    <Form onSubmit={handleSubmit(filterSearch)}>
                        <Row>
                            <Col lg="2" className={"mb-3"}>
                                <FormGroup>
                                    <Form.Label>{t('categories.filter.active.title')}</Form.Label>
                                    <Form.Select {...register('active')} defaultValue={filter.active} aria-label="Default select example">
                                        <option value="">{t('categories.filter.active.value1')}</option>
                                        <option value={true}>{t('categories.filter.active.value2')}</option>
                                        <option value={false}>{t('categories.filter.active.value3')}</option>
                                    </Form.Select>
                                </FormGroup>
                            </Col>
                            <Col lg="3" className={"mb-3"}>
                                <FormGroup>
                                    <Form.Label>{t('users.filter.search')}</Form.Label>
                                    <Form.Control type="text" {...register("search")} />
                                </FormGroup>
                            </Col>
                        </Row>
                        <Button type="submit" className={"mt-3"} disabled={!permissions.chargingPoints?.read}>{t('categories.filter.button')}</Button>
                    </Form>
                    <hr />
                    <Table responsive>
                        <thead>
                            <tr>
                                <TableHeaderSortComponent scope={"col"} text={t('chargingPoint.list.fields.name')} column={"name"} sortColumn={sortColumn} setSortColumn={setSortColumn} />
                                <TableHeaderSortComponent scope={"col"} text={t('chargingPoint.list.fields.address')} column={"address"} sortColumn={sortColumn} setSortColumn={setSortColumn} />
                                <TableHeaderSortComponent scope={"col"} text={t('chargingPoint.list.fields.province')} column={"province"} sortColumn={sortColumn} setSortColumn={setSortColumn} />
                                <TableHeaderSortComponent scope={"col"} text={t('chargingPoint.list.fields.region')} column={"region"} sortColumn={sortColumn} setSortColumn={setSortColumn} />
                                <TableHeaderSortComponent scope={"col"} text={t('chargingPoint.list.fields.locality')} column={"locality"} sortColumn={sortColumn} setSortColumn={setSortColumn} />
                                <TableHeaderSortComponent scope={"col"} text={t('chargingPoint.list.fields.creationDate')} column={"creationDate"} sortColumn={sortColumn} setSortColumn={setSortColumn} />
                                <TableHeaderSortComponent scope={"col"} text={t('chargingPoint.list.fields.webSite')} column={"siteUrl"} sortColumn={sortColumn} setSortColumn={setSortColumn} />
                                <TableHeaderSortComponent scope={"col"} text={t('chargingPoint.list.fields.pointType')} column={"pointType"} sortColumn={sortColumn} setSortColumn={setSortColumn} />
                                <TableHeaderSortComponent scope={"col"} text={t('chargingPoint.list.fields.ubicationType')} column={"ubicationType"} sortColumn={sortColumn} setSortColumn={setSortColumn} />
                                <TableHeaderSortComponent scope={"col"} text={t('chargingPoint.list.fields.active')} column={"active"} sortColumn={sortColumn} setSortColumn={setSortColumn} />
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {chargingPoints?.length > 0 ? chargingPoints.map((chargingPoint, index) => (
                                <tr key={index}>
                                    <td>
                                        <div className="d-flex flex-row flex-nowrap">
                                            {Object.values(chargingPoint.name).reverse().map((translation, i) =>
                                                <div key={i} className="me-2">
                                                    <Image src={translation.language.icon} className="me-2 flags-img-sm" />
                                                    {i + 1 === Object.values(chargingPoint.name).length ? translation.value : (translation.value === null ? "" : translation.value) + ' | '}
                                                </div>
                                            )}
                                        </div>
                                    </td>
                                    <td>{chargingPoint.address}</td>
                                    <td>{chargingPoint.locality.region.province.name[language].value}</td>
                                    <td>{chargingPoint.locality.region.name[language].value}</td>
                                    <td>{chargingPoint.locality.name[language].value}</td>
                                    <td>{UseFormatDate(chargingPoint.creationDate)}</td>
                                    <td>{chargingPoint.siteUrl ? <a href={chargingPoint.siteUrl} target="_blank" rel="noreferrer" className="ms-4"><FontAwesomeIcon icon="eye" /></a> : <FontAwesomeIcon className="ms-4" icon="eye-slash" />}</td>
                                    <td>{chargingPoint.pointType.pointType[language].value}</td>
                                    <td>{chargingPoint.ubication.ubicationType[language].value}</td>
                                    <td>{chargingPoint.active ? <FontAwesomeIcon className="text-primary ms-4" icon="eye" /> : <FontAwesomeIcon className="ms-4" icon="eye-slash" />}</td>
                                    <td className={"text-center"}>
                                        <Button variant="link" size="sm" className="ms-2" to={`/charging-point/${chargingPoint.id}`} as={Link} disabled={!permissions.chargingPoints?.read}>
                                            <FontAwesomeIcon icon="edit" />
                                        </Button>
                                        <Button variant="link" size="sm" className="text-danger" onClick={() => deleteChargingPoint(chargingPoint.id)} disabled={!permissions.chargingPoints?.delete}>
                                            <FontAwesomeIcon icon="trash" />
                                        </Button>
                                    </td>
                                </tr>
                            )) :
                                <tr>
                                    <td colSpan={7}>{t('general.empty')}</td>
                                </tr>
                            }
                        </tbody>
                    </Table>
                    <PaginationComponent pagination={pagination}
                        setPagination={setPagination}
                        alwaysShown={chargingPoints?.length > 0} />
                </Card.Body>
            </Card>
        </>
    );
}

export default ChargingPoint;