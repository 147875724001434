import api from './api';

const getAll = (pagination, filter) => api.get('/highlighted')
.then(response => {
    return response.data;
});

const getContentType = () => api.get('/highlighted/content-type')
.then(response => {
    return response.data;
});

const getPossible = () => api.get('/highlighted/possible-highlighted')
.then(response => {
    return response.data;
});

const save = (data) => api.post('/highlighted', data).then(response => {
    return response.data
});

const HighlightedService = {
    getAll,
    getContentType,
    getPossible,
    save
};

export default HighlightedService;