import {useEffect, useState} from "react";
import {useForm} from "react-hook-form";
import {Button, Col, Form, FormCheck, FormGroup, Row} from "react-bootstrap";
import TranslationInputLanguage from "./fields/TranslationInputLanguage";
import {t} from "i18next";
import _ from "lodash";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import BodyService from "../../services/body.service";
import TranslationInput from "./fields/TranslationInput";
import {v4 as uuidv4} from "uuid";

const ProjectForm = ({onSubmit, details, project, permissions}) => {

    const [titleTranslations, setTitleTranslations] = useState([]);


    const {register, handleSubmit, formState, reset} = useForm({
        mode: "all",
        defaultValues: {
            active: !details,
        },
    });

    const setTitleTranslationValue = (index, value) => {
        setTitleTranslations(titleTranslations.map((title, i) => {
            if (i === index) {
                title.value = value;
            }
            return title;
        }));
    }

    const onSubmitForm = async (data) => {
        data.title = titleTranslations;

        try {
            await onSubmit(data);
            if (!details) {
                const empty = await getEmptyTranslations();
                setTitleTranslations(_.cloneDeep(empty))
                reset();
            }
        } catch (error) {
            console.error(error)
        }

        setTimeout(function () {
            window.scrollTo(0, 0);
        }, 0);
    }

    const getEmptyTranslations = async () => {
        return await BodyService.getEmptyTranslations();
    }

    useEffect(() => {
        const init = async () => {
            const empty = await getEmptyTranslations();
            if (details) {
                reset(project);
                setTitleTranslations(project?.title);
            } else {
                setTitleTranslations(_.cloneDeep(empty))
            }
        }

        init();
    }, [project, details, reset]);

    return (
        <>
            <Form onSubmit={handleSubmit(onSubmitForm)}>
                <Row>
                    <h5>{t('projects.creation.fields.title')}</h5>
                    {titleTranslations?.length > 0 &&
                        <Row className="mt-2">
                            <TranslationInput translations={titleTranslations} refer="name"
                                                      onChange={setTitleTranslationValue}/>
                        </Row>
                    }
                    <Col xs="12" className="mt-md-3">
                        <FormGroup>
                            <FormCheck label={t('projects.creation.fields.active')} {...register("active")} />
                        </FormGroup>
                    </Col>
                </Row>
                {details ? 
                <Button type="submit" className={"mt-4"} disabled={ permissions.projects?.update === false ? true : !formState.isValid }>
                    <FontAwesomeIcon icon={"save"}/>
                </Button>
                : 
                <Button type="submit" className={"mt-4"} disabled={ permissions.projects?.create === false ? true : !formState.isValid }>
                    {t('projects.creation.button')}
                </Button>
                }
            </Form>
        </>
    );

}

export default ProjectForm;