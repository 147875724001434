import api from './api';

const getAll = (pagination, filter, sortColumn)=> api.get('/day-of-the-week/list',{
    pageNum: pagination.currentPage - 1, 
    pageLength: pagination.itemsPerPage, 
    active: filter.active,
    search: filter.search,
    sortBy: sortColumn.sortByColumn,
    orderBy: sortColumn.orderBy
}).then(response => response.data);

const getDaysByActive = (filter)=> api.get('/day-of-the-week/lazy',{
    active: filter.active
}).then(response => response.data);


const getById = (id) => api.get(`/day-of-the-week/${id}`).then(response => {
    return response.data
});

const create = (data) => api.post('/day-of-the-week', data).then(response => {
    return response.data
});

const remove = (id) => api.delete(`/day-of-the-week/${id}`).then(response => {
    return response.data
});

const update = (id, data) => api.put(`/day-of-the-week/${id}`, data).then(response => {
    return response.data
});


const DayOfTheWeekService ={
    getAll,
    getById,
    getDaysByActive,
    create,
    remove,
    update
}

export default DayOfTheWeekService;