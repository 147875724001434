import { Fragment, useRef, useState } from "react";
import { Accordion, Button, Col, Form, FormCheck, FormGroup, Image, Row } from "react-bootstrap"
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import Select from "react-select";
import BodyService from "../../services/body.service";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { v4 as uuidv4 } from "uuid";
import TranslationInputLanguage from "./fields/TranslationInputLanguage";
import { useEffect } from "react";
import LocalityService from "../../services/locality.service";
import RegionService from "../../services/region.service";

const ChargingPointForm = ({ onSubmit, details, chargingPoint, daysOfTheWeek, pointTypes, ubicationTypes, chargers, provinces, projects, name, permissions }) => {

    const { t, i18n } = useTranslation();

    const language = i18n.language.split('-')[0];

    const [pointType, setPointType] = useState();

    const [ubicationType, setUbicationType] = useState();

    const [province, setProvince] = useState();

    const [region, setRegion] = useState();

    const [locality, setLocality] = useState();

    const [latitude, setLatitude] = useState();

    const [longitude, setLongitude] = useState();

    const [project, setProject] = useState();

    const [imageList, setImageList] = useState([]);

    const [scheduleList, setScheduleList] = useState([]);

    const [chargerList, setChargerList] = useState([]);

    const [coordinates, setCoordinates] = useState({
        latitude: "",
        longitude: ""
    });

    const [alerts, setAlerts] = useState({
        latitude: false,
        longitude: false
    });

    const [localities, setLocalities] = useState([]);

    const [regions, setRegions] = useState([]);

    const [nameTranslations, setNameTranslations] = useState();

    const [nameValues, setNameValues] = useState();

    const mainPictureRef = useRef(null);
    const picture2Ref = useRef(null);
    const picture3Ref = useRef(null);

    const { register, handleSubmit, reset, formState } = useForm({
        mode: 'all',
        defaultValues: {
            active: !details,
        },
    });

    const oneLanguageIsPresent = () => {        
        return nameValues && Object.values(nameValues).some((element) => element?.value);
    };

    const isOverlapping = (index, skipPrevious) => {

        if (scheduleList.length === 1) {
            return false;
        }

        for (let i = skipPrevious ? index + 1 : 0; i < scheduleList.length; i++) {
            const skipSchedule = (i === index) || (scheduleList[index].dayOfTheWeekId !== scheduleList[i].dayOfTheWeekId);

            if (skipSchedule) {
                continue;
            }

            const startA = scheduleList[i].startTime;
            const startB = scheduleList[index].startTime;
            const endA = scheduleList[i].endTime;
            const endB = scheduleList[index].endTime;

            const overlappingCondition = ((startA > startB) ? startA : startB) < ((endA < endB) ? endA : endB);

            if (overlappingCondition) {
                return true;
            }
        }
        return false;
    };

    const validate = () => {

        let allScheduleStartTimeIsBeforeEndTime = !scheduleList.some((schedule) => schedule.startTime >= schedule.endTime);
        let noOverlappingSchedule = !scheduleList.some((_, index) => isOverlapping(index, true))
        let allChargerHasData = chargerList.length > 0 && chargerList.every((charger) => (charger.chargerTypeId && charger.number));
        let allScheduleHasData = scheduleList.length > 0 && scheduleList.every((schedule) => (schedule.dayOfTheWeekId && schedule.startTime && schedule.endTime));
        let mainImageIsPresent = imageList[0] && (imageList[0].imageUrl !== '' || imageList[0].imageBase64 !== '');

        return formState.isValid &&
            oneLanguageIsPresent() &&
            allScheduleStartTimeIsBeforeEndTime &&
            noOverlappingSchedule &&
            latitude &&
            longitude &&
            locality &&
            pointType &&
            ubicationType &&
            mainImageIsPresent &&
            allScheduleHasData &&
            allChargerHasData
    };

    const onSubmitForm = async (data) => {
        if (!validate()) {
            return;
        }
        let objSend = {
            ...data,
            name: [],
            schedule: scheduleList.map(({ uuid, ...scheduleList }) => scheduleList),
            mainPicture: {},
            picture2: {},
            picture3: {},
            localityId: locality.id,
            longitude: longitude,
            latitude: latitude,
            pointTypeId: pointType.id,
            ubicationTypeId: ubicationType.id,
            projectId: project?.id,
            chargerTypes: chargerList.map(({ uuid, ...chargerList }) => chargerList),
        }

        Object.values(nameValues).forEach((name) => {
            objSend.name.push({
                language: {
                    id: name.langId
                },
                value: name.value
            })
        })

        setImages(objSend);
        
        try {
            await onSubmit(objSend);
            if (!details) {
                setNameValues(null);
                setLatitude('');
                setLongitude('');
                setProvince(null);
                setRegion(null);
                setRegions([]);
                setLocalities([]);
                setLocality(null);
                setPointType(null);
                setUbicationType(null);
                setProject(null);
                setScheduleList([]);
                setChargerList([]);
                setImageList([]);
                reset();
            }
        } catch (error) {
            console.error(error)
        }

        setTimeout(function () {
            window.scrollTo(0, 0);
        }, 0);
    };

    const setImages = (objSend) => {
        if (imageList[0]) {
            if (imageList[0].id) {
                objSend.mainPicture = {
                    id: imageList[0].id,
                    imageBase64: null,
                    imageContentType: null,
                    imageName: null,
                }
            } else {
                if (imageList[0].imageBase64 || imageList[0].imageBase64 === "") {
                    objSend.mainPicture = imageList[0];
                }
            }
        }
        if (imageList[1]) {
            if (imageList[1].id) {
                objSend.picture2 = {
                    id: imageList[1].id,
                    imageBase64: null,
                    imageContentType: null,
                    imageName: null,
                }
            } else {
                if (imageList[1].imageBase64 || imageList[1].imageBase64 === "") {
                    objSend.picture2 = imageList[1];
                }
            }
        }
        if (imageList[2]) {
            if (imageList[2].id) {
                objSend.picture3 = {
                    id: imageList[2].id,
                    imageBase64: null,
                    imageContentType: null,
                    imageName: null,
                }
            } else {
                if (imageList[2].imageBase64 || imageList[2].imageBase64 === "") {
                    objSend.picture3 = imageList[2];
                }
            }
        }
    };

    const setImageToBase64 = async (file, index) => {
        if (file) {
            const base = await BodyService.getBase64(file);
            const copyImageList = [...imageList];
            const copyImage = { ...copyImageList[index] };
            copyImage.imageBase64 = base.split(',')[1];
            copyImage.imageName = file.name;
            copyImage.imageContentType = file.type;
            copyImageList[index] = copyImage;
            setImageList(copyImageList);
        }
    };

    const getBase64Src = (image) => {
        if (!image?.imageBase64 || !image?.imageContentType) {
            return false;
        }
        return `data:${image.imageContentType};base64,${image.imageBase64}`;
    };

    const checkNumberRange = (value, type, min, max, decimalMax) => {
        let calcNewRange = null
        let decimalLimit = false;

        if (value.toString().includes(',') && value.toString().length > 1) {
            if (value.toString().split(',').length === 2 && value.toString().split(',')?.[1] !== "") {
                let strSplit = value.toString().split(',');

                if (type === "latitude") {
                    calcNewRange = strSplit[1] // take second value from (xx.xxxx, xx.xxxx)
                    value = parseFloat(strSplit[0]) // take first value from (xx.xxxx, xx.xxxx)
                } else if (type === "longitude") {
                    calcNewRange = strSplit[0]
                    value = parseFloat(strSplit[1])
                }
            }
        }

        if (value) {
            if (!isNaN(value) || value === "-") {
                if (value.toString().includes(".")) {
                    if (value.toString().split(".")[1].length > decimalMax && value.toString().split(".")[0].length > 0) {
                        decimalLimit = true;
                        setCoordinates({ ...coordinates, [type]: value.toString().split(".")[0] + '.' + value.toString().split(".")[1].slice(0, decimalMax) })
                        if (type === "latitude") {
                            setLatitude(value.toString().split(".")[0] + '.' + value.toString().split(".")[1].slice(0, decimalMax))
                        } else {
                            setLongitude(value.toString().split(".")[0] + '.' + value.toString().split(".")[1].slice(0, decimalMax))
                        }
                        setAlerts({
                            ...alerts, [type]: false
                        });
                    } else {
                        if (type === "latitude") {
                            setLatitude(value)
                        } else {
                            setLongitude(value)
                        }
                        setAlerts({
                            ...alerts, [type]: false
                        });
                    }
                }
                if (!decimalLimit) {
                    if (value >= max) { // allow type "-" at start (-xx.xxxxx) and not force change value
                        setCoordinates({ ...coordinates, [type]: coordinates[type] })
                        if (type === "latitude") {
                            setLatitude(latitude)
                        } else {
                            setLongitude(longitude)
                        }
                        setAlerts({ ...alerts, [type]: true });
                    } else if (value <= min) {
                        setCoordinates({ ...coordinates, [type]: coordinates[type] })
                        if (type === "latitude") {
                            setLatitude(latitude)
                        } else {
                            setLongitude(longitude)
                        }
                        setAlerts({
                            ...alerts, [type]: true
                        });
                    } else {
                        if (type === "latitude") {
                            setLatitude(value)
                        } else {
                            setLongitude(value)
                        }
                        setAlerts({
                            ...alerts, [type]: false
                        });
                    }
                }
            } else {
                setAlerts({
                    ...alerts, [type]: true
                });
            }
        } else if (!value) {
            setCoordinates({ ...coordinates, [type]: "" })
            if (type === "latitude") {
                setLatitude("")
                setAlerts({
                    ...alerts, [type]: false
                });
            } else {
                setLongitude("")
                setAlerts({
                    ...alerts, [type]: false
                });
            }
        }

        if (calcNewRange) {
            checkNumberRange(calcNewRange.trim(), type === "latitude" ? "longitude" : "latitude", -100, 100, 16)
        }
    };

    const addSchedule = () => {
        setScheduleList([...scheduleList, {
            uuid: uuidv4(),
            dayOfTheWeekId: null,
            startTime: "00:00",
            endTime: "00:00",
            allDay: false
        }]);
    };

    const addCharger = () => {
        setChargerList([...chargerList, {
            uuid: uuidv4(),
            chargerTypeId: null,
            number: 1
        }]);
    };

    const removeImage = (ref, index) => {
        ref.current.value = "";
        const copyImageList = [...imageList];
        if (details) {
            if (copyImageList[index]) {
                copyImageList[index].imageBase64 = "";
                copyImageList[index].imageContentType = "";
                copyImageList[index].imageUrl = "";
            }
        } else {
            copyImageList.splice(index, 1);
        }
        setImageList(copyImageList);
    };

    const setNamesValuesForm = (iso, value, id) => {

        setNameValues({
            ...nameValues, [iso]: { value: value, langId: id, iso: iso }
        })
    };

    const removeCharger = (index) => {
        const copyChargerList = [...chargerList];
        copyChargerList.splice(index, 1);
        setChargerList(copyChargerList);
    };

    const removeSchedule = (index) => {
        const copyScheduleList = [...scheduleList];
        copyScheduleList.splice(index, 1);
        setScheduleList(copyScheduleList);
    };

    const onChangeSchedule = (index, component, data) => {
        const copyScheduleList = [...scheduleList];
        const copySchedule = { ...copyScheduleList[index] };
        copySchedule[component] = data;
        copyScheduleList[index] = copySchedule;

        setScheduleList(copyScheduleList);
    };

    const onChangeAllDaySchedule = (index) => {
        const copyScheduleList = [...scheduleList];
        const copySchedule = { ...copyScheduleList[index] };
        copySchedule['allDay'] = !copySchedule['allDay'];
        copySchedule['startTime'] = '00:00';
        copySchedule['endTime'] = '23:59';
        copyScheduleList[index] = copySchedule;

        setScheduleList(copyScheduleList);
    };

    const onChangeCharger = (index, component, data) => {
        const copyChargerList = [...chargerList];
        const copyCharger = { ...copyChargerList[index] };
        copyCharger[component] = data;
        copyChargerList[index] = copyCharger;
        setChargerList(copyChargerList);
    };

    const getDayOfTheWeekFromIndex = (index) => {
        const days = daysOfTheWeek.filter((day) => day.id === scheduleList[index]?.dayOfTheWeekId);
        if (days.length) {
            return days[0];
        }

        return '';
    };

    const getChargerFromId = (index) => {

        const chargersFound = chargers.filter((charger) => charger.id === chargerList[index]?.chargerTypeId);
        if (chargersFound.length) {
            return chargersFound[0];
        }

        return '';
    };

    const getLocalityByRegion = async (id) => {
        const data = await LocalityService.getByRegionId(id);
        setLocalities(data);
    };

    const getRegionByProvince = async (id) => {
        const data = await RegionService.getByProvinceId(id);
        setRegions(data);
    };

    useEffect(() => {
        setNameTranslations(name);
        if (details && chargingPoint) {
            reset(chargingPoint);
            let nameLang = {};
            Object.keys(chargingPoint.name).forEach((data) => {
                nameLang[data] = {
                    value: chargingPoint.name[data].value,
                    langId: chargingPoint.name[data].language.id,
                    iso: chargingPoint.name[data].language.iso,
                }
            });
            setNameValues(nameLang);
            setLatitude(chargingPoint.latitude);
            setLongitude(chargingPoint.longitude);
            setRegion(chargingPoint.locality.region);
            setProvince(chargingPoint.locality.region.province);

            getLocalityByRegion(chargingPoint.locality.region.id);
            getRegionByProvince(chargingPoint.locality.region.province.id); 

            setLocality(chargingPoint.locality);
            setPointType(chargingPoint.pointType);
            setUbicationType(chargingPoint.ubication);
            setProject(chargingPoint.project);
            setScheduleList(chargingPoint.chargingPointSchedules.map((schedule) => ({
                uuid: uuidv4(),
                dayOfTheWeekId: schedule.dayOfTheWeek.id,
                startTime: schedule.start,
                endTime: schedule.end,
                allDay: schedule.allDay
            })));
            setChargerList(chargingPoint.chargingPointChargerTypes.map((charger) => ({
                uuid: uuidv4(),
                chargerTypeId: charger.chargerType.id,
                number: charger.number
            })));
            setImageList([
                { imageUrl: chargingPoint.mainPicture },
                { imageUrl: chargingPoint.picture2 },
                { imageUrl: chargingPoint.picture3 }
            ]);
        }
    }, [name, chargingPoint, details, reset]);

    return (
        <Form onSubmit={handleSubmit(onSubmitForm)}>
            {((!validate() && !details) || (!validate() && details && chargingPoint)) && <small className='text-danger fw-bold d-block mb-2'>{t('miniGuide.form.required')}</small>}
            {((!oneLanguageIsPresent() && !details) || (!oneLanguageIsPresent() && details && chargingPoint)) && <small className='text-warning fw-bold d-block mb-3'>{t('miniGuide.form.warning')}</small>}
            <Accordion className="mb-3">
                <Accordion.Item eventKey="">
                    <Accordion.Header>
                        <h6 className={"m-0 fw-bold text-primary"}>{t('chargingPoint.creation.general.title')}<span className="ms-1 text-danger fw-bold">*</span></h6>
                    </Accordion.Header>
                    <Accordion.Body>
                        <Row className="mb-3">
                            <Col xs={12}>
                                {t('chargingPoint.creation.name')}<span className="text-warning fw-bold mb-3">*</span>
                                {name?.length > 0 ?
                                    <Row className="mt-3">
                                        <TranslationInputLanguage translations={nameTranslations} data={nameValues} onChange={setNamesValuesForm} />
                                    </Row>
                                    :
                                    <></>
                                }
                            </Col>
                        </Row>
                        <Row className="mb-3">
                            <Col md={3}>
                                <FormGroup>
                                    <Form.Label>{t('chargingPoint.creation.latitude')}<span className="text-danger fw-bold">*</span></Form.Label>
                                    <Form.Control type="text" value={latitude || ""} onChange={e => { checkNumberRange(e.target.value, 'latitude', -100, 100, 16) }} />
                                    {alerts.latitude && <span className="text-primary ">Introduce un valor menor a 100 y mayor a -100</span>}
                                </FormGroup>
                            </Col>
                            <Col md={3}>
                                <FormGroup>
                                    <Form.Label>{t('chargingPoint.creation.longitude')}<span className="text-danger fw-bold">*</span></Form.Label>
                                    <Form.Control type="text" value={longitude || ""} onChange={(e) => { checkNumberRange(e.target.value, 'longitude', -100, 100, 16) }} />
                                    {alerts.longitude && <span className="text-primary ">Introduce un valor menor a 100 y mayor a -100</span>}
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row className="mb-3">
                            <Col md={3}>
                                <FormGroup>
                                    <Form.Label>{t('chargingPoint.creation.address')}<span className="text-danger fw-bold">*</span></Form.Label>
                                    <Form.Control type="text" {...register("address", { required: true })} />
                                </FormGroup>
                            </Col>
                            <Col md={2}>
                                <FormGroup>
                                    <Form.Label>{t('chargingPoint.creation.postalCode')}</Form.Label>
                                    <Form.Control type="text" {...register("postalCode")} />
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row className="mb-3">
                            <Col md={3}>
                                <FormGroup>
                                    <Form.Label>{t('chargingPoint.creation.province')}<span className="text-danger fw-bold">*</span></Form.Label>
                                    <Select options={provinces}
                                        classNamePrefix="select"
                                        placeholder=""
                                        value={province || ''}
                                        getOptionLabel={(option) => option.name[language].value}
                                        getOptionValue={(option) => option.id}
                                        onChange={(data) => {
                                            setProvince(data);
                                            setRegions([]);
                                            setRegion(null);
                                            getRegionByProvince(data.id);
                                        }}
                                    />
                                </FormGroup>
                            </Col>
                            <Col md={3}>
                                <FormGroup>
                                    <Form.Label>{t('chargingPoint.creation.region')}<span className="text-danger fw-bold">*</span></Form.Label>
                                    <Select options={regions}
                                        classNamePrefix="select"
                                        placeholder=""
                                        value={region || ''}
                                        getOptionLabel={(option) => option.name[language].value}
                                        getOptionValue={(option) => option.id}
                                        onChange={(data) => {
                                            setRegion(data);
                                            setLocalities([]);
                                            setLocality(null);
                                            getLocalityByRegion(data.id);
                                        }}
                                    />
                                </FormGroup>
                            </Col>
                            <Col md={3}>
                                <FormGroup>
                                    <Form.Label>{t('chargingPoint.creation.locality')}<span className="text-danger fw-bold">*</span></Form.Label>
                                    <Select options={localities}
                                        classNamePrefix="select"
                                        placeholder=""
                                        value={locality || ''}
                                        getOptionLabel={(option) => option?.name[language].value}
                                        getOptionValue={(option) => option?.id}
                                        onChange={(data) => {
                                            setLocality(data)
                                        }}
                                    />
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row className="mb-3">
                            <Col md={3}>
                                <FormGroup>
                                    <Form.Label>{t('chargingPoint.creation.phone')}</Form.Label>
                                    <Form.Control type="text" {...register("phone")} />
                                </FormGroup>
                            </Col>
                            <Col md={3}>
                                <FormGroup>
                                    <Form.Label>{t('chargingPoint.creation.url')}</Form.Label>
                                    <Form.Control type="text" {...register("mainUrl")} />
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row className="mb-3">
                            <Col md={3}>
                                <FormGroup>
                                    <Form.Label>{t('chargingPoint.creation.pointType')}<span className="text-danger fw-bold">*</span></Form.Label>
                                    <Select options={pointTypes}
                                        classNamePrefix="select"
                                        placeholder=""
                                        value={pointType || ''}
                                        getOptionLabel={(option) => option.pointType[language].value}
                                        getOptionValue={(option) => option.id}
                                        onChange={(data) => {
                                            setPointType(data)
                                        }}
                                    />
                                </FormGroup>
                            </Col>
                            <Col md={3}>
                                <FormGroup>
                                    <Form.Label>{t('chargingPoint.creation.ubicationType')}<span className="text-danger fw-bold">*</span></Form.Label>
                                    <Select options={ubicationTypes}
                                        classNamePrefix="select"
                                        placeholder=""
                                        value={ubicationType || ''}
                                        getOptionLabel={(option) => option.ubicationType[language].value}
                                        getOptionValue={(option) => option.id}
                                        onChange={(data) => {
                                            setUbicationType(data)
                                        }}
                                    />
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row className="mb-3">
                            <Col md={3}>
                                <FormGroup>
                                    <Form.Label>{t('chargingPoint.creation.project')}</Form.Label>
                                    <Select options={projects}
                                        classNamePrefix="select"
                                        placeholder=""
                                        value={project || ''}
                                        getOptionLabel={(option) => option.title[0].value}
                                        getOptionValue={(option) => option.id}
                                        onChange={(data) => {
                                            setProject(data)
                                        }}
                                    />
                                </FormGroup>
                            </Col>
                            <Col md={1} className="d-flex flex-column justify-content-end">
                                <FormGroup>
                                    <FormCheck label={t('chargingPoint.creation.fields.active')} {...register("active")} />
                                </FormGroup>
                            </Col>
                        </Row>

                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>
            <Accordion className="mb-3">
                <Accordion.Item eventKey="">
                    <Accordion.Header>
                        <h6 className={"m-0 fw-bold text-primary"}>{t('chargingPoint.creation.images')}<span className="ms-1 text-danger fw-bold">*</span></h6>
                    </Accordion.Header>
                    <Accordion.Body>
                        <FormGroup>
                            <Form.Label>{t('chargingPoint.creation.image1')}<span className="text-danger fw-bold">*</span></Form.Label>
                            <Row className="my-2">
                                {(imageList[0]?.imageUrl || imageList[0]?.imageBase64) &&
                                    <Row className="my-3">
                                        <>
                                            <Col className="col-8 col-md-6 col-lg-2">
                                                <Image fluid thumbnail src={getBase64Src(imageList[0]) || imageList[0]?.imageUrl} />
                                            </Col>
                                        </>
                                    </Row>
                                }
                                <Col md="auto">
                                    <Form.Control type="file"
                                        accept="image/png, image/jpeg"
                                        onChange={(e) => setImageToBase64(e.target.files[0], 0)}
                                        ref={mainPictureRef}
                                    />
                                </Col>
                                <Col md="auto">
                                    <Button variant="link" size="ls" onClick={() => removeImage(mainPictureRef, 0)}
                                        className="border-primary">
                                        <FontAwesomeIcon icon="fas fa-trash" />
                                    </Button>
                                </Col>
                            </Row>
                        </FormGroup>
                        <FormGroup>
                            <Form.Label>{t('chargingPoint.creation.image2')}</Form.Label>
                            {(imageList[1]?.imageUrl || imageList[1]?.imageBase64) &&
                                <Row className="my-3">
                                    <>
                                        <Col className="col-8 col-md-6 col-lg-2">
                                            <Image fluid thumbnail src={getBase64Src(imageList[1]) || imageList[1]?.imageUrl} />
                                        </Col>
                                    </>
                                </Row>
                            }
                            <Row className="my-2">
                                <Col md="auto">
                                    <Form.Control type="file"
                                        accept="image/png, image/jpeg"
                                        onChange={(e) => setImageToBase64(e.target.files[0], 1)}
                                        ref={picture2Ref} />
                                </Col>
                                <Col md="auto">
                                    <Button variant="link" size="ls" onClick={() => removeImage(picture2Ref, 1)}
                                        className="border-primary">
                                        <FontAwesomeIcon icon="fas fa-trash" />
                                    </Button>
                                </Col>
                            </Row>
                        </FormGroup>
                        <FormGroup>
                            <Form.Label>{t('chargingPoint.creation.image3')}</Form.Label>
                            {(imageList[2]?.imageUrl || imageList[2]?.imageBase64) &&
                                <Row className="my-3">
                                    <>
                                        <Col className="col-8 col-md-6 col-lg-2">
                                            <Image fluid thumbnail src={getBase64Src(imageList[2]) || imageList[2]?.imageUrl} />
                                        </Col>
                                    </>
                                </Row>
                            }
                            <Row className="my-2">
                                <Col md="auto">
                                    <Form.Control type="file"
                                        accept="image/png, image/jpeg"
                                        onChange={(e) => setImageToBase64(e.target.files[0], 2)}
                                        ref={picture3Ref} />
                                </Col>
                                <Col md="auto">
                                    <Button variant="link" size="ls" onClick={() => removeImage(picture3Ref, 2)}
                                        className="border-primary">
                                        <FontAwesomeIcon icon="fas fa-trash" />
                                    </Button>
                                </Col>
                            </Row>
                        </FormGroup>
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>
            <Accordion className="mb-3">
                <Accordion.Item eventKey="">
                    <Accordion.Header>
                        <h6 className={"m-0 fw-bold text-primary"}>{t('chargingPoint.creation.schedule')}<span className="ms-1 text-danger fw-bold">*</span></h6>
                    </Accordion.Header>
                    <Accordion.Body>
                        <>
                            {
                                scheduleList.map((schedule, index) => (
                                    <Fragment key={index}>
                                        {index !== 0 && <hr />}
                                        <Row className="my-2">
                                            <Col md={3}>
                                                <FormGroup>
                                                    <Form.Label>{t('chargingPoint.creation.dayOfTheWeek')}<span className="text-danger fw-bold">*</span></Form.Label>
                                                    <Select options={daysOfTheWeek}
                                                        classNamePrefix="select"
                                                        placeholder=""
                                                        value={getDayOfTheWeekFromIndex(index)}
                                                        getOptionLabel={(option) => option.dayOfTheWeek[language].value}
                                                        getOptionValue={(option) => option.id}
                                                        onChange={
                                                            (data) => {
                                                                onChangeSchedule(index, "dayOfTheWeekId", data.id);
                                                            }}
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col md={3}>
                                                <FormGroup>
                                                    <Form.Label>{t('chargingPoint.creation.startTime')}<span className="text-danger fw-bold">*</span></Form.Label>
                                                    <Form.Control disabled={scheduleList[index]?.allDay} value={scheduleList[index]?.startTime || ''} 
                                                    onChange={(event) => { onChangeSchedule(index, "startTime", event.target.value) }} type="time" />
                                                </FormGroup>
                                            </Col>
                                            <Col md={3}>
                                                <FormGroup>
                                                    <Form.Label>{t('chargingPoint.creation.endTime')}<span className="text-danger fw-bold">*</span></Form.Label>
                                                    <Form.Control disabled={scheduleList[index]?.allDay} value={scheduleList[index]?.endTime || ''}
                                                     onChange={(event) => { 
                                                        onChangeSchedule(index, "endTime", event.target.value) }} type="time" />
                                                </FormGroup>
                                            </Col>
                                            <Col md={2} className= "d-flex flex-column justify-content-end">
                                            <FormGroup>
                                                
                                                <Form.Check label={t('chargingPoint.creation.allDay')} checked={scheduleList[index]?.allDay} onChange={() => { 
                                                    onChangeAllDaySchedule(index) 
                                                    }} />
                                            </FormGroup>
                                            </Col>
                                            <Col md={1} className="d-flex flex-column justify-content-end align-items-start">
                                                <Button variant="link" size="ls" onClick={() => removeSchedule(index)}
                                                    className="border-primary">
                                                    <FontAwesomeIcon icon="fas fa-trash" />
                                                </Button>
                                            </Col>
                                            {
                                                (scheduleList[index]?.startTime >= scheduleList[index]?.endTime) &&
                                                <span className="text-primary ">{t('chargingPoint.error.scheduleEndAfterStart')}</span>
                                            }
                                            {
                                                isOverlapping(index) && <span className="text-primary ">{t('chargingPoint.error.scheduleOverlapping')}</span>
                                            }
                                        </Row>
                                        {(index === scheduleList.length - 1 && (schedule?.dayOfTheWeekId || schedule?.startTime || schedule?.endTime)) &&
                                            <Row>
                                                <Col md="auto">
                                                    <FormGroup onClick={addSchedule} className="mt-3">
                                                        <Form.Label
                                                            style={{ cursor: "pointer" }}>{t('chargingPoint.creation.addSchedule')}</Form.Label>
                                                        <Button variant="link" size="ls" className={"ms-2 border-primary"}>
                                                            <FontAwesomeIcon icon="fas fa-plus" />
                                                        </Button>
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                        }
                                    </Fragment>
                                ))
                            }
                            {
                                scheduleList.length === 0 &&
                                <Row>
                                    <Col md="auto">
                                        <FormGroup onClick={addSchedule}>
                                            <Form.Label
                                                style={{ cursor: "pointer" }}>{t('chargingPoint.creation.addSchedule')}</Form.Label>
                                            <Button variant="link" size="ls" className={"ms-2 border-primary"}>
                                                <FontAwesomeIcon icon="fas fa-plus" />
                                            </Button>
                                        </FormGroup>
                                    </Col>
                                </Row>
                            }
                        </>
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>
            <Accordion className="mb-3">
                <Accordion.Item eventKey="">
                    <Accordion.Header>
                        <h6 className={"m-0 fw-bold text-primary"}>{t('chargingPoint.creation.chargers')}<span className="ms-1 text-danger fw-bold">*</span></h6>
                    </Accordion.Header>
                    <Accordion.Body>
                        <>
                            {
                                chargerList?.map((charger, index) => (
                                    <Fragment key={index}>
                                        {index !== 0 && <hr />}
                                        <Row className="my-2">
                                            <Col md={4}>
                                                <FormGroup>
                                                    <Form.Label>{t('chargingPoint.creation.chargerType')}<span className="text-danger fw-bold">*</span></Form.Label>
                                                    <Select options={chargers}
                                                        classNamePrefix="select"
                                                        placeholder=""
                                                        value={getChargerFromId(index)}
                                                        getOptionLabel={(option) => `${option.name["es"].value} (${option.power})`}
                                                        getOptionValue={(option) => option.id}
                                                        onChange={
                                                            (data) => {
                                                                onChangeCharger(index, "chargerTypeId", data.id);
                                                            }}
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col md={4}>
                                                <FormGroup>
                                                    <Form.Label>{t('chargingPoint.creation.chargerNumber')}<span className="text-danger fw-bold">*</span></Form.Label>
                                                    <Form.Control min="1" value={chargerList[index]?.number || 1} type="number" onChange={(event) => { onChangeCharger(index, "number", event.target.value) }} />
                                                </FormGroup>
                                            </Col>
                                            <Col md={1} className="d-flex flex-column justify-content-end align-items-start">
                                                <Button variant="link" size="ls" onClick={() => removeCharger(index)}
                                                    className="border-primary">
                                                    <FontAwesomeIcon icon="fas fa-trash" />
                                                </Button>
                                            </Col>
                                        </Row>
                                        {(index === chargerList.length - 1 && (charger?.chargerTypeId || charger?.number)) &&
                                            <Row>
                                                <Col md="auto">
                                                    <FormGroup onClick={addCharger} className="mt-3">
                                                        <Form.Label
                                                            style={{ cursor: "pointer" }}>{t('chargingPoint.creation.addCharger')}</Form.Label>
                                                        <Button variant="link" size="ls" className={"ms-2 border-primary"}>
                                                            <FontAwesomeIcon icon="fas fa-plus" />
                                                        </Button>
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                        }
                                    </Fragment>
                                ))
                            }
                            {chargerList.length === 0 &&
                                <Row>
                                    <Col md="auto">
                                        <FormGroup onClick={addCharger}>
                                            <Form.Label
                                                style={{ cursor: "pointer" }}>{t('chargingPoint.creation.addCharger')}</Form.Label>
                                            <Button variant="link" size="ls" className={"ms-2 border-primary"}>
                                                <FontAwesomeIcon icon="fas fa-plus" />
                                            </Button>
                                        </FormGroup>
                                    </Col>
                                </Row>
                            }
                        </>
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>
            {details ? 
            <Button type="submit" className={"mt-3"} disabled={ permissions.chargingPoints?.update === false ? true : !validate() }>
                {t('chargingPoint.update.button')}
            </Button>
            : 
            <Button type="submit" className={"mt-3"} disabled={ permissions.chargingPoints?.create === false ? true : !validate() }>
                {t('chargingPoint.creation.submit')}
            </Button>            
            }

        </Form>
    );
};

export default ChargingPointForm;