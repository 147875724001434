import {Col, Form, FormGroup} from "react-bootstrap";
import {useEffect, useRef} from "react";

const TranslationDescriptionInput = ({translations, onChange}) => {

    const translationsRefs = useRef([]);

    useEffect(() => {
        translationsRefs.current = translationsRefs.current.slice(0, translations.length);
    }, [translations]);

    return (
        translations?.map((translation, index) => (
            <Col md="auto" key={index}>
                <FormGroup>
                    <Form.Label>{translation.language.name}</Form.Label>
                    <Form.Control as="textarea" rows={3} value={translation?.value || ""}
                                  onChange={(e) => onChange(index, e.target.value)}
                                  ref={element => translationsRefs.current[index] = element}
                                  style={{maxHeight: "200px", minWidth:"450px"}}/>
                </FormGroup>
            </Col>
        ))
    );
}

export default TranslationDescriptionInput;