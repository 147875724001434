import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect, useState } from 'react'
import { Accordion, Alert, Button, Card, Col, Form, FormGroup, Image, Row, Table } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import APP_CONF from "../../scripts/constants";
import StampsForm from '../../components/forms/StampsForm';
import BodyService from '../../services/body.service';
import StampService from '../../services/stamps.service';
import PaginationComponent from '../../components/PaginationComponent';
import TableHeaderSortComponent from "../../components/TableHeaderSortComponent";
import UseAlertConfirm from '../../hooks/UseAlertConfirm';
import UserPermissionService from "../../services/userPermission.service";

function Stamps() {

    const { t } = useTranslation();

    const [stamps, setStamps] = useState()
    const [languages, setLanguages] = useState();
    const [permissions, setPermissions] = useState({});
    const [filter, setFilter] = useState({
        active: true,
        search: ""
    })

    const [sortColumn, setSortColumn] = useState({
        sortByColumn: null,
        orderBy: null
    });

    const { showModal } = UseAlertConfirm();

    const [pagination, setPagination] = useState({
        currentPage: 1,
        totalItems: 0,
        itemsPerPage: APP_CONF.ITEMS_PER_PAGE,
        numPages: 1
    });

    const [success, setSuccess] = useState({
        create: false,
        delete: false,
    });
    const [errors, setErrors] = useState({
        create: false,
        delete: false,
    });

    const { register, handleSubmit } = useForm({
        mode: "all",
    });

    const getPermissions = async () => {
        try {
            const data = await UserPermissionService.getPermissionsByConnected();
            setPermissions(data);
        } catch (error) {
            console.error(error);
        }
    }

    const getEmptyTranslations = async () => {
        const data = await BodyService.getEmptyTranslations();
        setLanguages(data);
    }

    const getStamps = async () => {
        try {
            const data = await StampService.getAll(pagination, filter, sortColumn)
            setStamps(data.content)
            setPagination({
                ...pagination,
                totalItems: data.totalElements,
                numPages: data.totalPages
            });
        } catch (error) {
            console.error(error);
        }
    }

    const createStamp = async (data) => {
        setErrors({ ...errors, create: false });
        setSuccess({ ...success, create: false });

        try {
            await StampService.create(data);
            setSuccess({ ...success, create: true });
            getStamps()
        } catch (e) {
            console.error(e.response);
            setErrors({ ...errors, create: true });
            return e.response
        }
    }

    const removeStamp = async (id) => {

        showModal({
            title: t("general.delete.title"),
            body: t("general.delete.message"),
            confirmButtonVariant: "danger",
            onConfirm: async () => {
                setErrors({ ...errors, create: false });
                setSuccess({ ...success, create: false });
                try {
                    await StampService.remove(id);
                    setSuccess({ ...success, delete: true });
                    getStamps();
                } catch (e) {
                    console.error(e.response);
                    setErrors({ ...errors, delete: true });
                }
            }
        });

    }

    const filterSearch = (data) => {
        let obj = data;
        if (obj.search === "") {
            obj = {
                active: data.active
            }
        }
        setFilter(obj);
    }

    useEffect(() => {
        const loadData = async () => {
            await getPermissions();
            await getEmptyTranslations();
            await getStamps();
        }

        loadData();
    }, [])

    useEffect(() => {
        if (stamps) {
            getStamps();
        }
    }, [pagination.currentPage, filter, sortColumn]);

    return (
        <>
            <div className={"d-sm-flex align-items-center justify-content-between mb-4"}>
                <h1 className={"h3 mb-0 text-gray-800"}>{t('stamps.title')}</h1>
            </div>

            <Alert variant={"success"} show={success.create} dismissible onClose={() => setSuccess({ ...success, create: false })}>
                {t('stamps.success.create')}
            </Alert>

            <Alert variant={"danger"} show={errors.create} dismissible onClose={() => setErrors({ ...errors, create: false })}>
                {t('stamps.error.create')}
            </Alert>

            {permissions.stamps?.create ? 
            <Card className={"shadow mb-4"}>
                <Accordion>
                    <Accordion.Item eventKey="0">
                        <Accordion.Header><h6 className={"m-0 fw-bold text-primary"}>{t('stamps.form.title')}</h6>
                        </Accordion.Header>
                        <Accordion.Body>
                            <StampsForm onSubmit={createStamp} languages={languages} permissions={permissions} />
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>
            </Card>
            : null}

            <Card className={"shadow mb-4"}>
                <Card.Header className={"py-3"}>
                    <h6 className={"m-0 fw-bold text-primary"}>{t('stamps.table.title')}</h6>
                </Card.Header>
                <Card.Body>
                    <Form onSubmit={handleSubmit(filterSearch)}>
                        <Row>
                            <Col lg="2" className={"mb-3"}>
                                <FormGroup>
                                    <Form.Label>{t('stamps.filter.active.title')}</Form.Label>
                                    <Form.Select {...register('active')} defaultValue={filter.active} aria-label="Default select example">
                                        <option value="">{t('stamps.filter.active.value1')}</option>
                                        <option value={true}>{t('stamps.filter.active.value2')}</option>
                                        <option value={false}>{t('stamps.filter.active.value3')}</option>
                                    </Form.Select>
                                </FormGroup>
                            </Col>
                            <Col lg="3" className={"mb-3"}>
                                <FormGroup>
                                    <Form.Label>{t('stamps.filter.search')}</Form.Label>
                                    <Form.Control type="text" {...register("search")} />
                                </FormGroup>
                            </Col>
                        </Row>
                        <Button type="submit" className={"mt-3"} disabled={!permissions.stamps?.read}>{t('stamps.filter.button')}</Button>
                    </Form>
                    <hr />
                    <Table responsive>
                        <thead>
                            <tr>
                                <TableHeaderSortComponent scope={"col"} text={t('stamps.table.name')} column={"name"} sortColumn={sortColumn} setSortColumn={setSortColumn} />
                                <th>{t('stamps.table.image')}</th>
                                <TableHeaderSortComponent scope={"col"} text={t('stamps.table.active')} column={"active"} sortColumn={sortColumn} setSortColumn={setSortColumn} />
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {stamps?.length > 0 ? stamps.map((stamp, index) => (
                                <tr key={index}>
                                    <td>
                                        <div className="d-flex flex-row flex-nowrap">
                                            {Object.values(stamp?.title).map((translation, i) => {
                                                if (translation.value) {
                                                    return (
                                                        <div key={i} className="me-2">
                                                            <Image src={translation.language.icon} className="me-2 flags-img-sm" />
                                                            {i + 1 === Object.values(stamp?.title).length ? translation.value : (translation.value === null ? "" : translation.value) + ' | '}
                                                        </div>
                                                    )
                                                } else {
                                                    return ""
                                                }
                                            }
                                            )}
                                        </div>
                                    </td>
                                    <td>{stamp.image[0].value && <Image fluid thumbnail src={stamp.image[0].value} className="flags-img-md" />}</td>
                                    {/* <td>{stamp?.webSiteUrl[0].value ? <a href={stamp?.webSiteUrl[0].value} target="_blank" rel="noreferrer" className="ms-4"><FontAwesomeIcon icon="eye" /></a> : <FontAwesomeIcon className="ms-4" icon="eye-slash" />}</td>
                                    <td>{stamp?.bookingUrl[0].value ? <a href={stamp?.bookingUrl[0].value} target="_blank" rel="noreferrer" className="ms-4"><FontAwesomeIcon icon="eye" /></a> : <FontAwesomeIcon className="ms-4" icon="eye-slash" />}</td> */}
                                    <td>{stamp.active ? <FontAwesomeIcon className="ms-4 text-primary" icon="eye" /> : <FontAwesomeIcon className="ms-4" icon="eye-slash" />}</td>
                                    <td className={"text-center"}>
                                        <Button variant="link" size="sm" className="ms-2" to={`/stamp/${stamp?.id}`} as={Link} disabled={!permissions.articles?.read}>
                                            <FontAwesomeIcon icon="edit" />
                                        </Button>
                                        <Button variant="link" size="sm" className="text-danger" onClick={() => { removeStamp(stamp?.id) }} disabled={!permissions.articles?.delete}>
                                            <FontAwesomeIcon icon="trash" />
                                        </Button>
                                    </td>
                                </tr>
                            )) :
                                <tr>
                                    <td colSpan={3}>{t('general.empty')}</td>
                                </tr>
                            }
                        </tbody>
                    </Table>
                    <PaginationComponent pagination={pagination}
                        setPagination={setPagination}
                        alwaysShown={stamps?.length > 0} />
                </Card.Body>
            </Card>
        </>
    )
}

export default Stamps