import { fas } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useContext, useEffect,  useCallback, useState } from "react";
import { library } from "@fortawesome/fontawesome-svg-core";
import { useTranslation } from "react-i18next";
import SidebarLink from "./SidebarLink";
import { useLocation } from "react-router-dom";
import { Nav } from "react-bootstrap";
import { AppContext } from "../../context/AppContext";
import SidebarDropdown from "./SidebarDropdown";

import UserPermissionService from "../../services/userPermission.service";

const Sidebar = () => {

    library.add(fas);

    const { pathname } = useLocation();

    const { t } = useTranslation();

    const { state, update } = useContext(AppContext);

    const [permissions, setPermissions] = useState({});

    const getPermissions = async () => {
        try {
            const data = await UserPermissionService.getPermissionsByConnected();
            setPermissions(data);
        } catch (error) {
            console.error(error);
        }
    }

    useEffect(() => {
        getPermissions();
    }, []);

    return (
        <Nav className={`navbar-nav bg-gradient-primary sidebar sidebar-dark accordion ${state.collapsed && "toggled"}`}
            defaultActiveKey={pathname === "/" ? "/home" : `/${pathname.split("/")[1]}`}>

            <div className={"sidebar-brand d-flex align-items-center justify-content-center"}>
                <div className={"sidebar-brand-icon rotate-n-15"}>
                    <FontAwesomeIcon icon={"book-open"} size={"lg"} />
                </div>
                <div className={"sidebar-brand-text mx-3"}>{t('general.title')}</div>
            </div>

            <hr className={"sidebar-divider my-0"} />

            <SidebarLink url="/home" icon="tachometer-alt" text={t('general.sidebar.dashboard')}
                collapsed={state.collapsed} />
            
            {(permissions.articles?.read || permissions.authors?.read || permissions.interestSites?.read || permissions.categories?.read 
            || permissions.miniGuides?.read ||  permissions.guideImages?.read ||  permissions.stamps?.read) ? 
                <SidebarDropdown icon="fa-newspaper" section={"guideContent"} text={t('general.sidebar.guideContent')}>
                    {permissions.articles?.read ? 
                        <SidebarLink url="/article" icon="fa-newspaper" text={t('general.sidebar.articles')}
                        collapsed={state.collapsed} />
                    : null }

                    {permissions.authors?.read ? 
                        <SidebarLink url="/author" icon="fa-pen-nib" text={t('general.sidebar.authors')}
                        collapsed={state.collapsed} />
                    : null }

                    {permissions.interestSites?.read ? 
                        <SidebarLink url="/site-interest" icon="fa-location-dot" text={t('general.sidebar.siteInterest')}
                        collapsed={state.collapsed} />
                    : null }

                    {permissions.miniGuides?.read ? 
                        <SidebarLink url="/mini-guide" icon="fa-file-invoice" text={t('general.sidebar.miniGuide')}
                        collapsed={state.collapsed} />
                    : null }

                    {permissions.categories?.read ? 
                        <SidebarLink url="/category" icon="fa-bookmark" text={t('general.sidebar.category')}
                        collapsed={state.collapsed} />
                    : null }

                    {permissions.guideImages?.read ? 
                        <SidebarLink url="/guide-images" icon="fa-image" text={t('general.sidebar.guideImages')}
                        collapsed={state.collapsed} />
                    : null }
                    
                    {permissions.stamps?.read ? 
                        <SidebarLink url="/stamp" icon="fa-stamp" text={t('general.sidebar.stamps')}
                        collapsed={state.collapsed} />
                    : null }

                </SidebarDropdown>
            : null }

            {(permissions.chargingPoints?.read || permissions.pointTypes?.read || permissions.chargerTypes?.read || permissions.locationTypes?.read) ? 
                <SidebarDropdown icon="fa-charging-station" section={"chargingPoints"} text={t('general.sidebar.chargingPoints')}>
                    {permissions.chargingPoints?.read ? 
                        <SidebarLink url="/charging-point" icon="fa-charging-station" text={t('general.sidebar.Point')}
                            collapsed={state.collapsed} />
                    : null }

                    {permissions.pointTypes?.read ? 
                        <SidebarLink url="/configuration/point-type" icon="fa-unlock" text={t('general.sidebar.pointType')}
                            collapsed={state.collapsed} />
                    : null }        

                    {permissions.locationTypes?.read ? 
                        <SidebarLink url="/configuration/ubication-type" icon="fa-map-pin" text={t('general.sidebar.ubicationType')}
                            collapsed={state.collapsed} />
                    : null }
                    
{                   permissions.chargerTypes?.read ? 
                        <SidebarLink url="/configuration/charger-type" icon="fa-plug" text={t('general.sidebar.chargerType')}
                            collapsed={state.collapsed} />
                    : null }  

                </SidebarDropdown> 
            : null }

            {(permissions.projects?.read) ? 
                <SidebarDropdown icon="fa-diagram-project" section={"projectManagement"} text={t('general.sidebar.projectManagement')}>

                    <SidebarLink url="/project" icon="fa-diagram-project" text={t('general.sidebar.project')}
                        collapsed={state.collapsed} />

                </SidebarDropdown>
            : null }

            {(permissions.tags?.read || permissions.provinces?.read || permissions.regions?.read || permissions.localities?.read || permissions.highlights?.read
                || permissions.weekDays?.read || permissions.languages?.read || permissions.promotions?.read || permissions.sections?.read || permissions.homeHeadlines?.read) ?
                <SidebarDropdown icon="fa-tags" section={"generalIndexes"} text={t('general.sidebar.generalIndexes')}>
                    {permissions.tags?.read ? 
                        <SidebarLink url="/tag" icon="fa-tags" text={t('general.sidebar.tag')}
                            collapsed={state.collapsed} />                    
                    : null }

                    {permissions.provinces?.read ? 
                        <SidebarLink url="/configuration/province" icon="fa-layer-group" text={t('general.sidebar.provinces')}
                            collapsed={state.collapsed} />                    
                    : null }

                    {permissions.regions?.read ? 
                        <SidebarLink url="/configuration/region" icon="fa-mountain" text={t('general.sidebar.regions')}
                            collapsed={state.collapsed} />
                    
                    : null }

                    {permissions.localities?.read ? 
                        <SidebarLink url="/configuration/locality" icon="fa-city" text={t('general.sidebar.localities')}
                            collapsed={state.collapsed} />
                    : null }

                    {permissions.weekDays?.read ? 
                        <SidebarLink url="/configuration/day-of-the-week" icon="fa-calendar-week" text={t('general.sidebar.dayOfTheWeek')}
                            collapsed={state.collapsed} />
                    : null }

                    {permissions.languages?.read ? 
                        <SidebarLink url="/language" icon="fa-language" text={t('general.sidebar.languages')}
                            collapsed={state.collapsed} />                  
                    : null }

                    {permissions.promotions?.read ? 
                        <SidebarLink url="/promotion" icon="fa-rectangle-ad" text={t('general.sidebar.promotions')}
                            collapsed={state.collapsed} />                    
                    : null }

                    {permissions.sections?.read ? 
                        <SidebarLink url={"/section"} icon="fa-folder-open" text={t('general.sidebar.section')} 
                            collapsed={state.collapsed} />                    
                    : null }

                    {permissions.highlights?.read ? 
                        <SidebarLink url="/highlighted" icon="fa-highlighter" text={t('general.sidebar.highlighted')} 
                            collapsed={state.collapsed} />                    
                    : null }

                    {permissions.homeHeadlines?.read ? 
                        <SidebarLink url="/home-headline" icon="fa-heading" text={t('general.sidebar.homeHeadline')} 
                            collapsed={state.collapsed} />                    
                    : null }

                </SidebarDropdown>            
            : null }

            {(permissions.users?.read || permissions.userPermissions?.read) ? 
                <SidebarDropdown icon="fa-user" section={"usersManagement"} text={t('general.sidebar.usersManagement')}>

                    {permissions.users?.read ? 
                        <SidebarLink url="/user" icon="fa-user" text={t('general.sidebar.user')}
                            collapsed={state.collapsed} />
                    : null }

                    {permissions.userPermissions?.read ? 
                        <SidebarLink url="/user-permission" icon="fa-user-shield" text={t('general.sidebar.userPermission')}
                            collapsed={state.collapsed} />
                    : null}

                </SidebarDropdown>
            : null}

            {(permissions.analyticUsers?.read || permissions.analyticBookings?.read || permissions.monitor?.read) ? 
                <SidebarDropdown icon="fa-chart-column" section={"stadistics"} text={t('general.sidebar.stadistics')}>
                    {permissions.monitor?.read ? 
                        <SidebarLink url="/monitor" icon="fa-tv" text={t('general.sidebar.monitor')}
                            collapsed={state.collapsed} />                    
                    : null }

                    {permissions.analyticBookings?.read ? 
                        <SidebarLink url="/analytic" icon="fa-chart-bar" text={t('general.sidebar.analytic')}
                            collapsed={state.collapsed} />                    
                    : null }

                    {permissions.analyticUsers?.read ? 
                        <SidebarLink url="/user-analytic" icon="fa-chart-bar" text={t('general.sidebar.userAnalytic')}
                            collapsed={state.collapsed} />                    
                    : null }
                </SidebarDropdown>
            : null }

            <hr className={"sidebar-divider d-none d-md-block"} />

            <div className={"text-center d-none d-md-inline"}>
                <button className={"rounded-circle border-0"} id="sidebarToggle"
                    onClick={() => update({ ...state, collapsed: !state.collapsed })}>
                    <FontAwesomeIcon className={"text-light"} icon={state.collapsed ? "chevron-right" : "chevron-left"}
                        size={"1x"} />
                </button>
            </div>
        </Nav>
    );
}

export default Sidebar;
