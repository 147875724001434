import { useEffect, useState } from "react";
import { Accordion, Alert, Card, Col, Form, FormGroup, Image, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import PointTypeForm from "../../../components/forms/configuration/PointTypeForm";
import { Table } from "react-bootstrap";
import { Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import PaginationComponent from "../../../components/PaginationComponent";
import TableHeaderSortComponent from "../../../components/TableHeaderSortComponent";
import APP_CONF from "../../../scripts/constants";
import BodyService from "../../../services/body.service";
import PointTypeService from "../../../services/pointTypes.service";
import UseAlertConfirm from "../../../hooks/UseAlertConfirm";
import { useForm } from "react-hook-form";
import UserPermissionService from "../../../services/userPermission.service";

const PointType = () => {
    const { t } = useTranslation();

    const { showModal } = UseAlertConfirm();

    const [pagination, setPagination] = useState({
        currentPage: 1,
        totalItems: 0,
        itemsPerPage: APP_CONF.ITEMS_PER_PAGE,
        numPages: 1
    });

    const [pointTypes, setPointTypes] = useState([]);

    const [translations, setTranslations] = useState([]);

    const [permissions, setPermissions] = useState({});

    const [success, setSuccess] = useState({
        create: false,
        delete: false,
    });

    const [errors, setErrors] = useState({
        create: false,
        delete: false,
    });

    const [sortColumn, setSortColumn] = useState({
        sortByColumn: null,
        orderBy: null
    });

    const { register, handleSubmit } = useForm({
        mode: "all",
    });

    const [filter, setFilter] = useState({
        active: "",
        search: ""
    });

    const getPointTypes = async () => {
        try {
            const data = await PointTypeService.getAll(pagination, filter, sortColumn);
            setPointTypes(data.content);
            setPagination({
                ...pagination,
                totalItems: data.totalElements,
                numPages: data.totalPages
            });
        } catch (response) {
            if (response?.data?.errorCode) {
                switch (response.data.errorCode) {
                    case 13002:
                        setErrors({ ...errors, email: true });
                        break;
                    default:
                        setErrors({ ...errors, create: true });
                }
            }
        }
    };

    const filterSearch = (data) => {       
        setFilter({
            active: data.active,
            search: data.search,
        });
    };

    const getEmptyTranslations = async () => {
        const data = await BodyService.getEmptyTranslations();
        setTranslations(data);
    };

    const getPermissions = async () => {
        try {
            const data = await UserPermissionService.getPermissionsByConnected();
            setPermissions(data);
        } catch (error) {
            console.error(error);
        }
    }

    useEffect(() => {
        const loadData = async () => {
            await getPermissions();
            await getEmptyTranslations();
        }

        loadData();
    }, []);

    useEffect(() => {
        getPointTypes();  
    }, [pagination.currentPage, sortColumn, filter])


    const createPointType = async (data) => {
        setErrors({ ...errors, create: false });
        setSuccess({ ...success, create: false });
        try {
            await PointTypeService.create(data);
            getEmptyTranslations();
            setSuccess({ ...success, create: true });
            getPointTypes();
        } catch (error) {
            console.error(error.response);
            setErrors({ ...errors, create: true });
        }
    };

    const deletePointType = async (id) => {
        showModal({
            title: t("general.delete.title"),
            body: t("general.delete.message"),
            confirmButtonVariant: "danger",
            onConfirm: async () => {
                setErrors({ ...errors, create: false });
                setSuccess({ ...success, create: false });
                try {
                    await PointTypeService.remove(id);
                    setSuccess({ ...success, delete: true });
                    getPointTypes();
                } catch (e) {
                    console.error(e.response);
                    setErrors({ ...errors, delete: true });
                }
            }
        });
    };

    return (
        <>
            <div className={"d-sm-flex align-items-center justify-content-between mb-4"}>
                <h1 className={"h3 mb-0 text-gray-800"}>{t('pointType.title')}</h1>
            </div>
            <Alert variant={"danger"} show={errors.create} dismissible onClose={() => setErrors({ ...errors, create: false })}>
                {t('pointType.error.create')}
            </Alert>

            <Alert variant={"success"} show={success.create} dismissible onClose={() => setSuccess({ ...success, create: false })}>
                {t('pointType.success.create')}
            </Alert>

            <Alert variant={"success"} show={success.delete} dismissible onClose={() => setSuccess({ ...success, delete: false })}>
                {t('pointType.success.delete')}
            </Alert>

            <Alert variant={"danger"} show={errors.delete} dismissible onClose={() => setErrors({ ...errors, delete: false })}>
                {t('pointType.error.delete')}
            </Alert>

            {permissions.pointTypes?.read && permissions.pointTypes.create ? 
            <Card className={"shadow mb-4"}>
                <Accordion>
                    <Accordion.Item eventKey="0">
                        <Accordion.Header><h6 className={"m-0 fw-bold text-primary"}>{t('pointType.form.title')}</h6>
                        </Accordion.Header>
                        <Accordion.Body>
                            <PointTypeForm onSubmit={createPointType} translations={translations} permissions={permissions}/>
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>
            </Card>
            : null }

            <Card className={"shadow mb-4"}>
                <Card.Header className={"py-3"}>
                    <h6 className={"m-0 fw-bold text-primary"}>{t('pointType.table.title')}</h6>
                </Card.Header>
                <Card.Body>
                <Form onSubmit={handleSubmit(filterSearch)}>
                        <Row>
                            <Col lg="2" className={"mb-3"}>
                                <FormGroup>
                                    <Form.Label>{t('pointType.filter.active.title')}</Form.Label>
                                    <Form.Select {...register('active')} aria-label="Default select example">
                                        <option value="">{t('pointType.filter.active.value1')}</option>
                                        <option value={true}>{t('pointType.filter.active.value2')}</option>
                                        <option value={false}>{t('pointType.filter.active.value3')}</option>
                                    </Form.Select>
                                </FormGroup>
                            </Col>
                            <Col lg="3" className={"mb-3"}>
                                <FormGroup>
                                    <Form.Label>{t('pointType.filter.search')}</Form.Label>
                                    <Form.Control type="text" {...register("search")} />
                                </FormGroup>
                            </Col>
                        </Row>
                        <Button type="submit" className={"mt-3"} disabled={!permissions.pointTypes?.read}>{t('pointType.filter.button')}</Button>
                    </Form>
                    <hr />
                    <Table responsive>
                        <thead>
                            <tr>
                                <TableHeaderSortComponent scope={"col"} text={t('pointType.table.name')} column={"name"} sortColumn={sortColumn} setSortColumn={setSortColumn} />
                                <TableHeaderSortComponent scope={"col"} text={t('pointType.table.active')} column={"active"} sortColumn={sortColumn} setSortColumn={setSortColumn} />
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                pointTypes ? pointTypes.map((pointType, index) => (
                                    <tr key={index}>
                                        <td>
                                            <div className="d-flex flex-row flex-nowrap">
                                                {Object.values(pointType.pointType).map((translation, i) =>
                                                    <div key={i} className="me-2">
                                                        <Image src={translation.language.icon} className="me-2 flags-img-sm" />
                                                        {i + 1 === Object.values(pointType.pointType).length ? translation.value : translation.value + ' | '}
                                                    </div>
                                                )}
                                            </div>
                                        </td>
                                        <td>{pointType?.active ? 'Sí' : 'No'}</td>
                                        <td className={"text-center"}>
                                            <Button variant="link" size="sm" className="ms-2" to={`/configuration/point-type/${pointType.id}`} as={Link} disabled={!permissions.pointTypes?.read}>
                                                <FontAwesomeIcon icon="edit" />
                                            </Button>
                                            <Button variant="link" size="sm" className="text-danger" onClick={() => deletePointType(pointType.id)} disabled={!permissions.pointTypes?.delete}>
                                                <FontAwesomeIcon icon="trash" />
                                            </Button>
                                        </td>
                                    </tr>
                                )) :
                                    <tr>
                                        <td colSpan={4}>{t('general.empty')}</td>
                                    </tr>
                            }
                        </tbody>
                    </Table>
                    <PaginationComponent pagination={pagination}
                        setPagination={setPagination}
                        alwaysShown={pointTypes.length > 0} />
                </Card.Body>
            </Card>
            <hr />
        </>
    );
};

export default PointType;