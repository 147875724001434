import {Accordion, Button, Card, Table, Alert, FormGroup, Form, Col, Row} from "react-bootstrap";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import AnalyticService from "../../services/analytic.service";
import DateTimePicker from "react-datetime-picker/dist/entry.nostyle";

import APP_CONF from "../../scripts/constants";
import PaginationComponent from "../../components/PaginationComponent";
import {useForm} from "react-hook-form";
import TableHeaderSortComponent from "../../components/TableHeaderSortComponent";
import UseFormatDate from "../../hooks/UseFormatDate";
import UserPermissionService from "../../services/userPermission.service";

const Analytic = () => {
    const { t } = useTranslation();

    const { register, handleSubmit, reset } = useForm({
        mode: "all"
    });

    const [pagination, setPagination] = useState({
        currentPage: 1,
        totalItems: 0,
        itemsPerPage: APP_CONF.ITEMS_PER_PAGE,
        numPages: 1
    });

    const generateDate = (days, endDay) => {
        var d = new Date();
        d.setDate(d.getDate() + days);
        if(endDay) {
            d.setHours(23,59,59,0); 
        }
        else {
            d.setHours(0,0,0,0); 
        }

        return d;
    }

    const [formData, setFormData] = useState({
        startDate: generateDate(-7, false),
        endDate: generateDate(1, true),
        villaId: null
    });

    const [errors, setErrors] = useState({
        create: false,
        delete: false,
    });

    const [analytics, setAnalytics] = useState([]);

    const [villas, setVillas] = useState([]);

    const [permissions, setPermissions] = useState({});

    const [sortColumn, setSortColumn] = useState({
        sortByColumn: null,
        orderBy: null
    });

    const updateFormData = (target, data) => {
        setFormData({
            ...formData,
            [target]: data
        });
    }

    const getComboboxData = async () => {
        try {
            const data = await AnalyticService.getVillaData();
            setVillas(data);

        } catch ({ response }) {
            if (response?.data?.errorCode) {
                switch (response.data.errorCode) {
                    case 13002:
                        setErrors({...errors, email: true});
                        break;
                    default:
                        setErrors({...errors, create: true});
                }
            }
        }
    }

    const getAnalytics = async () => {
        try {
            const data = await AnalyticService.getAll(pagination, sortColumn, formData)
            setAnalytics(data.content);
            setPagination({
                ...pagination,
                totalItems: data.totalElements,
                numPages: data.totalPages
            });
        } catch ({ response }) {
            if (response?.data?.errorCode) {
                switch (response.data.errorCode) {
                    case 13002:
                        setErrors({...errors, email: true});
                        break;
                    default:
                        setErrors({...errors, create: true});
                }
            }
        }
    }

    const filterSearch = (data) => {
        getAnalytics();
    }

    const getPermissions = async () => {
        try {
            const data = await UserPermissionService.getPermissionsByConnected();
            setPermissions(data);
        } catch (error) {
            console.error(error);
        }
    }

    useEffect(() => {
        getComboboxData();
        getPermissions();
    }, [])

    useEffect(() => {
        getAnalytics();
    }, [pagination.currentPage, sortColumn]);

    return (
        <>
            <div className={"d-sm-flex align-items-center justify-content-between mb-4"}>
                <h1 className={"h3 mb-0 text-gray-800"}>{t('analytic.title')}</h1>
            </div>

            <Card className={"shadow mb-4"}>
                <Card.Body>
                    <Form onSubmit={handleSubmit(filterSearch)}>
                        <Row>
                            <Col lg="3" className={"mb-3"}>
                                <FormGroup>
                                    <Form.Label>{t('analytic.filter.villa')}</Form.Label>
                                    <Form.Select onChange={e => updateFormData('villaId', e.target.value)}>
                                        <option value={null}> </option>
                                        {villas?.map(villa => (
                                            <option key={villa?.value}
                                                value={villa?.value}>{villa?.description}</option>
                                        ))}
                                    </Form.Select>
                                </FormGroup>
                            </Col>
                            <Col lg="2" className={"mb-3"}>
                                <FormGroup>
                                    <Form.Label>{t('monitor.filter.startDate')}</Form.Label>
                                    <DateTimePicker calendarIcon={null}
                                        disableClock={true}
                                        onChange={e => updateFormData('startDate', e)}
                                        value={formData?.startDate} />
                                </FormGroup>
                            </Col>
                            <Col lg="2" className={"mb-3"}>
                                <FormGroup>
                                    <Form.Label>{t('monitor.filter.endDate')}</Form.Label>
                                    <DateTimePicker calendarIcon={null}
                                        disableClock={true}
                                        onChange={e => updateFormData('endDate', e)}
                                        value={formData?.endDate} />
                                </FormGroup>
                            </Col>
                        </Row>
                        <Button type="submit" disabled={!permissions.analyticBookings?.read}>{t('users.filter.button')}</Button>
                    </Form>
                    <hr />
                    <Table responsive>
                        <thead>
                            <tr>
                                <TableHeaderSortComponent scope={"col"} text={t('analytic.list.id')} column={"id"} sortColumn={sortColumn} setSortColumn={setSortColumn} />
                                <TableHeaderSortComponent scope={"col"} text={t('analytic.list.villa')} column={"villa"} sortColumn={sortColumn} setSortColumn={setSortColumn} />
                                <TableHeaderSortComponent scope={"col"} text={t('analytic.list.startDate')} column={"startDate"} sortColumn={sortColumn} setSortColumn={setSortColumn} />
                                <TableHeaderSortComponent scope={"col"} text={t('analytic.list.endDate')} column={"endDate"} sortColumn={sortColumn} setSortColumn={setSortColumn} />
                                <TableHeaderSortComponent scope={"col"} text={t('analytic.list.amount')} column={"amount"} sortColumn={sortColumn} setSortColumn={setSortColumn} />
                                <TableHeaderSortComponent scope={"col"} text={t('analytic.list.guests')} column={"guests"} sortColumn={sortColumn} setSortColumn={setSortColumn} />    
                                <TableHeaderSortComponent scope={"col"} text={t('analytic.list.creationDate')} column={"creationDate"} sortColumn={sortColumn} setSortColumn={setSortColumn} />
                                <TableHeaderSortComponent scope={"col"} text={t('analytic.list.user')} column={"userId"} sortColumn={sortColumn} setSortColumn={setSortColumn} /> 
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {analytics.length > 0 ? analytics.map((analytic, index) => (
                                <tr key={index}>
                                    <td>{analytic.id}</td>
                                    <td>{analytic.villa}</td>
                                    <td>{analytic.startDate !== null ? UseFormatDate(analytic.startDate) : null}</td>
                                    <td>{analytic.endDate !== null ? UseFormatDate(analytic.endDate) : null}</td>
                                    <td>{analytic.amount}</td>
                                    <td>{analytic.guests}</td>
                                    <td>{analytic.creationDate !== null ? UseFormatDate(analytic.creationDate) : null}</td>
                                    <td>{analytic.user}</td>
                                </tr>
                            )) :
                                <tr>
                                    <td colSpan={4}>{t('general.empty')}</td>
                                </tr>
                            }
                        </tbody>
                    </Table>
                    <PaginationComponent pagination={pagination}
                        setPagination={setPagination}
                        alwaysShown={analytics.length > 0} />
                </Card.Body>
            </Card>
        </>
    );
};

export default Analytic;
