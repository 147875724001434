import api from './api';

const getAll = (pagination, sortColumn, filter) => api.get('/monitor', {
    startDate: filter.startDate,
    endDate: filter.endDate,
    pageNum: pagination.currentPage - 1,
    pageLength: pagination.itemsPerPage,
    sortBy: sortColumn.sortByColumn,
    orderBy: sortColumn.orderBy,
    systemId: filter.systemId,
    statusId: filter.statusId
}).then(response => {
    return response.data;
});

const getTypologyData = () => api.get('/monitor/typology-data').then(response => {
    return response.data
});


const MonitorService = {
    getAll,
    getTypologyData
}

export default MonitorService;