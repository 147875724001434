import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { t } from "i18next";
import { useEffect, useState } from "react";
import { Button, Col, Form, FormCheck, FormGroup, Row } from "react-bootstrap";
import { useForm } from "react-hook-form";
import BodyService from "../../services/body.service";


import TranslationInputLanguage from "./fields/TranslationInputLanguage";

const TagForm = ({ onSubmit, details, tag, translations, permissions }) => {

    const [nameTranslations, setNameTranslations] = useState();
    const [tagValues, setTagValues] = useState();

    const { register, handleSubmit, formState, reset } = useForm({
        mode: "all",
        defaultValues: {
            active: !details,
        },
    });

    const setTagValuesForm = (iso, value, id) => {
        setTagValues({
            ...tagValues, [iso]: { value: value, langId: id, iso: iso }
        })
    }

    const onSubmitForm = async (data) => {
        let objSend = {
            name: [],
            slug: [],
            active: data.active
        }

        Object.values(tagValues).forEach((tag) => {
            objSend.name.push({
                language: {
                    id: tag.langId
                },
                value: tag.value
            })

            objSend.slug.push({
                language: {
                    id: tag.langId
                },
                value: BodyService.slugifyStr(tag.value)
            })
        })

        try {
            await onSubmit(objSend);
            if (!details) {
                setTagValues(null);
                reset();
            }
        } catch (error) {
            console.error(error)
        }

        setTimeout(function () {
            window.scrollTo(0, 0);
        }, 0);
    }

    useEffect(() => {
        setNameTranslations(translations);

        if (details && tag) {
            let tagLang = {}

            Object.keys(tag.name).forEach((data) => {
                tagLang[data] = {
                    value: tag.name[data].value,
                    langId: tag.name[data].language.id,
                    iso: tag.name[data].language.iso,
                }
            })

            setTagValues(tagLang);
        }

    }, [translations, tag, details]);

    useEffect(() => {
        if (details) {
            reset(tag);
        }
    }, [tag, details, reset]);

    return (
        <Form onSubmit={handleSubmit(onSubmitForm)}>
            <Row>
                {translations?.length > 0 ?
                    <Row className="mt-2">
                        <TranslationInputLanguage translations={nameTranslations} data={tagValues} onChange={setTagValuesForm} />
                    </Row>
                    :
                    <></>
                }
                <Col xs="12" className="mt-md-3">
                    <FormGroup>
                        <FormCheck label={t('tags.creation.fields.active')} {...register("active")} />
                    </FormGroup>
                </Col>
            </Row>
            {details ?
            <Button type="submit" className={"mt-4"} disabled={ permissions.tags?.update === false ? true : !formState.isValid }>
                <FontAwesomeIcon icon={"save"} />
            </Button>
            : 
            <Button type="submit" className={"mt-4"} disabled={ permissions.tags?.create === false ? true : !formState.isValid }>
                {t('tags.creation.button')}
            </Button>
            }
        </Form>
    );
}

export default TagForm;