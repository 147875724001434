import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect, useState, Fragment } from 'react'
import { Accordion, Button, Col, Form, FormCheck, FormGroup, Image, Row } from 'react-bootstrap'
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import BodyService from '../../services/body.service';
import Select from "react-select";
import LocalityService from '../../services/locality.service';
import RegionService from '../../services/region.service';

const MiniGuideForm = ({ onSubmit, miniGuide, languages, tags, categories, articles, provinces, permissions, detail }) => {

    const { t, i18n } = useTranslation();

    const { register, handleSubmit, reset } = useForm({
        mode: "all",
        defaultValues: {
            active: !detail,
        },
    });

    const [langData, setLangData] = useState();
    const [selectedCategory, setSelectedCategory] = useState();
    const [selectedTags, setSelectedTags] = useState();
    const [articlesList, setArticlesList] = useState([]);
    const [selectedType, setSelectedType] = useState();
    const [province, setProvince] = useState();
    const [localitiesList, setLocalitiesList] = useState()
    const [locality, setLocality] = useState();
    const [regionsList, setRegionsList] = useState([]);
    const [region, setRegion] = useState();
    const [mainPicture, setMainPicture] = useState();
    const [formDisabled, setFormDisabled] = useState(true);
    const [requiredFields, setRequiredFields] = useState(true);
    const [resetFlag, setResetFlag] = useState(false);
    const [blockSlugLang, setBlockSlugLang] = useState({
        es: false,
        en: false,
        de: false
    })

    const onSubmitForm = async (data) => {
        if (!requiredFields && !formDisabled) {

            let tagIdsArray = []
            let articleListArray = []

            articlesList?.forEach((article) => {
                if (article.data) {
                    articleListArray.push({
                        id: parseInt(article?.data?.id),
                        order: parseInt(article?.order),
                        shortDescription: Object.keys(article?.shortDescription).map(function (key) {
                            return article?.shortDescription[key];
                        })
                    })
                }
            })

            selectedTags?.forEach((data) => tagIdsArray.push(data.id))

            let sendData = {
                active: data.active,
                order: data.order,
                categoryId: selectedCategory.id,
                articleIds: articleListArray,
                tagIds: tagIdsArray,
                format: selectedType?.value,
                mainPicture: {
                    fileBase64: mainPicture?.fileBase64 ? mainPicture?.fileBase64 : mainPicture?.fileBase64 === null ? null : "",
                    fileContentType: mainPicture?.fileContentType ? mainPicture?.fileContentType : mainPicture?.fileContentType === null ? null : ""
                },
                provinceId: province?.id !== 0 ? province?.id : null,
                regionId: region?.id !== 0 ? region?.id : null,
                localityId: (region?.id !== 0 && locality?.id !== 0) ? locality?.id : null,
                title: [],
                description: [],
                slugs: [],
                sponsor1Content: [],
                sponsor1Link: [],
                sponsor2Content: [],
                sponsor2Link: [],
            }

            Object.values(langData).forEach((data) => {
                sendData?.title?.push(data.title)
                sendData?.slugs?.push(data.slug)
                sendData?.description?.push(data.description);
                if (detail) {
                    sendData?.sponsor1Content?.push({
                        language: data?.sponsor1Content?.language,
                        imageBase64: data?.sponsor1Content?.value?.fileBase64 ? data?.sponsor1Content?.value?.fileBase64 : data?.sponsor1Content?.value?.fileBase64 === null ? null : "",
                        imageContentType: data?.sponsor1Content?.value?.fileContentType ? data?.sponsor1Content?.value?.fileContentType : data?.sponsor1Content?.value?.fileContentType === null ? null : "",
                    })
                    sendData?.sponsor2Content?.push({
                        language: data?.sponsor1Content?.language,
                        imageBase64: data?.sponsor2Content?.value?.fileBase64 ? data?.sponsor2Content?.value?.fileBase64 : data?.sponsor2Content?.value?.fileBase64 === null ? null : "",
                        imageContentType: data?.sponsor2Content?.value?.fileContentType ? data?.sponsor2Content?.value?.fileContentType : data?.sponsor2Content?.value?.fileContentType === null ? null : "",
                    })
                }


                if (!detail) {
                    if (data?.sponsor1Content?.value?.fileBase64) {
                        sendData?.sponsor1Content?.push({
                            language: data?.sponsor2Content?.language,
                            imageBase64: data?.sponsor1Content?.value?.fileBase64 ? data?.sponsor1Content?.value?.fileBase64 : data?.sponsor1Content?.value?.fileBase64 === null ? null : "",
                            imageContentType: data?.sponsor1Content?.value?.fileContentType ? data?.sponsor1Content?.value?.fileContentType : data?.sponsor1Content?.value?.fileContentType === null ? null : "",
                        })
                    }

                    if (data?.sponsor2Content?.value?.fileBase64) {
                        sendData?.sponsor2Content?.push({
                            language: data?.sponsor2Content?.language,
                            imageBase64: data?.sponsor2Content?.value?.fileBase64 ? data?.sponsor2Content?.value?.fileBase64 : data?.sponsor2Content?.value?.fileBase64 === null ? null : "",
                            imageContentType: data?.sponsor2Content?.value?.fileContentType ? data?.sponsor2Content?.value?.fileContentType : data?.sponsor2Content?.value?.fileContentType === null ? null : "",
                        })
                    }
                }
                sendData?.sponsor1Link?.push(data.sponsor1Link)
                sendData?.sponsor2Link?.push(data.sponsor2Link)
            })

            try {
                const createResponse = await onSubmit(sendData)

                if (!detail && !createResponse) {
                    reset();
                    setLangData();
                    setSelectedCategory();
                    setSelectedTags();
                    setSelectedType();
                    setMainPicture();
                    setFormDisabled(true)
                    setArticlesList([])
                    setResetFlag(resetFlag ? false : true);
                }

                setTimeout(function () {
                    window.scrollTo(0, 0);
                }, 0);

            } catch (error) {
                console.error(error);
            }
        }
    }

    const langInputUpdate = (data, iso, target) => {
        setLangData({
            ...langData, [iso]: {
                ...langData[iso], [target]: {
                    ...langData[iso][target], "value": data
                }
            }
        });
    }

    const setDataToBase64 = async (file, iso, id, lang) => {
        if (id) { // update video in front
            setLangData({
                ...langData, [iso]: {
                    ...langData[iso], [`sponsor${id}Content`]: {
                        ...langData[iso][`sponsor${id}Content`], "value": null
                    }
                }
            });
        }

        if (file) {
            const base = await BodyService.getBase64(file);
            const fileData = {};
            fileData.fileBase64 = base.split(',')[1];
            fileData.fileContentType = file.type;

            if (lang) {
                langInputUpdate(fileData, iso, `sponsor${id}Content`)
            } else {
                setMainPicture(fileData)
            }
        }
    }

    const removeImg = (iso, id) => {
        if (iso) {
            setLangData({
                ...langData, [iso]: {
                    ...langData[iso], [`sponsor${id}Content`]: {
                        ...langData[iso][`sponsor${id}Content`], "value": ""
                    }
                }
            });

            document.getElementById(`sponsor-${iso}-${id}`).value = null
        } else {
            setMainPicture(null)
            document.getElementById(`main-picture`).value = null
        }
    }

    const getBase64Src = (image) => {
        if (!image?.fileBase64 || !image?.fileContentType) {
            return false;
        }
        return `data:${image.fileContentType};base64,${image.fileBase64}`;
    }

    const updateArticleList = (articleData, index, order, language, shortDescription) => {

        const updateList = articlesList.map((article, i) => {
            if (i === index) {
                let obj = {
                    data: articleData,
                    order: (order || order === '') ? order : articleData?.order,
                    shortDescription: article.shortDescription || {}
                };
                if (language) {
                    obj.shortDescription[language.language.iso] = { ...language };
                    obj.shortDescription[language.language.iso].value = shortDescription;
                }
                return obj;
            } else {
                return article
            }
        });

        setArticlesList(updateList);
    }


    const removeArticle = (removeId) => {

        let removeList = []

        articlesList.map((article, i) => {
            if (i !== removeId) {
                removeList.push(article)
            }
        })

        setArticlesList(removeList)
    }

    const getLocalityByRegion = async (id) => {
        const data = await LocalityService.getByRegionId(id);

        const emptyLocality = {
            id: null,
            title: [{ value: null }]
        }
        setLocalitiesList([emptyLocality, ...data]);
    };

    const getRegionByProvince = async (id) => {
        const data = await RegionService.getByProvinceId(id);

        const emptyRegion = {
            id: null,
            title: [{ value: null }]
        }
        setRegionsList([emptyRegion, ...data]);
    };

    useEffect(() => {
        if (languages && !detail) {
            let configLangObj = {};

            if (languages) {
                languages.forEach(lang => {
                    configLangObj[lang.language.iso] = {
                        title: lang,
                        slug: lang,
                        description: lang,
                        sponsor1Content: lang,
                        sponsor1Link: lang,
                        sponsor2Content: lang,
                        sponsor2Link: lang,
                        language: lang.language,
                    }
                });
            }

            setLangData(configLangObj);
        }

        if (detail && miniGuide && !langData && languages && categories) {

            let blockSlugs = {};

            let configLangObj = {}
            languages.forEach(lang => {

                configLangObj[lang.language.iso] = {
                    title: {
                        language: { id: lang.language.id },
                        value: miniGuide?.title?.[lang.language.iso].value
                    },

                    slug: {
                        language: { id: lang.language.id },
                        value: miniGuide?.slugs?.[lang.language.iso].value
                    },
                    description: {
                        language: { id: lang.language.id },
                        value: miniGuide?.description?.[lang.language.iso].value
                    },
                    sponsor1Link: {
                        language: { id: lang.language.id },
                        value: miniGuide?.sponsor1link?.[lang.language.iso].value
                    },

                    sponsor2Link: {
                        language: { id: lang.language.id },
                        value: miniGuide?.sponsor2link?.[lang.language.iso].value
                    },

                    sponsor1Content: {
                        language: { id: lang.language.id },
                        value: {
                            url: miniGuide?.sponsor1content?.[lang.language.iso].value,
                            fileBase64: null,
                            fileContentType: null
                        }
                    },

                    sponsor2Content: {
                        language: { id: lang.language.id },
                        value: {
                            url: miniGuide?.sponsor2content?.[lang.language.iso].value,
                            fileBase64: null,
                            fileContentType: null
                        }
                    },

                    language: lang.language,
                }

                blockSlugs[lang.language.iso] = miniGuide?.slugs?.[lang.language.iso].value ? true : false
            });

            let articleList = [];

            miniGuide?.articlesIds?.map((article, i) => {
                articleList.push(
                    {
                        data: article,
                        order: article?.order,
                        shortDescription: article?.articleShortDescriptionByLanguage
                    })
            })
            reset(miniGuide);
            setLangData(configLangObj)
            setMainPicture({
                url: miniGuide?.mainPicture,
                fileBase64: null,
                fileContentType: null
            })
            setSelectedCategory(categories?.find(cat => cat.name?.['es'].value === miniGuide.category?.['es'].value))
            setSelectedTags(miniGuide?.tagIds);
            setArticlesList(articleList)
            setSelectedType(miniGuide?.format === 1 ? { name: "Ranking", value: 1 } : { name: "Listado", value: 2 });
            setBlockSlugLang(blockSlugs);
        }

    }, [languages, resetFlag, miniGuide, categories])

    const checkDisable = () => {
        let blocked = true;
        let status = false;
        let statusLang = false;

        if (!selectedCategory || !selectedType || !mainPicture) {
            if (!formDisabled) {
                if (!requiredFields) {
                    setRequiredFields(true)
                }
                status = true
            }
        } else if (selectedCategory && selectedType && requiredFields && mainPicture) {
            setRequiredFields(false)
            status = false
        }

        if (langData) {
            Object.values(langData).forEach((data) => {
                if ((data.title?.value !== "" && data.title?.value) && (data.slug?.value !== "" && data.slug?.value)) {
                    blocked = false
                    if (formDisabled) {
                        setFormDisabled(false)
                    }
                    statusLang = false
                }
            })

            if (blocked) {
                if (!formDisabled) {
                    setFormDisabled(true)
                }
                statusLang = true
            }
        }

        return (status || statusLang)
    }

    useEffect(() => {
        if (!mainPicture) {
            checkDisable()
        }
    }, [mainPicture])

    return (
        <Form onSubmit={handleSubmit(onSubmitForm)}>
            {((requiredFields && !detail) || (requiredFields && detail && miniGuide)) && <small className='text-danger fw-bold d-block mb-2'>{t('miniGuide.form.required')}</small>}
            {((formDisabled && !detail) || (formDisabled && detail && miniGuide)) && <small className='text-warning fw-bold d-block mb-3'>{t('miniGuide.form.warning')}</small>}
            <Row className='mb-3'>
                <Col md={3}>
                    <FormGroup>
                        <Form.Label>{t('miniGuide.form.fields.category')}{((requiredFields && !detail) || (requiredFields && detail && miniGuide)) && <span className="ms-1 text-danger fw-bold">*</span>}</Form.Label>
                        <Select options={categories}
                            classNamePrefix="select"
                            placeholder={t('miniGuide.form.fields.placeholder.category')}
                            value={selectedCategory || ""}
                            getOptionLabel={(option) => option.name?.['es']?.value}
                            getOptionValue={(option) => option.id}
                            onChange={(data) => {
                                setSelectedCategory(data)
                            }}
                        />
                    </FormGroup>
                </Col>
                <Col md={3}>
                    <FormGroup>
                        <Form.Label>{t('miniGuide.form.fields.tags')}</Form.Label>
                        <Select options={tags}
                            isMulti
                            closeMenuOnSelect={false}
                            classNamePrefix="select"
                            placeholder={t('miniGuide.form.fields.placeholder.tag')}
                            value={selectedTags || ""}
                            getOptionLabel={(option) => option.name?.['es']?.value}
                            getOptionValue={(option) => option.id}
                            onChange={(data) => {
                                setSelectedTags(data)
                            }}
                        />
                    </FormGroup>
                </Col>
                <Col md={3}>
                    <FormGroup>
                        <Form.Label>{t('miniGuide.form.fields.format')}{((requiredFields && !detail) || (requiredFields && detail && miniGuide)) && <span className="ms-1 text-danger fw-bold">*</span>}</Form.Label>
                        <Select options={[
                            { name: "Ranking", value: 1 },
                            { name: "Listado", value: 2 }
                        ]}
                            classNamePrefix="select"
                            placeholder={t('miniGuide.form.fields.placeholder.type')}
                            value={selectedType || ""}
                            getOptionLabel={(option) => option.name}
                            getOptionValue={(option) => option.id}
                            onChange={(data) => {
                                setSelectedType(data)
                            }}
                        />
                    </FormGroup>
                </Col>
                <Col md={1}>
                    <Form.Label>{t('miniGuide.form.fields.order')}</Form.Label>
                    <Form.Control type="number" {...register('order')} />
                </Col>
                <Col xs={'auto'} className="align-self-end pt-2">
                    <FormCheck label={t('stamps.form.active')} className=' form-check-input-md' {...register("active")} />
                </Col>
            </Row>
            <Row className="mb-4">
                <Col md={3}>
                    <FormGroup>
                        <Form.Label>{t('sitesInterest.creation.province')}</Form.Label>
                        <Select options={provinces}
                            classNamePrefix="select"
                            placeholder=""
                            value={province || ''}
                            getOptionLabel={(option) => option?.name?.['es']?.value}
                            getOptionValue={(option) => option.id}
                            onChange={(data) => {
                                setProvince(data);
                                getRegionByProvince(data.id);
                                setRegion();
                                setLocality();
                            }}
                        />
                    </FormGroup>
                </Col>
                <Col md={3}>
                    <FormGroup>
                        <Form.Label>{t('sitesInterest.creation.region')}</Form.Label>
                        <Select options={regionsList}
                            isDisabled={!province}
                            classNamePrefix="select"
                            placeholder=""
                            value={region || ''}
                            getOptionLabel={(option) => option?.name?.['es']?.value}
                            getOptionValue={(option) => option.id}
                            onChange={(data) => {
                                setRegion(data);
                                getLocalityByRegion(data.id);
                                setLocality();
                            }}
                        />
                    </FormGroup>
                </Col>
                <Col md={3}>
                    <FormGroup>
                        <Form.Label>{t('sitesInterest.creation.locality')}</Form.Label>
                        <Select options={localitiesList}
                            isDisabled={!region}
                            classNamePrefix="select"
                            placeholder=""
                            value={locality || ''}
                            getOptionLabel={(option) => option?.name?.['es']?.value}
                            getOptionValue={(option) => option.id}
                            onChange={(data) => {
                                setLocality(data);
                            }}
                        />
                    </FormGroup>
                </Col>
            </Row>
            <Accordion className='mb-3'>
                <Accordion.Item eventKey="">
                    <Accordion.Header>
                        <h6 className={"m-0 fw-bold text-primary"}>{t('miniGuide.form.fields.articles')}</h6>
                    </Accordion.Header>
                    <Accordion.Body>
                        {articlesList?.map((article, i) =>
                            <Fragment key={i}>
                                {article &&
                                    <Row className={i !== 0 ? "my-3" : "mb-3 mt-1"}>
                                        <Col md={4}>
                                            <Select options={articles}
                                                classNamePrefix="select"
                                                placeholder={t('miniGuide.form.fields.placeholder.article')}
                                                value={article?.data || ""}
                                                getOptionLabel={(option) => `${option?.titleFirstPartByLanguage?.[i18n.language.split('-')[0]]?.value?.trim()} ${option?.titleSecondPartByLanguage?.[i18n.language.split('-')[0]]?.value ? option?.titleSecondPartByLanguage?.[i18n.language.split('-')[0]]?.value?.trim() : ""}`}
                                                getOptionValue={(option) => option.id}
                                                onChange={(data) => {
                                                    updateArticleList(data, i, article?.order);
                                                }}
                                            />
                                        </Col>
                                        <Col md={1}>
                                            <Form.Control type="text" placeholder={t('miniGuide.form.fields.placeholder.order')} value={article?.order || ""} onChange={(e) => { !isNaN(e.target.value) && updateArticleList(article?.data, i, e.target.value.trim()) }} />
                                        </Col>
                                        <Col md="auto">
                                            <Button variant="link" size="ls" onClick={() => { removeArticle(i) }}
                                                className="border-danger">
                                                <FontAwesomeIcon icon="fas fa-trash" className='text-danger' />
                                            </Button>
                                        </Col>
                                    </Row>
                                }
                                {/* <hr /> */}
                                <Form.Label>{t('miniGuide.form.fields.articleShortDescription')}</Form.Label>
                                {languages && languages?.map((lang, j) => (
                                    <Accordion key={j} className="mb-3">
                                        <Accordion.Item eventKey="">
                                            <Accordion.Header>
                                                <h6 className={"m-0 fw-bold text-primary"}>{lang?.language?.name}{((formDisabled && !detail) || (formDisabled && detail && miniGuide)) && <span className="ms-1 text-warning fw-bold">*</span>}</h6>
                                            </Accordion.Header>
                                            <Accordion.Body>
                                                <Col md={8}>
                                                    <Form.Control as="textarea" rows={3} value={article?.shortDescription?.[lang?.language?.iso]?.value || ''} onChange={e => updateArticleList(article?.data, i, article?.order, lang, e.target.value)} />
                                                </Col>
                                            </Accordion.Body>
                                        </Accordion.Item>
                                    </Accordion>
                                ))}
                                <hr />
                            </Fragment>
                        )}
                        <Row className={`${articlesList.length > 0 && "mt-3"}`}>
                            <Col md="auto">
                                <FormGroup onClick={() => { setArticlesList(prevState => [...prevState, { data: null, order: null, shortDescription: null }]) }} >
                                    <Form.Label
                                        style={{ cursor: "pointer" }}>{t('miniGuide.form.fields.addArticle')}</Form.Label>
                                    <Button variant="link" size="ls" className={"ms-2 border-primary"}>
                                        <FontAwesomeIcon icon="fas fa-plus" />
                                    </Button>
                                </FormGroup>
                            </Col>
                        </Row>
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>
            {languages && languages?.map((lang, i) => (
                <Accordion key={i} className="mb-3">
                    <Accordion.Item eventKey="">
                        <Accordion.Header>
                            <h6 className={"m-0 fw-bold text-primary"}>{lang?.language?.name}{((formDisabled && !detail) || (formDisabled && detail && miniGuide)) && <span className="ms-1 text-warning fw-bold">*</span>}</h6>
                        </Accordion.Header>
                        <Accordion.Body>
                            <Row className='mb-3'>
                                <Col md={4}>
                                    <Form.Label>{t('miniGuide.form.fields.title')}{((formDisabled && !detail)) && <span className="ms-1 text-warning fw-bold">*</span>}</Form.Label>
                                    <Form.Control type="text" value={langData?.[lang.language.iso]?.title?.value || ''} onChange={e => langInputUpdate(e.target.value, lang.language.iso, "title")} />
                                </Col>
                                <Col md={4}>
                                    <Form.Label>{t('miniGuide.form.fields.slug')}{((formDisabled && !detail)) && <span className="ms-1 text-warning fw-bold">*</span>}</Form.Label>
                                    <Form.Control type="text" disabled={detail && blockSlugLang[lang.language.iso]} value={langData?.[lang.language.iso]?.slug.value || ''} onChange={e => langInputUpdate(BodyService.slugifyStr(e.target.value), lang.language.iso, "slug")} />
                                </Col>
                            </Row>
                            <Row className='mb-3'>
                                <Col md={8}>
                                    <Form.Label>{t('miniGuide.form.fields.description')}</Form.Label>
                                    <Form.Control as="textarea" rows={3} value={langData?.[lang.language.iso]?.description?.value || ''} onChange={e => langInputUpdate(e.target.value, lang.language.iso, "description")} />
                                </Col>
                            </Row>
                            <Accordion className='mb-3'>
                                <Accordion.Item eventKey="">
                                    <Accordion.Header>
                                        <h6 className={"m-0 fw-bold text-primary"}>{t('miniGuide.form.fields.sponsor1.title')}</h6>
                                    </Accordion.Header>
                                    <Accordion.Body>
                                        <Row className='mb-3'>
                                            <Col md={4}>
                                                <Form.Label>{t('miniGuide.form.fields.sponsor1.link')}</Form.Label>
                                                <Form.Control type="text" value={langData?.[lang.language.iso]?.sponsor1Link?.value || ''} onChange={e => langInputUpdate(e.target.value, lang.language.iso, "sponsor1Link")} />
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md={'auto'}>
                                                <Row>
                                                    <Col md="auto" className={(langData?.[lang.language.iso]?.sponsor1Content?.value?.fileBase64 || langData?.[lang.language.iso]?.sponsor1Content?.value?.url) && "mb-3"}>
                                                        <Form.Label>{t('miniGuide.form.fields.sponsor1.content')}</Form.Label>
                                                        <Form.Control id={`sponsor-${lang.language.iso}-1`} type="file"
                                                            accept="image/*, video/mp4, video/quicktime"
                                                            onChange={(e) => setDataToBase64(e.target.files[0], lang.language.iso, "1", true)} />
                                                    </Col>
                                                    {(langData?.[lang.language.iso]?.sponsor1Content?.value?.fileBase64 || langData?.[lang.language.iso]?.sponsor1Content?.value?.url) && <Col md="auto" className={`align-self-end ${(langData?.[lang.language.iso]?.sponsor1Content?.value?.url || langData?.[lang.language.iso]?.sponsor1Content?.value) && "mb-3"}`}>
                                                        <Button variant="link" size="ls" onClick={() => removeImg(lang.language.iso, 1)}
                                                            className="border-primary">
                                                            <FontAwesomeIcon icon="fas fa-trash" />
                                                        </Button>
                                                    </Col>}
                                                </Row>
                                                {(langData?.[lang.language.iso]?.sponsor1Content?.value?.fileBase64 || langData?.[lang.language.iso]?.sponsor1Content?.value?.url) &&
                                                    <>
                                                        {langData?.[lang.language.iso]?.sponsor1Content?.value?.fileContentType?.split('/')[0] === "image" || ((langData?.[lang.language.iso]?.sponsor1Content?.value?.url)?.includes("jpeg") || (langData?.[lang.language.iso]?.sponsor1Content?.value?.url)?.includes("png")) ?
                                                            <Col className="col-3">
                                                                <Image fluid thumbnail src={getBase64Src(langData?.[lang.language.iso]?.sponsor1Content?.value) || langData?.[lang.language.iso]?.sponsor1Content?.value?.url}
                                                                    alt="test" />
                                                            </Col>
                                                            :
                                                            <Col className="col-7">
                                                                <video autoPlay loop muted controls alt="test" className='w-100'>
                                                                    <source src={getBase64Src(langData?.[lang.language.iso]?.sponsor1Content?.value) || langData?.[lang.language.iso]?.sponsor1Content?.value?.url} type={"video/mp4"} />
                                                                </video>
                                                            </Col>
                                                        }
                                                    </>
                                                }
                                            </Col>
                                        </Row>
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                            <Accordion className='mb-3'>
                                <Accordion.Item eventKey="">
                                    <Accordion.Header>
                                        <h6 className={"m-0 fw-bold text-primary"}>{t('miniGuide.form.fields.sponsor2.title')}</h6>
                                    </Accordion.Header>
                                    <Accordion.Body>
                                        <Row className='mb-3'>
                                            <Col md={4}>
                                                <Form.Label>{t('miniGuide.form.fields.sponsor2.link')}</Form.Label>
                                                <Form.Control type="text" value={langData?.[lang.language.iso]?.sponsor2Link?.value || ''} onChange={e => langInputUpdate(e.target.value, lang.language.iso, "sponsor2Link")} />
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md={'auto'}>
                                                <Row>
                                                    <Col md="auto" className={langData?.[lang.language.iso]?.sponsor2Content?.value && "mb-3"}>
                                                        <Form.Label>{t('miniGuide.form.fields.sponsor2.content')}</Form.Label>
                                                        <Form.Control id={`sponsor-${lang.language.iso}-2`} type="file"
                                                            accept="image/*, video/*"
                                                            onChange={(e) => setDataToBase64(e.target.files[0], lang.language.iso, 2, true)} />
                                                    </Col>
                                                    {(langData?.[lang.language.iso]?.sponsor2Content?.value?.fileBase64 || langData?.[lang.language.iso]?.sponsor2Content?.value?.url) && <Col md="auto" className={`align-self-end ${langData?.[lang.language.iso]?.sponsor2Content?.value && "mb-3"}`}>
                                                        <Button variant="link" size="ls" onClick={() => removeImg(lang.language.iso, 2)}
                                                            className="border-primary">
                                                            <FontAwesomeIcon icon="fas fa-trash" />
                                                        </Button>
                                                    </Col>}
                                                </Row>
                                                {(langData?.[lang.language.iso]?.sponsor2Content?.value?.fileBase64 || langData?.[lang.language.iso]?.sponsor2Content?.value?.url) &&
                                                    <>
                                                        {langData?.[lang.language.iso]?.sponsor2Content?.value?.fileContentType?.split('/')[0] === "image" || ((langData?.[lang.language.iso]?.sponsor2Content?.value?.url)?.includes("jpeg") || (langData?.[lang.language.iso]?.sponsor2Content?.value?.url)?.includes("png")) ?
                                                            <Col className="col-3">
                                                                <Image fluid thumbnail src={getBase64Src(langData?.[lang.language.iso]?.sponsor2Content?.value) || langData?.[lang.language.iso]?.sponsor2Content?.value?.url}
                                                                    alt="test" />
                                                            </Col>
                                                            :
                                                            <Col className="col-7">
                                                                <video autoPlay loop muted controls alt="test" className='w-100'>
                                                                    <source src={getBase64Src(langData?.[lang.language.iso]?.sponsor2Content?.value) || langData?.[lang.language.iso]?.sponsor2Content?.value?.url} type={"video/mp4"} />
                                                                </video>
                                                            </Col>
                                                        }
                                                    </>
                                                }
                                            </Col>
                                        </Row>
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>
            ))}
            <Row>
                <Col md={'auto'}>
                    <Row>
                        <Col md="auto" className="mb-3">
                            <Form.Label>{t('miniGuide.form.fields.mainPicture')}{((requiredFields && !detail) || (requiredFields && detail && miniGuide)) && <span className="ms-1 text-danger fw-bold">*</span>}</Form.Label>
                            <Form.Control id={`main-picture`} type="file"
                                accept="image/png, image/jpeg"
                                onChange={(e) => setDataToBase64(e.target.files[0], 'es', 0, false)} />
                        </Col>
                        {(mainPicture?.fileBase64 || mainPicture?.url) &&
                            <Col md="auto" className={`align-self-end ${(mainPicture?.fileBase64 || mainPicture?.url) && "mb-3"}`}>
                                <Button variant="link" size="ls" onClick={() => removeImg(false)}
                                    className="border-primary">
                                    <FontAwesomeIcon icon="fas fa-trash" />
                                </Button>
                            </Col>}
                    </Row>
                    {(mainPicture?.fileBase64 || mainPicture?.url) &&
                        <Col className="col-3">
                            <Image fluid thumbnail src={mainPicture?.url || getBase64Src(mainPicture)} alt="test" />
                        </Col>
                    }
                </Col>
            </Row>
            {detail ? 
            <Button type="submit" className={"mt-3"} disabled={ permissions.miniGuides?.update === false ? true : checkDisable() } >
                {t('stamps.update.button')}
            </Button>        
            : 
            <Button type="submit" className={"mt-3"} disabled={ permissions.miniGuides?.create === false ? true : checkDisable() } >
                {t('stamps.form.submit')}
            </Button>
            }
        </Form>
    )
}

export default MiniGuideForm