import api from './api';

const getAll = (pagination, sortColumn, language, filter) => api.get('/section', {
    pageNum: pagination?.currentPage - 1,
    pageLength: pagination?.itemsPerPage,
    search: filter?.search,
    language: language,
    active: filter?.active,
    sortBy: sortColumn?.sortByColumn,
    orderBy: sortColumn?.orderBy
}).then(response => {
    return response.data;
});

const create = (data) => api.post('/section', data).then(response => {
    return response.data
});

const getById = (id) => api.get(`/section/${id}`).then(response => {
    return response.data
});

const update = (id, data) => api.put(`/section/${id}`, data).then(response => {
    return response.data
});

const remove = (id) => api.delete(`/section/${id}`).then(response => {
    return response.data
});

const SectionService = {
    getAll,
    create,
    getById,
    update,
    remove
};

export default SectionService;