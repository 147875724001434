import React, { useEffect, useState } from 'react'
import { Alert, Card } from 'react-bootstrap';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import BodyService from '../../../services/body.service';
import APP_CONF from '../../../scripts/constants';
import SectionService from '../../../services/section.service';
import SectionForm from '../../../components/forms/SectionForm';
import UserPermissionService from "../../../services/userPermission.service";

function SectionsDetails() {
  const { t } = useTranslation();
  const params = useParams();
  const navigate = useNavigate();

  const [languages, setLanguages] = useState();
  const [section, setSection] = useState();
  const [permissions, setPermissions] = useState({});

  const [success, setSuccess] = useState({
    update: false,
  });

  const [errors, setErrors] = useState({
    update: false,
  });

  const [filter, setFilter] = useState({
    active: true,
    search: ""
  })

  const [pagination, setPagination] = useState({
    currentPage: 1,
    totalItems: 0,
    itemsPerPage: APP_CONF.ITEMS_PER_PAGE,
    numPages: 1
  });

  const getSection = async (id) => {
    try {
      const data = await SectionService.getById(id)
      setSection(data)
    } catch (error) {
      console.error(error);
    }
  }

  const updateSection = async (data) => {
    setErrors({ ...errors, update: false });
    setSuccess({ ...success, update: false });

    try {
      await SectionService.update(params.id, data)
      setSuccess({ ...success, update: true });
    } catch (e) {
      setErrors({ ...errors, update: true });
    }
  }

  const getEmptyTranslations = async () => {
    const data = await BodyService.getEmptyTranslations();
    setLanguages(data);
  }

  const getPermissions = async () => {
    try {
        const data = await UserPermissionService.getPermissionsByConnected();
        setPermissions(data);
    } catch (error) {
        console.error(error);
    }
  }

  useEffect(() => {
    getSection(params.id);
  }, [params.id]);

  useEffect(() => {
    const loadData = async () => {
      await getPermissions();
      await getEmptyTranslations();

  }

  loadData();
  }, [])

  return (
    <>
      <div className={"d-flex justify-content-between my-3"}>
        <Link to="/section" className={"previous round rounded-circle text-decoration-none"}
          title={t('general.back')}>
          <FontAwesomeIcon icon={"chevron-left"} className={"mt-2 me-2"} />
          <span>{t('section.title')}</span>
        </Link>
      </div>
      <Alert variant={"success"} show={success.update} dismissible onClose={() => setSuccess({ ...success, update: false })}>
        {t('section.success.update')}
      </Alert>
      <Alert variant={"danger"} show={errors.update} dismissible onClose={() => setErrors({ ...errors, update: false })}>
        {t('section.error.update')}
      </Alert>
      <Card>
        <Card.Header className={"py-3"}>
          <h6 className={"m-0 fw-bold text-primary"}>{t('section.update.title')}</h6>
        </Card.Header>
        <Card.Body>
          <SectionForm onSubmit={updateSection} section={section} languages={languages} permissions={permissions} detail />
        </Card.Body>
      </Card>
    </>
  );
}

export default SectionsDetails