import { Button, Card, Table, Alert, Image, Row, Col, Form, FormGroup } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import LanguageService from "../../services/language.service";
import APP_CONF from "../../scripts/constants";

import PaginationComponent from "../../components/PaginationComponent";
import TableHeaderSortComponent from "../../components/TableHeaderSortComponent";
import UserPermissionService from "../../services/userPermission.service";

const Language = () => {
    const { t } = useTranslation();

    const { register, handleSubmit } = useForm({
        mode: "all",
    });

    const [sortColumn, setSortColumn] = useState({
        sortByColumn: null,
        orderBy: null
    });

    const [indexDesc, setIndexDesc] = useState(false);

    const [filter, setFilter] = useState({
    })

    const [pagination, setPagination] = useState({
        currentPage: 1,
        totalItems: 0,
        itemsPerPage: APP_CONF.ITEMS_PER_PAGE,
        numPages: 1
    });

    const [success, setSuccess] = useState({
        create: false,
        delete: false,
    });
    const [errors, setErrors] = useState({
        create: false,
        delete: false,
    });

    const [languages, setLanguages] = useState([]);

    const [permissions, setPermissions] = useState({});

    const getPermissions = async () => {
        try {
            const data = await UserPermissionService.getPermissionsByConnected();
            setPermissions(data);
        } catch (error) {
            console.error(error);
        }
    }

    useEffect(() => {
        const loadData = async () => {
            await getPermissions();
        }

        loadData();
    }, []);

    useEffect(() => {
        getLanguages()
        if (sortColumn.sortByColumn === 'id' && sortColumn.orderBy === 'desc') {
            setIndexDesc(true);
        } else {
            setIndexDesc(false);
        }
    }, [pagination.currentPage, filter, sortColumn]);

    const getLanguages = async () => {
        try {
            const data = await LanguageService.getAll(pagination, filter, sortColumn)
            setLanguages(data.content);
            setPagination({
                ...pagination,
                totalItems: data.totalElements,
                numPages: data.totalPages
            });
        } catch ({ response }) {
            if (response?.data?.errorCode) {
                switch (response.data.errorCode) {
                    case 13002:
                        setErrors({ ...errors, email: true });
                        break;
                    default:
                        setErrors({ ...errors, create: true });
                }
            }
        }
    }

    const filterSearch = (data) => {
        setFilter({
            search: data.search
        });
    }

    return (
        <>
            <div className={"d-sm-flex align-items-center justify-content-between mb-4"}>
                <h1 className={"h3 mb-0 text-gray-800"}>{t('languages.title')}</h1>
            </div>

            <Alert variant={"success"} show={success.create} dismissible onClose={() => setSuccess({ ...success, create: false })}>
                {t('languages.success.create')}
            </Alert>

            <Alert variant={"danger"} show={errors.create} dismissible onClose={() => setErrors({ ...errors, create: false })}>
                {t('languages.error.create')}
            </Alert>

            <Alert variant={"success"} show={success.delete} dismissible onClose={() => setSuccess({ ...success, delete: false })}>
                {t('languages.success.delete')}
            </Alert>

            <Alert variant={"danger"} show={errors.delete} dismissible onClose={() => setErrors({ ...errors, delete: false })}>
                {t('languages.error.delete')}
            </Alert>

            <Card className={"shadow mb-4"}>
                <Card.Header className={"py-3"}>
                    <h6 className={"m-0 fw-bold text-primary"}>{t('languages.list.title')}</h6>
                </Card.Header>
                <Card.Body>
                    <Form onSubmit={handleSubmit(filterSearch)}>
                        <Row>
                            <Col lg="3" >
                                <FormGroup>
                                    <Form.Label>{t('languages.filter.search')}</Form.Label>
                                    <Form.Control type="text" {...register("search")} />
                                </FormGroup>
                            </Col>
                        </Row>
                        <Button type="submit" className={"mt-3"} disabled={!permissions.languages?.read}>{t('languages.filter.button')}</Button>
                    </Form>
                    <hr />
                    <Table responsive>
                        <thead>
                            <tr>
                                <TableHeaderSortComponent scope={"col"} text={t('general.index')} column={"id"} sortColumn={sortColumn} setSortColumn={setSortColumn} />
                                <TableHeaderSortComponent scope={"col"} text={t('languages.list.fields.name')} column={"name"} sortColumn={sortColumn} setSortColumn={setSortColumn} />
                                <th scope="col">{t('languages.list.fields.icon')}</th>
                                <TableHeaderSortComponent scope={"col"} text={t('languages.list.fields.iso')} column={"iso"} sortColumn={sortColumn} setSortColumn={setSortColumn} />
                                <TableHeaderSortComponent scope={"col"} text={t('languages.list.fields.iso2')} column={"iso2"} sortColumn={sortColumn} setSortColumn={setSortColumn} />
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {languages.length > 0 ? languages.map((language, index) => (
                                <tr key={index}>
                                    {indexDesc ? <td>{languages.length - index}</td> : <td>{index + 1}</td>}
                                    <td>{language.name}</td>
                                    <td><Image fluid thumbnail src={language.icon} className="flags-img-md" /></td>
                                    <td>{language.iso}</td>
                                    <td>{language.iso2}</td>
                                    <td className={"text-center"}>
                                        <Button variant="link" size="sm" className={"ms-2"} to={`/language/${language.id}`} as={Link} disabled={!permissions.languages?.read}>
                                            <FontAwesomeIcon icon="edit" />
                                        </Button>
                                    </td>
                                </tr>
                            )) :
                                <tr>
                                    <td colSpan={4}>{t('general.empty')}</td>
                                </tr>
                            }
                        </tbody>
                    </Table>
                    <PaginationComponent pagination={pagination}
                        setPagination={setPagination}
                        alwaysShown={languages.length > 0} />
                </Card.Body>
            </Card>
        </>
    );
};

export default Language;
