import api from './api';

const getAll = (pagination, filter, sortColumn) => api.get('/language', {
    pageNum: pagination.currentPage - 1, 
    pageLength: pagination.itemsPerPage,
    search: filter.search,
    sortBy: sortColumn.sortByColumn,
    orderBy: sortColumn.orderBy
}).then(response => {
    return response.data;
});

const getById = (id) => api.get(`/language/${id}`).then(response => {
    return response.data
});

// const create = (data) => api.post('/user', data).then(response => {
//     return response.data
// });

const update = (id, data) => api.put(`/language/${id}`, data).then(response => {
    return response.data
});

const LanguageService = {
    getAll,
    getById,
    update
};

export default LanguageService;