import { Row, Col, Form, FormGroup } from "react-bootstrap";
import { useTranslation } from "react-i18next";

const ArticleUpdateMetaForm = ({ meta, iso, onChange }) => {

    const { t } = useTranslation();

    const setData = async (data, target, iso) => {
        onChange(data, target, iso);
    }

    return (
        <>
            <Row>
                <Col md="6" className="mt-3">
                    <FormGroup >
                        <Form.Label>{t('articles.creation.content.meta.fields.title')}</Form.Label>
                        <Form.Control type="text" value={meta?.[iso]?.metaTitleByLanguage || ''} onChange={e => setData('metaTitleByLanguage', e.target.value, iso)} />
                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col md="12" className="mt-3">
                    <FormGroup>
                        <Form.Label>{t('articles.creation.content.meta.fields.description')}</Form.Label>
                        <Form.Control as='textarea' value={meta?.[iso]?.metaDescriptionByLanguage || ''} rows={3} onChange={e => setData('metaDescriptionByLanguage', e.target.value, iso)} />
                    </FormGroup>
                </Col>
            </Row>
        </>
    )
}

export default ArticleUpdateMetaForm