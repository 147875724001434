import { Accordion, Button, Card, Table, Alert, Image, Row, Col, Form, FormGroup } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import { useState } from 'react'
import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';

import ChargerTypeService from '../../../services/chargerTypes.service';
import ChargerTypeForm from "../../../components/forms/configuration/ChargerTypeForm"
import UseAlertConfirm from "../../../hooks/UseAlertConfirm";
import BodyService from "../../../services/body.service";
import PaginationComponent from "../../../components/PaginationComponent";
import TableHeaderSortComponent from "../../../components/TableHeaderSortComponent";
import APP_CONF from "../../../scripts/constants";
import { useForm } from "react-hook-form";
import UserPermissionService from "../../../services/userPermission.service";

const ChargerType = () => {
    const { t } = useTranslation();

    const { showModal } = UseAlertConfirm();

    const [pagination, setPagination] = useState({
        currentPage: 1,
        totalItems: 0,
        itemsPerPage: APP_CONF.ITEMS_PER_PAGE,
        numPages: 1
    });

    const [sortColumn, setSortColumn] = useState({
        sortByColumn: null,
        orderBy: null
    });

    const { register, handleSubmit } = useForm({
        mode: "all",
    });

    const [filter, setFilter] = useState({
        active: "",
        search: ""
    })

    const [chargerTypes, setChargerTypes] = useState([]);
    
    const [translations, setTranslations] = useState([]);

    const [permissions, setPermissions] = useState({});

    const [success, setSuccess] = useState({
        create: false,
        delete: false,
    });

    const [errors, setErrors] = useState({
        create: false,
        delete: false,
    });

    const getPermissions = async () => {
        try {
            const data = await UserPermissionService.getPermissionsByConnected();
            setPermissions(data);
        } catch (error) {
            console.error(error);
        }
    }

    useEffect(() => {
        const loadData = async () => {
            await getPermissions();
            await getEmptyTranslations();
        }

        loadData();
    }, []);

    useEffect(() => {
        getChargerTypes();
    }, [pagination.currentPage, sortColumn, filter])

    const getChargerTypes = async () => {
        try {
            const data = await ChargerTypeService.getAll(pagination, filter, sortColumn);
            setChargerTypes(data.content);
            setPagination({
                ...pagination,
                totalItems: data.totalElements,
                numPages: data.totalPages
            });
        } catch (response) {
            if (response?.data?.errorCode) {
                switch (response.data.errorCode) {
                    case 13002:
                        setErrors({ ...errors, email: true });
                        break;
                    default:
                        setErrors({ ...errors, create: true });
                }
            }
        }
    }

    const getEmptyTranslations = async () => {
        const data = await BodyService.getEmptyTranslations();
        setTranslations(data);
    }

    const createChargerType = async (data) => {
        setErrors({ ...errors, create: false });
        setSuccess({ ...success, create: false });
        try {
            await ChargerTypeService.create(data);
            getEmptyTranslations();
            setSuccess({ ...success, create: true });
            getChargerTypes();
        } catch (error) {
            console.error(error.response);
            setErrors({ ...errors, create: true });
        }
    }

    const deleteChargerType = async (id) => {
        showModal({
            title: t("general.delete.title"),
            body: t("general.delete.message"),
            confirmButtonVariant: "danger",
            onConfirm: async () => {
                setErrors({ ...errors, create: false });
                setSuccess({ ...success, create: false });
                try {
                    await ChargerTypeService.remove(id);
                    setSuccess({ ...success, delete: true });
                    getChargerTypes();
                } catch (e) {
                    console.error(e.response);
                    setErrors({ ...errors, delete: true });
                }
            }
        });
    } 

    const filterSearch = (data) => {
        setFilter({
            active: data.active,
            search: data.search
        });
    }

    return (<>
        <div className={"d-sm-flex align-items-center justify-content-between mb-4"}>
            <h1 className={"h3 mb-0 text-gray-800"}>{t('chargerType.title')}</h1>
        </div>

        <Alert variant={"danger"} show={errors.create} dismissible onClose={() => setErrors({ ...errors, create: false })}>
            {t('chargerType.error.create')}
        </Alert>

        <Alert variant={"success"} show={success.create} dismissible onClose={() => setSuccess({ ...success, create: false })}>
            {t('chargerType.success.create')}
        </Alert>

        <Alert variant={"success"} show={success.delete} dismissible onClose={() => setSuccess({ ...success, delete: false })}>
            {t('chargerType.success.delete')}
        </Alert>

        <Alert variant={"danger"} show={errors.delete} dismissible onClose={() => setErrors({ ...errors, delete: false })}>
            {t('chargerType.error.delete')}
        </Alert>

        {permissions.chargerTypes?.read && permissions.chargerTypes?.create ? 
        <Card className={"shadow mb-4"}>
            <Accordion>
                <Accordion.Item eventKey="0">
                    <Accordion.Header><h6 className={"m-0 fw-bold text-primary"}>{t('chargerType.form.title')}</h6>
                    </Accordion.Header>
                    <Accordion.Body>
                        <ChargerTypeForm onSubmit={createChargerType} connector={translations} permissions={permissions}/>
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>
        </Card>        
        : null }

        <hr />
        <Card className={"shadow mb-4"}>
            <Card.Header className={"py-3"}>
                <h6 className={"m-0 fw-bold text-primary"}>{t('chargerType.table.title')}</h6>
            </Card.Header>
            <Card.Body>
            <Form onSubmit={handleSubmit(filterSearch)}>
                        <Row>
                            <Col lg="2" className={"mb-3"}>
                                <FormGroup>
                                    <Form.Label>{t('chargerType.filter.active.title')}</Form.Label>
                                    <Form.Select {...register('active')} aria-label="Default select example">
                                        <option value="">{t('chargerType.filter.active.value1')}</option>
                                        <option value={true}>{t('chargerType.filter.active.value2')}</option>
                                        <option value={false}>{t('chargerType.filter.active.value3')}</option>
                                    </Form.Select>
                                </FormGroup>
                            </Col>
                            <Col lg="3" className={"mb-3"}>
                                <FormGroup>
                                    <Form.Label>{t('chargerType.filter.search')}</Form.Label>
                                    <Form.Control type="text" {...register("search")} />
                                </FormGroup>
                            </Col>
                        </Row>
                        <Button type="submit" className={"mt-3"} disabled={!permissions.chargerTypes?.read}>{t('chargerType.filter.button')}</Button>
                    </Form>
                    <hr />
                <Table responsive>
                    <thead>
                        <tr>
                            <TableHeaderSortComponent scope={"col"} text={t('chargerType.table.connector')} column={"name"} sortColumn={sortColumn} setSortColumn={setSortColumn} />
                            <TableHeaderSortComponent scope={"col"} text={t('chargerType.table.power')} column={"power"} sortColumn={sortColumn} setSortColumn={setSortColumn} />
                            <TableHeaderSortComponent scope={"col"} text={t('chargerType.table.active')} column={"active"} sortColumn={sortColumn} setSortColumn={setSortColumn} />
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {chargerTypes ? chargerTypes.map((chargerType, index) => (
                            <tr key={index}>
                                <td>
                                    <div className="d-flex flex-row flex-nowrap">
                                        {Object.values(chargerType.name).map((translation, i) =>
                                            <div key={i} className="me-2">
                                                <Image src={translation.language.icon} className="me-2 flags-img-sm" />
                                                {i + 1 === Object.values(chargerType.name).length ? translation.value : translation.value + ' | '}
                                            </div>
                                        )}
                                    </div>
                                </td>
                                <td>{chargerType?.power}</td>
                                <td>{chargerType?.active ? 'Sí' : 'No'}</td>
                                <td className={"text-center"}>
                                    <Button variant="link" size="sm" className="ms-2" to={`/configuration/charger-type/${chargerType.id}`} as={Link} disabled={!permissions.chargerTypes?.read}>
                                        <FontAwesomeIcon icon="edit" />
                                    </Button>
                                    <Button variant="link" size="sm" className="text-danger" onClick={() => deleteChargerType(chargerType.id)} disabled={!permissions.chargerTypes?.delete}>
                                        <FontAwesomeIcon icon="trash" />
                                    </Button>
                                </td>
                            </tr>
                        )) :
                            <tr>
                                <td colSpan={4}>{t('general.empty')}</td>
                            </tr>
                        }
                    </tbody>
                </Table>
                <PaginationComponent pagination={pagination}
                        setPagination={setPagination}
                        alwaysShown={chargerTypes.length > 0} />
            </Card.Body>
        </Card>
    </>)
}

export default ChargerType;