import { Accordion, Button, Card, Table, Alert, Image, Row, Col, Form, FormGroup } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import CategoryService from "../../services/category.service";

import APP_CONF from "../../scripts/constants";
import CategoryForm from "../../components/forms/CategoryForm";
import PaginationComponent from "../../components/PaginationComponent";
import TableHeaderSortComponent from "../../components/TableHeaderSortComponent";
import UserPermissionService from "../../services/userPermission.service";

import UseAlertConfirm from "../../hooks/UseAlertConfirm";

const Category = () => {
    const { t } = useTranslation();

    const { showModal } = UseAlertConfirm();

    const { register, handleSubmit } = useForm({
        mode: "all",
    });

    const [sortColumn, setSortColumn] = useState({
        sortByColumn: null,
        orderBy: null
    });

    const [indexDesc, setIndexDesc] = useState(false);
    const [pagination, setPagination] = useState({
        currentPage: 1,
        totalItems: 0,
        itemsPerPage: APP_CONF.ITEMS_PER_PAGE,
        numPages: 1
    });
    const [filter, setFilter] = useState({
        active: ""
    })

    const [success, setSuccess] = useState({
        create: false,
        delete: false,
    });
    const [errors, setErrors] = useState({
        create: false,
        delete: false,
    });

    const [categories, setCategories] = useState([]);
    const [permissions, setPermissions] = useState({});

    useEffect(() => {
        getCategories();
        if (sortColumn.sortByColumn === 'id' && sortColumn.orderBy === 'desc') {
            setIndexDesc(true);
        } else {
            setIndexDesc(false);
        }
    }, [pagination.currentPage, filter, sortColumn]);

    const getPermissions = async () => {
        try {
            const data = await UserPermissionService.getPermissionsByConnected();
            setPermissions(data);
        } catch (error) {
            console.error(error);
        }
    }

    useEffect(() => {
        const loadData = async () => {
            await getPermissions();
        }

        loadData();
    }, [])

    const getCategories = async () => {
        try {
            const data = await CategoryService.getAll(pagination, filter, sortColumn)
            setCategories(data.content);
            setPagination({
                ...pagination,
                totalItems: data.totalElements,
                numPages: data.totalPages
            });
        } catch ({ response }) {
            if (response?.data?.errorCode) {
                switch (response.data.errorCode) {
                    case 13002:
                        setErrors({ ...errors, email: true });
                        break;
                    default:
                        setErrors({ ...errors, create: true });
                }
            }
        }
    }
    
    const createCategory = async (data) => {
        setErrors({ ...errors, create: false });
        setSuccess({ ...success, create: false });

        try {
            await CategoryService.create(data);
            setSuccess({ ...success, create: true });
            getCategories();
        } catch (e) {
            console.error(e.response);
            setErrors({ ...errors, create: true });
        }
    };

    const deleteCategory = async (id) => {

        showModal({
            title: t("general.delete.title"),
            body: t("general.delete.message"),
            confirmButtonVariant: "danger",
            onConfirm: async () => {
                setErrors({ ...errors, create: false });
                setSuccess({ ...success, create: false });
                try {
                    await CategoryService.remove(id);
                    setSuccess({ ...success, delete: true });
                    getCategories();
                } catch (e) {
                    console.error(e.response);
                    setErrors({ ...errors, delete: true });
                }
            }
        });

    }

    const filterSearch = (data) => {
        let obj = data;
        if (obj.search === "") {
            obj = {
                active: data.active
            }
        }
        setFilter(obj);
    }

    return (
        <>
            <div className={"d-sm-flex align-items-center justify-content-between mb-4"}>
                <h1 className={"h3 mb-0 text-gray-800"}>{t('categories.title')}</h1>
            </div>

            <Alert variant={"success"} show={success.create} dismissible onClose={() => setSuccess({ ...success, create: false })}>
                {t('categories.success.create')}
            </Alert>

            <Alert variant={"danger"} show={errors.create} dismissible onClose={() => setErrors({ ...errors, create: false })}>
                {t('categories.error.create')}
            </Alert>

            <Alert variant={"success"} show={success.delete} dismissible onClose={() => setSuccess({ ...success, delete: false })}>
                {t('categories.success.delete')}
            </Alert>

            <Alert variant={"danger"} show={errors.delete} dismissible onClose={() => setErrors({ ...errors, delete: false })}>
                {t('categories.error.delete')}
            </Alert>

            {permissions.categories?.create ? 
            <Card className={"shadow mb-4"}>
                <Accordion>
                    <Accordion.Item eventKey="0">
                        <Accordion.Header><h6 className={"m-0 fw-bold text-primary"}>{t('categories.creation.title')}</h6>
                        </Accordion.Header>
                        <Accordion.Body>
                            <CategoryForm onSubmit={createCategory} permissions={permissions}/>
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>
            </Card>
            : null}

            <Card className={"shadow mb-4"}>
                <Card.Header className={"py-3"}>
                    <h6 className={"m-0 fw-bold text-primary"}>{t('categories.list.title')}</h6>
                </Card.Header>
                <Card.Body>
                    <Form onSubmit={handleSubmit(filterSearch)}>
                        <Row>
                            <Col lg="2" className={"mb-3"}>
                                <FormGroup>
                                    <Form.Label>{t('categories.filter.active.title')}</Form.Label>
                                    <Form.Select {...register('active')} aria-label="Default select example">
                                        <option value="">{t('categories.filter.active.value1')}</option>
                                        <option value={true}>{t('categories.filter.active.value2')}</option>
                                        <option value={false}>{t('categories.filter.active.value3')}</option>
                                    </Form.Select>
                                </FormGroup>
                            </Col>
                            <Col lg="3" className={"mb-3"}>
                                <FormGroup>
                                    <Form.Label>{t('categories.filter.search')}</Form.Label>
                                    <Form.Control type="text" {...register("search")} />
                                </FormGroup>
                            </Col>
                        </Row>
                        <Button type="submit" className={"mt-3"} disabled={!permissions.categories?.read}>{t('categories.filter.button')}</Button>
                    </Form>
                    <hr />
                    <Table responsive>
                        <thead>
                            <tr>
                                <TableHeaderSortComponent scope={"col"} text={t('categories.list.fields.index')} column={"id"} sortColumn={sortColumn} setSortColumn={setSortColumn} />
                                <TableHeaderSortComponent scope={"col"} text={t('categories.list.fields.name')} column={"name"} sortColumn={sortColumn} setSortColumn={setSortColumn} />
                                <TableHeaderSortComponent scope={"col"} text={t('categories.list.fields.order')} column={"order"} sortColumn={sortColumn} setSortColumn={setSortColumn} />
                                <TableHeaderSortComponent scope={"col"} text={t('categories.list.fields.active')} column={"active"} sortColumn={sortColumn} setSortColumn={setSortColumn} />
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {categories.length > 0 ? categories.map((category, index) => (
                                <tr key={index}>
                                    {indexDesc ? <td>{categories.length - index}</td> : <td>{index + 1}</td>}
                                    <td>
                                        <div className="d-flex flex-row flex-nowrap">
                                            {Object.values(category.name).map((translation, i) =>
                                                <div key={i} className="me-2">
                                                    <Image src={translation.language.icon} className="me-2 flags-img-sm" />
                                                    {i + 1 === Object.values(category.name).length ? translation.value : translation.value + ' | '}
                                                </div>
                                            )}
                                        </div>
                                    </td>
                                    <td>{category.order}</td>
                                    <td>{category.active ? 'Si' : 'No'}</td>
                                    <td className={"text-center"}>
                                        <Button variant="link" size="sm" className="ms-2" to={`/category/${category.id}`} as={Link} disabled={!permissions.categories?.read}>
                                            <FontAwesomeIcon icon="edit" />
                                        </Button>
                                        <Button variant="link" size="sm" className="text-danger" onClick={() => deleteCategory(category.id)} disabled={!permissions.articles?.delete}>
                                            <FontAwesomeIcon icon="trash" />
                                        </Button>
                                    </td>
                                </tr>
                            )) :
                                <tr>
                                    <td colSpan={4}>{t('general.empty')}</td>
                                </tr>
                            }
                        </tbody>
                    </Table>
                    <PaginationComponent pagination={pagination}
                        setPagination={setPagination}
                        alwaysShown={categories.length > 0} />
                </Card.Body>
            </Card>
        </>
    );
}

export default Category