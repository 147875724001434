import React from 'react'
import { useTranslation } from 'react-i18next';
import { Accordion, Alert, Button, Card, Col, Form, FormGroup, Image, Row, Table } from 'react-bootstrap';
import { useState } from 'react';

import APP_CONF from "../../scripts/constants";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import PaginationComponent from '../../components/PaginationComponent';
import { useForm } from 'react-hook-form';
import PromotionForm from '../../components/PromotionForm';
import { useEffect } from 'react';

import BodyService from '../../services/body.service';
import PromotionsService from '../../services/promotions.service';
import SectionService from '../../services/section.service';
import UseAlertConfirm from '../../hooks/UseAlertConfirm';
import TableHeaderSortComponent from '../../components/TableHeaderSortComponent';
import UserPermissionService from "../../services/userPermission.service";

function Promotion() {

    const { t } = useTranslation();

    const [languages, setLanguages] = useState();
    const [promotions, setPromotions] = useState();
    const [sections, setSections] = useState();
    const [indexDesc, setIndexDesc] = useState(false);
    const [permissions, setPermissions] = useState({});

    const [sortColumn, setSortColumn] = useState({
        sortByColumn: null,
        orderBy: null
    });

    const [filter, setFilter] = useState({
        active: true,
        search: ""
    })

    const [pagination, setPagination] = useState({
        currentPage: 1,
        totalItems: 0,
        itemsPerPage: APP_CONF.ITEMS_PER_PAGE,
        numPages: 1
    });

    const [success, setSuccess] = useState({
        create: false,
        delete: false,
    });
    const [errors, setErrors] = useState({
        create: false,
        delete: false,
    });

    const { register, handleSubmit } = useForm({
        mode: "all",
    });

    const { showModal } = UseAlertConfirm();

    const getEmptyTranslations = async () => {
        const data = await BodyService.getEmptyTranslations();
        setLanguages(data);
    }

    const getPromotions = async () => {
        try {
            let language;
            if ((sortColumn.sortByColumn === 'name') &&
                (sortColumn.orderBy !== null || sortColumn.orderBy !== undefined)) {
                language = "es";
            }

            const data = await PromotionsService.getAll(pagination, sortColumn, language, filter);
            setPromotions(data.content)
            setPagination({
                ...pagination,
                totalItems: data.totalElements,
                numPages: data.totalPages
            });
        } catch (error) {
            console.error(error);
        }
    }

    const getSections = async () => {
        try {
            const data = await SectionService.getAll(pagination, null, null, { active: true, search: "" })
            setSections(data.content)
        } catch (error) {
            console.error(error);
        }
    }


    const createPromotion = async (data) => {
        setErrors({ ...errors, create: false });
        setSuccess({ ...success, create: false });

        try {
            await PromotionsService.create(data);
            setSuccess({ ...success, create: true });
            getPromotions();
        } catch (e) {
            console.error(e.response);
            setErrors({ ...errors, create: true });
            return e.response
        }
    }

    const deletePromotions = async (id) => {

        showModal({
            title: t("general.delete.title"),
            body: t("general.delete.message"),
            confirmButtonVariant: "danger",
            onConfirm: async () => {
                setErrors({ ...errors, create: false });
                setSuccess({ ...success, create: false });
                try {
                    await PromotionsService.remove(id);
                    setSuccess({ ...success, delete: true });
                    getPromotions();
                } catch (e) {
                    console.error(e.response);
                    setErrors({ ...errors, delete: true });
                }
            }
        });
    }

    const filterSearch = (data) => {
        let obj = data;
        if (obj.search === "") {
            obj = {
                active: data.active
            }
        }
        setFilter(obj);
    }

    const getAction = (key) => {
        switch (key) {
            case "SESSION":
                return { title: "Sesión", value: 1 }

            case "USER":
                return { title: "Usuario", value: 2 }

            case "DEVICE":
                return { title: "Dispositivo", value: 3 }

        }
    }

    const getPermissions = async () => {
        try {
            const data = await UserPermissionService.getPermissionsByConnected();
            setPermissions(data);
        } catch (error) {
            console.error(error);
        }
    }

    useEffect(() => {
        getPromotions()
        if (sortColumn.sortByColumn === 'id' && sortColumn.orderBy === 'desc') {
            setIndexDesc(true);
        } else {
            setIndexDesc(false);
        }

    }, [pagination.currentPage, filter, sortColumn]);

    useEffect(() => {
        const loadData = async () => {
            await getPromotions();
            await getSections();
            await getPermissions();
            await getEmptyTranslations();          
        }

        loadData();

        getPromotions();
        getSections();
        getEmptyTranslations();
        getPermissions();
    }, [])

    return (
        <>
            <div className={"d-sm-flex align-items-center justify-content-between mb-4"}>
                <h1 className={"h3 mb-0 text-gray-800"}>{t('promotions.title')}</h1>
            </div>

            <Alert variant={"success"} show={success.create} dismissible onClose={() => setSuccess({ ...success, create: false })}>
                {t('promotions.success.create')}
            </Alert>

            <Alert variant={"danger"} show={errors.create} dismissible onClose={() => setErrors({ ...errors, create: false })}>
                {t('promotions.error.create')}
            </Alert>

            {permissions.promotions?.read && permissions.promotions?.create ? 
            <Card className={"shadow mb-4"}>
                <Accordion>
                    <Accordion.Item eventKey="0">
                        <Accordion.Header><h6 className={"m-0 fw-bold text-primary"}>{t('promotions.create.title')}</h6>
                        </Accordion.Header>
                        <Accordion.Body>
                            <PromotionForm onSubmit={createPromotion} languages={languages} sections={sections} permissions={permissions}/>
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>
            </Card>
            : null}

            <Card className={"shadow mb-4"}>
                <Card.Header className={"py-3"}>
                    <h6 className={"m-0 fw-bold text-primary"}>{t('promotions.table.title')}</h6>
                </Card.Header>
                <Card.Body>
                    <Form onSubmit={handleSubmit(filterSearch)}>
                        <Row>
                            <Col lg="2" className={"mb-3"}>
                                <FormGroup>
                                    <Form.Label>{t('categories.filter.active.title')}</Form.Label>
                                    <Form.Select {...register('active')} defaultValue={filter.active} aria-label="Default select example">
                                        <option value="">{t('categories.filter.active.value1')}</option>
                                        <option value={true}>{t('categories.filter.active.value2')}</option>
                                        <option value={false}>{t('categories.filter.active.value3')}</option>
                                    </Form.Select>
                                </FormGroup>
                            </Col>
                            <Col lg="3" className={"mb-3"}>
                                <FormGroup>
                                    <Form.Label>{t('users.filter.search')}</Form.Label>
                                    <Form.Control type="text" {...register("search")} />
                                </FormGroup>
                            </Col>
                        </Row>
                        <Button type="submit" className={"mt-3"} disabled={!permissions.promotions?.read}>{t('categories.filter.button')}</Button>
                    </Form >
                    <hr />
                    <Table responsive>
                        <thead>
                            <tr>
                                <TableHeaderSortComponent scope={"col"} text={t('promotions.table.name')} column={"name"} sortColumn={sortColumn} setSortColumn={setSortColumn} />
                                <TableHeaderSortComponent scope={"col"} text={t('promotions.table.type')} column={"type"} sortColumn={sortColumn} setSortColumn={setSortColumn} />
                                <TableHeaderSortComponent scope={"col"} text={t('promotions.table.start')} column={"start"} sortColumn={sortColumn} setSortColumn={setSortColumn} />
                                <TableHeaderSortComponent scope={"col"} text={t('promotions.table.end')} column={"end"} sortColumn={sortColumn} setSortColumn={setSortColumn} />
                                <TableHeaderSortComponent scope={"col"} text={t('promotions.table.sections')} column={"section"} sortColumn={sortColumn} setSortColumn={setSortColumn} />
                                <TableHeaderSortComponent scope={"col"} text={t('promotions.table.active')} column={"active"} sortColumn={sortColumn} setSortColumn={setSortColumn} />
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {promotions?.map((promotion, i) =>
                                <tr key={i}>
                                    <td>{promotion?.name}</td>
                                    <td>{getAction(promotion?.actionType)?.title}</td>
                                    <td>{promotion?.start}</td>
                                    <td>{promotion?.end}</td>
                                    <td>
                                        <div className='d-flex flex-row gx-2'>
                                            {promotion?.sections.map((section, i) => {
                                                return (<span key={i} className='me-2'>
                                                    {i + 1 === promotion.sections.length ? section.name?.['es']?.value : (section.name?.['es']?.value === null ? "" : section.name?.['es']?.value) + ' | '}
                                                </span>)
                                            })}
                                        </div>
                                    </td>
                                    <td>{promotion?.active ? <FontAwesomeIcon className="ms-4 text-primary" icon="eye" /> : <FontAwesomeIcon className="ms-4" icon="eye-slash" />}</td>
                                    <td className={"text-center"}>
                                        <Button variant="link" size="sm" className="ms-2" to={`/promotion/${promotion?.id}`} as={Link} disabled={!permissions.promotions?.read}>
                                            <FontAwesomeIcon icon="edit" />
                                        </Button>
                                        <Button variant="link" size="sm" className="text-danger" onClick={() => deletePromotions(promotion?.id)} disabled={!permissions.promotions?.delete}>
                                            <FontAwesomeIcon icon="trash" />
                                        </Button>
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </Table>
                    <PaginationComponent pagination={pagination}
                        setPagination={setPagination}
                        alwaysShown={promotions?.length > 0} />
                </Card.Body>
            </Card >
        </>
    )
}

export default Promotion