import api from './api';

const getAll = (pagination, sortColumn, language, filter) => api.get('/project', {
    pageNum: pagination.currentPage - 1, 
    pageLength: pagination.itemsPerPage, 
    active: filter.active,
    search: filter.search,
    language: language,
    sortBy: sortColumn.sortByColumn,
    orderBy: sortColumn.orderBy
}).then(response => {
    return response.data;
});

const getAllOrderByTitle = (lang) => api.get('/project/order-by-title', {language: lang}).then(response => {
    return response.data;
});

const getById = (id) => api.get(`/project/${id}`).then(response => {
    return response.data
});

const create = (data) => api.post('/project', data).then(response => {
    return response.data
});

const update = (id, data) => api.put(`/project/${id}`, data).then(response => {
    return response.data
});

const remove = (id) => api.delete(`/project/${id}`).then(response => {
    return response.data
});

const ProjectService = {
    getAll,
    getAllOrderByTitle,
    getById,
    create,
    update,
    remove
};

export default ProjectService;