import { t } from "i18next";
import { useEffect, useState, useRef} from "react";
import { Button, Col, Form, FormCheck, FormGroup, Row, Image } from "react-bootstrap";
import { useForm } from "react-hook-form";
import TranslationInputLanguage from "../fields/TranslationInputLanguage";
import BodyService from "../../../services/body.service";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const PointTypeForm = ({ onSubmit, details, translations, pointType, permissions }) => {

    const [pointTypeNameValues, setPointTypeNameValues] = useState();

    const [translationValues, setTranslationValues] = useState();

    const [image, setImage] = useState({});

    const pictureRef = useRef(null);

    const { register, handleSubmit, formState, reset } = useForm({
        mode: "all",
        defaultValues: {
            active: !details,
        },
    });

    const setPointTypeNameValuesForm = (iso, value, id) => {
        setTranslationValues({
            ...translationValues, [iso]: { value: value, langId: id, iso: iso }
        })
    };

    const onSubmitForm = async (data) => {
        let objSend = {
            name: [],
            picture: {},
            active: data.active
        }

        Object.values(translationValues).forEach((name) => {
            objSend.name.push({
                language: {
                    id: name.langId
                },
                value: name.value,
                picture: {}
            })
        })

        setImages(objSend);

        try {
            await onSubmit(objSend);
            if (!details) {
                setTranslationValues(null);
                setImage({});
                reset();
            }
        } catch (error) {
            console.error(error)
        }

        setTimeout(function () {
            window.scrollTo(0, 0);
        }, 0);
    };

    const setImages = (objSend) => {
        if (image) {
            if (image.id) {
                objSend.picture = {
                    id: image.id,
                    imageBase64: null,
                    imageContentType: null,
                    imageName: null,
                }
            } else {
                if (image.imageBase64 || image.imageBase64 === "") {
                    objSend.picture = image;
                }
            }
        }
    };

    const setImageToBase64 = async (file) => {
        if (file) {
            const base = await BodyService.getBase64(file);
            const newImage = {...image};
            newImage.imageBase64 = base.split(',')[1];
            newImage.imageName = file.name;
            newImage.imageContentType = file.type;
            setImage(newImage);
        }
    };

    const getBase64Src = (image) => {
        if (!image?.imageBase64 || !image?.imageContentType) {
            return false;
        }
        return `data:${image.imageContentType};base64,${image.imageBase64}`;
    };

    
    const removeImage = (ref) => {
        ref.current.value = "";
        let newImage = {};
        if (details) {
            if (image) {
                newImage.imageBase64 = "";
                newImage.imageContentType = "";
                newImage.imageUrl = "";
            }
        }
        setImage(newImage);
    };

    useEffect(() => {
        setPointTypeNameValues(translations);

        if (details && pointType) {

            let pointTypeLang = {}

            Object.keys(pointType.pointType).forEach((data) => {
                pointTypeLang[data] = {
                    value: pointType.pointType[data].value,
                    langId: pointType.pointType[data].language.id,
                    iso: pointType.pointType[data].language.iso,
                }
            });
            setTranslationValues(pointTypeLang);
            setImage({ imageUrl: pointType.picture });
        }

    }, [translations, pointType, details]);

    useEffect(() => {
        if (details) {
            reset(pointType);
        }
    }, [pointType, details, reset]);
    return (
        <Form onSubmit={handleSubmit(onSubmitForm)}>
            <Row>
                <Col xs={12}>
                    <h5>{t('pointType.creation.fields.name')}</h5>
                    {translations?.length > 0 ?
                        <Row className="mt-3">
                            <TranslationInputLanguage translations={pointTypeNameValues} data={translationValues} onChange={setPointTypeNameValuesForm} />
                        </Row>
                        : <></>
                    }
                </Col>
            </Row>
            <Row>
                <Col xs={12} className="mt-md-3">  
                    <FormGroup>
                        <Form.Label>{t('chargingPointType.creation.image')}</Form.Label>
                        <Row className="my-2">
                            {(image?.imageUrl || image?.imageBase64) &&
                                <Row className="my-3">
                                    <>
                                        <Col className="col-8 col-md-6 col-lg-2">
                                            <Image fluid thumbnail src={getBase64Src(image) || image?.imageUrl} />
                                        </Col>
                                    </>
                                </Row>
                            }
                            <Col md="auto">
                                <Form.Control type="file"
                                    accept="image/png, image/jpeg"
                                    onChange={(e) => setImageToBase64(e.target.files[0])}
                                    ref={pictureRef}
                                />
                            </Col>
                            <Col md="auto">
                                <Button variant="link" size="ls" onClick={() => removeImage(pictureRef)}
                                    className="border-primary">
                                    <FontAwesomeIcon icon="fas fa-trash" />
                                </Button>
                            </Col>
                        </Row>
                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col xs="12" className="mt-md-3">
                    <FormGroup>
                        <FormCheck label={t('pointType.creation.fields.active')} {...register("active")} />
                    </FormGroup>
                </Col>
            </Row>
            {details ? 
            <Button type="submit" className={"mt-4"} disabled={ permissions.pointTypes?.update === false ? true : !formState.isValid }>
                <FontAwesomeIcon icon={"save"} />
            </Button>
            : 
            <Button type="submit" className={"mt-4"} disabled={ permissions.pointTypes?.create === false ? true : !formState.isValid }>
                {t('pointType.creation.button')}
            </Button>
            }
        </Form>
    );
};

export default PointTypeForm;