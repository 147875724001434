import {Container} from "react-bootstrap";
import {Outlet, useNavigate} from "react-router-dom";
import {useEffect} from "react";

import Sidebar from "../../components/sidebar/Sidebar";

import AuthService from "../../services/auth.service";
import Topbar from "../../components/Topbar";
import TokenService from "../../services/token.service";

const Main = () => {

    const navigate = useNavigate();

    useEffect(() => {
        if (!AuthService.getCurrentUser()) {
            navigate("/login");
        }
     
        try {
            TokenService.refreshToken()
        } catch (e) {
            navigate("/login");
        }
    }, [navigate]);

    return (
        <div id={"wrapper"}>
            <Sidebar/>
            <div id={"content-wrapper"} className={"d-flex flex-column"}>
                <div id={"content"}>
                    <Topbar/>
                    <Container fluid={true}>
                        <Outlet/>
                    </Container>
                </div>
            </div>
        </div>
    );
};

export default Main;
