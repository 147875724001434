import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate, useParams } from "react-router-dom";
import PointTypeService from "../../../../services/pointTypes.service";
import { useCallback } from "react";
import BodyService from "../../../../services/body.service";
import { useEffect } from "react";
import { Alert, Card } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PointTypeForm from "../../../../components/forms/configuration/PointTypeForm";
import UserPermissionService from "../../../../services/userPermission.service";

const PointTypeDetails = () => {
    const params = useParams();

    const navigate = useNavigate();

    const { t } = useTranslation();

    const [pointType, setPointType] = useState();

    const [translations, setTranslations] = useState([]);

    const [title, setTitle] = useState("");

    const [permissions, setPermissions] = useState({});

    const [success, setSuccess] = useState({
        update: false,
        email: false,
    });

    const [errors, setErrors] = useState({
        update: false,
    });

    const getPointType = useCallback(async (id) => {
        try {
            const data = await PointTypeService.getById(id);
            setPointType(data);
            setTitle(data.pointType["es"].value);
        } catch (error) {
            navigate("/configuration/point-type");
        }
    }, [navigate]);

    const updatePointType = async (data) => {
        setErrors({ ...errors, update: false });
        setSuccess({ ...success, update: false });

        try {
            await PointTypeService.update(params.id, data);
            getPointType(params.id);
            setSuccess({ ...success, update: true });
        } catch (e) {
            setErrors({ ...errors, update: true });
        }
    };


    const getEmptyTranslations = async () => {
        const data = await BodyService.getEmptyTranslations();
        setTranslations(data);
    };

    const getPermissions = async () => {
        try {
            const data = await UserPermissionService.getPermissionsByConnected();
            setPermissions(data);
        } catch (error) {
            console.error(error);
        }
    }

    useEffect(() => {
        const loadData = async () => {
            await getPermissions();
        }

        loadData();
    }, []);

    useEffect(() => {
        getPointType(params.id);
        getEmptyTranslations();
    }, [getPointType, params.id]);

    return (
        <>
            <Alert variant={"success"} show={success.update} dismissible onClose={() => setSuccess({ ...success, update: false })}>
                {t('pointType.success.update')}
            </Alert>

            <Alert variant={"danger"} show={errors.update} dismissible onClose={() => setErrors({ ...errors, update: false })}>
                {t('pointType.error.update')}
            </Alert>

            <div className={"d-flex justify-content-between my-3"}>
                <Link to="/configuration/point-type" className={"previous round rounded-circle text-decoration-none"}
                    title={t('general.back')}>
                    <FontAwesomeIcon icon={"chevron-left"} className={"mt-2 me-2"} />
                    <span>{t('pointType.title')}</span>
                </Link>
            </div>
            <Card>
                <Card.Header className={"py-3"}>
                    <h1 className={"h3 mb-0 text-gray-800 card-title text-capitalize"}>{title}</h1>
                </Card.Header>
                <Card.Body>
                    <PointTypeForm onSubmit={updatePointType} pointType={pointType} translations={translations} permissions={permissions} details />
                </Card.Body>
            </Card>
        </>
    );
};

export default PointTypeDetails;