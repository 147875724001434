import {useContext} from "react";
import {useTranslation} from "react-i18next";
import {AlertConfirmContext} from "../context/AlertConfirmContext";

const UseAlertConfirm = () => {

    const {update} = useContext(AlertConfirmContext);

    const {t} = useTranslation();

    const showModal = ({title = t("general.alertConfirm.title"),
                       body = t("general.alertConfirm.body"),
                       confirmButtonContent = t("general.alertConfirm.confirmButton"),
                       confirmButtonVariant = "primary",
                       confirmButtonIsDark = true,
                       dismissButtonContent = t("general.alertConfirm.dismissButton"), 
                       dismissButtonVariant = "secondary",
                       dismissButtonIsDark = true,  
                       onConfirm = () => {},
                       onDismiss = () => {}}) => {
        update({
            show: true,
            title,
            body,
            confirmButtonContent,
            confirmButtonVariant,
            confirmButtonIsDark,
            dismissButtonContent,
            dismissButtonVariant,
            dismissButtonIsDark,
            onConfirm,
            onDismiss,
        });
    }

    const hideModal = () => {
        update({
            show: false,
            title: '',
            body: '',
            confirmButtonContent: '',
            confirmButtonVariant: '',
            dismissButtonContent: '',
            dismissButtonVariant: '',
            onConfirm: () => { },
            onDismiss: () => { }
        });
    }

    return {
        showModal,
        hideModal
    };
}

export default UseAlertConfirm;