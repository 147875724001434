import { Accordion, Card, Alert } from "react-bootstrap";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import HighlightedService from "../../services/highlighted.service";
import UserPermissionService from "../../services/userPermission.service";

import HighlightedForm from "../../components/forms/HighlightedForm";

const Highlighted = () => {
    const { t } = useTranslation();

    const [success, setSuccess] = useState({
        create: false,
        delete: false,
    });
    const [errors, setErrors] = useState({
        create: false,
        delete: false,
    });

    const [highlighted, setHighlighted] = useState();

    const [articles, setArticles] = useState([]);

    const [miniGuides, setMiniGuides] = useState([]);

    const [interestSites, setInterestSites] = useState([]);

    const [accommodations,setAccommodations] = useState([]);

    const [contentType, setContentType] = useState([]);

    const [permissions, setPermissions] = useState({});

    const getPermissions = async () => {
        try {
            const data = await UserPermissionService.getPermissionsByConnected();
            setPermissions(data);
        } catch (error) {
            console.error(error);
        }
    }

    useEffect(() => {
        const loadData = async () => {
            await getPermissions();
            await getContentType();                                
            await getPossibleHighlighted();
            await getHighlighteds();
        }

        loadData();
    }, []);

    const getHighlighteds = async () => {
        try {
            const data = await HighlightedService.getAll();                      
            setHighlighted(data);            
        } catch ({ response }) {
            if (response?.data?.errorCode) {
                switch (response.data.errorCode) {
                    case 13002:
                        setErrors({ ...errors, email: true });
                        break;
                    default:
                        setErrors({ ...errors, create: true });
                }
            }
        }
    }
    
    const createHighlighted = async (data) => {
        setErrors({ ...errors, create: false });
        setSuccess({ ...success, create: false });
        try {
            await HighlightedService.save(data);
            setSuccess({ ...success, create: true });
            await getPossibleHighlighted();
            await getHighlighteds();
        } catch (e) {
            console.error(e.response);
            setErrors({ ...errors, create: true });
        }
    };

    const getPossibleHighlighted = async () => {
        try {
            const data = await HighlightedService.getPossible();
            setArticles(data.articles);
            setMiniGuides(data.miniGuides);
            setInterestSites(data.interestSites);
            setAccommodations(data.accommodations);
        } catch ({ response }) {
            if (response?.data?.errorCode) {
                switch (response.data.errorCode) {
                    case 13002:
                        setErrors({ ...errors, email: true });
                        break;
                    default:
                        setErrors({ ...errors, create: true });
                }
            }
        }
    }

    const getContentType = async () => {
        try {
            const data = await HighlightedService.getContentType();
            setContentType(data);
        } catch ({ response }) {
            if (response?.data?.errorCode) {
                switch (response.data.errorCode) {
                    case 13002:
                        setErrors({ ...errors, email: true });
                        break;
                    default:
                        setErrors({ ...errors, create: true });
                }
            }
        }
    }

    return (
        <>
            <div className={"d-sm-flex align-items-center justify-content-between mb-4"}>
                <h1 className={"h3 mb-0 text-gray-800"}>{t('highlights.title')}</h1>
            </div>

            <Alert variant={"success"} show={success.create} dismissible onClose={() => setSuccess({ ...success, create: false })}>
                {t('highlights.success.create')}
            </Alert>

            <Alert variant={"danger"} show={errors.create} dismissible onClose={() => setErrors({ ...errors, create: false })}>
                {t('highlights.error.create')}
            </Alert>

            <Alert variant={"success"} show={success.delete} dismissible onClose={() => setSuccess({ ...success, delete: false })}>
                {t('highlights.success.delete')}
            </Alert>

            <Alert variant={"danger"} show={errors.delete} dismissible onClose={() => setErrors({ ...errors, delete: false })}>
                {t('highlights.error.delete')}
            </Alert>

            <Card className={"shadow mb-4"}>
                <Accordion defaultActiveKey="0">
                    <Accordion.Item eventKey="0">
                        <Accordion.Header><h6 className={"m-0 fw-bold text-primary"}>{t('highlights.form.title')}</h6>
                        </Accordion.Header>
                        <Accordion.Body>
                            <HighlightedForm onSubmit={createHighlighted} articles={articles} miniGuides={miniGuides} interestSites={interestSites} accommodations={accommodations} highlighted={highlighted} contentType={contentType} permissions={permissions}/>
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>
            </Card>
        </>
    );
}

export default Highlighted