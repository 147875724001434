import { Card, Alert } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useState, useEffect } from "react";

import APP_CONF from "../../../scripts/constants";
import SiteInterestForm from "../../../components/forms/SiteInterestForm";
import CategoryService from "../../../services/category.service";
import BodyService from "../../../services/body.service";
import TagService from "../../../services/tag.service";
import ProjectService from "../../../services/project.service";
import SiteInterestService from "../../../services/siteInterest.service";
import ChargingPointService from "../../../services/chargingPoint.service";
import ProvinceService from "../../../services/province.service";
import UserPermissionService from "../../../services/userPermission.service";

const SiteInterestDetails = () => {

    const { t } = useTranslation();
    const params = useParams();

    const [categories, setCategories] = useState();
    const [languages, setLanguages] = useState();
    const [tagList, setTagList] = useState([]);
    const [projectList, setProjectList] = useState([])
    const [interestSites, setInteresSites] = useState();
    const [chargingPointList, setChargingPointList] = useState([]);
    const [provinces, setProvinces] = useState();
    const [permissions, setPermissions] = useState({});

    const [success, setSuccess] = useState({
        create: false,
        delete: false,
        update: false
    });
    const [errors, setErrors] = useState({
        create: false,
        delete: false,
        update: false,
        slug: false,
        usedSlug: false
    });

    const [pagination] = useState({
        currentPage: 1,
        totalItems: 0,
        itemsPerPage: APP_CONF.ITEMS_PER_PAGE,
        numPages: 1
    });

    const [filter] = useState({
        active: ""
    })

    const getEmptyTranslations = async () => {
        const data = await BodyService.getEmptyTranslations();
        setLanguages(data);
    }

    const getPermissions = async () => {
        try {
            const data = await UserPermissionService.getPermissionsByConnected();
            setPermissions(data);
        } catch (error) {
            console.error(error);
        }
    }

    const getInterestSiteDetails = async () => {
        try {
            const data = await SiteInterestService.getById(params.id)
            setInteresSites(data)
        } catch ({ response }) {
            console.error(response);
        }
    }

    const updateInterestSite = async (data) => {
        setErrors({ ...errors, update: false, slug: false, usedSlug: false, });
        setSuccess({ ...success, update: false });

        try {
            await SiteInterestService.update(params.id, data);
            setSuccess({ ...success, update: true });
            getInterestSiteDetails();
        } catch (e) {
            if (e?.response?.data?.errorCode === 15002) {
                setErrors({ ...errors, update: false, slug: true, usedSlug: false, });
            } else if (e?.response?.data?.errorCode === 15001) {
                setErrors({ ...errors, update: false, slug: false, usedSlug: true });
            } else {
                setErrors({ ...errors, update: true, slug: false, usedSlug: false, });
            }
        }
    };

    useEffect(() => {
        const loadData = async () => {
            await getPermissions();
        }

        loadData();
    }, []);

    useEffect(() => {
        const getCategories = async () => {
            try {
                const data = await CategoryService.selectAll();
                setCategories(data);
            } catch ({ response }) {
                console.error(response);
            }
        }

        const getTags = async () => {
            try {
                const data = await TagService.getAllList();
                setTagList(data);
            } catch ({ response }) {
                console.error(response);
            }
        }

        const getProjects = async () => {
            try {
                const data = await ProjectService.getAllOrderByTitle("es");
                setProjectList(data)
            } catch ({ response }) {
                console.error(response);
            }
        }
        const getChargingPoints = async () =>{
            try {
                const data = await ChargingPointService.getAllList();
                setChargingPointList(data)
            } catch ({ response }) {
                console.error(response);
            }
        }

        const getProvinces = async () => {
            try {
                const data = await ProvinceService.getList();
                setProvinces(data);
            } catch ({ response }) {
                console.error(response);
            }
        }

        getCategories();
        getEmptyTranslations();
        getTags();
        getProjects();
        getChargingPoints();
        getProvinces();
        getInterestSiteDetails();
    }, [filter, pagination, params.id])

    return (
        <>
            <div className={"d-flex justify-content-between my-3"}>
                <Link to="/site-interest" className={"previous round rounded-circle text-decoration-none"}
                    title={t('general.back')}>
                    <FontAwesomeIcon icon={"chevron-left"} className={"mt-2 me-2"} />
                    <span>{t('sitesInterest.title')}</span>
                </Link>
            </div>

            <Alert variant={"success"} show={success.update} dismissible onClose={() => setSuccess({ ...success, update: false })}>
                {t('sitesInterest.success.update')}
            </Alert>

            <Alert variant={"danger"} show={errors.update} dismissible onClose={() => setErrors({ ...errors, update: false })}>
                {t('sitesInterest.error.update')}
            </Alert>

            <Alert variant={"danger"} show={errors.slug} dismissible onClose={() => setErrors({ ...errors, slug: false })}>
                {t('articles.error.emptySlug')}
            </Alert>

            <Alert variant={"danger"} show={errors.usedSlug} dismissible onClose={() => setErrors({ ...errors, usedSlug: false })}>
                {t('articles.error.alreadyUsedSlug')}
            </Alert>

            <Card>
                <Card.Header className={"py-3"}>
                    <h6 className={"m-0 fw-bold text-primary"}>{t('sitesInterest.update.title')}</h6>
                </Card.Header>
                <Card.Body>
                    <SiteInterestForm onSubmit={updateInterestSite} categories={categories} languages={languages} tags={tagList} projects={projectList} interestSite={interestSites} provinces={provinces} details chargingPoints={chargingPointList} permissions={permissions}/>
                </Card.Body>
            </Card>
        </>
    )
}

export default SiteInterestDetails