import api from './api';

const getAll = (pagination, filter, sortColumn) => api.get('/stamp', {
    pageNum: pagination.currentPage - 1, 
    pageLength: pagination.itemsPerPage, 
    active: filter.active, 
    title: filter.search,
    sortBy: sortColumn.sortByColumn,
    orderBy: sortColumn.orderBy
}).then(response => response.data);

const lazy = () => api.get('/stamp/lazy').then(response => response.data);

const getById = (id) => api.get(`/stamp/${id}`).then(response => response.data);

const create = (data) => api.post('/stamp', data).then(response => response.data);

const update = (id, data) => api.put(`/stamp/${id}`, data).then(response => response.data);

const remove = (id) => api.delete(`/stamp/${id}`).then(response => response.data);

const StampService = {
    getAll,
    getById,
    lazy,
    create,
    update,
    remove
};

export default StampService;