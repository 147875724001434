import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Accordion, Button, Col, Form, FormGroup, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const HighlightedForm = ({
  onSubmit,
  articles,
  interestSites,
  miniGuides,
  accommodations,
  highlighted,
  contentType,
  permissions
}) => {
  const ARTICLE = 1;
  const MINI_GUIDE = 2;
  const INTEREST_SITE = 3;
  const ACCOMMODATIONS = 4;
  const NUMBER_OF_HIGHLIGHTS = 6;

  const { t } = useTranslation();

  const [itemsMonthHighlight, setItemsMonthHighlight] = useState(new Array(NUMBER_OF_HIGHLIGHTS));

  const [itemsHighLight, setItemsHighlight] = useState(new Array(4));

  const [monthContentType, setMonthContentType] = useState(null);

  const [monthHighLight, setMonthHighLight] = useState({});

  const [resources, setResources] = useState([]);

  const [submitButtonDisabled, setSubmitButtonDisabled] = useState(true);

  const handleMonthItemChange = (itemId, contentTypeId) => {
    var itemSelected = {
      id: null,
      itemId: itemId,
      typeId: contentTypeId,
      month: true,
      home: true,
    };

    setMonthHighLight(itemSelected);
  };

  const { handleSubmit } = useForm({
    mode: "all",
  });

  const getAnEmptyItemHighList = () => {
    var emptyItemsHighLight = [];
    for (let { id: type } of contentType) {
      emptyItemsHighLight[type] = [];
      emptyItemsHighLight[type].push({ itemId: null, typeId: null });
    }
    return emptyItemsHighLight;
  };

  useEffect(() => {
    setItemsHighlight(getAnEmptyItemHighList());
  }, [contentType]);

  useEffect(() => {
    checkSubmitDisabled();
  }, [monthContentType, monthHighLight, itemsMonthHighlight]);

  useEffect(() => {
    const resources = [];
    const empty = {
      id: null,
      title: null,
    };
    resources[ARTICLE] = [empty, ...articles];
    resources[MINI_GUIDE] = [empty, ...miniGuides];
    resources[INTEREST_SITE] = [empty, ...interestSites];
    resources[ACCOMMODATIONS] = [empty, ...accommodations];
    setResources(resources);
  }, [contentType, articles, miniGuides, interestSites, accommodations]);

  useEffect(() => {
    if (highlighted?.length > 0 && contentType?.length > 0) {
      let prevMonth = [...itemsMonthHighlight];
      let prevRest = getAnEmptyItemHighList();
      for (let item of highlighted) {
        if (item.month && item.home) {
          setMonthHighLight(item);
          setMonthContentType(item.typeId);
        } else {
          if (item.month) {
            prevMonth[item.typeId] = item;
          } else {
            if (prevRest[item.typeId][0].itemId === null) {
              prevRest[item.typeId][0] = item;
            } else {
              prevRest[item.typeId].push(item);
            }
          }
        }
      }
      setItemsMonthHighlight(prevMonth);
      setItemsHighlight(prevRest);
    }
  }, [highlighted, contentType]);

  const onSubmitForm = async () => {
    let sendData = [monthHighLight];
    for (let { id: type } of contentType) {
      if (itemsMonthHighlight[type] !== undefined) {
        sendData = [...sendData, itemsMonthHighlight[type]];
      }

      let data = itemsHighLight[type].filter((i) => i.itemId !== null);
      sendData = [...sendData, ...data];
    }
    let filteredData = sendData.filter(
      (sd) => sd !== null && (sd?.itemId !== null && sd?.typeId !== null && sd?.itemId !== "")
    );
    try {
      const createResponse = await onSubmit(filteredData);
    } catch (error) {
      console.error(error);
    } finally {
      setTimeout(function () {
        window.scrollTo(0, 0);
      }, 0);
    }
  };

  const handleAddItemClick = (section) => {
    let prev = [...itemsHighLight];
    prev[section].push({
      itemId: null,
      typeId: null,
    });
    setItemsHighlight(prev);
  };

  const handleRemoveItemClick = (section, index) => {
    let prev = [...itemsHighLight];
    prev[section].splice(index, 1);
    setItemsHighlight(prev);
  };

  const nameFromContentType = (contentType) => {
    const tag = `highlights.filter.type.value${contentType}`;
    return t(tag);
  };

  const handleHighlightType = (contentType, itemId, isMonth, index) => {
    var itemSelected = {
      id: null,
      itemId: itemId,
      typeId: contentType,
      month: isMonth,
      home: false,
    };
    if (isMonth) {
      let prevItems = [...itemsMonthHighlight];
      prevItems[contentType] = itemId ? itemSelected : null;
      setItemsMonthHighlight(prevItems);
    } else {
      let prevItems = [...itemsHighLight];
      prevItems[contentType][index] = itemSelected;
      setItemsHighlight(prevItems);
    }
  };

  const checkSubmitDisabled = () => {
    if (
      monthContentType !== null &&
      monthContentType !== "" &&
      monthHighLight?.itemId !== null &&
      monthHighLight?.itemId !== ""
    ) {
      setSubmitButtonDisabled(false);
    } else {
      setSubmitButtonDisabled(true);
      return;
    }
  };

  return (
    <Form onSubmit={handleSubmit(onSubmitForm)}>
      {submitButtonDisabled && (
        <div className="text-danger mb-3">
          {t("highlights.form.incomplete")}
        </div>
      )}
      <Accordion className="mb-3 shadow" defaultActiveKey="0">
        <Accordion.Item eventKey="0">
          <Accordion.Header>
            <h6 className={"m-0 fw-bold text-primary"}>
              {t("highlights.form.fields.month")}
            </h6>
          </Accordion.Header>
          <Accordion.Body>
            <Row className="mb-4">
              <Col xl="6" lg="6">
                <FormGroup>
                  <Form.Label>{t("highlights.filter.type.title")}</Form.Label>
                  <Form.Select
                    aria-label="contentType"
                    value={monthContentType || 0}
                    onChange={(e) => {
                      setMonthContentType(e.target.value);
                      setMonthHighLight(null);
                    }}
                  >
                    <option value={0} disabled hidden>
                      {t("highlights.filter.type.contentTypePlaceholder")}
                    </option>
                    <option value={null}>{}</option>
                    <option value={ARTICLE}>
                      {t("highlights.filter.type.value1")}
                    </option>
                    <option value={MINI_GUIDE}>
                      {t("highlights.filter.type.value2")}
                    </option>
                    <option value={INTEREST_SITE}>
                      {t("highlights.filter.type.value3")}
                    </option>
                    <option value={ACCOMMODATIONS}>
                      {t("highlights.filter.type.value4")}
                    </option>
                  </Form.Select>
                </FormGroup>
              </Col>
              <Col xl="6" lg="6">
                <FormGroup>
                  <Form.Label>
                    {t("highlights.filter.type.item")}
                    <span className="text-danger fw-bold">*</span>
                  </Form.Label>
                  <Form.Select
                    aria-label="item"
                    value={monthHighLight?.itemId || 0}
                    onChange={(e) => {
                      handleMonthItemChange(e.target.value, monthContentType);
                    }}
                  >
                    <option value={0} disabled hidden>
                      {t("highlights.filter.type.contentPlaceholder")}
                    </option>
                    {resources[monthContentType]?.map((item) => (
                      <option key={item?.id} value={item?.id}>
                        {item?.title}
                      </option>
                    ))}
                  </Form.Select>
                </FormGroup>
              </Col>
            </Row>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
      {contentType?.map((contentType, index) => {
        return (
          <Accordion className="mb-3 shadow" key={index}>
            <Accordion.Header>
              <h6 className={"m-0 fw-bold text-primary"}>
                {nameFromContentType(contentType.id)}
              </h6>
            </Accordion.Header>
            <Accordion.Body>
              <Accordion className="mb-3" defaultActiveKey="0">
                <Accordion.Item eventKey="0">
                  <Accordion.Header>
                    <h6 className={"m-0 fw-bold text-primary"}>
                      {t("highlights.form.fields.item.month")}
                    </h6>
                  </Accordion.Header>
                  <Accordion.Body>
                    <Row className="mb-4">
                      <Col xl="9" lg="9">
                        <FormGroup>
                          <Form.Label>
                            {t("highlights.filter.type.item")}
                          </Form.Label>
                          <Form.Select
                            aria-label="item"
                            value={
                              itemsMonthHighlight?.[contentType.id]?.itemId || 0
                            }
                            onChange={(e) => {
                              handleHighlightType(
                                contentType.id,
                                e.target.value,
                                true
                              );
                            }}
                          >
                            <option value={0} disabled hidden>
                              {t("highlights.filter.type.contentPlaceholder")}
                            </option>
                            {resources[contentType.id]?.map((item) => (
                              <option key={item?.id} value={item?.id}>
                                {item?.title}
                              </option>
                            ))}
                          </Form.Select>
                        </FormGroup>
                      </Col>
                    </Row>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
              <Accordion className="mb-3" defaultActiveKey="0">
                <Accordion.Item eventKey="0">
                  <Accordion.Header>
                    <h6 className={"m-0 fw-bold text-primary"}>
                      {t("highlights.form.fields.item.rest")}
                    </h6>
                  </Accordion.Header>
                  <Accordion.Body>
                    {itemsHighLight?.[contentType.id]?.map(
                      (itemHighLight, index) => (
                        <Row className="mb-4" key={index}>
                          <Col xl="9" lg="9" className="d-flex">
                            <FormGroup className="w-100">
                              <Form.Label>
                                {t("highlights.filter.type.item")}
                              </Form.Label>
                              <Form.Select
                                aria-label="item"
                                value={itemHighLight?.itemId || 0}
                                onChange={(e) => {
                                  handleHighlightType(
                                    contentType.id,
                                    e.target.value,
                                    false,
                                    index
                                  );
                                }}
                              >
                                <option value={0} disabled hidden>
                                  {t(
                                    "highlights.filter.type.contentPlaceholder"
                                  )}
                                </option>
                                {resources[contentType.id]?.map((item) => (
                                  <option key={item?.id} value={item?.id}>
                                    {item?.title}
                                  </option>
                                ))}
                              </Form.Select>
                            </FormGroup>
                            {index < NUMBER_OF_HIGHLIGHTS - 1 &&
                              index ===
                                itemsHighLight?.[contentType.id].length - 1 && (
                                <Button
                                  size="ls"
                                  variant="link"
                                  className="mx-1 align-self-end border-primary"
                                  onClick={() =>
                                    handleAddItemClick(contentType.id)
                                  }
                                  disabled={itemHighLight.itemId === null}
                                >
                                  <FontAwesomeIcon icon={"plus"} size={"sm"} />
                                </Button>
                              )}
                            {itemsHighLight?.[contentType.id].length > 1 && (
                              <Button
                                size="ls"
                                variant="link"
                                className="mx-1 align-self-end border-primary"
                                onClick={() =>
                                  handleRemoveItemClick(contentType.id, index)
                                }
                              >
                                <FontAwesomeIcon icon={"trash"} size={"sm"} />
                              </Button>
                            )}
                          </Col>
                        </Row>
                      )
                    )}
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </Accordion.Body>
          </Accordion>
        );
      })}
      <Button type="submit" className={"mt-3"} disabled={ (!permissions.highlights?.update && !permissions.highlights?.create && !permissions.highlights?.delete) ? true : submitButtonDisabled }>
        {t("highlights.form.submit")}
      </Button>
    </Form>
  );
};

export default HighlightedForm;
