import api from './api';

const getAll = (pagination, sortColumn, filter) => api.get('/user-permission', {
    pageNum: pagination.currentPage - 1,
    pageLength: pagination.itemsPerPage,
    search: filter.search,
    active: filter.active,
    sortBy: sortColumn.sortByColumn,
    orderBy: sortColumn.orderBy
}).then(response => {
    return response.data;
});

const getById = (id) => api.get(`/user-permission/${id}`).then(response => {
    return response.data
});

const getPermissionsByConnected = () => api.get('/user-permission/connected-user').then(response => {
    return convertPermissions(response.data.permissions)
});

const update = (id, data) => api.put(`/user-permission/${id}`, data).then(response => {
    return response.data
});

const convertPermissions = (permissionsData) => {
    const permissionsObject = {};

    for (const resource in permissionsData) {
      if (permissionsData[resource].length > 0) {
        permissionsObject[resource] = {
            read: permissionsData[resource].includes(0),
            create: permissionsData[resource].includes(1),
            update: permissionsData[resource].includes(2),
            delete: permissionsData[resource].includes(3)
        };
      } else {
        permissionsObject[resource] = {
            read: false,
            create: false,
            update: false,
            delete: false
        };
      }
    }

    return permissionsObject;
  }

const UserPermissionService = {
    getAll,
    getById,
    getPermissionsByConnected,
    update
};

export default UserPermissionService;