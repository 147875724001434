import {useCallback, useEffect, useState} from "react";
import ProjectService from "../../../services/project.service";
import {Link, useNavigate, useParams} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Alert, Card} from "react-bootstrap";
import ProjectForm from "../../../components/forms/ProjectForm";
import UserPermissionService from "../../../services/userPermission.service";

const ProjectDetails = () => {

    const params = useParams();

    const navigate = useNavigate();

    const { t } = useTranslation();

    const [project, setProject] = useState();

    const [permissions, setPermissions] = useState({});

    const [success, setSuccess] = useState({
        update: false,
        email: false,
    });

    const [errors, setErrors] = useState({
        update: false,
    });

    const getProject = useCallback(async (id) => {

        try {
            const data = await ProjectService.getById(id);
            setProject(data);
        } catch (e) {
            navigate("/project");
        }
    }, [navigate]);

    const updateProject = async (data) => {
        setErrors({ ...errors, update: false });
        setSuccess({ ...success, update: false });

        try {
            await ProjectService.update(params.id, data);
            getProject(params.id);
            setSuccess({...success, update: true});
        } catch (e) {
            setErrors({...errors, update: true});
        }
    };

    const getPermissions = async () => {
        try {
            const data = await UserPermissionService.getPermissionsByConnected();
            setPermissions(data);
        } catch (error) {
            console.error(error);
        }
    }

    useEffect(() => {
        const loadData = async () => {
            await getPermissions();
        }

        loadData();
    }, []);

    useEffect(() => {
        getProject(params.id);
    }, [getProject, params.id]);

    return (
        <>
            <div className={"d-flex justify-content-between my-3"}>
                <Link to="/project" className={"previous round rounded-circle text-decoration-none"}
                      title={t('general.back')}>
                    <FontAwesomeIcon icon={"chevron-left"} className={"mt-2 me-2"} />
                    <span>{t('projects.title')}</span>
                </Link>
            </div>
            <Alert variant={"success"} show={success.update} dismissible onClose={() => setSuccess({...success, update: false})}>
                {t('projects.success.update')}
            </Alert>
            <Alert variant={"danger"} show={errors.update} dismissible onClose={() => setErrors({...errors, update: false})}>
                {t('projects.error.update')}
            </Alert>
            <Card>
                <Card.Header className={"py-3"}>
                    <h6 className={"m-0 fw-bold text-primary"}>{t('projects.update.title')}</h6>
                </Card.Header>
                <Card.Body>
                    <ProjectForm onSubmit={updateProject} project={project} permissions={permissions} details />
                </Card.Body>
            </Card>
        </>
    );
}

export default ProjectDetails;