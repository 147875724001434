import { Accordion, Button, Card, Table, Alert, Row, Col, Form, FormGroup } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";

import APP_CONF from "../../scripts/constants";
import PaginationComponent from "../../components/PaginationComponent";
import BodyService from "../../services/body.service";
import CategoryService from "../../services/category.service";
import ArticleService from "../../services/article.service";
import ArticleForm from "../../components/forms/article/ArticleForm";
import UseAlertConfirm from "../../hooks/UseAlertConfirm";
import ProjectService from "../../services/project.service";
import UseFormatDate from "../../hooks/UseFormatDate";
import TableHeaderSortComponent from "../../components/TableHeaderSortComponent";
import StampService from "../../services/stamps.service";
import AuthorService from "../../services/author.service";
import ProvinceService from "../../services/province.service";
import UserPermissionService from "../../services/userPermission.service";

const Article = () => {

    const { t } = useTranslation();

    const [languages, setLanguages] = useState();
    const [categories, setCategories] = useState();
    const [projects, setProjects] = useState();
    const [stamps, setStamps] = useState();
    const [articles, setArticles] = useState([]);
    const [indexDesc, setIndexDesc] = useState(false);
    const [totalImages] = useState(4);
    const [author, setAuthor] = useState();
    const [provinces, setProvinces] = useState();
    const [permissions, setPermissions] = useState({});

    const [pagination, setPagination] = useState({
        currentPage: 1,
        totalItems: 0,
        itemsPerPage: APP_CONF.ITEMS_PER_PAGE,
        numPages: 1
    });

    const { register, handleSubmit } = useForm({
        mode: "all",
    });

    const [filter, setFilter] = useState({
        active: ""
    })

    const { showModal } = UseAlertConfirm();

    const [success, setSuccess] = useState({
        create: false,
        delete: false,
    });
    const [errors, setErrors] = useState({
        create: false,
        delete: false,
        config: false,
        slug: false,
        usedSlug: false,
    });

    const getEmptyTranslations = async () => {
        const data = await BodyService.getEmptyTranslations();
        setLanguages(data);
    }

    const [sortColumn, setSortColumn] = useState({
        sortByColumn: null,
        orderBy: null
    });

    const getArticles = async () => {
        try {
            let language;
            if ((sortColumn.sortByColumn === 'titleFirstPartByLanguage' || sortColumn.sortByColumn === 'category') &&
                (sortColumn.orderBy != null || sortColumn.orderBy != undefined)) {
                language = "es";
            }
            const data = await ArticleService.getAll(pagination, sortColumn, language, filter)
            setArticles(data.content)
            setPagination({
                ...pagination,
                totalItems: data.totalElements,
                numPages: data.totalPages
            });
        } catch ({ response }) {
            console.error(response);
        }
    }

    const createArticle = async (article) => {
        setErrors({ ...errors, create: false, delete: false, config: false, slug: false, usedSlug: false, });
        setSuccess({ ...success, create: false });

        if ((article.typeId === 1 && article.authorId !== 0 && article.categoryId !== 0) || article.typeId === 2) {
            try {
                await ArticleService.create(article);
                setSuccess({ ...success, create: true });
                getArticles();
            } catch (e) {
                if (e?.response?.data?.errorCode === 15002) {
                    setErrors({ ...errors, create: false, delete: false, config: false, slug: true, usedSlug: false, });
                } else if (e?.response?.data?.errorCode === 15001) {
                    setErrors({ ...errors, create: false, delete: false, config: false, slug: false, usedSlug: true });
                } else {
                    setErrors({ ...errors, create: true, delete: false, config: false, slug: false, usedSlug: false, });
                }
                return e.response
            }
        } else {
            setErrors({ ...errors, config: true })
        }
    }

    const deleteArticle = async (id) => {

        showModal({
            title: t("general.delete.title"),
            body: t("general.delete.message"),
            confirmButtonVariant: "danger",
            onConfirm: async () => {
                setErrors({ ...errors, create: false });
                setSuccess({ ...success, create: false });
                try {
                    await ArticleService.remove(id);
                    setSuccess({ ...success, delete: true });
                    getArticles();
                } catch (e) {
                    console.error(e.response);
                    setErrors({ ...errors, delete: true });
                }
            }
        });
    }

    const filterSearch = (data) => {
        let obj = data;
        if (obj.search === "") {
            obj = {
                active: data.active
            }
        }
        setFilter(obj);
    }

    const downloadFile = () => {
        try {
            let language = "es";
            ArticleService.downloadExcel(language, filter);
        } catch ({ response }) {
            console.error(response);
        }
    }

    const getCategories = async () => {
        try {
            const data = await CategoryService.selectAll();
            setCategories(data);
        } catch ({ response }) {
            console.error(response);
        }
    }
    const getProjects = async () => {
        try {
            const data = await ProjectService.getAllOrderByTitle("es");
            setProjects(data);
        } catch ({ response }) {
            console.error(response);
        }
    }

    const getStamps = async () => {
        try {
            const data = await StampService.lazy();
            setStamps(data);
        } catch ({ response }) {
            console.error(response);
        }
    }

    const getAuthor = async () => {
        try {
            const data = await AuthorService.getList();
            setAuthor(data);
        } catch ({ response }) {
            console.error(response);
        }
    }

    const getProvinces = async () => {
        try {
            const data = await ProvinceService.getList();
            setProvinces(data);
        } catch ({ response }) {
            console.error(response);
        }
    }

    const getPermissions = async () => {
        try {
            const data = await UserPermissionService.getPermissionsByConnected();
            setPermissions(data);
        } catch (error) {
            console.error(error);
        }
    }

    useEffect(() => {
        const loadData = async () => {
            await getPermissions();

            await getCategories();
            await getProjects();
            await getAuthor();
            await getStamps();
            await getProvinces();
            await getEmptyTranslations();
        }

        loadData();
    }, [])

    useEffect(() => {
        getArticles()
        if (sortColumn.sortByColumn === 'id' && sortColumn.orderBy === 'desc') {
            setIndexDesc(true);
        } else {
            setIndexDesc(false);
        }

    }, [pagination.currentPage, filter, sortColumn]);

    return (
        <>
            <Alert variant={"success"} show={success.create} dismissible onClose={() => setSuccess({ ...success, create: false })}>
                {t('articles.success.create')}
            </Alert>

            <Alert variant={"danger"} show={errors.create} dismissible onClose={() => setErrors({ ...errors, create: false })}>
                {t('articles.error.create')}
            </Alert>

            <Alert variant={"success"} show={success.delete} dismissible onClose={() => setSuccess({ ...success, delete: false })}>
                {t('articles.success.delete')}
            </Alert>

            <Alert variant={"danger"} show={errors.delete} dismissible onClose={() => setErrors({ ...errors, delete: false })}>
                {t('articles.error.delete')}
            </Alert>

            <Alert variant={"danger"} show={errors.config} dismissible onClose={() => setErrors({ ...errors, config: false })}>
                {t('articles.error.config')}
            </Alert>

            <Alert variant={"danger"} show={errors.slug} dismissible onClose={() => setErrors({ ...errors, slug: false })}>
                {t('articles.error.emptySlug')}
            </Alert>

            <Alert variant={"danger"} show={errors.usedSlug} dismissible onClose={() => setErrors({ ...errors, usedSlug: false })}>
                {t('articles.error.alreadyUsedSlug')}
            </Alert>

            {permissions.articles?.read && permissions.articles?.create ? 
                <Card className={"shadow mb-4"}>
                    <Accordion key={1}>
                        <Accordion.Item eventKey="0">
                            <Accordion.Header><h6 className={"m-0 fw-bold text-primary"}>{t('articles.creation.title')}</h6>
                            </Accordion.Header>
                            <Accordion.Body>
                                <ArticleForm categories={categories} projects={projects} stamps={stamps} provinces={provinces} languages={languages} authors={author} totalImages={totalImages} permissions={permissions} onSubmit={createArticle} />
                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>
                </Card>
            : null }

            <Card className={"shadow mb-4"}>
                <Card.Header className={"py-3"}>
                    <h6 className={"m-0 fw-bold text-primary"}>{t('articles.list.title')}</h6>
                </Card.Header>
                <Card.Body>
                    <Form onSubmit={handleSubmit(filterSearch)}>
                        <Row>
                            <Col lg="2" className={"mb-3"}>
                                <FormGroup>
                                    <Form.Label>{t('categories.filter.active.title')}</Form.Label>
                                    <Form.Select {...register('active')} aria-label="Default select example">
                                        <option value="">{t('categories.filter.active.value1')}</option>
                                        <option value={true}>{t('categories.filter.active.value2')}</option>
                                        <option value={false}>{t('categories.filter.active.value3')}</option>
                                    </Form.Select>
                                </FormGroup>
                            </Col>
                            <Col lg="3" className={"mb-3"}>
                                <FormGroup>
                                    <Form.Label>{t('users.filter.search')}</Form.Label>
                                    <Form.Control type="text" {...register("search")} />
                                </FormGroup>
                            </Col>
                        </Row>
                  
                        <div className='d-flex flex-row gap-2'>
                            <Button type="submit" className={"mt-3"} disabled={!permissions.articles?.read}>{t('categories.filter.button')}</Button>
                            <Button variant="primary" onClick={() => downloadFile()} className={"mt-3"} disabled={!permissions.articles?.read}>{t("general.download")}</Button>
                        </div>
                    </Form>
                    <hr />
                    <Table responsive>
                        <thead>
                            <tr>
                                <TableHeaderSortComponent scope={"col"} text={t('general.index')} column={"id"} sortColumn={sortColumn} setSortColumn={setSortColumn} />
                                <TableHeaderSortComponent scope={"col"} text={t('articles.list.fields.title')} column={"titleFirstPartByLanguage"} sortColumn={sortColumn} setSortColumn={setSortColumn} />
                                <TableHeaderSortComponent scope={"col"} text={t('articles.list.fields.author')} column={"articleAuthor"} sortColumn={sortColumn} setSortColumn={setSortColumn} />
                                <TableHeaderSortComponent scope={"col"} text={t('articles.list.fields.category')} column={"category"} sortColumn={sortColumn} setSortColumn={setSortColumn} />
                                <TableHeaderSortComponent scope={"col"} text={t('articles.list.fields.creationDate')} column={"creationDate"} sortColumn={sortColumn} setSortColumn={setSortColumn} />
                                <TableHeaderSortComponent scope={"col"} text={t('articles.list.fields.active')} column={"active"} sortColumn={sortColumn} setSortColumn={setSortColumn} />
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {articles.length > 0 ? articles.map((article, i) => (
                                <tr key={i}>
                                    {indexDesc ? <td>{articles.length - i}</td> :
                                        <td>{i + 1}</td>}
                                    <td>{article.titleFirstPartByLanguage['es'].value && article.titleFirstPartByLanguage['es'].value} {article.titleSecondPartByLanguage['es'].value && article.titleSecondPartByLanguage['es'].value}</td>
                                    <td>{article.articleAuthor}</td>
                                    <td>{article.category['es'].value}</td>
                                    <td>{UseFormatDate(article.creationDate)}</td>
                                    <td>{article.active ? 'Si' : 'No'}</td>
                                    <td className={"text-center"}>
                                        <Button variant="link" size="sm" className={"ms-2"} to={`/article/${article.id}`} as={Link} disabled={!permissions.articles?.read}>
                                            <FontAwesomeIcon icon="edit" />
                                        </Button>
                                        <Button variant="link" className="text-danger" size="sm" onClick={() => { deleteArticle(article.id) }} disabled={!permissions.articles?.delete}>
                                            <FontAwesomeIcon icon="trash" />
                                        </Button>
                                    </td>
                                </tr>
                            )) :
                                <tr>
                                    <td colSpan={7}>{t('general.empty')}</td>
                                </tr>
                            }
                        </tbody>
                    </Table>
                    <PaginationComponent pagination={pagination}
                        setPagination={setPagination}
                        alwaysShown={articles.length > 0} />
                </Card.Body>
            </Card>
        </>
    )
}

export default Article