import { t } from "i18next";
import { Button, Col, Form, FormCheck, FormGroup, Row } from "react-bootstrap"
import TranslationInputLanguage from "../fields/TranslationInputLanguage";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect } from "react";
import Select from "react-select";
import ProvinceService from "../../../services/province.service";

const RegionForm = ({ onSubmit, details, translations, region, permissions }) => {

    const [regionNameValues, setRegionNameValues] = useState();

    const [translationValues, setTranslationValues] = useState();

    const [province, setProvince] = useState();

    const [provinces, setProvinces] = useState([]);

    const { register, handleSubmit, formState, reset } = useForm({
        mode: "all",
        defaultValues: {
            active: !details,
        },
    });

    const setRegionNameValuesForm = (iso, value, id) => {
        setTranslationValues({
            ...translationValues, [iso]: { value: value, langId: id, iso: iso }
        })
    };

    const onSubmitForm = async (data) => {
        let objSend = {
            name: [],
            provinceId: province.id,
            active: data.active
        }

        Object.values(translationValues).forEach((name) => {
            objSend.name.push({
                language: {
                    id: name.langId
                },
                value: name.value
            })
        })

        try {
            await onSubmit(objSend);
            if (!details) {
                setTranslationValues(null);
                reset();
            }
        } catch (error) {
            console.error(error)
        }

        setTimeout(function () {
            window.scrollTo(0, 0);
        }, 0);
    };

    const getProvinces = async () => {
        try {
            const data = await ProvinceService.getList();            
            setProvinces(data);
        } catch (error) {
            console.error(error);
        }
    }

    useEffect(() => {       
        setRegionNameValues(translations);
        getProvinces();

        if (details && region) {

            let regionLang = {}

            Object.keys(region.name).forEach((data) => {
                regionLang[data] = {
                    value: region.name[data].value,
                    langId: region.name[data].language.id,
                    iso: region.name[data].language.iso,
                }
            });

            setTranslationValues(regionLang);
        }

    }, [translations, region, details]);

    useEffect(() => {      
        if (details) {            
            reset(region);
            setProvince(region?.province);
        }
    }, [region, details, reset]);

    return (
        <Form onSubmit={handleSubmit(onSubmitForm)}>
            <Row>
                <Col xs={12}>
                    <h5>{t('region.creation.fields.name')}</h5>
                    {translations?.length > 0 ?
                        <Row className="mt-3">
                            <TranslationInputLanguage translations={regionNameValues} data={translationValues} onChange={setRegionNameValuesForm} />
                        </Row>
                        : <></>
                    }
                </Col>
            </Row>
            <Row className="mb-4">
                <Col md={3}>
                    <FormGroup>
                        <Form.Label>{t('region.creation.province')}</Form.Label>
                        <Select options={provinces}
                            classNamePrefix="select"
                            placeholder=""
                            value={province || ''}
                            getOptionLabel={(option) => option.name["es"].value}
                            getOptionValue={(option) => option.id}
                            onChange={(data) => {
                                setProvince(data)
                            }}
                        />
                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col xs="12" className="mt-md-3">
                    <FormGroup>
                        <FormCheck label={t('region.creation.fields.active')} {...register("active")} />
                    </FormGroup>
                </Col>
            </Row>
            {details ? 
            <Button type="submit" className={"mt-4"} disabled={ permissions.regions?.update === false ? true : !formState.isValid }>
                    <FontAwesomeIcon icon={"save"} />
            </Button>
            : 
            <Button type="submit" className={"mt-4"} disabled={ permissions.regions?.create === false ? true : !formState.isValid }>
                    {t('region.creation.button')}
            </Button>
            }
        </Form>
    );
};

export default RegionForm;