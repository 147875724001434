import React, { useEffect, useState } from 'react'
import { Accordion, Alert, Button, Card, Col, Form, FormGroup, Image, Row, Table } from 'react-bootstrap';
import PromotionsService from '../../../services/promotions.service';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PromotionForm from '../../../components/PromotionForm';
import BodyService from '../../../services/body.service';
import SectionService from '../../../services/section.service';
import APP_CONF from '../../../scripts/constants';
import UserPermissionService from "../../../services/userPermission.service";

function PromotionDetails() {

    const { t } = useTranslation();
    const params = useParams();
    const navigate = useNavigate();

    const [languages, setLanguages] = useState();
    const [promotion, setPromotion] = useState()
    const [sections, setSections] = useState();
    const [permissions, setPermissions] = useState({});

    const [success, setSuccess] = useState({
        update: false,
    });

    const [errors, setErrors] = useState({
        update: false,
    });

    const [filter, setFilter] = useState({
        active: true,
        search: ""
    })

    const [pagination, setPagination] = useState({
        currentPage: 1,
        totalItems: 0,
        itemsPerPage: APP_CONF.ITEMS_PER_PAGE,
        numPages: 1
    });

    const getPromotion = async (id) => {
        try {
            const data =  await PromotionsService.getById(id)
            setPromotion(data)
        } catch (error) {
            console.error(error);
        }
    }

    const  updatePromotion = async (data) => {
        setErrors({ ...errors, update: false });
        setSuccess({ ...success, update: false });

        try {
            await PromotionsService.update(params.id, data)
            setSuccess({...success, update: true});
        } catch (e) {
            setErrors({...errors, update: true});
        }
    }

    const getEmptyTranslations = async () => {
		const data = await BodyService.getEmptyTranslations();
		setLanguages(data);
	}

    const getSections = async () => {
        try {
            const data = await SectionService.getAll(pagination, null, null, {active: true, search: ""})
            setSections(data.content)
        } catch (error) {
            console.error(error);
        }
    }

    const getPermissions = async () => {
        try {
            const data = await UserPermissionService.getPermissionsByConnected();
            setPermissions(data);
        } catch (error) {
            console.error(error);
        }
    }

    useEffect(() => {
        getPromotion(params.id);
    }, [params.id]);


    useEffect(() => {
        const loadData = async () => {
            await getPermissions();
            await getEmptyTranslations();
            await getSections();

        }

        loadData();
    }, [])

    return (
        <>
            <div className={"d-flex justify-content-between my-3"}>
                <Link to="/promotion" className={"previous round rounded-circle text-decoration-none"}
                    title={t('general.back')}>
                    <FontAwesomeIcon icon={"chevron-left"} className={"mt-2 me-2"} />
                    <span>{t('promotions.title')}</span>
                </Link>
            </div>
            <Alert variant={"success"} show={success.update} dismissible onClose={() => setSuccess({ ...success, update: false })}>
                {t('promotions.success.update')}
            </Alert>
            <Alert variant={"danger"} show={errors.update} dismissible onClose={() => setErrors({ ...errors, update: false })}>
                {t('promotions.error.update')}
            </Alert>
            <Card>
                <Card.Header className={"py-3"}>
                    <h6 className={"m-0 fw-bold text-primary"}>{t('promotions.update.title')}</h6>
                </Card.Header>
                <Card.Body>
                    <PromotionForm onSubmit={updatePromotion} promotion={promotion} languages={languages} sections={sections} permissions={permissions} detail />
                </Card.Body>
            </Card>
        </>
    );
}

export default PromotionDetails