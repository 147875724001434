import { Accordion, Button, Row, Col, Form, FormGroup, FormCheck } from "react-bootstrap";
import { useEffect, useState } from "react";
import { t } from "i18next";

import BodyService from "../../../services/body.service";
import ArticleUpdateMetaForm from "./ArticleUpdateMetaForm";
import RichTextArea from "../fields/RichTextarea";
import ArticleImagesForm from "./ArticleImagesForm";
import ArticleTagForm from "./ArticleTagForm";
import DateTimePicker from 'react-datetime-picker/dist/entry.nostyle';
import Select from "react-select";
import { usePromiseTracker } from "react-promise-tracker";

import 'react-calendar/dist/Calendar.css';
import 'react-clock/dist/Clock.css';
import LocalityService from "../../../services/locality.service";
import RegionService from "../../../services/region.service";

const ArticleForm = ({ article, categories, projects, stamps, provinces, languages, authors, detail, totalImages, permissions, onSubmit }) => {

    const { promiseInProgress } = usePromiseTracker();

    const [configuration, setConfiguration] = useState({
        categoryId: 0,
        projectId: 0,
        articleAuthor: "",
        active: true,
        privateArticle: false,
        publicationDate: new Date(),
        order: null,
        listed: true,
        provinceId: 0,
        regionId: 0,
        localityId: 0
    });
    const [meta, setMeta] = useState();
    const [richTexts, setRichTexts] = useState();
    const [images, setImages] = useState();
    const [tags, setTags] = useState();
    const [formInvalid, setFormInvalid] = useState(!detail ? true : false);
    const [resetFlag, setResetFlag] = useState(false);
    const [listImage, setListImage] = useState();
    const [stamp, setStamp] = useState();
    const [articleType, setArticleType] = useState(!detail && { title: "Completo", value: 1 });
    const [localities, setLocalities] = useState();
    const [regions, setRegions] = useState([]);

    const [province, setProvince] = useState();
    const [region, setRegion] = useState();
    const [locality, setLocality] = useState();

    const formatData = () => {
        let dataSend = {
            slug: [],
            metaTitleByLanguage: [],
            metaDescriptionByLanguage: [],
            breadcrumbsTitleByLanguage: [],
            shortDescriptionByLanguage: [],
            titleFirstPartByLanguage: [],
            titleSecondPartByLanguage: [],
            textBlock1ByLanguage: [],
            textBlock2ByLanguage: [],
            textBlock3ByLanguage: [],
            textBlock4ByLanguage: [],
            quote1: [],
            quote2: [],
            quote3: [],
            active: configuration.active,
            privateArticle: configuration.privateArticle,
            articleAuthor: configuration.articleAuthor,
            authorId: configuration.authorId,
            photographerId: configuration.photographerId,
            provinceId: configuration.provinceId !== 0 ? configuration.provinceId : null,
            regionId: configuration.regionId !== 0 ? configuration.regionId : null,
            localityId: (configuration.regionId !== 0 && configuration.localityId !== 0) ? configuration.localityId : null,
            categoryId: parseInt(configuration.categoryId),
            projectId: configuration.projectId,
            publicationDate: configuration.publicationDate !== null ? configuration.publicationDate : null,
            order: configuration.order,
            tags: tags,
            typeId: articleType?.value,
            stampId: stamp?.id || null,
            listed: configuration.listed
        }

        if (richTexts) {
            Object.values(richTexts).forEach((data, index) => {

                dataSend.titleFirstPartByLanguage.push({
                    language: {
                        id: data.langId
                    },
                    value: data.titleFirstPartByLanguage || ""
                });

                dataSend.titleSecondPartByLanguage.push({
                    language: {
                        id: data.langId
                    },
                    value: data.titleSecondPartByLanguage || ""
                });

                dataSend.slug.push({
                    language: {
                        id: data.langId
                    },
                    value: (data.slug ? data.slug : data.titleFirstPartByLanguage && data.titleSecondPartByLanguage ? BodyService.slugifyStr(`${data.titleFirstPartByLanguage} ${data.titleSecondPartByLanguage}`) : undefined)
                });

                dataSend.breadcrumbsTitleByLanguage.push({
                    language: {
                        id: data.langId
                    },
                    value: data.breadcrumbsTitleByLanguage || ""
                });

                dataSend.shortDescriptionByLanguage.push({
                    language: {
                        id: data.langId
                    },
                    value: data.articleShortDescriptionByLanguage || ""
                });

                dataSend.textBlock1ByLanguage.push({
                    language: {
                        id: data.langId
                    },
                    value: data.textBlock1ByLanguage || ""
                });

                dataSend.textBlock2ByLanguage.push({
                    language: {
                        id: data.langId
                    },
                    value: data.textBlock2ByLanguage || ""
                });

                dataSend.textBlock3ByLanguage.push({
                    language: {
                        id: data.langId
                    },
                    value: data.textBlock3ByLanguage || ""
                });

                dataSend.textBlock4ByLanguage.push({
                    language: {
                        id: data.langId
                    },
                    value: data.textBlock4ByLanguage || ""
                });

                dataSend.quote1.push({
                    language: {
                        id: data.langId
                    },
                    value: data.quote1 || ""
                });

                dataSend.quote2.push({
                    language: {
                        id: data.langId
                    },
                    value: data.quote2 || ""
                });

                dataSend.quote3.push({
                    language: {
                        id: data.langId
                    },
                    value: data.quote3 || ""
                });
            })
        }

        if (images) {
            Object.values(images).forEach((img, index) => {
                if (img?.imgData?.url?.split(',').length > 1) {
                    dataSend[`img${index + 1}Base64`] = (img.imgData.url.split(',').length === 1 ? "" : img.imgData.url.split(',')[1]); // 1 == url - >1 == base64
                    dataSend[`img${index + 1}ContentType`] = (img.imgData.url.split(',').length === 1 ? "" : img.imgData.type);
                }
                if (img.imgData.url === "") {
                    dataSend[`img${index + 1}Base64`] = img.imgData.url;
                }
                dataSend[`img${index + 1}Author`] = img.author;
            })
        }

        if (listImage) {
            if (listImage?.url?.split(',').length > 1) {
                dataSend[`listImgBase64`] = (listImage?.url.split(',').length === 1 ? "" : listImage?.url.split(',')[1]); // 1 == url - >1 == base64
                dataSend[`listImgContentType`] = (listImage?.url.split(',').length === 1 ? "" : listImage?.type);
            }
            if (listImage?.url === "") {
                dataSend[`listImgBase64`] = listImage?.url;
            }
        }

        if (meta) {
            Object.values(meta).forEach((meta, index) => {

                dataSend.metaTitleByLanguage.push({
                    language: {
                        id: meta.langId
                    },
                    value: meta.metaTitleByLanguage
                });

                dataSend.metaDescriptionByLanguage.push({
                    language: {
                        id: meta.langId
                    },
                    value: meta.metaDescriptionByLanguage
                });
            })
        }

        return dataSend
    };

    const updateConfig = (target, data) => {
        setConfiguration({
            ...configuration,
            [target]: data
        });
    }

    const updateMeta = (target, data, iso) => {

        setMeta({
            ...meta, [iso]: {
                ...meta[iso], [target]: data
            }
        });
    }

    const updateRichText = (target, data, iso) => {

        if (target === "slug") {
            data = BodyService.slugifyStr(data)
        }

        setRichTexts({
            ...richTexts, [iso]: {
                ...richTexts[iso], [target]: data
            }
        });
    }

    const updateImages = async (data, ref, target) => {
        if (ref === null && target === null) {
            setListImage({ url: data.url, type: data.type });
        } else {
            setImages({
                ...images, [ref]: {
                    ...images[ref], [target]: data
                }
            });
        }

    }

    const updateTags = (data) => {
        setTags(data);
    }

    const resetInputs = () => {
        setConfiguration({
            categoryId: 0,
            projectId: 0,
            authorId: 0,
            photographerId: 0,
            province: "",
            articleAuthor: "",
            active: true,
            promotiom: "test...",
            privateArticle: false,
            publicationDate: new Date(),
            order: '',
            provinceId: 0,
            regionId: 0,
            localityId: 0
        });
        setMeta();
        setListImage();
        setImages();
        setTags();
    }

    const handleOnSubmit = async () => {
        const data = formatData();
        const createResponse = await onSubmit(data);
        if (!detail && !createResponse) {
            resetInputs();
            setResetFlag(resetFlag ? false : true);
        }
        setTimeout(function () {
            window.scrollTo(0, 0);
        }, 0);
    }

    const getLocalityByRegion = async (id) => {
        const data = await LocalityService.getByRegionId(id);

        const emptyLocality = {
            id: null,
            title: [{ value: null }]
        }
        setLocalities([emptyLocality, ...data]);
    };

    const getRegionByProvince = async (id) => {
        const data = await RegionService.getByProvinceId(id);

        const emptyRegion = {
            id: null,
            title: [{ value: null }]
        }
        setRegions([emptyRegion, ...data]);
    };

    useEffect(() => {
        const setLanguages = async (lang) => {
            let metaArray = {};
            let richTextArray = {};
            let imagesArray = {};

            if (lang) {
                lang.forEach(lang => {
                    metaArray[lang.language.iso] = {
                        langId: lang.language.id,
                    }
                    richTextArray[lang.language.iso] = {
                        langId: lang.language.id,
                    }
                });
            }

            if (detail && article) {
                Object.keys(metaArray).forEach(lang => {
                    metaArray[lang].metaTitleByLanguage = article.metaTitleByLanguage[lang];
                    metaArray[lang].metaDescriptionByLanguage = article.metaDescriptionByLanguage[lang];
                })

                Object.keys(richTextArray).forEach(lang => {
                    richTextArray[lang].titleFirstPartByLanguage = article.titleFirstPartByLanguage[lang];
                    richTextArray[lang].titleSecondPartByLanguage = article.titleSecondPartByLanguage[lang];
                    richTextArray[lang].articleShortDescriptionByLanguage = article.articleShortDescriptionByLanguage[lang];
                    richTextArray[lang].slug = article.slug[lang];
                    richTextArray[lang].isSlugEmpty = article.slug[lang] === "" || article.slug[lang] === null
                    richTextArray[lang].breadcrumbsTitleByLanguage = article.breadcrumbsTitleByLanguage[lang];
                    richTextArray[lang].textBlock1ByLanguage = article.textBlock1ByLanguage[lang];
                    richTextArray[lang].textBlock2ByLanguage = article.textBlock2ByLanguage[lang];
                    richTextArray[lang].textBlock3ByLanguage = article.textBlock3ByLanguage[lang];
                    richTextArray[lang].textBlock4ByLanguage = article.textBlock4ByLanguage[lang];
                    richTextArray[lang].quote1 = article.quote1[lang];
                    richTextArray[lang].quote2 = article.quote2[lang];
                    richTextArray[lang].quote3 = article.quote3[lang];
                })

                article.images.forEach((img, i) => {
                    imagesArray[`img${i}`] = {
                        author: img.imgAuthor,
                        imgData: {
                            url: img.imgData.url,
                            type: img.imgData.type
                        }
                    }
                });
            }

            if (totalImages && !article) {
                for (let i = 0; i < totalImages; i++) {
                    imagesArray[`img${i}`] = {
                        author: "",
                        imgData: {
                            url: "",
                            type: ""
                        }
                    }

                }
            }

            setMeta(metaArray);
            setRichTexts(richTextArray);
            setImages(imagesArray)
            setListImage(article?.listImageUrl)
            setTags(article?.tags || []);
            setStamp(article?.stamp);

            if (article) {
                setArticleType(article?.type === "REVIEW" ? { title: "Review", value: 2 } : { title: "Completo", value: 1 });
            }
        }

        if (article) {
            setConfiguration({
                categoryId: article.category.id,
                projectId: article.project ? article.project.id : 0,
                articleAuthor: article.articleAuthor,
                authorId: article.authorId,
                photographerId: article.photographerId,
                provinceId: article?.province ? article?.province.id : 0,
                regionId: article?.region ? article?.region.id : 0,
                localityId: article?.locality ? article?.locality.id : 0,
                active: article.active,
                privateArticle: article.privateArticle,
                publicationDate: article.publicationDate !== null ? new Date(article.publicationDate) : null,
                order: article.order,
                listed: article.listed
            })
            setLanguages(languages)
         
            if (article.province !== null){
                getRegionByProvince(article.province.id); 
            }
            
            if (article.region !== null){
                getLocalityByRegion(article.region.id);
            }

            setProvince(article.province);
            setRegion(article.region);
            setLocality(article.locality);
        } else if (languages) {
            setLanguages(languages)
        }

    }, [article, languages, detail, totalImages, resetFlag])

    useEffect(() => {
        const checkForm = async () => {
            if (!detail || detail) {
                if (articleType?.value === 1) {
                    (configuration.authorId && configuration.categoryId) ? setFormInvalid(false) : setFormInvalid(true)
                } else if (articleType?.value === 2) {
                    (configuration.categoryId && configuration.authorId && configuration.projectId) ? setFormInvalid(false) : setFormInvalid(true)
                }
            }
        }

        checkForm()
    }, [configuration, articleType, stamp])

    return (
        <>
            {formInvalid && <small className='text-danger fw-bold d-block mb-3'>{t('articles.creation.warning')}</small>}
            <FormGroup className="mb-3">
                <Select options={[
                    { title: "Completo", value: 1 },
                    { title: "Review", value: 2 }
                ]}
                    classNamePrefix="select"
                    placeholder={!detail ? t('articles.creation.general.type') : ""}
                    getOptionLabel={(option) => option.title}
                    getOptionValue={(option) => option.value}
                    value={articleType || ''}
                    onChange={(data) => {
                        setArticleType(data)
                    }}
                />
            </FormGroup>
            <Accordion className="mb-3">
                <Accordion.Item eventKey="2">
                    <Accordion.Header>
                        <h6 className={"m-0 fw-bold text-primary"}>{t('articles.creation.general.title')}<span className="ms-1 text-danger fw-bold">*</span></h6>
                    </Accordion.Header>
                    <Accordion.Body>
                        <Row className='mb-4'>
                            <Col xl="3" lg="6">
                                <FormGroup>
                                    <Form.Label>{t('articles.creation.general.author.title')}</Form.Label>
                                    <Form.Control type="text" value={configuration?.articleAuthor || ''}
                                        onChange={e => updateConfig('articleAuthor', e.target.value)}
                                        disabled={true} />
                                </FormGroup>
                            </Col>
                            <Col xl="3" lg="6">
                                <FormGroup>
                                    <Form.Label>{t('articles.creation.general.author.title')}<span className="ms-1 text-danger fw-bold">*</span></Form.Label>
                                    <Form.Select aria-label="author" value={configuration?.authorId || 0}
                                        onChange={e => updateConfig('authorId', e.target.value)}>
                                        <option value={0} disabled hidden>{t('articles.creation.general.author.placeholder')}</option>
                                        {authors?.map(author => (
                                            <option key={author?.id}
                                                value={author?.id}>{author?.fullName}</option>
                                        ))}
                                    </Form.Select>
                                </FormGroup>
                            </Col>
                            <Col xl="3" lg="6">
                                <FormGroup>
                                    <Form.Label>{t('articles.creation.general.photographer.title')}</Form.Label>
                                    <Form.Select aria-label="photographer" value={configuration?.photographerId || 0}
                                        onChange={e => updateConfig('photographerId', e.target.value)}>
                                        <option value={0} disabled hidden>{t('articles.creation.general.photographer.placeholder')}</option>
                                        {authors?.map(author => (
                                            <option key={author?.id}
                                                value={author?.id}>{author?.fullName}</option>
                                        ))}
                                    </Form.Select>
                                </FormGroup>
                            </Col>
                            <Col xl="3" lg="6">
                                <FormGroup>
                                    <Form.Label>{t('articles.creation.general.category.title')}<span className="ms-1 text-danger fw-bold">*</span></Form.Label>
                                    <Form.Select aria-label="category" value={configuration?.categoryId || 0}
                                        onChange={e => updateConfig('categoryId', e.target.value)}>
                                        <option value={0} disabled hidden>{t('articles.creation.general.category.placeholder')}</option>
                                        {categories?.map(category => (
                                            <option key={category?.id}
                                                value={category?.id}>{category?.name?.['es']?.value}</option>
                                        ))}
                                    </Form.Select>
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row className="mb-4">

                            <Col xl="3" lg="6">
                                <FormGroup>
                                    <Form.Label>{t('articles.creation.general.project.title')}{articleType.value === 2 && <span className="ms-1 text-danger fw-bold">*</span>}</Form.Label>
                                    <Form.Select aria-label="project" value={configuration?.projectId || 0}
                                        onChange={e => updateConfig('projectId', e.target.value)}>
                                        <option value={0} disabled hidden>{t('articles.creation.general.project.placeholder')}</option>
                                        {projects?.map(project => (
                                            <option key={project?.id}
                                                value={project?.id}>{project?.title[0].value}</option>
                                        ))}
                                    </Form.Select>
                                </FormGroup>
                            </Col>
                            <Col xl="3" lg="6">
                                <FormGroup>
                                    <Form.Label>{t('articles.creation.general.publicationDate')}</Form.Label>
                                    <DateTimePicker calendarIcon={null}
                                        disableClock={true}
                                        onChange={e => updateConfig('publicationDate', e)}
                                        value={configuration?.publicationDate || ''} />
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row className="mb-4"> 
                            <Col xl="3" lg="6">
                                <FormGroup>
                                    <Form.Label>{t('articles.creation.general.province.title')}</Form.Label>
                                    <Form.Select aria-label="province" value={configuration?.provinceId || 0}
                                        onChange={e => {
                                            updateConfig('provinceId', parseInt(e.target.value));
                                            getRegionByProvince(parseInt(e.target.value));
                                            setRegion(null);
                                            setLocalities([]);
                                            setLocality(null);
                                        }}>
                                        <option value={0} disabled hidden>{t('articles.creation.general.province.placeholder')}</option>
                                        {provinces?.map(province => (
                                            <option key={province?.id}
                                                value={province?.id}>{province?.name?.['es']?.value}</option>
                                        ))}
                                    </Form.Select>
                                </FormGroup>
                            </Col>
                            <Col xl="3" lg="6">
                                <FormGroup>
                                    <Form.Label>{t('articles.creation.general.region.title')}</Form.Label>
                                    <Form.Select aria-label="region" value={configuration?.regionId || 0}
                                        onChange={e => {
                                            updateConfig('regionId', parseInt(e.target.value))
                                            setLocalities([]);
                                            setLocality(null);
                                            if (e.target.value !== "") {
                                                getLocalityByRegion(parseInt(e.target.value));
                                            }
                                        }}
                                        disabled={!configuration.provinceId}
                                    >
                                        <option value={0} disabled hidden>{t('articles.creation.general.region.placeholder')}</option>
                                        {regions?.map(region => (
                                            <option key={region?.id}
                                                value={region?.id}>{region?.name?.['es']?.value}</option>
                                        ))}
                                    </Form.Select>
                                </FormGroup>
                            </Col>
                            <Col xl="3" lg="6">
                                <FormGroup>
                                    <Form.Label>{t('articles.creation.general.locality.title')}</Form.Label>
                                    <Form.Select aria-label="locality" value={configuration?.localityId || 0}
                                        onChange={e => {
                                            updateConfig('localityId', parseInt(e.target.value))
                                            // getLocalityByProvince(e.target.id);
                                        }}
                                        disabled={!configuration.regionId}
                                    >
                                        <option value={0} disabled hidden>{t('articles.creation.general.locality.placeholder')}</option>
                                        {localities?.map(locality => (
                                            <option key={locality?.id}
                                                value={locality?.id}>{locality?.name?.['es']?.value}</option>
                                        ))}
                                    </Form.Select>
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row className="mb-4">
                            <Col xl="3" lg="6">
                                <FormGroup>
                                    <Form.Label>{t('articles.creation.general.order')}</Form.Label>
                                    <Form.Control type="number" min="1" value={configuration?.order || ''}
                                        onChange={e => updateConfig('order', e.target.value)} />
                                </FormGroup>
                            </Col>
                            <Col lg="auto" className="mt-3 d-flex align-items-end justify-content-start">
                                <FormGroup className="">
                                    <FormCheck className="form-check-input-md"
                                        label={t('articles.creation.general.private')}
                                        checked={configuration?.privateArticle}
                                        onChange={e => updateConfig('privateArticle', e.target.checked)} />
                                </FormGroup>
                            </Col>
                            <Col lg="auto" className="mt-3 d-flex align-items-end justify-content-start">
                                <FormGroup className="">
                                    <FormCheck className="form-check-input-md"
                                        label={t('articles.creation.general.active')}
                                        checked={configuration?.active}
                                        onChange={e => updateConfig('active', e.target.checked)} />
                                </FormGroup>
                            </Col>
                            <Col lg="auto" className="mt-3 d-flex align-items-end justify-content-start">
                                <FormGroup className="">
                                    <FormCheck className="form-check-input-md"
                                        label={t('articles.creation.general.listed')}
                                        checked={configuration?.listed}
                                        onChange={e => updateConfig('listed', e.target.checked)} />
                                </FormGroup>
                            </Col>
                        </Row>
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>
            {articleType?.value === 1 &&
                <Accordion className="mb-3">
                    <Accordion.Item eventKey="2">
                        <Accordion.Header>
                            <h6 className={"m-0 fw-bold text-primary"}>{t('articles.creation.tags.title')}</h6>
                        </Accordion.Header>
                        <Accordion.Body>
                            <ArticleTagForm onChange={updateTags} tags={tags} />
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>}
            {languages && languages.map((lang, i) =>
                <Accordion key={i + 2} className="mb-3">
                    <Accordion.Item eventKey="2">
                        <Accordion.Header><h6 className={"m-0 fw-bold text-primary"}>{lang.language.name}</h6>
                        </Accordion.Header>
                        <Accordion.Body>
                            <Accordion className="mb-3">
                                <Accordion.Item eventKey="2">
                                    <Accordion.Header>
                                        <h6 className={"m-0 fw-bold text-primary"}>{t('articles.creation.content.meta.title')}</h6>
                                    </Accordion.Header>
                                    <Accordion.Body>
                                        <ArticleUpdateMetaForm meta={meta} iso={lang.language.iso}
                                            languageid={lang.language.id} order={i}
                                            onChange={updateMeta} />
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                            <Accordion className="mb-3">
                                <Accordion.Item eventKey="2">
                                    <Accordion.Header><h6
                                        className={"m-0 fw-bold text-primary"}>{t('articles.creation.content.texts.title')}</h6>
                                    </Accordion.Header>
                                    <Accordion.Body>
                                        <Row>
                                            <Col md="4" className="mt-3">
                                                <FormGroup>
                                                    <Form.Label>{t('articles.creation.content.texts.fields.title1')}</Form.Label>
                                                    <Form.Control type="text"
                                                        value={richTexts?.[lang.language.iso]?.titleFirstPartByLanguage || ''}
                                                        onChange={e => {
                                                            updateRichText('titleFirstPartByLanguage', e.target.value, lang.language.iso)
                                                        }} />
                                                </FormGroup>
                                            </Col>
                                            <Col md="4" className="mt-3">
                                                <FormGroup>
                                                    <Form.Label>{t('articles.creation.content.texts.fields.title2')}</Form.Label>
                                                    <Form.Control type="text"
                                                        value={richTexts?.[lang.language.iso]?.titleSecondPartByLanguage || ''}
                                                        onChange={e => {
                                                            updateRichText('titleSecondPartByLanguage', e.target.value, lang.language.iso)
                                                        }} />
                                                </FormGroup>
                                            </Col>

                                        </Row>
                                        <Row>
                                            <Col md="4" className="mt-3">
                                                <FormGroup>
                                                    <Form.Label>{t('articles.creation.content.texts.fields.slug')}</Form.Label>
                                                    <Form.Control type="text"
                                                        value={richTexts?.[lang.language.iso]?.slug || ''}
                                                        onChange={e => {
                                                            updateRichText('slug', e.target.value, lang.language.iso)
                                                        }}
                                                        disabled={!richTexts?.[lang.language.iso]?.isSlugEmpty && detail} />
                                                </FormGroup>
                                            </Col>
                                            <Col md="4" className="mt-3">
                                                <FormGroup>
                                                    <Form.Label>{t('articles.creation.content.texts.fields.breadcrumbs')}</Form.Label>
                                                    <Form.Control type="text"
                                                        value={richTexts?.[lang.language.iso]?.breadcrumbsTitleByLanguage || ''}
                                                        onChange={e => {
                                                            updateRichText('breadcrumbsTitleByLanguage', e.target.value, lang.language.iso)
                                                        }} />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md="12" className="mt-3">
                                                <FormGroup>
                                                    <Form.Label>{t('articles.creation.content.texts.fields.description')}</Form.Label>
                                                    <Form.Control as='textarea' rows={3}
                                                        value={richTexts?.[lang.language.iso]?.articleShortDescriptionByLanguage || ''}
                                                        onChange={e => {
                                                            updateRichText('articleShortDescriptionByLanguage', e.target.value, lang.language.iso)
                                                        }} />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row className="mt-3">
                                            <p className="mb-2">{`${t('articles.creation.content.texts.fields.block')} 1`}</p>
                                            <Col md="12">
                                                <RichTextArea texts={richTexts} editorRef={article}
                                                    iso={lang.language.iso} languageid={lang.language.id}
                                                    block={'textBlock1ByLanguage'} height={220} detail={true}
                                                    onChange={updateRichText} className="mb-3" />
                                            </Col>
                                        </Row>
                                        <hr />
                                        <Row className="mt-3">
                                            <p className="mb-2">{`${t('articles.creation.content.texts.fields.quote')} 1`}</p>
                                            <Col md="12">
                                                <RichTextArea texts={richTexts} editorRef={article}
                                                    iso={lang.language.iso} languageid={lang.language.id}
                                                    block={'quote1'} detail={true} height={140}
                                                    onChange={updateRichText} className="mb-3" />
                                            </Col>
                                        </Row>
                                        {articleType?.value === 1 &&
                                            <Row className="mt-3">
                                                <p className="mb-2">{`${t('articles.creation.content.texts.fields.block')} 2`}</p>
                                                <Col md="12">
                                                    <RichTextArea texts={richTexts} editorRef={article}
                                                        iso={lang.language.iso} languageid={lang.language.id}
                                                        block={'textBlock2ByLanguage'} height={220} detail={true}
                                                        onChange={updateRichText} className="mb-3" />
                                                </Col>
                                            </Row>
                                        }
                                        {articleType?.value === 1 &&
                                            <Row className="mt-3">
                                                <p className="mb-2">{`${t('articles.creation.content.texts.fields.quote')} 2`}</p>
                                                <Col md="12">
                                                    <RichTextArea texts={richTexts} editorRef={article}
                                                        iso={lang.language.iso} languageid={lang.language.id}
                                                        block={'quote2'} detail={true} height={140}
                                                        onChange={updateRichText} className="mb-3" />
                                                </Col>
                                            </Row>}
                                        <hr />
                                        {articleType?.value === 1 &&
                                            <Row className="mt-3">
                                                <p className="mb-2">{`${t('articles.creation.content.texts.fields.block')} 3`}</p>
                                                <Col md="12">
                                                    <RichTextArea texts={richTexts} editorRef={article}
                                                        iso={lang.language.iso} languageid={lang.language.id}
                                                        block={'textBlock3ByLanguage'} height={220} detail={true}
                                                        onChange={updateRichText} className="mb-3" />
                                                </Col>
                                            </Row>}
                                        {articleType?.value === 1 &&
                                            <Row className="mt-3">
                                                <p className="mb-2">{`${t('articles.creation.content.texts.fields.quote')} 3`}</p>
                                                <Col md="12">
                                                    <RichTextArea texts={richTexts} editorRef={article}
                                                        iso={lang.language.iso} languageid={lang.language.id}
                                                        block={'quote3'} detail={true} height={140}
                                                        onChange={updateRichText} className="mb-3" />
                                                </Col>
                                            </Row>}
                                        <Row className="mt-3">
                                            <p className="mb-2">{`${t('articles.creation.content.texts.fields.block')} ${articleType.value === 2 ? 2 : 4}`}</p>
                                            <Col md="12">
                                                <RichTextArea texts={richTexts} editorRef={article}
                                                    iso={lang.language.iso} languageid={lang.language.id}
                                                    block={'textBlock4ByLanguage'} height={220} detail={true}
                                                    onChange={updateRichText} className="mb-3" />
                                            </Col>
                                        </Row>
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>
            )}
            <Accordion key={2} className="mb-3">
                <Accordion.Item eventKey="1">
                    <Accordion.Header><h6
                        className={"m-0 fw-bold text-primary"}>{t('articles.creation.images.title')}</h6>
                    </Accordion.Header>
                    <Accordion.Body>
                        <ArticleImagesForm articleImages={images} language='es' totalImages={totalImages}
                            articleListImage={listImage} onChange={updateImages} />
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>
            <Accordion key={3} className="mb-3">
                <Accordion.Item eventKey="2">
                    <Accordion.Header><h6
                        className={"m-0 fw-bold text-primary"}>{t('articles.creation.stamps.title')}</h6>
                    </Accordion.Header>
                    <Accordion.Body>
                        <Row>
                            <Col md={4}>
                                <FormGroup className="mb-3">
                                    <Form.Label>{t('articles.creation.stamps.stamp')}</Form.Label>
                                    <Select options={stamps}
                                        classNamePrefix="select"
                                        placeholder={t('articles.creation.stamps.default')}
                                        getOptionLabel={(option) => option.title['es'].value}
                                        getOptionValue={(option) => option.id}
                                        value={stamp || ''}
                                        onChange={(data) => {
                                            setStamp(data)
                                        }}
                                    />
                                </FormGroup>
                            </Col>
                        </Row>
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>

            {detail ? 
                <Button disabled={ permissions.articles?.update === false ? true : formInvalid }
                    onClick={handleOnSubmit}>{ t('articles.buttons.update') }</Button>
            : 
                <Button disabled={ permissions.articles?.create === false ? true : formInvalid } 
                    onClick={handleOnSubmit}>{ t('articles.buttons.create') }</Button>
            }

        </>
    );
}

export default ArticleForm;