import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Accordion, Button, Col, Form, FormGroup, Row, Image } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import BodyService from "../../services/body.service";

const HomeHeadlineForm = ({
  onSubmit,
  homeHeadline,
  languages,
  permissions
}) => {

  const { t } = useTranslation();

  const [configLangParams, setConfigLangParams] = useState();

  const [submitButtonDisabled, setSubmitButtonDisabled] = useState(true);

  const { handleSubmit } = useForm({
    mode: "all",
  });

  useEffect(() => {
    if (languages && !homeHeadline) {
        let configLangObj = {};

        if (languages) {
            languages.forEach(lang => {
                configLangObj[lang.language.iso] = {
                  text: {
                    language: lang.language,
                    value: ""
                  },
                  imgData: {
                    language: lang.language,
                    value: null
                  } 
                }
            });
        }

        setConfigLangParams(configLangObj);
    }

    if (languages && homeHeadline) {
        let configLangObj = {}
        languages.forEach(lang => {
            configLangObj[lang.language.iso] = {
                text: {
                    language: lang.language,
                    value: homeHeadline?.text?.find(nameObj => nameObj.language.iso === lang.language.iso).value
                },
                imgData: {
                    language: lang.language,
                    value: {
                      imageBase64: homeHeadline?.imgData?.find(nameObj => nameObj.language.iso === lang.language.iso).imageBase64,
                      imageContentType: homeHeadline?.imgData?.find(nameObj => nameObj.language.iso === lang.language.iso).imageContentType,
                      imageUrl: homeHeadline?.imgData?.find(nameObj => nameObj.language.iso === lang.language.iso).imageUrl
                    }
                } 
            }
        });
        setConfigLangParams(configLangObj)
    }
  }, [languages, homeHeadline])

  useEffect(() => {
    checkSubmitDisabled();
  }, [configLangParams])

  const langInputUpdate = (data, iso, target) => {
    setConfigLangParams({
        ...configLangParams, [iso]: {
            ...configLangParams[iso], [target]: {
                ...configLangParams[iso][target], "value": data
            }
        }
    });
  } 

  const getBase64Src = (image) => {
    if (!image?.imageBase64 || !image?.imageContentType) {
      return false;
  }
  return `data:${image.imageContentType};base64,${image.imageBase64}`;
  }

  const setImageToBase64 = async (file, ref) => {
    if (file) {
        let base64 = await BodyService.getBase64(file);
        return base64;
    }
  }

  const setImageData = async (data, iso, target) => {
    if (data) {
        let base = await setImageToBase64(data)
        let obj = {
            imageBase64: base.split(',')[1],
            imageName: data.name,
            imageContentType: data.type,
            imageUrl: null
        }
        langInputUpdate(obj, iso, target);
    }
  }

  const onSubmitForm = async (data) => {
    try {

      let sendData = {
        ...data,
        id: homeHeadline.id,
        text: [],
        imgData: []
    }

    Object.values(configLangParams).forEach((data) => {
      sendData.text.push(data.text)
      sendData.imgData.push({
        language: data.imgData.language,
        imageBase64: data.imgData.value.imageBase64,
        imageContentType: data.imgData.value.imageContentType,
        imageName: data.imgData.value.imageName,
        imageUrl: data.imgData.value.imageUrl
      })
    })

      const createResponse = await onSubmit(sendData);
    } catch (error) {
      console.error(error);
    } finally {
      setTimeout(function () {
        window.scrollTo(0, 0);
      }, 0);
    }
  };

  const checkSubmitDisabled = () => {
    let blocked = false;
  
    if (languages) {
      languages.forEach((lang) => {
        const langKey = lang.language.iso;
        const data = configLangParams[langKey];
        if (
          !data ||
          (!data.imgData.value?.imageUrl && (!data.imgData.value?.imageBase64 || !data.imgData.value?.imageContentType)) ||
          data.text.value === null ||
          data.text.value.trim() === ""
        ) {
          blocked = true;
        }
      });
    }
  
    setSubmitButtonDisabled(blocked);
  };

  return (
    <Form onSubmit={handleSubmit(onSubmitForm)}>
      <Accordion className="mb-3">
        {languages && languages?.map((lang, i) => (
            <Accordion key={i} className="mb-3">
                <Accordion.Item eventKey="">
                    <Accordion.Header>
                        <h6 className={"m-0 fw-bold text-primary"}>{lang?.language?.name}{<span className="ms-1 text-danger fw-bold">*</span>}</h6>
                    </Accordion.Header>
                    <Accordion.Body>
                        <Row className="mb-3">
                            <Col md={3}>
                                <FormGroup>
                                    <Form.Label>{t('homeHeadline.form.imagen')}</Form.Label>
                                    <Form.Control type="file" accept="image/png, image/jpeg" onChange={(e) => setImageData(e.target.files[0], lang.language.iso, 'imgData')} />
                                </FormGroup>
                            </Col>
                            <Col md={6}>
                                <FormGroup>
                                    <Form.Label>{t('homeHeadline.form.text')}</Form.Label>
                                    <Form.Control as="textarea" rows={3} value={configLangParams?.[lang.language.iso].text.value || ''} onChange={e => langInputUpdate(e.target.value, lang.language.iso, 'text')} />
                                </FormGroup>
                            </Col>
                        </Row>
                        {(configLangParams?.[lang.language.iso]?.imgData?.value) &&
                            <Row className="my-3">
                                <>
                                    <Col className="col-8 col-md-6 col-lg-2">
                                        <Image fluid thumbnail src={getBase64Src(configLangParams?.[lang.language.iso]?.imgData?.value) || configLangParams?.[lang.language.iso]?.imgData?.value.imageUrl} />
                                    </Col>
                                </>
                            </Row>
                        }
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>
        ))}
      </Accordion>

      <Button type="submit" className={"mt-3"} disabled={ (!permissions.homeHeadlines?.update && !permissions.homeHeadlines?.create && !permissions.homeHeadlines?.delete) ? true : submitButtonDisabled }>
        {t("highlights.form.submit")}
      </Button>
    </Form>
  );
};

export default HomeHeadlineForm;
