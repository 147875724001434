import { Card, Alert } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useCallback, useEffect, useState } from "react";
import CategoryService from "../../../services/category.service";
import CategoryForm from "../../../components/forms/CategoryForm";
import UserPermissionService from "../../../services/userPermission.service";

const CategoryDetails = () => {

    const params = useParams();

    const navigate = useNavigate();

    const { t } = useTranslation();

    const [category, setCategory] = useState();
    const [title, setTitle] = useState("");
    const [permissions, setPermissions] = useState({});

    const [success, setSuccess] = useState({
        update: false,
        email: false
    });

    const [errors, setErrors] = useState({
        update: false,
        wordLength: false
    });

    const getCategory = useCallback(async (id) => {

        try {
            const data = await CategoryService.getById(id);
            setCategory(data);
            setTitle(data.name[0].value);
        } catch (e) {
            navigate("/category");
        }
    }, [navigate]);
    
    const updateCategory = async (data) => {
        setErrors({...errors, update: false, wordLength: false});
        setSuccess({...success, update: false});

        try {
            await CategoryService.update(params.id, data);
            getCategory(params.id);
            setSuccess({...success, update: true});
        } catch (e) {
            if (e?.response?.data?.errorCode) {
                switch (e.response.data.errorCode) {
                    case 1700:
                        setErrors({...errors, wordLength: true});
                        break;
                    default:
                        setErrors({...errors, create: true});
                }
            }
        }
    };

    const getPermissions = async () => {
        try {
            const data = await UserPermissionService.getPermissionsByConnected();
            setPermissions(data);
        } catch (error) {
            console.error(error);
        }
    }


    useEffect(() => {
        const loadData = async () => {
            await getPermissions();
        }

        loadData();
    }, []);

    useEffect(() => {
        getCategory(params.id);
    }, [getCategory, params.id]);


    return (
        <>
            <div className={"d-flex justify-content-between my-3"}>
                <Link to="/category" className={"previous round rounded-circle text-decoration-none"}
                    title={t('general.back')}>
                    <FontAwesomeIcon icon={"chevron-left"} className={"mt-2 me-2"} />
                    <span>{t('categories.title')}</span>
                </Link>
            </div>
            <Alert variant={"success"} show={success.update} dismissible onClose={() => setSuccess({...success, update: false})}>
                {t('categories.success.update')}
            </Alert>
            <Alert variant={"danger"} show={errors.update} dismissible onClose={() => setErrors({...errors, update: false})}>
                {t('categories.error.update')}
            </Alert>
            <Alert variant={"danger"} show={errors.wordLength} dismissible onClose={() => setErrors({...errors, wordLength: false})}>
                {t('categories.error.wordLength')}
            </Alert>
            <Card>
                <Card.Header className={"py-3"}>
                    <h1 className={"h3 mb-0 text-gray-800 card-title text-capitalize"}>{title}</h1>
                </Card.Header>
                <Card.Body>
                    <CategoryForm onSubmit={updateCategory} category={category} translations={category?.name} slugs={category?.slug} baseTitleTranslations={category?.title} baseDescriptionTranslations={category?.description} permissions={permissions} details />
                </Card.Body>
            </Card>
        </>
    );
}

export default CategoryDetails;
