import { Accordion, Alert, Button, Card, Col, Form, FormGroup, Row, Table } from "react-bootstrap";
import AuthorForm from "../../components/forms/AuthorForm";
import { useState } from "react";
import { useTranslation } from 'react-i18next';
import BodyService from "../../services/body.service";
import { useEffect } from "react";
import AuthorService from "../../services/author.service";
import APP_CONF from "../../scripts/constants";
import UseAlertConfirm from "../../hooks/UseAlertConfirm";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PaginationComponent from "../../components/PaginationComponent";
import TableHeaderSortComponent from "../../components/TableHeaderSortComponent";
import UserPermissionService from "../../services/userPermission.service";

const Author = () => {

    const { t } = useTranslation();

    const { showModal } = UseAlertConfirm();

    const [sortColumn, setSortColumn] = useState({
        sortByColumn: null,
        orderBy: null
    });

    const [pagination, setPagination] = useState({
        currentPage: 1,
        totalItems: 0,
        itemsPerPage: APP_CONF.ITEMS_PER_PAGE,
        numPages: 1
    });

    const { register, handleSubmit } = useForm({
        mode: "all",
    });

    const [filter, setFilter] = useState({
        active: true,
        search: ""
    })

    const filterSearch = (data) => {
        let obj = data;
        if (obj.search === "") {
            obj = {
                active: data.active
            }
        }
        setFilter(obj);
    }

    const [success, setSuccess] = useState({
        create: false,
        delete: false,
    });

    const [errors, setErrors] = useState({
        create: false,
        delete: false,
    });

    const [translations, setTranslations] = useState([]);

    const [authors, setAuthors] = useState([]);

    const [permissions, setPermissions] = useState({});

    const getEmptyTranslations = async () => {
        const data = await BodyService.getEmptyTranslations();
        setTranslations(data);
    }

    const getPermissions = async () => {
        try {
            const data = await UserPermissionService.getPermissionsByConnected();
            setPermissions(data);
        } catch (error) {
            console.error(error);
        }
    }

    const getAuthors = async () => {
        try {

            const sortColumnsByLanguage = ['name', 'type', 'active']

            if (sortColumnsByLanguage.includes(sortColumn.sortByColumn) &&
                (sortColumn.orderBy !== null || sortColumn.orderBy !== undefined)) {
            }

            const data = await AuthorService.getAll(pagination, sortColumn, filter);
            setAuthors(data.content);
            setPagination({
                ...pagination,
                totalItems: data.totalElements,
                numPages: data.totalPages
            });
        } catch ({ response }) {
            console.error(response);
        }
    }

    const createAuthor = async (data) => {
        setErrors({ ...errors, create: false });
        setSuccess({ ...success, create: false });
        try {
            await AuthorService.create(data);
            getEmptyTranslations();
            setSuccess({ ...success, create: true });
            getAuthors();
        } catch (error) {
            console.error(error.response);
            setErrors({ ...errors, create: true });
        }
    }

    const deleteAuthor = async (id) => {
        showModal({
            title: t("general.delete.title"),
            body: t("general.delete.message"),
            confirmButtonVariant: "danger",
            onConfirm: async () => {
                setErrors({ ...errors, create: false });
                setSuccess({ ...success, create: false });
                try {
                    await AuthorService.remove(id);
                    setSuccess({ ...success, delete: true });
                    getAuthors();
                } catch (e) {
                    console.error(e.response);
                    setErrors({ ...errors, delete: true });
                }
            }
        });
    }
    useEffect(() => {
        const loadData = async () => {
            await getPermissions();
            await getEmptyTranslations();
        }

        loadData();
    }, []);

    useEffect(() => {
        getAuthors();
    }, [pagination.currentPage, filter, sortColumn])

    return (
        <>
            <div className={"d-sm-flex align-items-center justify-content-between mb-4"}>
                <h1 className={"h3 mb-0 text-gray-800"}>{t('author.title')}</h1>
            </div>
            <Alert variant={"danger"} show={errors.create} dismissible onClose={() => setErrors({ ...errors, create: false })}>
                {t('author.error.create')}
            </Alert>

            <Alert variant={"success"} show={success.create} dismissible onClose={() => setSuccess({ ...success, create: false })}>
                {t('author.success.create')}
            </Alert>

            <Alert variant={"success"} show={success.delete} dismissible onClose={() => setSuccess({ ...success, delete: false })}>
                {t('author.success.delete')}
            </Alert>

            <Alert variant={"danger"} show={errors.delete} dismissible onClose={() => setErrors({ ...errors, delete: false })}>
                {t('author.error.delete')}
            </Alert>
            {permissions.authors?.create ? 
                <Card className={"shadow mb-4"}>
                    <Accordion>
                        <Accordion.Item eventKey="0">
                            <Accordion.Header><h6 className={"m-0 fw-bold text-primary"}>{t('author.form.title')}</h6>
                            </Accordion.Header>
                            <Accordion.Body>
                                <AuthorForm onSubmit={createAuthor} translations={translations} permissions={permissions} />
                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>
                </Card>
            : null }
            <hr />
            <Card className={"shadow mb-4"}>
                <Card.Header className={"py-3"}>
                    <h6 className={"m-0 fw-bold text-primary"}>{t('author.table.title')}</h6>
                </Card.Header>
                <Card.Body>
                    <Form onSubmit={handleSubmit(filterSearch)}>
                        <Row>
                            <Col md="3">
                                <FormGroup>
                                    <Form.Label>{t('author.filter.active.title')}</Form.Label>
                                    <Form.Select {...register('active')} aria-label="Default select example">
                                        <option value="">{t('author.filter.active.value1')}</option>
                                        <option value={true}>{t('author.filter.active.value2')}</option>
                                        <option value={false}>{t('author.filter.active.value3')}</option>
                                    </Form.Select>
                                </FormGroup>
                            </Col>
                            <Col lg="3" className={"mb-3"}>
                                <FormGroup>
                                    <Form.Label>{t('author.filter.search')}</Form.Label>
                                    <Form.Control type="text" {...register("search")} />
                                </FormGroup>
                            </Col>
                        </Row>
                        <Button type="submit" className={"mt-3"} disabled={!permissions.authors?.read}>{t('author.filter.button')}</Button>
                    </Form>
                    <hr />
                    <Table responsive>
                        <thead>
                            <tr>
                                <TableHeaderSortComponent scope={"col"} text={t('author.table.fullName')} column={"name"} sortColumn={sortColumn} setSortColumn={setSortColumn} />
                                <TableHeaderSortComponent scope={"col"} text={t('author.table.type')} column={"type"} sortColumn={sortColumn} setSortColumn={setSortColumn} />
                                <TableHeaderSortComponent scope={"col"} text={t('author.table.active')} column={"active"} sortColumn={sortColumn} setSortColumn={setSortColumn} />
                            </tr>
                        </thead>
                        <tbody>
                            {authors ? authors.map((author, index) => (
                                <tr key={index}>
                                    <td>
                                        {author?.fullName}
                                    </td>
                                    <td>{author?.type === 1 ? "Interno" : "Externo"}</td>
                                    <td>{author?.active ? <FontAwesomeIcon className="text-primary ms-4" icon="eye" /> : <FontAwesomeIcon className="ms-4" icon="eye-slash" />}</td>
                                    <td className={"text-center"}>
                                        <Button variant="link" size="sm" className="ms-2" to={`/author/${author.id}`} as={Link} disabled={!permissions.authors?.read}>
                                            <FontAwesomeIcon icon="edit" />
                                        </Button>
                                        <Button variant="link" size="sm" className="text-danger" onClick={() => deleteAuthor(author.id)} disabled={!permissions.authors?.delete}>
                                            <FontAwesomeIcon icon="trash" />
                                        </Button>
                                    </td>
                                </tr>
                            )) :
                                <tr>
                                    <td colSpan={4}>{t('general.empty')}</td>
                                </tr>
                            }
                        </tbody>
                    </Table>
                    <PaginationComponent pagination={pagination}
                        setPagination={setPagination}
                        alwaysShown={authors.length > 0} />
                </Card.Body>
            </Card>
        </>
    )
}

export default Author;