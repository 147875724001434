import api from './api';

const getAll = (pagination, sortColumn, language, filter) => api.get('/interest-site', {pageNum: pagination.currentPage - 1, 
    pageLength: pagination.itemsPerPage, 
    active: filter.active, 
    search: filter.search,
    language: language,
    sortBy: sortColumn.sortByColumn,
    orderBy: sortColumn.orderBy
}).then(response => {
    return response.data;
});

const getById = (id) => api.get(`/interest-site/${id}`).then(response => {
    return response.data
});

const create = (data) => api.post('/interest-site', data).then(response => {
    return response.data
});

const update = (id, data) => api.put(`/interest-site/${id}`, data).then(response => {
    return response.data
});

const remove = (id) => api.delete(`/interest-site/${id}`).then(response => {
    return response.data
});

const downloadExcel = (language, filter) => api.getFile('/interest-site/download-excel', {
    search: filter.search,
    language: language,
    active: filter.active
}).then(response => {
    var downloadLink = window.document.createElement('a');
    downloadLink.href = window.URL.createObjectURL(new Blob([response.data], { 
    type: 'application/octet-stream' }));
    downloadLink.download = 'InterestSites.xlsx';
    
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
});

const SiteInterestService = {
    create,
    getAll,
    getById,
    remove,
    update,
    downloadExcel
};

export default SiteInterestService;