import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect, useState } from 'react'
import { Accordion, Alert, Button, Card, Col, Form, FormGroup, Row, Table } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import APP_CONF from "../../scripts/constants";
import BodyService from '../../services/body.service';
import TagService from '../../services/tag.service';
import CategoryService from '../../services/category.service';
import ArticleService from '../../services/article.service';
import MiniGuideService from '../../services/miniGuide.service';
import PaginationComponent from '../../components/PaginationComponent';
import UseAlertConfirm from '../../hooks/UseAlertConfirm';
import UseFormatDate from "../../hooks/UseFormatDate";
import MiniGuideForm from '../../components/forms/MiniGuideForm';
import TableHeaderSortComponent from '../../components/TableHeaderSortComponent';
import ProvinceService from '../../services/province.service';
import UserPermissionService from "../../services/userPermission.service";

const MiniGuide = () => {
    const { t } = useTranslation();

    const [languages, setLanguages] = useState();
    const [tags, setTags] = useState();
    const [categories, setCategories] = useState();
    const [articles, setArticles] = useState();
    const [miniGuides, setMiniGuides] = useState();
    const [provinces, setProvinces] = useState();
    const [permissions, setPermissions] = useState({});

    const { showModal } = UseAlertConfirm();
    const { register, handleSubmit } = useForm({
        mode: "all",
    });

    const [sortColumn, setSortColumn] = useState({
        sortByColumn: null,
        orderBy: null
    });

    const [filter, setFilter] = useState({
        active: true,
        search: ""
    })

    const [pagination, setPagination] = useState({
        currentPage: 1,
        totalItems: 0,
        itemsPerPage: APP_CONF.ITEMS_PER_PAGE,
        numPages: 1
    });

    const [success, setSuccess] = useState({
        create: false,
        delete: false,
    });
    const [errors, setErrors] = useState({
        create: false,
        delete: false,
    });


    const getEmptyTranslations = async () => {
        const data = await BodyService.getEmptyTranslations();
        setLanguages(data);
    }

    const getPermissions = async () => {
        try {
            const data = await UserPermissionService.getPermissionsByConnected();
            setPermissions(data);
        } catch (error) {
            console.error(error);
        }
    }

    const getMiniGuides = async () => {
        try {

            let language;
            if ((sortColumn.sortByColumn === 'name') &&
                (sortColumn.orderBy !== null || sortColumn.orderBy !== undefined)) {
                language = "es";
            }

            const data = await MiniGuideService.getAll(pagination, filter, language, sortColumn)
            setPagination({
                ...pagination,
                totalItems: data.totalElements,
                numPages: data.totalPages
            });
            setMiniGuides(data.content)
        } catch (error) {
            console.error(error);
        }
    }

    const getTags = async () => {
        try {
            const data = await TagService.getAllList();
            setTags(data);
        } catch ({ response }) {
            console.error(response);
        }
    }

    const getCategories = async () => {
        try {
            const data = await CategoryService.selectAll()
            setCategories(data)
        } catch ({ error }) {
            console.error(error);
        }
    }

    const getArticles = async () => {
        try {
            const data = await ArticleService.getLazy()
            setArticles(data)
        } catch (error) {
            console.error(error);
        }
    }

    const getProvinces = async () => {
        try {
            const data = await ProvinceService.getList();
            setProvinces(data);
        } catch ({ response }) {
            console.error(response);
        }
    }

    useEffect(() => {
        const loadData = async () => {
            await getPermissions();
            await getEmptyTranslations();
            await getTags();
            await getCategories();
            await getArticles();
            await getMiniGuides();
            await getProvinces();

        }

        loadData();
    }, [])

    const createMiniGuide = async (data) => {
        setErrors({ ...errors, create: false });
        setSuccess({ ...success, create: false });

        try {
            await MiniGuideService.create(data);
            setSuccess({ ...success, create: true });
            getMiniGuides();
        } catch (e) {
            console.error(e.response);
            setErrors({ ...errors, create: true });
            return e.response
        }
    }

    const removeMiniGuide = async (id) => {

        showModal({
            title: t("general.delete.title"),
            body: t("general.delete.message"),
            confirmButtonVariant: "danger",
            onConfirm: async () => {
                setErrors({ ...errors, create: false });
                setSuccess({ ...success, create: false });
                try {
                    await MiniGuideService.remove(id);
                    setSuccess({ ...success, delete: true });
                    getMiniGuides();
                } catch (e) {
                    console.error(e.response);
                    setErrors({ ...errors, delete: true });
                }
            }
        });

    }


    const filterSearch = (data) => {
        let obj = data;
        if (obj.search === "") {
            obj = {
                active: data.active
            }
        }
        setFilter(obj);
    }

    useEffect(() => {
        if (miniGuides) {
            getMiniGuides();
        }
    }, [pagination.currentPage, filter, sortColumn]);

    return (
        <>
            <div className={"d-sm-flex align-items-center justify-content-between mb-4"}>
                <h1 className={"h3 mb-0 text-gray-800"}>{t('miniGuide.title')}</h1>
            </div>

            <Alert variant={"success"} show={success.create} dismissible onClose={() => setSuccess({ ...success, create: false })}>
                {t('miniGuide.success.create')}
            </Alert>

            <Alert variant={"danger"} show={errors.create} dismissible onClose={() => setErrors({ ...errors, create: false })}>
                {t('miniGuide.error.create')}
            </Alert>
            {permissions.miniGuides?.create ? 
            <Card className={"shadow mb-4"}>
                <Accordion>
                    <Accordion.Item eventKey="0">
                        <Accordion.Header><h6 className={"m-0 fw-bold text-primary"}>{t('miniGuide.form.title')}</h6>
                        </Accordion.Header>
                        <Accordion.Body>
                            <MiniGuideForm onSubmit={createMiniGuide} languages={languages} tags={tags} categories={categories} articles={articles} provinces={provinces} permissions={permissions}/>
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>
            </Card>
            
            : null }

            <Card className={"shadow mb-4"}>
                <Card.Header className={"py-3"}>
                    <h6 className={"m-0 fw-bold text-primary"}>{t('miniGuide.table.title')}</h6>
                </Card.Header>
                <Card.Body>
                    <Form onSubmit={handleSubmit(filterSearch)}>
                        <Row>
                            <Col lg="2" className={"mb-3"}>
                                <FormGroup>
                                    <Form.Label>{t('categories.filter.active.title')}</Form.Label>
                                    <Form.Select {...register('active')} defaultValue={filter.active} aria-label="Default select example">
                                        <option value="">{t('categories.filter.active.value1')}</option>
                                        <option value={true}>{t('categories.filter.active.value2')}</option>
                                        <option value={false}>{t('categories.filter.active.value3')}</option>
                                    </Form.Select>
                                </FormGroup>
                            </Col>
                            <Col lg="3" className={"mb-3"}>
                                <FormGroup>
                                    <Form.Label>{t('users.filter.search')}</Form.Label>
                                    <Form.Control type="text" {...register("search")} />
                                </FormGroup>
                            </Col>
                        </Row>
                        <Button type="submit" className={"mt-3"} disabled={!permissions.miniGuides?.read}>{t('categories.filter.button')}</Button>
                    </Form>
                    <hr />
                    <Table responsive>
                        <thead>
                            <tr>
                                <TableHeaderSortComponent scope={"col"} text={t('miniGuide.table.name')} column={"title"} sortColumn={sortColumn} setSortColumn={setSortColumn} />
                                <TableHeaderSortComponent scope={"col"} text={t('miniGuide.table.category')} column={"category"} sortColumn={sortColumn} setSortColumn={setSortColumn} />
                                <TableHeaderSortComponent scope={"col"} text={t('miniGuide.table.format')} column={"format"} sortColumn={sortColumn} setSortColumn={setSortColumn} />
                                <TableHeaderSortComponent scope={"col"} text={t('miniGuide.table.creationDate')} column={"creationDate"} sortColumn={sortColumn} setSortColumn={setSortColumn} />
                                <TableHeaderSortComponent scope={"col"} text={t('miniGuide.table.active')} column={"active"} sortColumn={sortColumn} setSortColumn={setSortColumn} />
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {miniGuides ? miniGuides.map((guide, index) => (
                                <tr key={index}>
                                    <td>{guide?.title?.['es']?.value || guide?.title?.['en']?.value || guide?.title?.['de']?.value}</td>
                                    <td>{guide?.category?.['es']?.value}</td>
                                    <td>{guide?.format === 1 ? "Ranking" : "Listado"}</td>
                                    <td>{UseFormatDate(guide?.creationDate)}</td>
                                    <td>{guide?.active ? <FontAwesomeIcon className="ms-4 text-primary" icon="eye" /> : <FontAwesomeIcon className="ms-4" icon="eye-slash" />}</td>
                                    <td className={"text-center"}>
                                        <Button variant="link" size="sm" className="ms-2" to={`/mini-guide/${guide?.id}`} as={Link} disabled={!permissions.miniGuides?.read}>
                                            <FontAwesomeIcon icon="edit" />
                                        </Button>
                                        <Button variant="link" size="sm" className="text-danger" onClick={() => { removeMiniGuide(guide?.id) }} disabled={!permissions.miniGuides?.delete}>
                                            <FontAwesomeIcon icon="trash" />
                                        </Button>
                                    </td>
                                </tr>
                            )) :
                                <tr>
                                    <td colSpan={4}>{t('general.empty')}</td>
                                </tr>
                            }
                        </tbody>
                    </Table>
                    <PaginationComponent pagination={pagination}
                        setPagination={setPagination}
                        alwaysShown={miniGuides?.length > 0} />
                </Card.Body>
            </Card>
        </>

    )
}

export default MiniGuide