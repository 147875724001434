import api from './api';

const getBase64 = (file) => {
    return new Promise(function (resolve) {
        var reader = new FileReader();
        reader.onloadend = function () {
            resolve(reader.result)
        }
        reader.readAsDataURL(file);
    })
}

const getEmptyTranslations = () => api.get("/translations/empty").then(response => response.data);

function slugifyStr(text) {
    return text
        .toString()                   // Cast to string (optional)
        .normalize('NFKD')            // The normalize() using NFKD method returns the Unicode Normalization Form of a given string.
        .toLowerCase()                // Convert the string to lowercase letters
        .trim()                       // Remove whitespace from both sides of a string (optional)
        .replace(/\s+/g, '-')         // Replace spaces with -
        .replace(/_+/g, '-')          // Replace _ with -
        .replace(/[^\w-]+/g, '')     // Remove all non-word chars
        .replace(/--+/g, '-');      // Replace multiple - with single -
}


const BodyService = {
    getBase64,
    getEmptyTranslations,
    slugifyStr
};

export default BodyService;
