import {Accordion, Button, Card, Table, Alert, FormGroup, Form, Col, Row} from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import UserService from "../../services/user.service";
import UserPermissionService from "../../services/userPermission.service";

import APP_CONF from "../../scripts/constants";
import UserForm from "../../components/forms/user/UserForm";
import PaginationComponent from "../../components/PaginationComponent";
import {useForm} from "react-hook-form";
import TableHeaderSortComponent from "../../components/TableHeaderSortComponent";
import UseFormatDate from "../../hooks/UseFormatDate";

const User = () => {
    const { t } = useTranslation();

    const { register, handleSubmit, reset } = useForm({
        mode: "all",
    });

    const [pagination, setPagination] = useState({
        currentPage: 1,
        totalItems: 0,
        itemsPerPage: APP_CONF.ITEMS_PER_PAGE,
        numPages: 1
    });

    const [filter, setFilter] = useState({
        active: ""
    });

    const [success, setSuccess] = useState({
        create: false,
        delete: false,
    });
    const [errors, setErrors] = useState({
        create: false,
        delete: false,
    });

    const [users, setUsers] = useState([]);

    const [permissions, setPermissions] = useState({});

    const [sortColumn, setSortColumn] = useState({
        sortByColumn: null,
        orderBy: null
    });

    const getUsers = async () => {
        try {
            const data = await UserService.getAll(pagination, sortColumn, filter)
            setUsers(data.content);
            setPagination({
                ...pagination,
                totalItems: data.totalElements,
                numPages: data.totalPages
            });
        } catch ({ response }) {
            if (response?.data?.errorCode) {
                switch (response.data.errorCode) {
                    case 13002:
                        setErrors({...errors, email: true});
                        break;
                    default:
                        setErrors({...errors, create: true});
                }
            }
        }
    }

    const filterSearch = (data) => {
        let obj = data;
        if (obj.search === "" ) {
            obj = {
                adminUser: data.adminUser,
                active: data.active
            }
        }
        setFilter(obj);
    }

    const createUser = async (data) => {
        setErrors({ ...errors, create: false });
        setSuccess({ ...success, create: false });
        try {
            await UserService.create(data);
            setSuccess({ ...success, create: true });
            getUsers();
        } catch (e) {
            console.error(e.response);
            setErrors({ ...errors, create: true });
        }
    };

    const downloadFile = () => {
        try {
            UserService.downloadExcel(filter);
        } catch ({ response }) {
            console.error(response);
        }
    }

    const cleanForm = () => {
        reset()
    }

    const getPermissions = async () => {
        try {
            const data = await UserPermissionService.getPermissionsByConnected();
            setPermissions(data);
        } catch (error) {
            console.error(error);
        }
    }

    useEffect(() => {
        const loadData = async () => {
            await getPermissions();

        }

        loadData();
    }, []);

    useEffect(() => {
        getUsers();
    }, [pagination.currentPage, filter, sortColumn]);

    return (
        <>
            <div className={"d-sm-flex align-items-center justify-content-between mb-4"}>
                <h1 className={"h3 mb-0 text-gray-800"}>{t('users.title')}</h1>
            </div>

            <Alert variant={"success"} show={success.create} dismissible onClose={() => setSuccess({ ...success, create: false })}>
                {t('users.success.create')}
            </Alert>

            <Alert variant={"danger"} show={errors.create} dismissible onClose={() => setErrors({ ...errors, create: false })}>
                {t('users.error.create')}
            </Alert>

            <Alert variant={"success"} show={success.delete} dismissible onClose={() => setSuccess({ ...success, delete: false })}>
                {t('users.success.delete')}
            </Alert>

            <Alert variant={"danger"} show={errors.delete} dismissible onClose={() => setErrors({ ...errors, delete: false })}>
                {t('users.error.delete')}
            </Alert>
            {permissions.users?.read && permissions.users?.create ? 
            <Card className={"shadow mb-4"}>
                <Accordion>
                    <Accordion.Item eventKey="0">
                        <Accordion.Header><h6 className={"m-0 fw-bold text-primary"}>{t('users.creation.title')}</h6>
                        </Accordion.Header>
                        <Accordion.Body>
                            <UserForm onSubmit={createUser} permissions={permissions}/>
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>
            </Card>
            : null}

            <Card className={"shadow mb-4"}>
                <Card.Header className={"py-3"}>
                    <h6 className={"m-0 fw-bold text-primary"}>{t('users.list.title')}</h6>
                </Card.Header>
                <Card.Body>
                    <Form onSubmit={handleSubmit(filterSearch)}>
                        <Row>
                            <Col lg="2" className={"mb-3"}>
                                <FormGroup>
                                    <Form.Label>{t('users.filter.active.title')}</Form.Label>
                                    <Form.Select {...register('active')}>
                                        <option value="">{t('users.filter.active.value1')}</option>
                                        <option value={true}>{t('users.filter.active.value2')}</option>
                                        <option value={false}>{t('users.filter.active.value3')}</option>
                                    </Form.Select>
                                </FormGroup>
                            </Col>
                            <Col lg="3" className={"mb-3"}>
                                <FormGroup>
                                    <Form.Label>{t('users.filter.search')}</Form.Label>
                                    <Form.Control type="text" {...register("search")} />
                                </FormGroup>
                            </Col>
                            <Col lg="2" className={"mb-3"}>
                                <FormGroup>
                                    <Form.Label>{t('users.filter.admin.title')}</Form.Label>
                                    <Form.Select {...register('adminUser')} >
                                        <option value="">{t('users.filter.admin.value1')}</option>
                                        <option value={true}>{t('users.filter.admin.value2')}</option>
                                        <option value={false}>{t('users.filter.admin.value3')}</option>
                                    </Form.Select>
                                </FormGroup>
                            </Col>
                        </Row>
                        <div className='d-flex flex-row gap-2'>
                            <Button type="submit" disabled={!permissions.users?.read}>{t('users.filter.button')}</Button>
                            <Button variant="primary" onClick={() => downloadFile()} disabled={!permissions.users?.read}>{t("general.download")}</Button>
                            <Button variant={"outline-primary"} type="submit" onClick={cleanForm}>{t('users.cleanButton')}</Button>
                        </div>
                    </Form>
                    <hr />
                    <Table responsive>
                        <thead>
                            <tr>
                                <th scope="col">{t('general.index')}</th>
                                <TableHeaderSortComponent scope={"col"} text={t('users.creation.fields.firstName')} column={"firstName"} sortColumn={sortColumn} setSortColumn={setSortColumn} />
                                <TableHeaderSortComponent scope={"col"} text={t('users.creation.fields.lastName')} column={"lastName"} sortColumn={sortColumn} setSortColumn={setSortColumn} />
                                <TableHeaderSortComponent scope={"col"} text={t('users.creation.fields.email')} column={"email"} sortColumn={sortColumn} setSortColumn={setSortColumn} />
                                <TableHeaderSortComponent scope={"col"} text={t('users.creation.fields.creationDate')} column={"creationDate"} sortColumn={sortColumn} setSortColumn={setSortColumn} />
                                <TableHeaderSortComponent scope={"col"} text={t('users.creation.fields.active')} column={"active"} sortColumn={sortColumn} setSortColumn={setSortColumn} />
                                <TableHeaderSortComponent scope={"col"} text={t('users.creation.fields.admin')} column={"admin"} sortColumn={sortColumn} setSortColumn={setSortColumn} />

                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {users.length > 0 ? users.map((user, index) => (
                                <tr key={index}>
                                    <td>{index + 1}</td>
                                    <td>{user.firstName}</td>
                                    <td>{user.lastName}</td>
                                    <td>{user.email}</td>
                                    <td>{UseFormatDate(user.creationDate)}</td>
                                    <td>{user.active ? 'Si' : 'No'}</td>
                                    <td>{user.admin ? 'Si' : 'No'}</td>
                                    <td className={"text-center"}>
                                        <Button variant="link" size="sm" className={"ms-2"} to={`/user/${user.id}`} as={Link} disabled={!permissions.users?.read}>
                                            <FontAwesomeIcon icon="edit" />
                                        </Button>
                                    </td>
                                </tr>
                            )) :
                                <tr>
                                    <td colSpan={4}>{t('general.empty')}</td>
                                </tr>
                            }
                        </tbody>
                    </Table>
                    <PaginationComponent pagination={pagination}
                        setPagination={setPagination}
                        alwaysShown={users.length > 0} />
                </Card.Body>
            </Card>
        </>
    );
};

export default User;
