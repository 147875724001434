import {Accordion, Button, Card, Table, Alert, FormGroup, Form, Col, Row} from "react-bootstrap";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import DateTimePicker from "react-datetime-picker/dist/entry.nostyle";
import MonitorService from "../../services/monitor.service";

import APP_CONF from "../../scripts/constants";
import PaginationComponent from "../../components/PaginationComponent";
import {useForm} from "react-hook-form";
import TableHeaderSortComponent from "../../components/TableHeaderSortComponent";
import UseFormatDate from "../../hooks/UseFormatDate";
import UserPermissionService from "../../services/userPermission.service";

const Monitor = () => {
    const { t } = useTranslation();

    const { register, handleSubmit, reset } = useForm({
        mode: "all"
    });

    const [pagination, setPagination] = useState({
        currentPage: 1,
        totalItems: 0,
        itemsPerPage: APP_CONF.ITEMS_PER_PAGE,
        numPages: 1
    });

    const generateDate = (days, endDay) => {
        var d = new Date();
        d.setDate(d.getDate() + days);
        if(endDay) {
           d.setHours(23,59,59,0); 
        }
        else {
           d.setHours(0,0,0,0); 
        }

        return d;
    }

    const [formData, setFormData] = useState({
        startDate: generateDate(-7, false),
        endDate: generateDate(1, true),
        systemId: null,
        statusId: null
    });

    const [errors, setErrors] = useState({
        create: false,
        delete: false,
    });

    const [monitors, setMonitors] = useState([]);

    const [monitorStatus, setMonitorStatus] = useState([]);

    const [integrationSystems, setIntegrationSystems] = useState([]);

    const [systemOperations, setSystemOperations] = useState([]);

    const [permissions, setPermissions] = useState({});

    const [sortColumn, setSortColumn] = useState({
        sortByColumn: null,
        orderBy: null
    });

    const updateFormData = (target, data) => {
        setFormData({
            ...formData,
            [target]: data
        });
    }

    const getComboboxData = async () => {
        try {
            const data = await MonitorService.getTypologyData();
            setSystemOperations(data.integrationSystemOperation);
            setIntegrationSystems(data.integrationSystem);
            setMonitorStatus(data.monitorStatus);

        } catch ({ response }) {
            if (response?.data?.errorCode) {
                switch (response.data.errorCode) {
                    case 13002:
                        setErrors({...errors, email: true});
                        break;
                    default:
                        setErrors({...errors, create: true});
                }
            }
        }
    }

    const getMonitors = async () => {
        try {
            const data = await MonitorService.getAll(pagination, sortColumn, formData)
            setMonitors(data.content);
            setPagination({
                ...pagination,
                totalItems: data.totalElements,
                numPages: data.totalPages
            });
        } catch ({ response }) {
            if (response?.data?.errorCode) {
                switch (response.data.errorCode) {
                    case 13002:
                        setErrors({...errors, email: true});
                        break;
                    default:
                        setErrors({...errors, create: true});
                }
            }
        }
    }

    const filterSearch = (data) => {
        getMonitors();
    }

    const renderMonitorStatus = (monitorStatusId) => {
        switch (monitorStatusId) {
            case 0:
                return {
                    color: "orange"
                };
            break;
            case 10:
                return {
                    color: "blue"
                };
            break;
            case 100:
                return {
                    color: "green"
                };
            break;
            case 400:
                return {
                    color: "red"
                };
            break;
            default:
                return {
                    color: "gray"
                };
            break;
        }
    }

    const getPermissions = async () => {
        try {
            const data = await UserPermissionService.getPermissionsByConnected();
            setPermissions(data);
        } catch (error) {
            console.error(error);
        }
    }

    useEffect(() => {
        getComboboxData();
        getPermissions();
    }, [])

    useEffect(() => {
        getMonitors();
    }, [pagination.currentPage, sortColumn]);

    return (
        <>
            <div className={"d-sm-flex align-items-center justify-content-between mb-4"}>
                <h1 className={"h3 mb-0 text-gray-800"}>{t('monitor.title')}</h1>
            </div>

            <Card className={"shadow mb-4"}>
                <Card.Body>
                    <Form onSubmit={handleSubmit(filterSearch)}>
                        <Row>
                            <Col lg="2" className={"mb-3"}>
                                <FormGroup>
                                    <Form.Label>{t('monitor.filter.startDate')}</Form.Label>
                                    <DateTimePicker calendarIcon={null}
                                        disableClock={true}
                                        onChange={e => updateFormData('startDate', e)}
                                        value={formData?.startDate} />
                                </FormGroup>
                            </Col>
                            <Col lg="2" className={"mb-3"}>
                                <FormGroup>
                                    <Form.Label>{t('monitor.filter.endDate')}</Form.Label>
                                    <DateTimePicker calendarIcon={null}
                                        disableClock={true}
                                        onChange={e => updateFormData('endDate', e)}
                                        value={formData?.endDate} />
                                </FormGroup>
                            </Col>
                            <Col lg="2" className={"mb-3"}>
                                <FormGroup>
                                    <Form.Label>{t('monitor.filter.system')}</Form.Label>
                                    <Form.Select onChange={e => updateFormData('systemId', e.target.value)}>
                                        <option value={null}> </option>
                                        {integrationSystems?.map(system => (
                                            <option key={system?.value}
                                                value={system?.value}>{system?.description}</option>
                                        ))}
                                    </Form.Select>
                                </FormGroup>
                            </Col>
                            <Col lg="2" className={"mb-3"}>
                                <FormGroup>
                                    <Form.Label>{t('monitor.filter.status')}</Form.Label>
                                    <Form.Select onChange={e => updateFormData('statusId', e.target.value)}>
                                        <option value={null}> </option>
                                        {monitorStatus?.map(status => (
                                            <option key={status?.value}
                                                value={status?.value}>{status?.description}</option>
                                        ))}
                                    </Form.Select>
                                </FormGroup>
                            </Col>
                        </Row>
                        <Button type="submit" disabled={!permissions.monitor?.read}>{t('users.filter.button')}</Button>
                    </Form>
                    <hr />
                    <Table responsive>
                        <thead>
                            <tr>
                                <TableHeaderSortComponent scope={"col"} text={t('monitor.list.id')} column={"id"} sortColumn={sortColumn} setSortColumn={setSortColumn} />
                                <TableHeaderSortComponent scope={"col"} text={t('monitor.list.startDate')} column={"startDate"} sortColumn={sortColumn} setSortColumn={setSortColumn} />
                                <TableHeaderSortComponent scope={"col"} text={t('monitor.list.endDate')} column={"endDate"} sortColumn={sortColumn} setSortColumn={setSortColumn} />
                                <TableHeaderSortComponent scope={"col"} text={t('monitor.list.system')} column={"system"} sortColumn={sortColumn} setSortColumn={setSortColumn} />
                                <TableHeaderSortComponent scope={"col"} text={t('monitor.list.type')} column={"type"} sortColumn={sortColumn} setSortColumn={setSortColumn} />
                                <TableHeaderSortComponent scope={"col"} text={t('monitor.list.status')} column={"status"} sortColumn={sortColumn} setSortColumn={setSortColumn} />
                                <TableHeaderSortComponent scope={"col"} text={t('monitor.list.errors')} column={"errorMessage"} sortColumn={sortColumn} setSortColumn={setSortColumn} />
                                <TableHeaderSortComponent scope={"col"} text={t('monitor.list.user')} column={"userId"} sortColumn={sortColumn} setSortColumn={setSortColumn} />
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {monitors.length > 0 ? monitors.map((monitor, index) => (
                                <tr key={index}>
                                    <td>{monitor.id}</td>
                                    <td>{monitor.startDate !== null ? UseFormatDate(monitor.startDate) : null}</td>
                                    <td>{monitor.endDate !== null ? UseFormatDate(monitor.endDate) : null}</td>
                                    <td>{integrationSystems.find(item => item.value === monitor.system)?.description}</td>
                                    <td>{systemOperations.find(item => item.value === monitor.operation)?.description}</td>
                                    <td style={{ color: renderMonitorStatus(monitor.status).color, fontWeight: "bold" }}>{monitorStatus.find(item => item.value === monitor.status)?.description}</td>
                                    <td>{monitor.errors}</td>
                                    <td>{monitor.userName}</td>
                                </tr>
                            )) :
                                <tr>
                                    <td colSpan={4}>{t('general.empty')}</td>
                                </tr>
                            }
                        </tbody>
                    </Table>
                    <PaginationComponent pagination={pagination}
                        setPagination={setPagination}
                        alwaysShown={monitors.length > 0} />
                </Card.Body>
            </Card>
        </>
    );
};

export default Monitor;
