import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Accordion, Button, Col, Form, FormCheck, FormGroup, Image, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import Select from "react-select";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { v4 as uuidv4 } from "uuid";
import _ from "lodash";

import BodyService from "../../services/body.service";
import { Fragment } from "react";
import LocalityService from "../../services/locality.service";
import RegionService from "../../services/region.service";

const SiteInterestForm = ({ onSubmit, categories, languages, tags, projects, interestSite, details, chargingPoints, provinces, permissions }) => {

    const { t, i18n } = useTranslation();

    const [configLangParams, setConfigLangParams] = useState();
    const [project, setProject] = useState();
    const [chargingPoint, setChargingPoint] = useState();
    const [category, setCategory] = useState();
    const [selectedTags, setSelectedTags] = useState();
    const [selectedCategories, setSelectedCategories] = useState([]);
    const [categoryList, setCategoryList] = useState();
    const [categoryListUpdate, setCategoryListUpdate] = useState();
    const [imageList, setImageList] = useState([]);
    const [resetFlag, setResetFlag] = useState(false);
    const [latitude, setLatitude] = useState();
    const [longitude, setLongitude] = useState();
    const [chargingPointsEnabled, setChargingPointsEnabled] = useState(false);
    const [province, setProvince] = useState();
    const [localitiesList, setLocalitiesList] = useState()
    const [locality, setLocality] = useState();

    const [regionsList, setRegionsList] = useState([]);
    const [region, setRegion] = useState();

    const [coordinates, setCoordinates] = useState({
        latitude: "",
        longitude: ""
    })
    const [alerts, setAlerts] = useState({
        latitude: false,
        longitude: false
    })

    const { register, handleSubmit, reset, formState, getValues } = useForm({
        mode: "all",
    });

    const langInputUpdate = (data, iso, target) => {

        if (target === "slug") {
            data = BodyService.slugifyStr(data)
        }

        setConfigLangParams({
            ...configLangParams, [iso]: {
                ...configLangParams[iso], [target]: {
                    ...configLangParams[iso][target], "value": data
                }
            }
        });
    }

    const setImageToBase64 = async (file, index) => {
        if (file) {
            const base = await BodyService.getBase64(file);
            const copyImageList = [...imageList];
            const copyImage = { ...copyImageList[index] };
            copyImage.imageBase64 = base.split(',')[1];
            copyImage.imageName = file.name;
            copyImage.imageContentType = file.type;
            copyImageList[index] = copyImage;
            setImageList(copyImageList);
        }
    }

    const getBase64Src = (image) => {
        if (!image?.imageBase64 || !image?.imageContentType) {
            return false;
        }
        return `data:${image.imageContentType};base64,${image.imageBase64}`;
    }

    const addImage = () => {
        setImageList([...imageList, {
            uuid: uuidv4()
        }]);
    }

    const removeImage = (index) => {
        const copyImageList = [...imageList];
        copyImageList.splice(index, 1);
        setImageList(copyImageList);
    }

    useEffect(() => {
        if (languages && !details) {
            let configLangObj = {};

            if (languages) {
                languages.forEach(lang => {
                    configLangObj[lang.language.iso] = {
                        name: lang,
                        website: lang,
                        booking: lang,
                        slug: lang,
                        description: lang
                    }
                });
            }

            setConfigLangParams(configLangObj)
        }

        if (details && interestSite) {

            let configLangObj = {}
            languages.forEach(lang => {

                configLangObj[lang.language.iso] = {
                    name: {
                        language: lang.language,
                        value: interestSite?.name.find(nameObj => nameObj.language.iso === lang.language.iso).value
                    },
                    website: {
                        language: lang.language,
                        value: interestSite?.webSiteUrl.find(webObj => webObj.language.iso === lang.language.iso).value
                    },
                    booking: {
                        language: lang.language,
                        value: interestSite?.bookingUrl.find(bookingObj => bookingObj.language.iso === lang.language.iso).value
                    },
                    slug: {
                        language: lang.language,
                        value: interestSite?.slug.find(slugObj => slugObj.language.iso === lang.language.iso).value
                    },
                    description: {
                        language: lang.language,
                        value: interestSite?.shortDescription.find(descriptionObj => descriptionObj.language.iso === lang.language.iso).value
                    },
                    isSlugEmpty: interestSite?.slug.find(slugObj => slugObj.language.iso === lang.language.iso).value === "" || interestSite?.slug.find(slugObj => slugObj.language.iso === lang.language.iso).value == null
                }
            });
            setConfigLangParams(configLangObj)
        }

    }, [languages, interestSite, details, resetFlag])

    const onSubmitForm = async (data) => {

        let sendData = {
            ...data,
            latitude: latitude,
            longitude: longitude,
            name: [],
            bookingUrl: [],
            shortDescription: [],
            webSiteUrl: [],
            slug: [],
            projectId: project?.id || null,
            mainCategoryId: category?.id || "",
            categories: selectedCategories || [],
            tags: selectedTags || [],
            images: [],
            isChargingPoint: (chargingPointsEnabled && chargingPoint?.id) || false,
            chargingPointId: chargingPointsEnabled ? chargingPoint?.id || null : null,
            localityId: locality?.id
        }

        Object.values(configLangParams).forEach((data) => {
            sendData.bookingUrl.push(data.booking)
            sendData.name.push(data.name)
            sendData.shortDescription.push(data.description)
            sendData.webSiteUrl.push(data.website)
            sendData.slug.push(data.slug)
        })

        Object.values(imageList).forEach((img) => {
            if (img.imageUrl) {
                sendData.images.push({
                    id: img.id,
                    imageBase64: null,
                    imageContentType: null,
                    imageName: null,
                })
            } else {
                if (img.imageBase64) {
                    sendData.images.push(img)
                }
            }
        })
        try {
            const createResponse = await onSubmit(sendData)
            if (!details && !createResponse) {
                reset();
                setResetFlag(resetFlag ? false : true)
                setCategory();
                setSelectedCategories([]);
                setSelectedTags([])
                setLatitude();
                setLongitude()
                setImageList([]);
                setProject();
                setChargingPoint();
                setChargingPointsEnabled(false);
            }
        } catch (error) {
            console.error(error)
        }
        setTimeout(function () {
            window.scrollTo(0, 0);
        }, 0);
    }

    const checkNumberRange = (value, type, min, max, decimalMax) => {
        let calcNewRange = null
        let decimalLimit = false;

        if (value.toString().includes(',') && value.toString().length > 1) {
            if (value.toString().split(',').length === 2 && value.toString().split(',')?.[1] !== "") {
                let strSplit = value.toString().split(',');

                if (type === "latitude") {
                    calcNewRange = strSplit[1] // take second value from (xx.xxxx, xx.xxxx)
                    value = parseFloat(strSplit[0]) // take first value from (xx.xxxx, xx.xxxx)
                } else if (type === "longitude") {
                    calcNewRange = strSplit[0]
                    value = parseFloat(strSplit[1])
                }
            }
        }

        if (value) {
            if (!isNaN(value) || value === "-") {
                if (value.toString().includes(".")) {
                    if (value.toString().split(".")[1].length > decimalMax && value.toString().split(".")[0].length > 0) {
                        decimalLimit = true;
                        setCoordinates({ ...coordinates, [type]: value.toString().split(".")[0] + '.' + value.toString().split(".")[1].slice(0, decimalMax) })
                        if (type === "latitude") {
                            setLatitude(value.toString().split(".")[0] + '.' + value.toString().split(".")[1].slice(0, decimalMax))
                        } else {
                            setLongitude(value.toString().split(".")[0] + '.' + value.toString().split(".")[1].slice(0, decimalMax))
                        }
                        setAlerts({
                            ...alerts, [type]: false
                        });
                    } else {
                        if (type === "latitude") {
                            setLatitude(value)
                        } else {
                            setLongitude(value)
                        }
                        setAlerts({
                            ...alerts, [type]: false
                        });
                    }
                }
                if (!decimalLimit) {
                    if (value >= max) { // allow type "-" at start (-xx.xxxxx) and not force change value
                        setCoordinates({ ...coordinates, [type]: coordinates[type] })
                        if (type === "latitude") {
                            setLatitude(latitude)
                        } else {
                            setLongitude(longitude)
                        }
                        setAlerts({ ...alerts, [type]: true });
                    } else if (value <= min) {
                        setCoordinates({ ...coordinates, [type]: coordinates[type] })
                        if (type === "latitude") {
                            setLatitude(latitude)
                        } else {
                            setLongitude(longitude)
                        }
                        setAlerts({
                            ...alerts, [type]: true
                        });
                    } else {
                        if (type === "latitude") {
                            setLatitude(value)
                        } else {
                            setLongitude(value)
                        }
                        setAlerts({
                            ...alerts, [type]: false
                        });
                    }
                }
            } else {
                setAlerts({
                    ...alerts, [type]: true
                });
            }
        } else if (!value) {
            setCoordinates({ ...coordinates, [type]: "" })
            if (type === "latitude") {
                setLatitude("")
                setAlerts({
                    ...alerts, [type]: false
                });
            } else {
                setLongitude("")
                setAlerts({
                    ...alerts, [type]: false
                });
            }
        }

        if (calcNewRange) {
            checkNumberRange(calcNewRange.trim(), type === "latitude" ? "longitude" : "latitude", -100, 100, 16)
        }
    }

    const updateCategoryList = (categorySelect) => {

        if (category) {
            categoryListUpdate ? categoryListUpdate.push(category) : categoryList.push(category)
        }

        if (selectedCategories.map(cat => cat.id).indexOf(categorySelect.id) > -1) {
            selectedCategories.splice(selectedCategories.map(cat => cat.id).indexOf(categorySelect.id), 1);
        }

        categoryListUpdate ? categoryListUpdate.splice(categoryListUpdate.indexOf(categorySelect), 1) : categoryList.splice(categoryList.map(cat => cat.id).indexOf(categorySelect.id), 1)
    }

    const getLocalityByRegion = async (id) => {
        const data = await LocalityService.getByRegionId(id);
        setLocalitiesList(data);
    };

    const getRegionByProvince = async (id) => {
        const data = await RegionService.getByProvinceId(id);
        setRegionsList(data);
    };

    useEffect(() => {
        if (!categoryList && categories) {
            setCategoryList(_.clone(categories))
        }

        if (interestSite?.mainCategory && categories) {
            let list = _.clone(categories)
            list.splice(list.map(cat => cat.id).indexOf(interestSite?.mainCategory?.id), 1)
            setCategoryListUpdate(_.clone(list))
        }
    }, [categories, interestSite])


    useEffect(() => {
        if (details) {
            reset(interestSite);
            setLatitude(interestSite?.latitude);
            setLongitude(interestSite?.longitude);
            setProject(interestSite?.project);
            setCategory(categories?.find(cat => cat.id === interestSite?.mainCategory?.id));
            setSelectedCategories(interestSite?.categories);
            setSelectedTags(interestSite?.tags);
            setImageList(interestSite?.images || []);
            setChargingPoint(chargingPoints?.find(x => x.id === interestSite?.chargingPointId));
            setChargingPointsEnabled(interestSite?.isChargingPoint);

            if (interestSite?.locality) {
                setRegion(interestSite?.locality.region);
                setProvince(interestSite?.locality.region.province);
                setLocality(interestSite?.locality);
    
                getLocalityByRegion(interestSite?.locality.region.id);
                getRegionByProvince(interestSite?.locality.region.province.id); 
            }
        }
    }, [interestSite, details, categories, reset]);

    return (
        <Form onSubmit={handleSubmit(onSubmitForm)}>
            <Accordion className="mb-3">
                <Accordion.Item eventKey="">
                    <Accordion.Header>
                        <h6 className={"m-0 fw-bold text-primary"}>{t('articles.creation.general.title')}<span className="ms-1 text-danger fw-bold">*</span></h6>
                    </Accordion.Header>
                    <Accordion.Body>
                        <Row className="mb-3">
                            <Col md={3}>
                                <FormGroup>
                                    <Form.Label>{t('sitesInterest.creation.reference')}<span className="ms-1 text-danger fw-bold">*</span></Form.Label>
                                    <Form.Control type="text" {...register("internalReference", { required: true })} />
                                </FormGroup>
                            </Col>
                            <Col md={3}>
                                <FormGroup>
                                    <Form.Label>{t('sitesInterest.creation.address')}</Form.Label>
                                    <Form.Control type="text" {...register("address")} />
                                </FormGroup>
                            </Col>
                            {/* <Col md={3}>
                                <FormGroup>
                                    <Form.Label>{t('sitesInterest.creation.poblation')}</Form.Label>
                                    <Form.Control type="text" {...register("place")} />
                                </FormGroup>
                            </Col> */}
                            <Col md={3}>
                                <FormGroup>
                                    <Form.Label>{t('sitesInterest.creation.email')}</Form.Label>
                                    <Form.Control type="text" {...register("email")} />
                                </FormGroup>
                            </Col>
                            <Col md={3}>
                                <FormGroup>
                                    <Form.Label>{t('sitesInterest.creation.phone')}</Form.Label>
                                    <Form.Control type="text" {...register("phone")} />
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row className="mb-3">
                            <Col md={3}>
                                <FormGroup>
                                    <Form.Label>{t('sitesInterest.creation.schedule')}</Form.Label>
                                    <Form.Control type="text" {...register("schedule")} />
                                </FormGroup>
                            </Col>
                            <Col md={3}>
                                <FormGroup>
                                    <Form.Label>{t('sitesInterest.creation.latitude')}</Form.Label>
                                    <Form.Control type="text" value={latitude || ""} onChange={e => { checkNumberRange(e.target.value, 'latitude', -100, 100, 16) }} />
                                    {alerts.latitude && <span className="text-primary ">Introduce un valor menor a 100 y mayor a -100</span>}
                                </FormGroup>
                            </Col>
                            <Col md={3}>
                                <FormGroup>
                                    <Form.Label>{t('sitesInterest.creation.longitude')}</Form.Label>
                                    <Form.Control type="text" value={longitude || ""} onChange={(e) => { checkNumberRange(e.target.value, 'longitude', -100, 100, 16) }} />
                                    {alerts.longitude && <span className="text-primary ">Introduce un valor menor a 100 y mayor a -100</span>}
                                </FormGroup>
                            </Col>
                            <Col md={3}>
                                <FormGroup>
                                    <Form.Label>{t('sitesInterest.creation.project')}</Form.Label>
                                    <Select options={projects}
                                        classNamePrefix="select"
                                        placeholder=""
                                        value={project || ''}
                                        // getOptionLabel={(option) => option.title[i18n.language.split('-')[0]].value}
                                        getOptionLabel={(option) => option.title[0].value}
                                        getOptionValue={(option) => option.id}
                                        onChange={(data) => {
                                            setProject(data)
                                        }}
                                    />
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row className="mb-4">
                            <Col md={3}>
                                <FormGroup>
                                    <Form.Label>{t('sitesInterest.creation.province')}<span className="ms-1 text-danger fw-bold">*</span></Form.Label>
                                    <Select options={provinces}
                                        classNamePrefix="select"
                                        placeholder=""
                                        value={province || ''}
                                        getOptionLabel={(option) => option?.name?.['es']?.value}
                                        getOptionValue={(option) => option.id}
                                        onChange={(data) => {
                                            setProvince(data);
                                            getRegionByProvince(data.id);
                                            setRegion();
                                            setLocality();
                                        }}
                                    />
                                </FormGroup>
                            </Col>
                            <Col md={3}>
                                <FormGroup>
                                    <Form.Label>{t('sitesInterest.creation.region')}<span className="ms-1 text-danger fw-bold">*</span></Form.Label>
                                    <Select options={regionsList}
                                        isDisabled={!province}
                                        classNamePrefix="select"
                                        placeholder=""
                                        value={region || ''}
                                        getOptionLabel={(option) => option?.name?.['es']?.value}
                                        getOptionValue={(option) => option.id}
                                        onChange={(data) => {
                                            setRegion(data);
                                            getLocalityByRegion(data.id);
                                            setLocality();
                                        }}
                                    />
                                </FormGroup>
                            </Col>
                            <Col md={3}>
                                <FormGroup>
                                    <Form.Label>{t('sitesInterest.creation.locality')}<span className="ms-1 text-danger fw-bold">*</span></Form.Label>
                                    <Select options={localitiesList}
                                        isDisabled={!region}
                                        classNamePrefix="select"
                                        placeholder=""
                                        value={locality || ''}
                                        getOptionLabel={(option) => option?.name?.['es']?.value}
                                        getOptionValue={(option) => option.id}
                                        onChange={(data) => {
                                            setLocality(data)
                                        }}
                                    />
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row className="mb-4">
                            <Col md={3}>
                                <FormGroup>
                                    <Form.Label>{t('sitesInterest.creation.chargingPoint')}</Form.Label>
                                    <Select options={chargingPoints}
                                        classNamePrefix="select"
                                        placeholder=""
                                        value={chargingPoint || ''}
                                        getOptionLabel={(option) => option.name[i18n.language.split('-')[0]].value}
                                        getOptionValue={(option) => option.id}
                                        onChange={(data) => {
                                            setChargingPoint(data)
                                        }}
                                        isDisabled={!chargingPointsEnabled}
                                    />
                                </FormGroup>
                            </Col>
                            <Col md="auto" className="mt-2 d-flex align-items-end justify-content-start form-check-input-md">
                                <FormGroup className="mt-3 me-4">
                                    <FormCheck label="¿Tienes punto/s de carga?" checked={chargingPointsEnabled} onChange={() => {
                                        setChargingPointsEnabled(!chargingPointsEnabled);
                                    }}
                                    />
                                </FormGroup>
                                <FormGroup className="me-4 mt-3">
                                    <Form.Check label="Activo" {...register("active")} />
                                </FormGroup>
                                <FormGroup className="mt-3">
                                    <FormCheck label="Validado" {...register("validated")} />
                                </FormGroup>
                            </Col>
                        </Row>

                        <Accordion className="mb-3">
                            <Accordion.Item eventKey="">
                                <Accordion.Header>
                                    <h6 className={"m-0 fw-bold text-primary"}>{t('sitesInterest.creation.multilanguage')}<span className="ms-1 text-danger fw-bold">*</span></h6>
                                </Accordion.Header>
                                <Accordion.Body>
                                    {languages && languages?.map((lang, i) => (
                                        <Accordion key={i} className="mb-3">
                                            <Accordion.Item eventKey="">
                                                <Accordion.Header>
                                                    <h6 className={"m-0 fw-bold text-primary"}>{lang?.language?.name}{lang.language.iso === "es" && <span className="ms-1 text-danger fw-bold">*</span>}</h6>
                                                </Accordion.Header>
                                                <Accordion.Body>
                                                    <Row className="mb-3">
                                                        <Col md={3}>
                                                            <FormGroup>
                                                                <Form.Label>{t('sitesInterest.creation.name')}{lang.language.iso === "es" && <span className="ms-1 text-danger fw-bold">*</span>}</Form.Label>
                                                                <Form.Control type="text" value={configLangParams?.[lang.language.iso].name.value || ''} onChange={e => langInputUpdate(e.target.value, lang.language.iso, "name")} />
                                                            </FormGroup>
                                                        </Col>
                                                        <Col md={3}>
                                                            <FormGroup>
                                                                <Form.Label>{t('sitesInterest.creation.web')}</Form.Label>
                                                                <Form.Control type="text" value={configLangParams?.[lang.language.iso].website.value || ''} onChange={e => langInputUpdate(e.target.value, lang.language.iso, "website")} />
                                                            </FormGroup>
                                                        </Col>
                                                        <Col md={3}>
                                                            <FormGroup>
                                                                <Form.Label>{t('sitesInterest.creation.bookingLink')}</Form.Label>
                                                                <Form.Control type="text" value={configLangParams?.[lang.language.iso].booking.value || ''} onChange={e => langInputUpdate(e.target.value, lang.language.iso, "booking")} />
                                                            </FormGroup>
                                                        </Col>
                                                        <Col md={3}>
                                                            <FormGroup>
                                                                <Form.Label>{t('sitesInterest.creation.slug')}{lang.language.iso === "es" && <span className="ms-1 text-danger fw-bold">*</span>}</Form.Label>
                                                                <Form.Control type="text" value={configLangParams?.[lang.language.iso].slug.value || ''} onChange={e => langInputUpdate(e.target.value, lang.language.iso, "slug")}
                                                                    disabled={!(configLangParams?.[lang.language.iso].isSlugEmpty) && details} />
                                                            </FormGroup>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col md={12}>
                                                            <FormGroup>
                                                                <Form.Label>{t('sitesInterest.creation.shortDescription')}{lang.language.iso === "es" && <span className="ms-1 text-danger fw-bold">*</span>}</Form.Label>
                                                                <Form.Control as="textarea" rows={3} value={configLangParams?.[lang.language.iso].description.value || ''} onChange={e => langInputUpdate(e.target.value, lang.language.iso, "description")} />
                                                            </FormGroup>
                                                        </Col>
                                                    </Row>
                                                </Accordion.Body>
                                            </Accordion.Item>
                                        </Accordion>
                                    ))}
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>

            <Accordion className="mb-3">
                <Accordion.Item eventKey="">
                    <Accordion.Header>
                        <h6 className={"m-0 fw-bold text-primary"}>{t('sitesInterest.creation.category.title')}<span className="ms-1 text-danger fw-bold">*</span></h6>
                    </Accordion.Header>
                    <Accordion.Body>
                        <Row className="mb-3">
                            <Col md={3}>
                                <FormGroup>
                                    <Form.Label>{t('sitesInterest.creation.mainCategory')}<span className="ms-1 text-danger fw-bold">*</span></Form.Label>
                                    <Select options={categories}
                                        classNamePrefix="select"
                                        closeMenuOnSelect={true}
                                        placeholder="Seleccionar categoria"
                                        value={category || ''}
                                        getOptionLabel={(option) => option.name[i18n.language.split('-')[0]].value}
                                        getOptionValue={(option) => option.id}
                                        onChange={(data) => {
                                            updateCategoryList(data)
                                            setCategory(data)
                                        }}
                                    />
                                </FormGroup>
                            </Col>
                            <Col md={3}>
                                <FormGroup>
                                    <Form.Label>{t('sitesInterest.creation.category.associatedCategories')}</Form.Label>
                                    <Select options={categoryListUpdate || categoryList}
                                        isMulti
                                        classNamePrefix="select"
                                        closeMenuOnSelect={false}
                                        placeholder=""
                                        value={selectedCategories}
                                        getOptionLabel={(option) => option.name[i18n.language.split('-')[0]].value}
                                        getOptionValue={(option) => option.id}
                                        onChange={(data) => {
                                            setSelectedCategories(data)
                                        }}
                                    />
                                </FormGroup>
                            </Col>
                        </Row>
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>
            <Accordion className="mb-3">
                <Accordion.Item eventKey="">
                    <Accordion.Header>
                        <h6 className={"m-0 fw-bold text-primary"}>{t('sitesInterest.creation.tags')}</h6>
                    </Accordion.Header>
                    <Accordion.Body>
                        <Row className="mb-3">
                            <Col md={3}>
                                <FormGroup>
                                    <Form.Label>{t('sitesInterest.creation.tags')}</Form.Label>
                                    <Select options={tags}
                                        isMulti
                                        classNamePrefix="select"
                                        closeMenuOnSelect={false}
                                        placeholder=""
                                        value={selectedTags}
                                        getOptionLabel={(option) => option.name[i18n.language.split('-')[0]].value}
                                        getOptionValue={(option) => option.id}
                                        onChange={(data) => setSelectedTags(data)}
                                    />
                                </FormGroup>
                            </Col>
                        </Row>
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>
            <Accordion className="mb-3">
                <Accordion.Item eventKey="">
                    <Accordion.Header>
                        <h6 className={"m-0 fw-bold text-primary"}>{t('sitesInterest.creation.images.title')}</h6>
                    </Accordion.Header>
                    <Accordion.Body>
                        <>
                            {imageList?.map((image, index) => (
                                <Fragment key={index}>
                                    {index !== 0 && <hr />}
                                    {(image?.imageUrl || image?.imageBase64) &&
                                        <Row className="my-3">
                                            <>
                                                <Col className="col-8 col-md-6 col-lg-2">
                                                    <Image fluid thumbnail src={getBase64Src(image) || image.imageUrl} />
                                                </Col>
                                            </>
                                        </Row>
                                    }
                                    <Row className="my-2">
                                        <Col md="auto">
                                            <Form.Control type="file"
                                                accept="image/png, image/jpeg"
                                                onChange={(e) => setImageToBase64(e.target.files[0], index)} />
                                        </Col>
                                        <Col md="auto">
                                            <Button variant="link" size="ls" onClick={() => removeImage(index)}
                                                className="border-primary">
                                                <FontAwesomeIcon icon="fas fa-trash" />
                                            </Button>
                                        </Col>
                                    </Row>
                                    {(index === imageList.length - 1 && (image?.imageUrl || image?.imageBase64)) &&
                                        <Row>
                                            <Col md="auto">
                                                <FormGroup onClick={addImage} className="mt-3">
                                                    <Form.Label
                                                        style={{ cursor: "pointer" }}>{t('general.addImage')}</Form.Label>
                                                    <Button variant="link" size="ls" className={"ms-2 border-primary"}>
                                                        <FontAwesomeIcon icon="fas fa-plus" />
                                                    </Button>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                    }
                                </Fragment>
                            ))}
                            {imageList.length === 0 &&
                                <Row>
                                    <Col md="auto">
                                        <FormGroup onClick={addImage}>
                                            <Form.Label
                                                style={{ cursor: "pointer" }}>{t('general.addImage')}</Form.Label>
                                            <Button variant="link" size="ls" className={"ms-2 border-primary"}>
                                                <FontAwesomeIcon icon="fas fa-plus" />
                                            </Button>
                                        </FormGroup>
                                    </Col>
                                </Row>
                            }
                        </>
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>
            {details ? 
            <Button type="submit" className={"mt-3"} disabled={ permissions.interestSites?.update === false ? true : (!formState.isValid || !configLangParams['es'].name.value || !configLangParams['es'].description.value || !configLangParams['es'].slug.value || !category || !province || !locality) }>
                {t('sitesInterest.update.button')}
            </Button>
            : 
            <Button type="submit" className={"mt-3"} disabled={ permissions.interestSites?.create === false ? true : (!formState.isValid || !configLangParams['es'].name.value || !configLangParams['es'].description.value || !configLangParams['es'].slug.value || !category || !province || !locality) }>
                {t('sitesInterest.creation.submit')}
            </Button>
            }

        </Form >
    )
}

export default SiteInterestForm;
