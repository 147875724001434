import { t } from "i18next";
import { useEffect, useState, useRef } from "react";
import { Button, Col, Form, FormCheck, FormGroup, Row, Image} from "react-bootstrap"
import { useForm } from "react-hook-form";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import BodyService from "../../../services/body.service";
import TranslationInputLanguage from "../fields/TranslationInputLanguage";

const ChargerTypeForm = ({ onSubmit, details, connector, chargerType, permissions }) => {

    const [chargerTypeConnectors, setChargerTypeConnectors] = useState();

    const [connectorValues, setConnectorValues] = useState();

    const [image, setImage] = useState({});

    const pictureRef = useRef(null);

    const { register, handleSubmit, formState, reset } = useForm({
        mode: "all",
        defaultValues: {
            active: !details,
        },
    });

    const setChargerTypeConnectorValuesForm = (iso, value, id) => {

        setConnectorValues({
            ...connectorValues, [iso]: { value: value, langId: id, iso: iso }
        })
    }

    const onSubmitForm = async (data) => {
        let objSend = {
            connector: [],
            power: data.power,
            picture: {},
            active: data.active
        };

        Object.values(connectorValues).forEach((connector) => {
            objSend.connector.push({
                language: {
                    id: connector.langId
                },
                value: connector.value,
                picture: {}
            })
        })

        setImages(objSend);

        try {
            await onSubmit(objSend);
            if (!details) {
                setConnectorValues(null);
                setImage({});
                reset();
            }
        } catch (error) {
            console.error(error)
        }

        setTimeout(function () {
            window.scrollTo(0, 0);
        }, 0);
    }

    const setImages = (objSend) => {
        if (image) {
            if (image.id) {
                objSend.picture = {
                    id: image.id,
                    imageBase64: null,
                    imageContentType: null,
                    imageName: null,
                }
            } else {
                if (image.imageBase64 || image.imageBase64 === "") {
                    objSend.picture = image;
                }
            }
        }
    };

    const setImageToBase64 = async (file) => {
        if (file) {
            const base = await BodyService.getBase64(file);
            const newImage = {...image};
            newImage.imageBase64 = base.split(',')[1];
            newImage.imageName = file.name;
            newImage.imageContentType = file.type;
            setImage(newImage);
        }
    };

    const getBase64Src = (image) => {
        if (!image?.imageBase64 || !image?.imageContentType) {
            return false;
        }
        return `data:${image.imageContentType};base64,${image.imageBase64}`;
    };

    
    const removeImage = (ref) => {
        ref.current.value = "";
        let newImage = {};
        if (details) {
            if (image) {
                newImage.imageBase64 = "";
                newImage.imageContentType = "";
                newImage.imageUrl = "";
            }
        }
        setImage(newImage);
    };

    useEffect(() => {
        setChargerTypeConnectors(connector);

        if (details && chargerType) {
            let connectorLang = {}

            Object.keys(chargerType.name).forEach((data) => {
                connectorLang[data] = {
                    value: chargerType.name[data].value,
                    langId: chargerType.name[data].language.id,
                    iso: chargerType.name[data].language.iso,
                }
            })

            setConnectorValues(connectorLang);
            setImage({ imageUrl: chargerType.picture });
        }
    }, [connector, chargerType, details])

    useEffect(() => {
        if (details) {
            reset(chargerType);
        }
    }, [chargerType, details, reset]);

    return (
        <Form onSubmit={handleSubmit(onSubmitForm)}>
            <Row>
                <Col xs={12}>
                    <h5>{t('chargerType.creation.fields.connector')}</h5>
                    {connector?.length > 0 ?
                        <Row className="mt-3">
                            <TranslationInputLanguage translations={chargerTypeConnectors} data={connectorValues} onChange={setChargerTypeConnectorValuesForm} />
                        </Row>
                        :
                        <></>
                    }
                </Col>
            </Row>
            <Row className="mt-3">
                <Col md={2}>
                    <h5>{t('chargerType.creation.fields.power')}</h5>
                    <Form.Control type="text" {...register('power')} />
                </Col>
            </Row>
            <Row>
                <Col xs={12} className="mt-md-3">  
                    <FormGroup>
                        <Form.Label>{t('chargerType.creation.image')}</Form.Label>
                        <Row className="my-2">
                            {(image?.imageUrl || image?.imageBase64) &&
                                <Row className="my-3">
                                    <>
                                        <Col className="col-8 col-md-6 col-lg-2">
                                            <Image fluid thumbnail src={getBase64Src(image) || image?.imageUrl} />
                                        </Col>
                                    </>
                                </Row>
                            }
                            <Col md="auto">
                                <Form.Control type="file"
                                    accept="image/png, image/jpeg"
                                    onChange={(e) => setImageToBase64(e.target.files[0])}
                                    ref={pictureRef}
                                />
                            </Col>
                            <Col md="auto">
                                <Button variant="link" size="ls" onClick={() => removeImage(pictureRef)}
                                    className="border-primary">
                                    <FontAwesomeIcon icon="fas fa-trash" />
                                </Button>
                            </Col>
                        </Row>
                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col xs="12" className="mt-md-3">
                    <FormGroup>
                        <FormCheck label={t('chargerType.creation.fields.active')} {...register("active")} />
                    </FormGroup>
                </Col>
            </Row>
            {details ? 
            <Button type="submit" className={"mt-4"} disabled={ permissions.chargerTypes?.update === false ? true : !formState.isValid }>
                <FontAwesomeIcon icon={"save"} />
            </Button>
            : 
            <Button type="submit" className={"mt-4"} disabled={ permissions.chargerTypes?.create === false ? true : !formState.isValid }>
                {t('chargerType.creation.button')}
            </Button>
            }
        </Form>
    )
}

export default ChargerTypeForm;