import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect, useState } from 'react'
import { Alert, Card } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { Link, useParams } from 'react-router-dom';

import BodyService from '../../../services/body.service';
import TagService from '../../../services/tag.service';
import CategoryService from '../../../services/category.service';
import ArticleService from '../../../services/article.service';
import MiniGuideService from '../../../services/miniGuide.service';
import MiniGuideForm from '../../../components/forms/MiniGuideForm';
import { useCallback } from 'react';
import ProvinceService from '../../../services/province.service';
import UserPermissionService from "../../../services/userPermission.service";

const MiniGuideDetails = () => {

	const { t } = useTranslation();
	const params = useParams();

	const [miniGuide, setMiniGuide] = useState();
	const [languages, setLanguages] = useState();
	const [tags, setTags] = useState();
	const [categories, setCategories] = useState();
	const [articles, setArticles] = useState();
    const [provinces, setProvinces] = useState();
	const [permissions, setPermissions] = useState({});

	const [success, setSuccess] = useState({
		update: false,
		delete: false,
	});
	const [errors, setErrors] = useState({
		update: false,
		delete: false,
	});

	const updateMiniGuide = async (data) => {
		setErrors({ ...errors, update: false });
        setSuccess({ ...success, update: false });
		
		try {
            await MiniGuideService.update(params.id, data)
            setSuccess({...success, update: true});
        } catch (e) {
            setErrors({...errors, update: true});
        }
	}

	const getMiniGuideDetail = useCallback(async () => {
		try {
			const data = await MiniGuideService.getById(params.id)
			setMiniGuide(data)
		} catch (error) {
			console.error(error);
		}
	}, [params.id])

	const getPermissions = async () => {
        try {
            const data = await UserPermissionService.getPermissionsByConnected();
            setPermissions(data);
        } catch (error) {
            console.error(error);
        }
    }

	const getEmptyTranslations = async () => {
		const data = await BodyService.getEmptyTranslations();
		setLanguages(data);
	}

	const getTags = async () => {
		try {
			const data = await TagService.getAllList();
			setTags(data);
		} catch ({ response }) {
			console.error(response);
		}
	}

	const getCategories = async () => {
		try {
			const data = await CategoryService.selectAll()
			setCategories(data)
		} catch ({ error }) {
			console.error(error);
		}
	}

	const getArticles = async () => {
		try {
			const data = await ArticleService.getLazy()
			setArticles(data)
		} catch (error) {
			console.error(error);
		}
	}

	const getProvinces = async () => {
		try {
			const data = await ProvinceService.getList();
			setProvinces(data);
		} catch ({ response }) {
			console.error(response);
		}
	}

	useEffect(() => {
		const loadData = async () => {
			await getPermissions();
            await getEmptyTranslations();
            await getTags();
            await getCategories();
            await getArticles();
            await getMiniGuideDetail();
            await getProvinces();
		
		}

        loadData();
	}, [])

	return (
		<>
			<div className={"d-flex justify-content-between my-3"}>
				<Link to="/mini-guide" className={"previous round rounded-circle text-decoration-none"}
					title={t('general.back')}>
					<FontAwesomeIcon icon={"chevron-left"} className={"mt-2 me-2"} />
					<span>{t('miniGuide.title')}</span>
				</Link>
			</div>

			<Alert variant={"success"} show={success.update} dismissible onClose={() => setSuccess({ ...success, update: false })}>
				{t('miniGuide.success.update')}
			</Alert>

			<Alert variant={"danger"} show={errors.update} dismissible onClose={() => setErrors({ ...errors, update: false })}>
				{t('miniGuide.error.update')}
			</Alert>

			<Card className={"shadow mb-4"}>
				<Card.Header className='py-3'>
					<h6 className={"m-0 fw-bold text-primary"}>{t('miniGuide.update.title')}</h6>
				</Card.Header>
				<Card.Body>
					<MiniGuideForm onSubmit={updateMiniGuide} miniGuide={miniGuide} languages={languages} tags={tags} categories={categories} articles={articles} provinces={provinces} permissions={permissions} detail />
				</Card.Body>
			</Card>
		</>
	)
}

export default MiniGuideDetails