import { Card, Alert } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useCallback, useEffect, useState } from "react";
import TagService from "../../../services/tag.service";
import TagForm from "../../../components/forms/TagForm";
import BodyService from "../../../services/body.service";
import UserPermissionService from "../../../services/userPermission.service";

const TagDetails = () => {

    const params = useParams();

    const navigate = useNavigate();

    const { t } = useTranslation();

    const [tag, setTag] = useState();
    const [translations, setTranslations] = useState([]);
    const [title, setTitle] = useState("");
    const [permissions, setPermissions] = useState({});

    const [success, setSuccess] = useState({
        update: false,
        email: false,
    });

    const [errors, setErrors] = useState({
        update: false,
    });

    const getTag = useCallback(async (id) => {
        try {
            const data = await TagService.getById(id);
            setTag(data);
            setTitle(data.name['es'].value);
        } catch (e) {
            navigate("/tag");
        }
    }, [navigate]);


    const updateTag = async (data) => {
        setErrors({ ...errors, update: false });
        setSuccess({ ...success, update: false });

        try {
            await TagService.update(params.id, data);
            getTag(params.id);
            setSuccess({...success, update: true});
        } catch (e) {
            setErrors({...errors, update: true});
        }
    };

    const getEmptyTranslations = async () => {
        const data = await BodyService.getEmptyTranslations();
        setTranslations(data);
    }

    const getPermissions = async () => {
        try {
            const data = await UserPermissionService.getPermissionsByConnected();
            setPermissions(data);
        } catch (error) {
            console.error(error);
        }
    }

    useEffect(() => {
        const loadData = async () => {
            await getPermissions();
        }

        loadData();
    }, []);

    useEffect(() => {
        getTag(params.id);
        getEmptyTranslations();
    }, [getTag, params.id]);

    return (
        <>
            <div className={"d-flex justify-content-between my-3"}>
                <Link to="/tag" className={"previous round rounded-circle text-decoration-none"}
                    title={t('general.back')}>
                    <FontAwesomeIcon icon={"chevron-left"} className={"mt-2 me-2"} />
                    <span>{t('tags.title')}</span>
                </Link>
            </div>
            <Alert variant={"success"} show={success.update} dismissible onClose={() => setSuccess({ ...success, update: false })}>
                {t('tags.success.update')}
            </Alert>
            <Alert variant={"danger"} show={errors.update} dismissible onClose={() => setErrors({ ...errors, update: false })}>
                {t('tags.error.update')}
            </Alert>
            <Card>
                <Card.Header className={"py-3"}>
                    <h1 className={"h3 mb-0 text-gray-800 card-title text-capitalize"}>{title}</h1>
                </Card.Header>
                <Card.Body>
                    <TagForm onSubmit={updateTag} tag={tag} translations={translations} permissions={permissions} details />
                </Card.Body>
            </Card>
        </>
    );
}

export default TagDetails;
