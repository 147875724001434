import { Accordion, Button, Card, Table, Alert, Row, Col, Form, FormGroup, Image } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";

import APP_CONF from "../../scripts/constants";
import PaginationComponent from "../../components/PaginationComponent";
import SiteInterestForm from "../../components/forms/SiteInterestForm";
import CategoryService from "../../services/category.service";
import BodyService from "../../services/body.service";
import TagService from "../../services/tag.service";
import ProjectService from "../../services/project.service";
import SiteInterestService from "../../services/siteInterest.service";
import UseAlertConfirm from "../../hooks/UseAlertConfirm";
import TableHeaderSortComponent from "../../components/TableHeaderSortComponent";
import UseFormatDate from "../../hooks/UseFormatDate";
import ChargingPointService from "../../services/chargingPoint.service";
import ProvinceService from "../../services/province.service";
import UserPermissionService from "../../services/userPermission.service";

const SiteInterest = () => {

    const { t } = useTranslation();

    const [categories, setCategories] = useState();
    const [languages, setLanguages] = useState();
    const [tagList, setTagList] = useState([]);
    const [projectList, setProjectList] = useState([])
    const [interestSites, setInteresSites] = useState();
    const [chargingPointList, setChargingPointList] = useState([]);
    const [provinces, setProvinces] = useState();
    const [permissions, setPermissions] = useState({});

    const [sortColumn, setSortColumn] = useState({
        sortByColumn: null,
        orderBy: null
    });

    const [pagination, setPagination] = useState({
        currentPage: 1,
        totalItems: 0,
        itemsPerPage: APP_CONF.ITEMS_PER_PAGE,
        numPages: 1
    });

    const [success, setSuccess] = useState({
        create: false,
        delete: false,
    });
    const [errors, setErrors] = useState({
        create: false,
        delete: false,
        slug: false,
        usedSlug: false
    });

    const { register, handleSubmit } = useForm({
        mode: "all",
    });

    const { showModal } = UseAlertConfirm();

    const [filter, setFilter] = useState({
        active: ""
    })

    const getPermissions = async () => {
        try {
            const data = await UserPermissionService.getPermissionsByConnected();
            setPermissions(data);
        } catch (error) {
            console.error(error);
        }
    }

    const getEmptyTranslations = async () => {
        const data = await BodyService.getEmptyTranslations();
        setLanguages(data);
    }

    const createSiteInterest = async (data) => {
        setErrors({ ...errors, create: false, delete: false, config: false, slug: false, usedSlug: false, });
        setSuccess({ ...success, create: false });

        try {
            await SiteInterestService.create(data);
            setSuccess({ ...success, create: true });
            getInterestSites();
        } catch (e) {
            console.error(e.response);
            if (e?.response?.data?.errorCode === 15002) {
                setErrors({ ...errors, create: false, delete: false, config: false, slug: true, usedSlug: false, });
            } else if (e?.response?.data?.errorCode === 15001) {
                setErrors({ ...errors, create: false, delete: false, config: false, slug: false, usedSlug: true});
            } else {
                setErrors({ ...errors, create: true, delete: false, config: false, slug: false, usedSlug: false, });
            }
            return e.response
        }
    }

    const getInterestSites = async () => {
        try {

            let language;
            if ((sortColumn.sortByColumn === 'name') &&
                (sortColumn.orderBy !== null || sortColumn.orderBy !== undefined)) {
                language = "es";
            }

            const data = await SiteInterestService.getAll(pagination, sortColumn, language, filter);
            setInteresSites(data.content);
            setPagination({
                ...pagination,
                totalItems: data.totalElements,
                numPages: data.totalPages
            });
        } catch ({ response }) {
            console.error(response);
        }
    }

    const deleteInterestSite = async (id) => {

        showModal({
            title: t("general.delete.title"),
            body: t("general.delete.message"),
            confirmButtonVariant: "danger",
            onConfirm: async () => {
                setErrors({ ...errors, create: false });
                setSuccess({ ...success, create: false });
                try {
                    await SiteInterestService.remove(id);
                    setSuccess({ ...success, delete: true });
                    getInterestSites();
                } catch (e) {
                    console.error(e.response);
                    setErrors({ ...errors, delete: true });
                }
            }
        });

    }

    const filterSearch = (data) => {
        let obj = data;
        if (obj.search === "") {
            obj = {
                active: data.active
            }
        }
        setFilter(obj);
    }

    const downloadFile = () => {
        try {
            let language = "es";
            SiteInterestService.downloadExcel(language, filter);
        } catch ({ response }) {
            console.error(response);
        }
    }

    useEffect(() => {
        const loadData = async () => {
            await getPermissions();
        }

        loadData();
    }, [])

    useEffect(() => {
        const getCategories = async () => {
            try {
                const data = await CategoryService.selectAll();
                setCategories(data);
            } catch ({ response }) {
                console.error(response);
            }
        }

        const getTags = async () => {
            try {
                const data = await TagService.getAllList();
                setTagList(data);
            } catch ({ response }) {
                console.error(response);
            }
        }

        const getProjects = async () => {
            try {
                const data = await ProjectService.getAllOrderByTitle("es");
                setProjectList(data)
            } catch ({ response }) {
                console.error(response);
            }
        }

        const getChargingPoints = async () =>{
            try{
                const data = await ChargingPointService.getAllList();
                setChargingPointList(data)
            } catch ({ response }) {
                console.error(response);
            }
        }

        const getProvinces = async () => {
            try {
                const data = await ProvinceService.getList();
                setProvinces(data);
            } catch ({ response }) {
                console.error(response);
            }
        }

        getCategories();
        getEmptyTranslations();
        getTags();
        getProjects();
        getChargingPoints();
        getProvinces();
    }, [filter, pagination])

    useEffect(() => {
        getInterestSites();
    }, [pagination.currentPage, filter, sortColumn]);

    return (
        <>
            <div className={"d-sm-flex align-items-center justify-content-between mb-4"}>
                <h1 className={"h3 mb-0 text-gray-800"}>{t('sitesInterest.title')}</h1>
            </div>

            <Alert variant={"success"} show={success.create} dismissible onClose={() => setSuccess({ ...success, create: false })}>
                {t('sitesInterest.success.create')}
            </Alert>

            <Alert variant={"danger"} show={errors.create} dismissible onClose={() => setErrors({ ...errors, create: false })}>
                {t('sitesInterest.error.create')}
            </Alert>

            <Alert variant={"success"} show={success.delete} dismissible onClose={() => setSuccess({ ...success, delete: false })}>
                {t('sitesInterest.success.delete')}
            </Alert>

            <Alert variant={"danger"} show={errors.slug} dismissible onClose={() => setErrors({ ...errors, slug: false })}>
                {t('sitesInterest.error.emptySlug')}
            </Alert>

            <Alert variant={"danger"} show={errors.usedSlug} dismissible onClose={() => setErrors({ ...errors, usedSlug: false })}>
                {t('sitesInterest.error.alreadyUsedSlug')}
            </Alert>

            {permissions.interestSites?.create ? 
                <Card className={"shadow mb-4"}>
                    <Accordion>
                        <Accordion.Item eventKey="0">
                            <Accordion.Header><h6 className={"m-0 fw-bold text-primary"}>{t('sitesInterest.creation.title')}</h6>
                            </Accordion.Header>
                            <Accordion.Body>
                                <SiteInterestForm onSubmit={createSiteInterest} categories={categories} languages={languages} tags={tagList} projects={projectList} chargingPoints={chargingPointList} provinces={provinces} permissions={permissions} />
                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>
                </Card>
            : null }

            <Card className={"shadow mb-4"}>
                <Card.Header className={"py-3"}>
                    <h6 className={"m-0 fw-bold text-primary"}>{t('sitesInterest.list.title')}</h6>
                </Card.Header>
                <Card.Body>
                    <Form onSubmit={handleSubmit(filterSearch)}>
                        <Row>
                            <Col lg="2" className={"mb-3"}>
                                <FormGroup>
                                    <Form.Label>{t('categories.filter.active.title')}</Form.Label>
                                    <Form.Select {...register('active')} aria-label="Default select example">
                                        <option value="">{t('categories.filter.active.value1')}</option>
                                        <option value={true}>{t('categories.filter.active.value2')}</option>
                                        <option value={false}>{t('categories.filter.active.value3')}</option>
                                    </Form.Select>
                                </FormGroup>
                            </Col>
                            <Col lg="3" className={"mb-3"}>
                                <FormGroup>
                                    <Form.Label>{t('users.filter.search')}</Form.Label>
                                    <Form.Control type="text" {...register("search")} />
                                </FormGroup>
                            </Col>
                        </Row>
                        <div className='d-flex flex-row gap-2'>     
                            <Button type="submit" className={"mt-3"} disabled={!permissions.interestSites?.read}>{t('categories.filter.button')}</Button>
                            <Button variant="primary" onClick={() => downloadFile()} className={"mt-3"} disabled={!permissions.interestSites?.read}>{t("general.download")}</Button>
                        </div>
                    </Form>
                    <hr />
                    <Table responsive>
                        <thead>
                            <tr>
                                <TableHeaderSortComponent scope={"col"} text={t('sitesInterest.list.fields.reference')} column={"internalReference"} sortColumn={sortColumn} setSortColumn={setSortColumn} />
                                <TableHeaderSortComponent scope={"col"} text={t('sitesInterest.list.fields.name')} column={"name"} sortColumn={sortColumn} setSortColumn={setSortColumn} />
                                <TableHeaderSortComponent scope={"col"} text={t('sitesInterest.list.fields.mainCategory')} column={"mainCategory"} sortColumn={sortColumn} setSortColumn={setSortColumn} />
                                <TableHeaderSortComponent scope={"col"} text={t('sitesInterest.list.fields.place')} column={"place"} sortColumn={sortColumn} setSortColumn={setSortColumn} />
                                <TableHeaderSortComponent scope={"col"} text={t('sitesInterest.list.fields.creationDate')} column={"creationDate"} sortColumn={sortColumn} setSortColumn={setSortColumn} />
                                <TableHeaderSortComponent scope={"col"} text={t('sitesInterest.list.fields.website')} column={"webSiteUrl"} sortColumn={sortColumn} setSortColumn={setSortColumn} />
                                <TableHeaderSortComponent scope={"col"} text={t('sitesInterest.list.fields.bookingUrl')} column={"bookingUrl"} sortColumn={sortColumn} setSortColumn={setSortColumn} />
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {interestSites?.length > 0 ? interestSites.map((site, index) => (
                                <tr key={index}>
                                    <td>{site.internalReference}</td>
                                    <td>
                                        <div className="d-flex flex-row flex-nowrap">
                                            {Object.values(site.name).map((translation, i) =>
                                                <div key={i} className="me-2">
                                                    <Image src={translation.language.icon} className="me-2 flags-img-sm" />
                                                    {i + 1 === Object.values(site.name).length ? translation.value : (translation.value === null ? "" : translation.value) + ' | '}
                                                </div>
                                            )}
                                        </div>
                                    </td>
                                    <td>{site.mainCategory.name['es'].value}</td>
                                    <td>{site.place}</td>
                                    <td>{UseFormatDate(site.creationDate)}</td>
                                    <td>{site.webSiteUrl[0].value ? <a href={site.webSiteUrl[0].value} target="_blank" rel="noreferrer" className="ms-4"><FontAwesomeIcon icon="eye" /></a> : <FontAwesomeIcon className="ms-4" icon="eye-slash" />}</td>
                                    <td>{site.bookingUrl[0].value ? <a href={site.bookingUrl[0].value} target="_blank" rel="noreferrer" className="ms-4"><FontAwesomeIcon icon="eye" /></a> : <FontAwesomeIcon className="ms-4" icon="eye-slash" />}</td>
                                    {/* <td>{category.active ? 'Si' : 'No'}</td> */}
                                    <td className={"text-center"}>
                                        <Button variant="link" size="sm" className="ms-2" to={`/site-interest/${site.id}`} as={Link} disabled={!permissions.interestSites?.read}>
                                            <FontAwesomeIcon icon="edit" />
                                        </Button>
                                        <Button variant="link" size="sm" className="text-danger" onClick={() => deleteInterestSite(site.id)} disabled={!permissions.interestSites?.delete}>
                                            <FontAwesomeIcon icon="trash" />
                                        </Button>
                                    </td>
                                </tr>
                            )) :
                                <tr>
                                    <td colSpan={7}>{t('general.empty')}</td>
                                </tr>
                            }
                        </tbody>
                    </Table>
                    <PaginationComponent pagination={pagination}
                        setPagination={setPagination}
                        alwaysShown={interestSites?.length > 0} />
                </Card.Body>
            </Card>
        </>
    )
}

export default SiteInterest