import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate, useParams } from "react-router-dom";
import DayOfTheWeekService from "../../../services/dayOfTheWeek.service";
import BodyService from "../../../services/body.service";
import { useCallback } from "react";
import { useEffect } from "react";
import DayOfTheWeekForm from "../../../components/forms/configuration/DayOfTheWeekForm";
import { Alert, Card } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import UserPermissionService from "../../../services/userPermission.service";

const DayOfTheWeekDetails = () => {
    const params = useParams();

    const navigate = useNavigate();

    const { t } = useTranslation();

    const [dayOfTheWeek, setDayOfTheWeek] = useState();

    const [translations, setTranslations] = useState([]);

    const [permissions, setPermissions] = useState({});

    const [title, setTitle] = useState("");

    const [success, setSuccess] = useState({
        update: false,
        email: false,
    });

    const [errors, setErrors] = useState({
        update: false,
    });

    const getDayOfTheWeek = useCallback(async (id) => {
        try {
            const data = await DayOfTheWeekService.getById(id);            
            setDayOfTheWeek(data);
            setTitle(data.dayOfTheWeek["es"].value);
        } catch (error) {
            navigate("/configuration/day-of-the-week");
        }
    }, [navigate]);

    const updateDayOfTheWeek = async (data) => {
        setErrors({ ...errors, update: false });
        setSuccess({ ...success, update: false });

        try {
            await DayOfTheWeekService.update(params.id, data);
            getDayOfTheWeek(params.id);
            setSuccess({ ...success, update: true });
        } catch (e) {
            setErrors({ ...errors, update: true });
        }
    };

    const getEmptyTranslations = async () => {
        const data = await BodyService.getEmptyTranslations();
        setTranslations(data);
    };

    const getPermissions = async () => {
        try {
            const data = await UserPermissionService.getPermissionsByConnected();
            setPermissions(data);
        } catch (error) {
            console.error(error);
        }
    }

    useEffect(() => {
        const loadData = async () => {
            await getPermissions();
        }

        loadData();
    }, []);

    useEffect(() => {
        getDayOfTheWeek(params.id);
        getEmptyTranslations();
    }, [getDayOfTheWeek, params.id]);

    return (
        <>
            <Alert variant={"success"} show={success.update} dismissible onClose={() => setSuccess({ ...success, update: false })}>
                {t('dayOfTheWeek.success.update')}
            </Alert>

            <Alert variant={"danger"} show={errors.update} dismissible onClose={() => setErrors({ ...errors, update: false })}>
                {t('dayOfTheWeek.error.update')}
            </Alert>

            <div className={"d-flex justify-content-between my-3"}>
                <Link to="/configuration/day-of-the-week" className={"previous round rounded-circle text-decoration-none"}
                    title={t('general.back')}>
                    <FontAwesomeIcon icon={"chevron-left"} className={"mt-2 me-2"} />
                    <span>{t('dayOfTheWeek.title')}</span>
                </Link>
            </div>
            <Card>
                <Card.Header className={"py-3"}>
                    <h1 className={"h3 mb-0 text-gray-800 card-title text-capitalize"}>{title}</h1>
                </Card.Header>
                <Card.Body>
                    <DayOfTheWeekForm onSubmit={updateDayOfTheWeek} dayOfTheWeek={dayOfTheWeek} translations={translations} permissions={permissions} details />
                </Card.Body>
            </Card>
        </>
    );
}

export default DayOfTheWeekDetails;