import { Card, Alert } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useCallback, useEffect, useState } from "react";
import UserForm from "../../../components/forms/user/UserForm";
import UserService from "../../../services/user.service";
import UserPermissionService from "../../../services/userPermission.service";

const UserDetails = () => {

    const params = useParams();

    const navigate = useNavigate();

    const { t } = useTranslation();

    const [user, setUser] = useState({});
    const [title, setTitle] = useState("");
    const [permissions, setPermissions] = useState({});

    const [success, setSuccess] = useState({
        update: false,
        email: false,
    });

    const [errors, setErrors] = useState({
        update: false,
    });

    const getUser = useCallback(async (id) => {
        try {
            const data = await UserService.getById(id);
            setUser(data);
            setTitle(data.firstName + " " + data.lastName);
        } catch (e) {
            navigate("/user");
        }
    }, [navigate]);

    const getPermissions = async () => {
        try {
            const data = await UserPermissionService.getPermissionsByConnected();
            setPermissions(data);
        } catch (error) {
            console.error(error);
        }
    }

    useEffect(() => {
        const loadData = async () => {
            await getPermissions();
        }

        loadData();
    }, []);

    useEffect(() => {
        getUser(params.id);
    }, [getUser, params.id]);

    const updateUser = async (data) => {
        setErrors({...errors, update: false});
        setSuccess({...success, update: false});
        try {
            await UserService.update(params.id, data);
            setSuccess({...success, update: true});
        } catch (e) {
            setErrors({...errors, update: true});
        }
    };

    return (
        <>
            <div className={"d-flex justify-content-between my-3"}>
                <Link to="/user" className={"previous round rounded-circle text-decoration-none"}
                    title={t('general.back')}>
                    <FontAwesomeIcon icon={"chevron-left"} className={"mt-2 me-2"} />
                    <span>{t('users.title')}</span>
                </Link>
            </div>
            <Alert variant={"success"} show={success.update} dismissible onClose={() => setSuccess({...success, update: false})}>
                {t('users.success.update')}
            </Alert>
            <Alert variant={"danger"} show={errors.update} dismissible onClose={() => setErrors({...errors, update: false})}>
                {t('users.error.update')}
            </Alert>
            <Card>
                <Card.Header className={"py-3"}>
                    <h1 className={"h3 mb-0 text-gray-800 card-title"}>{title}</h1>
                </Card.Header>
                <Card.Body>
                    <UserForm onSubmit={updateUser} user={user} permissions={permissions} details />
                </Card.Body>
            </Card>
        </>
    );
}

export default UserDetails;
