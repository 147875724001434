import { t } from "i18next";
import { Button, Col, Form, FormCheck, FormGroup, Row } from "react-bootstrap"
import TranslationInputLanguage from "../fields/TranslationInputLanguage";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect } from "react";

const ProvinceForm = ({ onSubmit, details, translations, province, permissions }) => {

    const [provinceNameValues, setProvinceNameValues] = useState();

    const [translationValues, setTranslationValues] = useState();

    const { register, handleSubmit, formState, reset } = useForm({
        mode: "all",
        defaultValues: {
            active: !details,
        },
    });

    const setProvinceNameValuesForm = (iso, value, id) => {
        setTranslationValues({
            ...translationValues, [iso]: { value: value, langId: id, iso: iso }
        })
    };

    const onSubmitForm = async (data) => {
        let objSend = {
            name: [],
            active: data.active
        }

        Object.values(translationValues).forEach((name) => {
            objSend.name.push({
                language: {
                    id: name.langId
                },
                value: name.value
            })
        })

        try {
            await onSubmit(objSend);
            if (!details) {
                setTranslationValues(null);
                reset();
            }
        } catch (error) {
            console.error(error)
        }

        setTimeout(function () {
            window.scrollTo(0, 0);
        }, 0);
    };   

    useEffect(() => {
        setProvinceNameValues(translations);

        if (details && province) {
                       
            let provinceLang = {}

            Object.keys(province.name).forEach((data) => {
                provinceLang[data] = {
                    value: province.name[data].value,
                    langId: province.name[data].language.id,
                    iso: province.name[data].language.iso,
                }});
            
            setTranslationValues(provinceLang);
        }

    }, [translations, province, details]);

    useEffect(() => {
        if (details) {
            reset(province);
        }
    }, [province, details, reset]);

    return (
        <Form onSubmit={handleSubmit(onSubmitForm)}>
            <Row>
                <Col xs={12}>
                    <h5>{t('province.creation.fields.name')}</h5>
                    {translations?.length > 0 ?
                        <Row className="mt-3">
                            <TranslationInputLanguage translations={provinceNameValues} data={translationValues} onChange={setProvinceNameValuesForm} />
                        </Row>
                        : <></>
                    }
                </Col>
            </Row>
            <Row>
                <Col xs="12" className="mt-md-3">
                    <FormGroup>
                        <FormCheck label={t('province.creation.fields.active')} {...register("active")} />
                    </FormGroup>
                </Col>
            </Row>
            {details ? 
            <Button type="submit" className={"mt-4"} disabled={ permissions.provinces?.update === false ? true : !formState.isValid }>
                    <FontAwesomeIcon icon={"save"} /> 
            </Button>
            : 
            <Button type="submit" className={"mt-4"} disabled={ permissions.provinces?.create === false ? true : !formState.isValid }>
                    {t('province.creation.button')}
            </Button>            
            }
        </Form>
    );
};

export default ProvinceForm;