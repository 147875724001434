import { Navigate, Route, Router, Routes } from "react-router-dom";
import React from "react";
import { usePromiseTracker } from "react-promise-tracker";

import Dashboard from "./pages/dashboard/Dashboard";
import Login from "./pages/login/Login";
import Main from "./pages/main/Main";
import User from "./pages/user/User";
import UserDetails from "./pages/user/details/UserDetails";
import Language from "./pages/language/Language";
import LanguageDetails from "./pages/language/details/LanguageDetails";
import Category from "./pages/category/Category";
import CategoryDetails from "./pages/category/details/CategoryDetails";
import Tag from "./pages/tag/Tag";
import TagDetails from "./pages/tag/details/TagDetails";

import './scss/index.scss';
import { Spinner } from "react-bootstrap";
import Article from "./pages/article/Article";
import ArticleDetails from "./pages/article/details/ArticleDetails";
import GuideImages from "./pages/guideImages/GuideImages";
import GuideImagesDetails from "./pages/guideImages/details/GuideImagesDetails";
import Project from "./pages/project/Project";
import ProjectDetails from "./pages/project/details/ProjectDetails";
import SiteInterest from "./pages/siteInterest/SiteInterest";
import SiteInterestDetails from "./pages/siteInterest/details/SiteInterestDetails";
import Stamps from "./pages/stamps/Stamps";
import StampsDetails from "./pages/stamps/details/StampsDetails";
import MiniGuide from "./pages/miniGuides/MiniGuide";
import MiniGuideDetails from "./pages/miniGuides/details/MiniGuideDetails";
import ChargerType from "./pages/configuration/chargertype/ChargerType";
import ChargerTypeDetails from "./pages/configuration/chargertype/details/ChargerTypeDetails";
import Province from "./pages/configuration/province/Province";
import ProvinceDetails from "./pages/configuration/province/details/ProvinceDetails";
import Region from "./pages/configuration/region/Region";
import RegionDetails from "./pages/configuration/region/details/RegionDetails";
import Locality from "./pages/configuration/locality/Locality";
import LocalityDetails from "./pages/configuration/locality/details/LocalityDetails";
import DayOfTheWeek from "./pages/configuration/dayOfTheWeek/DayOfTheWeek";
import DayOfTheWeekDetails from "./pages/configuration/dayOfTheWeek/DayOfTheWeekDetails";
import PointType from "./pages/configuration/pointType/PointType";
import PointTypeDetails from "./pages/configuration/pointType/details/PointTypeDetails";
import UbicationType from "./pages/configuration/ubicationType/UbicationType";
import UbicationTypeDetails from "./pages/configuration/ubicationType/details/UbicationTypeDetails";
import ChargingPoint from "./pages/chargingPoint/ChargingPoint";
import ChargingPointDetails from "./pages/chargingPoint/details/ChargingPointDetails";
import Promotion from "./pages/promotion/Promotion";
import Sections from "./pages/section/Sections";
import PromotionDetails from "./pages/promotion/detail/PromotionDetails";
import SectionsDetails from "./pages/section/detail/SectionsDetails";
import Author from "./pages/author/Author";
import AuthorDetails from "./pages/author/details/AuthorDetails";
import Highlighted from "./pages/highlighted/Highlighted";
import Monitor from "./pages/monitor/Monitor";
import Analytic from "./pages/analytic/Analytic";
import UserAnalytic from "./pages/userAnalytic/UserAnalytic";
import UserPermission from "./pages/userPermission/UserPermission";
import UserPermissionDetails from "./pages/userPermission/details/UserPermissionDetails";
import HomeHeadline from "./pages/homeHeadline/HomeHeadline";

const App = () => {

    const { promiseInProgress } = usePromiseTracker();

    return (
        <>
            <Routes>
                <Route path="/login" element={<Login />} />
                <Route path="/" element={<Main />}>
                    <Route path="" element={<Dashboard />} />
                    <Route path="user" element={<User />} />
                    <Route path="user/:id" element={<UserDetails />} />
                    <Route path="language" element={<Language />} />
                    <Route path="language/:id" element={<LanguageDetails />} />
                    <Route path="category" element={<Category />} />
                    <Route path="category/:id" element={<CategoryDetails />} />
                    <Route path="tag" element={<Tag />} />
                    <Route path="tag/:id" element={<TagDetails />} />
                    <Route path="article" element={<Article />} />
                    <Route path="article/:id" element={<ArticleDetails />} />
                    <Route path="guide-images" element={<GuideImages />} />
                    <Route path="guide-images/:id" element={<GuideImagesDetails />} />
                    <Route path="project" element={<Project />} />
                    <Route path="project/:id" element={<ProjectDetails />} />
                    <Route path="site-interest" element={<SiteInterest />} />
                    <Route path="site-interest/:id" element={<SiteInterestDetails />} />
                    <Route path="stamp" element={<Stamps />} />
                    <Route path="stamp/:id" element={<StampsDetails />} />
                    <Route path="mini-guide/" element={<MiniGuide />} />
                    <Route path="mini-guide/:id" element={<MiniGuideDetails />} />
                    <Route path="charging-point" element={<ChargingPoint />} />
                    <Route path="charging-point/:id" element={<ChargingPointDetails />} />
                    <Route path="configuration/charger-type" element={<ChargerType />} />
                    <Route path="configuration/charger-type/:id" element={<ChargerTypeDetails />} />
                    <Route path="configuration/province" element={<Province />} />
                    <Route path="configuration/province/:id" element={<ProvinceDetails />} />
                    <Route path="configuration/region" element={<Region />} />
                    <Route path="configuration/region/:id" element={<RegionDetails />} />
                    <Route path="configuration/locality" element={<Locality />} />
                    <Route path="configuration/locality/:id" element={<LocalityDetails />} />
                    <Route path="configuration/day-of-the-week" element={<DayOfTheWeek />} />
                    <Route path="configuration/day-of-the-week/:id" element={<DayOfTheWeekDetails />} />
                    <Route path="configuration/point-type" element={<PointType />} />
                    <Route path="configuration/point-type/:id" element={<PointTypeDetails />} />
                    <Route path="configuration/ubication-type" element={<UbicationType />} />
                    <Route path="configuration/ubication-type/:id" element={<UbicationTypeDetails />} />
                    <Route path="promotion" element={<Promotion />} />
                    <Route path="promotion/:id" element={<PromotionDetails />} />
                    <Route path="section" element={<Sections />} />
                    <Route path="section/:id" element={<SectionsDetails />} />
                    <Route path="author" element={<Author/>}/>
                    <Route path="author/:id" element={<AuthorDetails />} />
                    <Route path="highlighted" element={<Highlighted/>}/>
                    <Route path="monitor" element={<Monitor/>}/>
                    <Route path="analytic" element={<Analytic/>}/>
                    <Route path="user-analytic" element={<UserAnalytic/>}/>
                    <Route path="user-permission" element={<UserPermission />} />
                    <Route path="user-permission/:id" element={<UserPermissionDetails />} />
                    <Route path="home-headline" element={<HomeHeadline/>}/>
                </Route>
                <Route path="*" element={<Navigate to="/" />} />
            </Routes>
            {promiseInProgress &&
                <div className="spinner-overlay d-flex justify-content-center align-items-center">
                    <Spinner animation="border" className="spinner-center" variant="light" size="xl" role="status" />
                </div>
            }
        </>
    );
};

export default App;
