import { Accordion, Card, Alert } from "react-bootstrap";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import HomeHeadlineService from "../../services/homeHeadline.service";
import UserPermissionService from "../../services/userPermission.service";
import BodyService from "../../services/body.service";

import HomeHeadlineForm from "../../components/forms/HomeHeadlineForm";

const HomeHeadline = () => {
    const { t } = useTranslation();

    const [success, setSuccess] = useState({
        create: false,
        delete: false,
    });
    const [errors, setErrors] = useState({
        create: false,
        word_length: false,
        delete: false,
    });

    const [homeHeadline, setHomeHeadline] = useState();
    const [languages, setLanguages] = useState();
    const [permissions, setPermissions] = useState({});

    const getPermissions = async () => {
        try {
            const data = await UserPermissionService.getPermissionsByConnected();
            setPermissions(data);
        } catch (error) {
            console.error(error);
        }
    }

    const getEmptyTranslations = async () => {
        const data = await BodyService.getEmptyTranslations();
        setLanguages(data);
    }

    useEffect(() => {
        const loadData = async () => {
            await getEmptyTranslations();
            await getPermissions();
            await getHomeHeadline();
        }
        
        loadData();
    }, []);

    const getHomeHeadline = async () => {
        try {
            const data = await HomeHeadlineService.getHomeHeadline();                      
            setHomeHeadline(data);            
        } catch ({ response }) {
            if (response?.data?.errorCode) {
                switch (response.data.errorCode) {
                    default:
                        setErrors({ ...errors, create: true });
                }
            }
        }
    }

    const saveHomeHeadline = async (data) => {
        setErrors({ ...errors, create: false, word_length: false  });
        setSuccess({ ...success, create: false });
        try {
            await HomeHeadlineService.save(data);

            setSuccess({ ...success, create: true });

            await getHomeHeadline();
        } catch (e) {
            if (e?.response?.data?.errorCode) {
                switch (e.response.data.errorCode) {
                    case 1700:
                        setErrors({...errors, word_length: true});
                        break;
                    default:
                        setErrors({...errors, create: true});
                }
            }
        }
    };

    return (
        <>
            <div className={"d-sm-flex align-items-center justify-content-between mb-4"}>
                <h1 className={"h3 mb-0 text-gray-800"}>{t('homeHeadline.title')}</h1>
            </div>

            <Alert variant={"success"} show={success.create} dismissible onClose={() => setSuccess({ ...success, create: false })}>
                {t('homeHeadline.success.create')}
            </Alert>

            <Alert variant={"danger"} show={errors.create} dismissible onClose={() => setErrors({ ...errors, create: false })}>
                {t('homeHeadline.error.create')}
            </Alert>

            <Alert variant={"danger"} show={errors.word_length} dismissible onClose={() => setErrors({ ...errors, word_length: false })}>
                {t('homeHeadline.error.wordLength')}
            </Alert>

            <Alert variant={"success"} show={success.delete} dismissible onClose={() => setSuccess({ ...success, delete: false })}>
                {t('homeHeadline.success.delete')}
            </Alert>

            <Alert variant={"danger"} show={errors.delete} dismissible onClose={() => setErrors({ ...errors, delete: false })}>
                {t('homeHeadline.error.delete')}
            </Alert>

            <Card className={"shadow mb-4"}>
                <Accordion defaultActiveKey="0">
                    <Accordion.Item eventKey="0">
                        <Accordion.Header><h6 className={"m-0 fw-bold text-primary"}>{t('homeHeadline.form.title')}</h6>
                        </Accordion.Header>
                        <Accordion.Body>
                            <HomeHeadlineForm onSubmit={saveHomeHeadline} homeHeadline={homeHeadline} languages={languages} permissions={permissions}/>
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>
            </Card>
        </>
    );
}

export default HomeHeadline