import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Link} from 'react-router-dom';
import Nav from 'react-bootstrap/Nav';

const SidebarLink = ({icon, url, text, collapsed, dropdown}) => {
    return (
        <Nav.Item>
            <Nav.Link to={url} eventKey={url} as={Link} className={dropdown && "nav-link dropDown-item pb-3"}>
                <FontAwesomeIcon icon={icon}/>
                <span className={`${!collapsed && "ms-2"}`}>{text}</span>
            </Nav.Link>
        </Nav.Item>
    );
}

export default SidebarLink;
