import { Col, Form, FormGroup } from "react-bootstrap";

const TranslationInput = ({ translations, onChange, imgTitleIndex, refer, disable }) => {
    return (
        Object.values(translations)?.map((translation, index) => (
            <Col md="auto" key={index}>
                <FormGroup>
                    <Form.Label>{translation?.language?.name}</Form.Label>
                    <Form.Control type="text"
                        value={translation?.value || ""}
                        disabled={disable?.[translation?.language?.iso]}
                        onChange={(e) => { !disable?.[translation?.language?.iso] && onChange(index, e.target.value, imgTitleIndex) }}
                    />
                </FormGroup>
            </Col>
        )));
}

export default TranslationInput;