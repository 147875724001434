import { t } from "i18next";
import { Button, Col, Form, FormGroup, Image, Row } from "react-bootstrap";
import BodyService from "../../../services/body.service";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const ArticleImagesForm = ({ totalImages, articleImages, articleListImage, onChange }) => {

    let createForm = []

    const setData = async (data, ref, target) => {
        if (data) {
            let base = await setImageToBase64(data)
            let obj = {
                url: base,
                type: data?.type
            }
            onChange(obj, ref, target);
        }
    }

    const setDataAuthor = async (data, ref, target) => {
        onChange(data, ref, target);
    }

    const setImageToBase64 = async (file, ref) => {
        if (file) {
            let base64 = await BodyService.getBase64(file);
            // base64 = base64.split(',')[1]
            return base64;
        }
    }

    const deleteImage = async (ref, target) => {
        let obj = {
            url: ""
        }
        onChange(obj, ref, target);
    }

    if (totalImages) {
        for (let i = 0; i < totalImages; i++) {
            createForm.push(`img${i}`)
        }
    }

    return (
        <Form>
            <Row>
                <Col md={6}>
                    {!totalImages && articleImages ?
                        Object.keys(articleImages).map((image, i) =>
                            <FormGroup key={i} className="mb-3 ">
                                <Row>
                                    <Col md={6}>
                                        <Form.Label>Bloque {i + 1}</Form.Label>
                                        <Form.Control type="file" accept="image/png, image/jpeg" onChange={(e) => setData(e.target.files[0], [`img${i}`], 'imgData', true)} />
                                    </Col>
                                    <Col md={5}>
                                        <Form.Label>{t('articles.creation.general.author.title')}</Form.Label>
                                        <Form.Control type="text"
                                            value={articleImages[image]?.author || ''}
                                            onChange={(e) => setDataAuthor(e.target.value, [`img${i}`], 'author')} />
                                    </Col>
                                    <Col md={1} className="d-flex align-items-end">
                                        <Button variant="link" className="border-primary" onClick={(e) => { deleteImage([`img${i}`], 'imgData', true) }}>
                                            <FontAwesomeIcon icon="trash" />
                                        </Button>
                                    </Col>
                                </Row>
                                {articleImages?.[image]?.imgData?.url &&
                                    <Row>
                                        <Col className="col-8 col-md-6 col-lg-3">
                                            <Image fluid thumbnail className="mt-4" src={articleImages[image]?.imgData?.url} alt={articleImages[image].author} />
                                        </Col>
                                    </Row>
                                }
                            </FormGroup>
                        ) : createForm.map((block, i) =>
                            <FormGroup key={i} className="mb-3 ">
                                <Row>
                                    <Col md={6}>
                                        <Form.Label>{`${t('articles.creation.images.image')} ${i + 1}`}</Form.Label>
                                        <Form.Control type="file" accept="image/png, image/jpeg" onChange={(e) => setData(e.target.files[0], [block], 'imgData', true)} />
                                    </Col>
                                    <Col md={6}>
                                        <Form.Label>{t('articles.creation.general.author.title')}</Form.Label>
                                        <Form.Control type="text"
                                            value={articleImages?.[block]?.author || ""}
                                            onChange={(e) => setDataAuthor(e.target.value, [block], 'author')} />
                                    </Col>
                                </Row>
                                {articleImages?.[block]?.imgData?.url &&
                                    <Row>
                                        <Col className="col-8 col-md-6 col-lg-5">
                                            <Image fluid thumbnail className="mt-4" src={articleImages?.[block]?.imgData?.url} alt={articleImages?.[block]?.author} />
                                        </Col>
                                    </Row>
                                }
                            </FormGroup>
                        )}
                    <FormGroup>
                        <Row>
                            <Col md={6}>
                                <Form.Label>{t('articles.creation.images.listImage')}</Form.Label>
                                <Form.Control type="file" accept="image/png, image/jpeg"
                                    onChange={(e) => setData(e.target.files[0], null, null)} />
                            </Col>
                            {
                                !totalImages && articleListImage ?
                                    <Col md={1} className="d-flex align-items-end">
                                        <Button variant="link" className="border-primary" onClick={(e) => { deleteImage(null, null) }}>
                                            <FontAwesomeIcon icon="trash" />
                                        </Button>
                                    </Col> : null
                            }
                        </Row>
                        {articleListImage?.url ?
                            <Row>
                                <Col className="col-8 col-md-6 col-lg-3">
                                    <Image fluid thumbnail className="mt-4" src={articleListImage?.url} />
                                </Col>
                            </Row> : null
                        }
                    </FormGroup>
                </Col>
            </Row>
        </Form>
    );
}

export default ArticleImagesForm;
