import {Button, Card, Form, Col, Row, Accordion, Image} from "react-bootstrap";

import { useTranslation } from "react-i18next";
import React, {useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import UseFormatDate from "../../../hooks/UseFormatDate";

import './UserPermissionForm.css'; 

const UserPermissionForm = ({ onSubmit, user, userPerm, details }) => {

    const { t } = useTranslation();

    const { handleSubmit, reset } = useForm({
        mode: "all",
        defaultValues: 
        {
          permissions: {
              articles: {read: false, create: false, update: false, delete: false},
              authors: {read: false, create: false, update: false, delete: false},
              interestSites: {read: false, create: false, update: false, delete: false},
              miniGuides: {read: false, create: false, update: false, delete: false},
              categories: {read: false, create: false, update: false, delete: false},
              guideImages: {read: false, create: false, update: false, delete: false},
              stamps: {read: false, create: false, update: false, delete: false},
              chargingPoints: {read: false, create: false, update: false, delete: false},
              pointTypes: {read: false, create: false, update: false, delete: false},
              locationTypes: {read: false, create: false, update: false, delete: false},
              chargerTypes: {read: false, create: false, update: false, delete: false},
              projects: {read: false, create: false, update: false, delete: false},
              tags: {read: false, create: false, update: false, delete: false},
              provinces: {read: false, create: false, update: false, delete: false},
              regions: {read: false, create: false, update: false, delete: false},
              localities: {read: false, create: false, update: false, delete: false},
              weekDays: {read: false, create: false, update: false, delete: false},
              languages: {read: false, create: false, update: false, delete: false},
              promotions: {read: false, create: false, update: false, delete: false},
              sections: {read: false, create: false, update: false, delete: false},
              highlights: {read: false, create: false, update: false, delete: false},
              homeHeadlines: {read: false, create: false, update: false, delete: false},
              users: {read: false, create: false, update: false, delete: false},
              userPermissions: {read: false, create: false, update: false, delete: false},
              monitor: {read: false, create: false, update: false, delete: false},
              analyticBookings: {read: false, create: false, update: false, delete: false},
              analyticUsers: {read: false, create: false, update: false, delete: false}
          }
        },
    });

    const [permissions, setPermissions] = useState({
      articles: {read: false, create: false, update: false, delete: false},
      authors: {read: false, create: false, update: false, delete: false},
      interestSites: {read: false, create: false, update: false, delete: false},
      miniGuides: {read: false, create: false, update: false, delete: false},
      categories: {read: false, create: false, update: false, delete: false},
      guideImages: {read: false, create: false, update: false, delete: false},
      stamps: {read: false, create: false, update: false, delete: false},

      chargingPoints: {read: false, create: false, update: false, delete: false},
      pointTypes: {read: false, create: false, update: false, delete: false},
      locationTypes: {read: false, create: false, update: false, delete: false},
      chargerTypes: {read: false, create: false, update: false, delete: false},

      projects: {read: false, create: false, update: false, delete: false},

      tags: {read: false, create: false, update: false, delete: false},
      provinces: {read: false, create: false, update: false, delete: false},
      regions: {read: false, create: false, update: false, delete: false},
      localities: {read: false, create: false, update: false, delete: false},
      weekDays: {read: false, create: false, update: false, delete: false},
      languages: {read: false, create: false, update: false, delete: false},
      promotions: {read: false, create: false, update: false, delete: false},
      sections: {read: false, create: false, update: false, delete: false},
      highlights: {read: false, create: false, update: false, delete: false},
      homeHeadlines: {read: false, create: false, update: false, delete: false},

      users: {read: false, create: false, update: false, delete: false},
      userPermissions: {read: false, create: false, update: false, delete: false},

      monitor: {read: false, create: false, update: false, delete: false},
      analyticBookings: {read: false, create: false, update: false, delete: false},
      analyticUsers: {read: false, create: false, update: false, delete: false}
    });

    const [parentMap, setParentMap] = useState({
      contentGuide: {all: false, read: false, create: false, update: false, delete: false},
      chargingPoint: {all: false, read: false, create: false, update: false, delete: false},
      project: {all: false, read: false, create: false, update: false, delete: false},
      generalIndex: {all: false, read: false, create: false, update: false, delete: false},
      users: {all: false, read: false, create: false, update: false, delete: false},
      stadistics: {all: false, read: false, create: false, update: false, delete: false}
    });

    const updatePermissionsForResources = (value, resourceList, updatedPermissions) => {
      if (resourceList.length === 0) {
        for (const resource in updatedPermissions) {
          updatedPermissions[resource] = {
              read: value,
              create: value,
              update: value,
              delete: value
          };
        }
      } else {
        resourceList.forEach(resource => {
          updatedPermissions[resource] = {
              read: value,
              create: value,
              update: value,
              delete: value
          };
        });
      }
  
      return updatedPermissions;
  }  

    const setTogglePermissions = (area, permission, parent) => {
      let data = {...permissions};
      data[area][permission] = !data[area][permission];

      let parentData = {...parentMap};

      if (parent === "contentGuide") {
        parentData[parent][permission] = data.articles[permission] && data.authors[permission] && 
            data.miniGuides[permission] && data.categories[permission] && data.guideImages[permission] && 
            data.stamps[permission] && data.interestSites[permission];
      }

      if (parent === "chargingPoint") {
        parentData[parent][permission] = data.chargingPoints[permission] && data.pointTypes[permission] && 
            data.chargerTypes[permission] && data.locationTypes[permission];
      }

      if (parent === "project") {
        parentData[parent][permission] = data.projects[permission];
      }

      if (parent === "generalIndex") {
        parentData[parent][permission] = data.languages[permission] && data.weekDays[permission] && 
            data.localities[permission] && data.regions[permission] && data.provinces[permission] && 
            data.tags[permission] && data.highlights[permission] && data.sections[permission] && 
            data.promotions[permission] && data.homeHeadlines[permission];
      }

      if (parent === "users") {
        parentData[parent][permission] = data.users[permission] && data.userPermissions[permission];
      }

      if (parent === "stadistics") {
        parentData[parent][permission] = data.monitor[permission] && data.analyticBookings[permission] && 
            data.analyticUsers[permission];
      }

      parentData[parent]["all"] = parentData[parent]["read"] && parentData[parent]["create"] 
      && parentData[parent]["update"] && parentData[parent]["delete"];

      setParentMap(parentData);
      setPermissions(data);
    };

    const setToggleParentPermission = (area, permission) => {
      let data = {...parentMap};

      data[area][permission] = !data[area][permission];

      if (permission !== "all") {
        data[area]["all"] = data[area]["read"] && data[area]["create"] && data[area]["update"] && data[area]["delete"];
      }

      if (area === "contentGuide") {
        let childrenData = {...permissions};
        
        if (permission === "all") {
          data[area]["read"] = data[area][permission];
          data[area]["create"] = data[area][permission];
          data[area]["update"] = data[area][permission];
          data[area]["delete"] = data[area][permission];

          childrenData = updatePermissionsForResources(data[area][permission], 
            ["articles", "authors", "interestSites", "categories", "miniGuides", "guideImages", "stamps"], 
            childrenData);

        } else {
          childrenData.articles[permission] = data[area][permission];
          childrenData.authors[permission] = data[area][permission];
          childrenData.interestSites[permission] = data[area][permission];
          childrenData.categories[permission] = data[area][permission];
          childrenData.miniGuides[permission] = data[area][permission];
          childrenData.guideImages[permission] = data[area][permission];
          childrenData.stamps[permission] = data[area][permission];
        }

        setPermissions(childrenData);
      }

      if (area === "chargingPoint") {
        let childrenData = {...permissions};

        if (permission === "all") {
          data[area]["read"] = data[area][permission];
          data[area]["create"] = data[area][permission];
          data[area]["update"] = data[area][permission];
          data[area]["delete"] = data[area][permission];

          childrenData = updatePermissionsForResources(data[area][permission], 
            ["chargingPoints", "pointTypes", "chargerTypes", "locationTypes"], 
            childrenData);

        } else {
          childrenData.chargingPoints[permission] = data[area][permission];
          childrenData.pointTypes[permission] = data[area][permission];
          childrenData.chargerTypes[permission] = data[area][permission];
          childrenData.locationTypes[permission] = data[area][permission];
        }

        setPermissions(childrenData);
      }

      if (area === "project") {
        let childrenData = {...permissions};

        if (permission === "all") {
          data[area]["read"] = data[area][permission];
          data[area]["create"] = data[area][permission];
          data[area]["update"] = data[area][permission];
          data[area]["delete"] = data[area][permission];

          childrenData = updatePermissionsForResources(data[area][permission], 
            ["projects"], 
            childrenData);

        } else {
          childrenData.projects[permission] = data[area][permission];
        }

        setPermissions(childrenData);
      }

      if (area === "generalIndex") {
        let childrenData = {...permissions};

        if (permission === "all") {
          data[area]["read"] = data[area][permission];
          data[area]["create"] = data[area][permission];
          data[area]["update"] = data[area][permission];
          data[area]["delete"] = data[area][permission];

          childrenData = updatePermissionsForResources(data[area][permission], 
            ["tags", "provinces", "regions", "localities", "weekDays", "languages", "promotions", "sections", "highlights", "homeHeadlines"], 
            childrenData);
          
        } else {
          childrenData.tags[permission] = data[area][permission];
          childrenData.provinces[permission] = data[area][permission];
          childrenData.regions[permission] = data[area][permission];
          childrenData.localities[permission] = data[area][permission];
          childrenData.weekDays[permission] = data[area][permission];
          childrenData.languages[permission] = data[area][permission];
          childrenData.promotions[permission] = data[area][permission];
          childrenData.sections[permission] = data[area][permission];
          childrenData.highlights[permission] = data[area][permission];
        }

        setPermissions(childrenData);
      }

      if (area === "users") {
        let childrenData = {...permissions};

        if (permission === "all") {
          data[area]["read"] = data[area][permission];
          data[area]["create"] = data[area][permission];
          data[area]["update"] = data[area][permission];
          data[area]["delete"] = data[area][permission];

          childrenData = updatePermissionsForResources(data[area][permission], 
            ["users", "userPermissions"], 
            childrenData);
          
        }
        else {
          childrenData.users[permission] = data[area][permission];
          childrenData.userPermissions[permission] = data[area][permission];
        }

        setPermissions(childrenData);
      }

      if (area === "stadistics") {
        let childrenData = {...permissions};

        if (permission === "all") {
          data[area]["read"] = data[area][permission];
          data[area]["create"] = data[area][permission];
          data[area]["update"] = data[area][permission];
          data[area]["delete"] = data[area][permission];

          childrenData = updatePermissionsForResources(data[area][permission], 
            ["analyticUsers", "analyticBookings", "monitor"], 
            childrenData);
          
        } else {
          childrenData.analyticUsers[permission] = data[area][permission];
          childrenData.analyticBookings[permission] = data[area][permission];
          childrenData.monitor[permission] = data[area][permission];
        }

        setPermissions(childrenData);
      }

      setParentMap(data);
    }

    const convertPermissions = (permissionsData) => {
      const permissionsObject = {};
  
      for (const resource in permissionsData) {
        if (permissionsData[resource].length > 0) {
          permissionsObject[resource] = {
              read: permissionsData[resource].includes(0),
              create: permissionsData[resource].includes(1),
              update: permissionsData[resource].includes(2),
              delete: permissionsData[resource].includes(3)
          };
        } else {
          permissionsObject[resource] = {
              read: false,
              create: false,
              update: false,
              delete: false
          };
        }
      }
  
      return permissionsObject;
    }

    const convertParentPermissions = (data) => {
        let parentData = {...parentMap};

        const contentGuide = ["articles", "authors", "interestSites", "categories", "miniGuides", "guideImages", "stamps"];
        const chargingPoint = ["chargingPoints", "pointTypes", "chargerTypes", "locationTypes"];
        const project = ["projects"];
        const generalIndex = ["tags", "provinces", "regions", "localities", "weekDays", "languages", "promotions", "sections", "highlights", "homeHeadlines"];
        const users = ["users", "userPermissions"];
        const stadistics = ["analyticUsers", "analyticBookings", "monitor"];
        
        const permissionTypes = ["read", "create", "update", "delete"];

        permissionTypes.forEach(permission => {
            parentData.contentGuide[permission] = contentGuide.every(resource => data[resource][permission]);
            parentData.chargingPoint[permission] = chargingPoint.every(resource => data[resource][permission]);
            parentData.project[permission] = project.every(resource => data[resource][permission]);
            parentData.generalIndex[permission] = generalIndex.every(resource => data[resource][permission]);
            parentData.users[permission] = users.every(resource => data[resource][permission]);
            parentData.stadistics[permission] = stadistics.every(resource => data[resource][permission]);
        });

        parentData.contentGuide.all = permissionTypes.every(permission => parentData.contentGuide[permission]);
        parentData.chargingPoint.all = permissionTypes.every(permission => parentData.chargingPoint[permission]);
        parentData.project.all = permissionTypes.every(permission => parentData.project[permission]);
        parentData.generalIndex.all = permissionTypes.every(permission => parentData.generalIndex[permission]);
        parentData.users.all = permissionTypes.every(permission => parentData.users[permission]);
        parentData.stadistics.all = permissionTypes.every(permission => parentData.stadistics[permission]);

        return parentData;
    }

    const loadPermissions = (user) => {
        let data = convertPermissions(user.permissions);
        let parentData = convertParentPermissions(data);

        setPermissions(data);
        setParentMap(parentData);
    }

    const onSubmitForm = async () => {
      try {
          const perms = {
            read: 0,
            create: 1,
            update: 2,
            delete: 3
        };
        
        const request = {};
        
        for (const resource in permissions) {
          request[resource] = [];
          for (const permission in permissions[resource]) {
              if (permissions[resource][permission]) {
                  request[resource].push(perms[permission]);
              }
          }
        }

        await onSubmit(request);

        if (!details) {
            reset();
        }
      } catch (error) {
          console.error(error)
      }

      setTimeout(function () {
          window.scrollTo(0, 0);
      }, 0);
    }

    const preventOpen = (event) => {
      event.stopPropagation();
    };

    useEffect(() => {
      if (details && user) {
        reset(user);
        if (user?.permissions) {
          loadPermissions(user);
        }
      }
    }, [user, details, reset]);

    return (
        <>
          <div className="d-flex m-1">
            <Card className="w-25 m-2">
              <Card.Body>
                <Row className='mb-4'>
                  <Col className="text-center">
                    <div className={"mb-4"}>
                      <img className={"img-profile rounded img-fluid"}
                            src={"/img/traveltozero-logo.svg"}
                            alt="Travel"
                            style={{ width: '125px', height: '125px' }}
                        />
                    </div>
                    <h2 className={"h3 mb-0 text-black-800 card-title"}>{user?.firstName + " " + user?.lastName}</h2>
 
                    {user && user.applications && user.applications?.map((app, i) =>
                      (
                      <div key={i} className="rounded app-bg-gray d-inline-block app-margin">
                        <p className="m-0 p-2 app-text">{app.name}</p>
                      </div>
                      )
                    )}
                  </Col>
                </Row>
                <Row className="mb-4 border-table">
                  <Col xl="3" lg="6">
                    <div className="d-flex align-items-center">
                      <strong>{t('userPermission.form.details')}</strong>
                    </div>
                  </Col>
                </Row>
                <Row className="mb-0">
                  <Col xl="3" lg="4">
                    <div className="d-flex align-items-center">
                      <p className="form-text form-margin">{t('userPermission.form.email')}: </p> <p className="form-margin">{user?.email}</p>
                    </div>
                  </Col>
                </Row>
                <Row className="mb-0">
                  <Col xl="3" lg="4">
                    <div className="d-flex align-items-center">
                      <p className="form-text form-margin">{t('userPermission.form.name')}: </p> <p className="form-margin">{user?.firstName}</p>
                    </div>
                  </Col>
                </Row>
                <Row className="mb-0">
                  <Col xl="8" lg="2">
                    <div className="d-flex align-items-center">
                      <p className="form-text form-margin">{t('userPermission.form.lastName')}: </p> <p className="form-margin">{user?.lastName}</p>
                    </div>
                  </Col>
                </Row>
                <Row className="mb-0">
                  <Col xl="2" lg="4">
                    <div className="d-flex align-items-center">
                      <p className="form-text form-margin">{t('userPermission.form.status')}: </p> 

                      {
                      user && user?.active ? 
                      <div className="rounded form-bg-green d-inline-block form-margin">
                        <p className="m-0 p-2 form-text form-text-color">Activo</p>
                      </div>
                      : 
                      <div className="rounded form-bg-red d-inline-block form-margin">
                        <p className="m-0 p-2 form-text form-text-color">Inactivo</p>
                      </div>
                      }
                    </div>
                  </Col>
                </Row>
                <Row className="mb-0">
                  <Col xl="3" lg="4">
                    <div className="d-flex align-items-center">
                      <p className="form-text form-margin">{t('userPermission.form.contact')}: </p> <p className="form-margin">{user?.phone}</p>
                    </div>
                  </Col>
                </Row>
                <Row className="mb-0">
                  <Col xl="3" lg="4">
                    <div className="d-flex align-items-center">
                      <p className="form-text form-margin">{t('userPermission.form.language')}: </p> 
                      
                      <div className="d-flex flex-row flex-nowrap">
                        {user && user.language ?
                            <div className="me-4">
                              <Image src={user.language.icon} className="me-4 flags-img-sm" />
                            </div>  
                        : null
                        }
                      </div>
                    </div>
                  </Col>
                </Row>
                <Row className="mb-4">
                  <Col xl="8" lg="7">
                    <div className="d-flex align-items-center">
                      <p className="form-text form-margin">{t('userPermission.form.lastAccess')}: </p> <p className="form-margin">{user?.lastAcessDate !== null ? UseFormatDate(user?.lastAcessDate) : null}</p>
                    </div>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
            <Card className="w-75">
              <Card.Body>
                <Form onSubmit={handleSubmit(onSubmitForm)}>
                  <div className="d-flex align-items-center border-table">
                    <strong className="w-25">{t('userPermission.table.type')}</strong>
                    <strong className="w-15 header-center">{t('userPermission.table.all')}</strong>
                    <strong className="w-15 header-center">{t('userPermission.table.read')}</strong>
                    <strong className="w-15 header-center">{t('userPermission.table.create')}</strong>
                    <strong className="w-15 header-center">{t('userPermission.table.update')}</strong>
                    <strong className="w-15 header-center">{t('userPermission.table.delete')}</strong>
                  </div>
                  <Accordion className="permissions__accordion">
                    <Accordion.Header className="d-flex">
                      <strong className="w-header-content">{t('userPermission.table.contentGuide.title')}</strong>
                      <Form.Check
                        className="w-15 header-chechbox"
                        onClick={(e) => preventOpen(e)}
                        onChange={() => setToggleParentPermission("contentGuide", "all")}
                        checked = {parentMap.contentGuide.all}
                      ></Form.Check>
                      <Form.Check
                        className="w-15 header-chechbox"
                        onClick={(e) => preventOpen(e)}
                        onChange={() => setToggleParentPermission("contentGuide", "read")}
                        checked = {parentMap.contentGuide.read}
                      ></Form.Check>
                      <Form.Check
                        className="w-15 header-chechbox"
                        onClick={(e) => preventOpen(e)}
                        onChange={() => setToggleParentPermission("contentGuide", "create")}
                        checked = {parentMap.contentGuide.create}
                      ></Form.Check>
                      <Form.Check
                        className="w-15 header-chechbox"
                        onClick={(e) => preventOpen(e)}
                        onChange={() => setToggleParentPermission("contentGuide", "update")}
                        checked = {parentMap.contentGuide.update}
                      ></Form.Check>
                      <Form.Check
                        className="w-15 header-chechbox"
                        onClick={(e) => preventOpen(e)}
                        onChange={() => setToggleParentPermission("contentGuide", "delete")}
                        checked = {parentMap.contentGuide.delete}
                      ></Form.Check>
                    </Accordion.Header>
                    <Accordion.Body className="d-flex flex-column">
                      <div className="d-flex flex-row w-100">
                        <span className="b-header-content">{t('userPermission.table.contentGuide.articles')}</span>
                        <span className="w-15"></span>
                        <Form.Check className="w-15 body-chechbox" checked={permissions.articles.read} onChange={() => setTogglePermissions("articles", "read", "contentGuide")}></Form.Check>
                        <Form.Check className="w-15 body-chechbox" checked={permissions.articles.create} onChange={() => setTogglePermissions("articles", "create", "contentGuide")} ></Form.Check>
                        <Form.Check className="w-15 body-chechbox" checked={permissions.articles.update} onChange={() => setTogglePermissions("articles", "update", "contentGuide")} ></Form.Check>
                        <Form.Check className="w-15 body-chechbox" checked={permissions.articles.delete} onChange={() => setTogglePermissions("articles", "delete", "contentGuide")}></Form.Check>
                      </div>
                      <div className="d-flex flex-row">
                        <span className="b-header-content">{t('userPermission.table.contentGuide.authors')}</span>
                        <span className="w-15"></span>
                        <Form.Check className="w-15 body-chechbox" checked={permissions.authors.read} onChange={() => setTogglePermissions("authors", "read", "contentGuide")}></Form.Check>
                        <Form.Check className="w-15 body-chechbox" checked={permissions.authors.create} onChange={() => setTogglePermissions("authors", "create", "contentGuide")}></Form.Check>
                        <Form.Check className="w-15 body-chechbox" checked={permissions.authors.update} onChange={() => setTogglePermissions("authors", "update", "contentGuide")}></Form.Check>
                        <Form.Check className="w-15 body-chechbox" checked={permissions.authors.delete} onChange={() => setTogglePermissions("authors", "delete", "contentGuide")}></Form.Check>
                      </div>
                      <div className="d-flex flex-row">
                        <span className="b-header-content">{t('userPermission.table.contentGuide.interestSites')}</span>
                        <span className="w-15"></span>
                        <Form.Check className="w-15 body-chechbox" checked={permissions.interestSites.read} onChange={() => setTogglePermissions("interestSites", "read", "contentGuide")} ></Form.Check>
                        <Form.Check className="w-15 body-chechbox" checked={permissions.interestSites.create} onChange={() => setTogglePermissions("interestSites", "create", "contentGuide")} ></Form.Check>
                        <Form.Check className="w-15 body-chechbox" checked={permissions.interestSites.update} onChange={() => setTogglePermissions("interestSites", "update", "contentGuide")} ></Form.Check>
                        <Form.Check className="w-15 body-chechbox" checked={permissions.interestSites.delete} onChange={() => setTogglePermissions("interestSites", "delete", "contentGuide")} ></Form.Check>
                      </div>
                      <div className="d-flex flex-row">
                        <span className="b-header-content">{t('userPermission.table.contentGuide.miniGuides')}</span>
                        <span className="w-15"></span>
                        <Form.Check className="w-15 body-chechbox" checked={permissions.miniGuides.read} onChange={() => setTogglePermissions("miniGuides", "read", "contentGuide")} ></Form.Check>
                        <Form.Check className="w-15 body-chechbox" checked={permissions.miniGuides.create} onChange={() => setTogglePermissions("miniGuides", "create", "contentGuide")} ></Form.Check>
                        <Form.Check className="w-15 body-chechbox" checked={permissions.miniGuides.update} onChange={() => setTogglePermissions("miniGuides", "update", "contentGuide")} ></Form.Check>
                        <Form.Check className="w-15 body-chechbox" checked={permissions.miniGuides.delete} onChange={() => setTogglePermissions("miniGuides", "delete", "contentGuide")} ></Form.Check>
                      </div>
                      <div className="d-flex flex-row">
                        <span className="b-header-content">{t('userPermission.table.contentGuide.categories')}</span>
                        <span className="w-15"></span>
                        <Form.Check className="w-15 body-chechbox" checked={permissions.categories.read} onChange={() => setTogglePermissions("categories", "read", "contentGuide")} ></Form.Check>
                        <Form.Check className="w-15 body-chechbox" checked={permissions.categories.create} onChange={() => setTogglePermissions("categories", "create", "contentGuide")} ></Form.Check>
                        <Form.Check className="w-15 body-chechbox" checked={permissions.categories.update} onChange={() => setTogglePermissions("categories", "update", "contentGuide")} ></Form.Check>
                        <Form.Check className="w-15 body-chechbox" checked={permissions.categories.delete} onChange={() => setTogglePermissions("categories", "delete", "contentGuide")} ></Form.Check>
                      </div>
                      <div className="d-flex flex-row">
                        <span className="b-header-content">{t('userPermission.table.contentGuide.imagesGuides')}</span>
                        <span className="w-15"></span>
                        <Form.Check className="w-15 body-chechbox" checked={permissions.guideImages.read} onChange={() => setTogglePermissions("guideImages", "read", "contentGuide")}></Form.Check>
                        <Form.Check className="w-15 body-chechbox" checked={permissions.guideImages.create} onChange={() => setTogglePermissions("guideImages", "create", "contentGuide")}></Form.Check>
                        <Form.Check className="w-15 body-chechbox" checked={permissions.guideImages.update} onChange={() => setTogglePermissions("guideImages", "update", "contentGuide")}></Form.Check>
                        <Form.Check className="w-15 body-chechbox" checked={permissions.guideImages.delete} onChange={() => setTogglePermissions("guideImages", "delete", "contentGuide")}></Form.Check>
                      </div>
                      <div className="d-flex flex-row">
                        <span className="b-header-content">{t('userPermission.table.contentGuide.stamps')}</span>
                        <span className="w-15"></span>
                        <Form.Check className="w-15 body-chechbox" checked={permissions.stamps.read} onChange={() => setTogglePermissions("stamps", "read", "contentGuide")}></Form.Check>
                        <Form.Check className="w-15 body-chechbox" checked={permissions.stamps.create} onChange={() => setTogglePermissions("stamps", "create", "contentGuide")}></Form.Check>
                        <Form.Check className="w-15 body-chechbox" checked={permissions.stamps.update} onChange={() => setTogglePermissions("stamps", "update", "contentGuide")}></Form.Check>
                        <Form.Check className="w-15 body-chechbox" checked={permissions.stamps.delete} onChange={() => setTogglePermissions("stamps", "delete", "contentGuide")}></Form.Check>
                      </div>
                    </Accordion.Body>
                  </Accordion>
                  <Accordion className="permissions__accordion">
                    <Accordion.Header className="d-flex">
                      <strong className="w-header-content">{t('userPermission.table.chargingPoint.title')}</strong>
                      <Form.Check
                        className="w-15 header-chechbox"
                        onClick={(e) => preventOpen(e)}
                        onChange={() => setToggleParentPermission("chargingPoint", "all")}
                        checked = {parentMap.chargingPoint.all}
                      ></Form.Check>
                      <Form.Check
                        className="w-15 header-chechbox"
                        onClick={(e) => preventOpen(e)}
                        onChange={() => setToggleParentPermission("chargingPoint", "read")}
                        checked = {parentMap.chargingPoint.read}
                      ></Form.Check>
                      <Form.Check
                        className="w-15 header-chechbox"
                        onClick={(e) => preventOpen(e)}
                        onChange={() => setToggleParentPermission("chargingPoint", "create")}
                        checked = {parentMap.chargingPoint.create}
                      ></Form.Check>
                      <Form.Check
                        className="w-15 header-chechbox"
                        onClick={(e) => preventOpen(e)}
                        onChange={() => setToggleParentPermission("chargingPoint", "update")}
                        checked = {parentMap.chargingPoint.update}
                      ></Form.Check>
                      <Form.Check
                        className="w-15 header-chechbox"
                        onClick={(e) => preventOpen(e)}
                        onChange={() => setToggleParentPermission("chargingPoint", "delete")}
                        checked = {parentMap.chargingPoint.delete}
                      ></Form.Check>
                    </Accordion.Header>
                    <Accordion.Body className="d-flex flex-column">
                      <div className="d-flex flex-row w-100">
                        <span className="b-header-content">{t('userPermission.table.chargingPoint.points')}</span>
                        <span className="w-15"></span>
                        <Form.Check className="w-15 body-chechbox" checked={permissions.chargingPoints.read} onChange={() => setTogglePermissions("chargingPoints", "read", "chargingPoint")}></Form.Check>
                        <Form.Check className="w-15 body-chechbox" checked={permissions.chargingPoints.create} onChange={() => setTogglePermissions("chargingPoints", "create", "chargingPoint")}></Form.Check>
                        <Form.Check className="w-15 body-chechbox" checked={permissions.chargingPoints.update} onChange={() => setTogglePermissions("chargingPoints", "update", "chargingPoint")}></Form.Check>
                        <Form.Check className="w-15 body-chechbox"checked={permissions.chargingPoints.delete} onChange={() => setTogglePermissions("chargingPoints", "delete", "chargingPoint")}></Form.Check>
                      </div>
                      <div className="d-flex flex-row">
                        <span className="b-header-content">{t('userPermission.table.chargingPoint.pointType')}</span>
                        <span className="w-15"></span>
                        <Form.Check className="w-15 body-chechbox" checked={permissions.pointTypes.read} onChange={() => setTogglePermissions("pointTypes", "read", "chargingPoint")}></Form.Check>
                        <Form.Check className="w-15 body-chechbox" checked={permissions.pointTypes.create} onChange={() => setTogglePermissions("pointTypes", "create", "chargingPoint")}></Form.Check>
                        <Form.Check className="w-15 body-chechbox" checked={permissions.pointTypes.update} onChange={() => setTogglePermissions("pointTypes", "update", "chargingPoint")}></Form.Check>
                        <Form.Check className="w-15 body-chechbox" checked={permissions.pointTypes.delete} onChange={() => setTogglePermissions("pointTypes", "delete", "chargingPoint")}></Form.Check>
                      </div>
                      <div className="d-flex flex-row">
                        <span className="b-header-content">{t('userPermission.table.chargingPoint.locationType')}</span>
                        <span className="w-15"></span>
                        <Form.Check className="w-15 body-chechbox" checked={permissions.locationTypes.read} onChange={() => setTogglePermissions("locationTypes", "read", "chargingPoint")}></Form.Check>
                        <Form.Check className="w-15 body-chechbox" checked={permissions.locationTypes.create} onChange={() => setTogglePermissions("locationTypes", "create", "chargingPoint")}></Form.Check>
                        <Form.Check className="w-15 body-chechbox" checked={permissions.locationTypes.update} onChange={() => setTogglePermissions("locationTypes", "update", "chargingPoint")}></Form.Check>
                        <Form.Check className="w-15 body-chechbox" checked={permissions.locationTypes.delete} onChange={() => setTogglePermissions("locationTypes", "delete", "chargingPoint")}></Form.Check>
                      </div>
                      <div className="d-flex flex-row">
                        <span className="b-header-content">{t('userPermission.table.chargingPoint.chargerType')}</span>
                        <span className="w-15"></span>
                        <Form.Check className="w-15 body-chechbox" checked={permissions.chargerTypes.read} onChange={() => setTogglePermissions("chargerTypes", "read", "chargingPoint")}></Form.Check>
                        <Form.Check className="w-15 body-chechbox" checked={permissions.chargerTypes.create} onChange={() => setTogglePermissions("chargerTypes", "create", "chargingPoint")}></Form.Check>
                        <Form.Check className="w-15 body-chechbox" checked={permissions.chargerTypes.update} onChange={() => setTogglePermissions("chargerTypes", "update", "chargingPoint")}></Form.Check>
                        <Form.Check className="w-15 body-chechbox" checked={permissions.chargerTypes.delete} onChange={() => setTogglePermissions("chargerTypes", "delete", "chargingPoint")}></Form.Check>
                      </div>
                    </Accordion.Body>
                  </Accordion>
                  <Accordion className="permissions__accordion">
                    <Accordion.Header className="d-flex">
                      <strong className="w-header-content">{t('userPermission.table.project.title')}</strong>
                      <Form.Check
                        className="w-15 header-chechbox"
                        onClick={(e) => preventOpen(e)}
                        onChange={() => setToggleParentPermission("project", "all")}
                        checked = {parentMap.project.all}
                      ></Form.Check>
                      <Form.Check
                        className="w-15 header-chechbox"
                        onClick={(e) => preventOpen(e)}
                        onChange={() => setToggleParentPermission("project", "read")}
                        checked = {parentMap.project.read}
                      ></Form.Check>
                      <Form.Check
                        className="w-15 header-chechbox"
                        onClick={(e) => preventOpen(e)}
                        onChange={() => setToggleParentPermission("project", "create")}
                        checked = {parentMap.project.create}
                      ></Form.Check>
                      <Form.Check
                        className="w-15 header-chechbox"
                        onClick={(e) => preventOpen(e)}
                        onChange={() => setToggleParentPermission("project", "update")}
                        checked = {parentMap.project.update}
                      ></Form.Check>
                      <Form.Check
                        className="w-15 header-chechbox"
                        onClick={(e) => preventOpen(e)}
                        onChange={() => setToggleParentPermission("project", "delete")}
                        checked = {parentMap.project.delete}
                      ></Form.Check>
                    </Accordion.Header>
                    <Accordion.Body className="d-flex flex-column">
                      <div className="d-flex flex-row w-100">
                        <span className="b-header-content">{t('userPermission.table.project.projects')}</span>
                        <span className="w-15"></span>
                        <Form.Check className="w-15 body-chechbox" checked={permissions.projects.read} onChange={() => setTogglePermissions("projects", "read", "project")}></Form.Check>
                        <Form.Check className="w-15 body-chechbox" checked={permissions.projects.create} onChange={() => setTogglePermissions("projects", "create", "project")}></Form.Check>
                        <Form.Check className="w-15 body-chechbox" checked={permissions.projects.update} onChange={() => setTogglePermissions("projects", "update", "project")}></Form.Check>
                        <Form.Check className="w-15 body-chechbox" checked={permissions.projects.delete} onChange={() => setTogglePermissions("projects", "delete", "project")}></Form.Check>
                      </div>
                    </Accordion.Body>
                  </Accordion>
                  <Accordion className="permissions__accordion">
                    <Accordion.Header className="d-flex">
                      <strong className="w-header-content">{t('userPermission.table.generalIndexes.title')}</strong>
                      <Form.Check
                        className="w-15 header-chechbox"
                        onClick={(e) => preventOpen(e)}
                        onChange={() => setToggleParentPermission("generalIndex", "all")}
                        checked = {parentMap.generalIndex.all}
                      ></Form.Check>
                      <Form.Check
                        className="w-15 header-chechbox"
                        onClick={(e) => preventOpen(e)}
                        onChange={() => setToggleParentPermission("generalIndex", "read")}
                        checked = {parentMap.generalIndex.read}
                      ></Form.Check>
                      <Form.Check
                        className="w-15 header-chechbox"
                        onClick={(e) => preventOpen(e)}
                        onChange={() => setToggleParentPermission("generalIndex", "create")}
                        checked = {parentMap.generalIndex.create}
                      ></Form.Check>
                      <Form.Check
                        className="w-15 header-chechbox"
                        onClick={(e) => preventOpen(e)}
                        onChange={() => setToggleParentPermission("generalIndex", "update")}
                        checked = {parentMap.generalIndex.update}
                      ></Form.Check>
                      <Form.Check
                        className="w-15 header-chechbox"
                        onClick={(e) => preventOpen(e)}
                        onChange={() => setToggleParentPermission("generalIndex", "delete")}
                        checked = {parentMap.generalIndex.delete}
                      ></Form.Check>
                    </Accordion.Header>
                    <Accordion.Body className="d-flex flex-column">
                      <div className="d-flex flex-row w-100">
                        <span className="b-header-content">{t('userPermission.table.generalIndexes.tags')}</span>
                        <span className="w-15"></span>
                        <Form.Check className="w-15 body-chechbox" checked={permissions.tags.read} onChange={() => setTogglePermissions("tags", "read", "generalIndex")}></Form.Check>
                        <Form.Check className="w-15 body-chechbox" checked={permissions.tags.create} onChange={() => setTogglePermissions("tags", "create", "generalIndex")}></Form.Check>
                        <Form.Check className="w-15 body-chechbox" checked={permissions.tags.update} onChange={() => setTogglePermissions("tags", "update", "generalIndex")}></Form.Check>
                        <Form.Check className="w-15 body-chechbox" checked={permissions.tags.delete} onChange={() => setTogglePermissions("tags", "delete", "generalIndex")}></Form.Check>
                      </div>
                      <div className="d-flex flex-row">
                        <span className="b-header-content">{t('userPermission.table.generalIndexes.provinces')}</span>
                        <span className="w-15"></span>
                        <Form.Check className="w-15 body-chechbox" checked={permissions.provinces.read} onChange={() => setTogglePermissions("provinces", "read", "generalIndex")}></Form.Check>
                        <Form.Check className="w-15 body-chechbox" checked={permissions.provinces.create} onChange={() => setTogglePermissions("provinces", "create", "generalIndex")}></Form.Check>
                        <Form.Check className="w-15 body-chechbox" checked={permissions.provinces.update} onChange={() => setTogglePermissions("provinces", "update", "generalIndex")}></Form.Check>
                        <Form.Check className="w-15 body-chechbox" checked={permissions.provinces.delete} onChange={() => setTogglePermissions("provinces", "delete", "generalIndex")}></Form.Check>
                      </div>
                      <div className="d-flex flex-row">
                        <span className="b-header-content">{t('userPermission.table.generalIndexes.regions')}</span>
                        <span className="w-15"></span>
                        <Form.Check className="w-15 body-chechbox" checked={permissions.regions.read} onChange={() => setTogglePermissions("regions", "read", "generalIndex")}></Form.Check>
                        <Form.Check className="w-15 body-chechbox" checked={permissions.regions.create} onChange={() => setTogglePermissions("regions", "create", "generalIndex")}></Form.Check>
                        <Form.Check className="w-15 body-chechbox" checked={permissions.regions.update} onChange={() => setTogglePermissions("regions", "update", "generalIndex")}></Form.Check>
                        <Form.Check className="w-15 body-chechbox" checked={permissions.regions.delete} onChange={() => setTogglePermissions("regions", "delete", "generalIndex")}></Form.Check>
                      </div>
                      <div className="d-flex flex-row">
                        <span className="b-header-content">{t('userPermission.table.generalIndexes.localities')}</span>
                        <span className="w-15"></span>
                        <Form.Check className="w-15 body-chechbox" checked={permissions.localities.read} onChange={() => setTogglePermissions("localities", "read", "generalIndex")}></Form.Check>
                        <Form.Check className="w-15 body-chechbox" checked={permissions.localities.create} onChange={() => setTogglePermissions("localities", "create", "generalIndex")}></Form.Check>
                        <Form.Check className="w-15 body-chechbox" checked={permissions.localities.update} onChange={() => setTogglePermissions("localities", "update", "generalIndex")}></Form.Check>
                        <Form.Check className="w-15 body-chechbox" checked={permissions.localities.delete} onChange={() => setTogglePermissions("localities", "delete", "generalIndex")}></Form.Check>
                      </div>
                      <div className="d-flex flex-row">
                        <span className="b-header-content">{t('userPermission.table.generalIndexes.weekDays')}</span>
                        <span className="w-15"></span>
                        <Form.Check className="w-15 body-chechbox" checked={permissions.weekDays.read} onChange={() => setTogglePermissions("weekDays", "read", "generalIndex")}></Form.Check>
                        <Form.Check className="w-15 body-chechbox" checked={permissions.weekDays.create} onChange={() => setTogglePermissions("weekDays", "create", "generalIndex")}></Form.Check>
                        <Form.Check className="w-15 body-chechbox" checked={permissions.weekDays.update} onChange={() => setTogglePermissions("weekDays", "update", "generalIndex")}></Form.Check>
                        <Form.Check className="w-15 body-chechbox" checked={permissions.weekDays.delete} onChange={() => setTogglePermissions("weekDays", "delete", "generalIndex")}></Form.Check>
                      </div>
                      <div className="d-flex flex-row">
                        <span className="b-header-content">{t('userPermission.table.generalIndexes.languages')}</span>
                        <span className="w-15"></span>
                        <Form.Check className="w-15 body-chechbox" checked={permissions.languages.read} onChange={() => setTogglePermissions("languages", "read", "generalIndex")}></Form.Check>
                        <Form.Check className="w-15 body-chechbox" checked={permissions.languages.create} onChange={() => setTogglePermissions("languages", "create", "generalIndex")}></Form.Check>
                        <Form.Check className="w-15 body-chechbox" checked={permissions.languages.update} onChange={() => setTogglePermissions("languages", "update", "generalIndex")}></Form.Check>
                        <Form.Check className="w-15 body-chechbox" checked={permissions.languages.delete} onChange={() => setTogglePermissions("languages", "delete", "generalIndex")}></Form.Check>
                      </div>
                      <div className="d-flex flex-row">
                        <span className="b-header-content">{t('userPermission.table.generalIndexes.promotions')}</span>
                        <span className="w-15"></span>
                        <Form.Check className="w-15 body-chechbox" checked={permissions.promotions.read} onChange={() => setTogglePermissions("promotions", "read", "generalIndex")}></Form.Check>
                        <Form.Check className="w-15 body-chechbox" checked={permissions.promotions.create} onChange={() => setTogglePermissions("promotions", "create", "generalIndex")}></Form.Check>
                        <Form.Check className="w-15 body-chechbox" checked={permissions.promotions.update} onChange={() => setTogglePermissions("promotions", "update", "generalIndex")}></Form.Check>
                        <Form.Check className="w-15 body-chechbox" checked={permissions.promotions.delete} onChange={() => setTogglePermissions("promotions", "delete", "generalIndex")}></Form.Check>
                      </div>
                      <div className="d-flex flex-row">
                        <span className="b-header-content">{t('userPermission.table.generalIndexes.sections')}</span>
                        <span className="w-15"></span>
                        <Form.Check className="w-15 body-chechbox" checked={permissions.sections.read} onChange={() => setTogglePermissions("sections", "read", "generalIndex")}></Form.Check>
                        <Form.Check className="w-15 body-chechbox" checked={permissions.sections.create} onChange={() => setTogglePermissions("sections", "create", "generalIndex")}></Form.Check>
                        <Form.Check className="w-15 body-chechbox" checked={permissions.sections.update} onChange={() => setTogglePermissions("sections", "update", "generalIndex")}></Form.Check>
                        <Form.Check className="w-15 body-chechbox" checked={permissions.sections.delete} onChange={() => setTogglePermissions("sections", "delete", "generalIndex")}></Form.Check>
                      </div>
                      <div className="d-flex flex-row">
                        <span className="b-header-content">{t('userPermission.table.generalIndexes.highlights')}</span>
                        <span className="w-15"></span>
                        <Form.Check className="w-15 body-chechbox" checked={permissions.highlights.read} onChange={() => setTogglePermissions("highlights", "read", "generalIndex")}></Form.Check>
                        <Form.Check className="w-15 body-chechbox" checked={permissions.highlights.create} onChange={() => setTogglePermissions("highlights", "create", "generalIndex")}></Form.Check>
                        <Form.Check className="w-15 body-chechbox" checked={permissions.highlights.update} onChange={() => setTogglePermissions("highlights", "update", "generalIndex")}></Form.Check>
                        <Form.Check className="w-15 body-chechbox" checked={permissions.highlights.delete} onChange={() => setTogglePermissions("highlights", "delete", "generalIndex")}></Form.Check>
                      </div>
                      <div className="d-flex flex-row">
                        <span className="b-header-content">{t('userPermission.table.generalIndexes.homeHeadlines')}</span>
                        <span className="w-15"></span>
                        <Form.Check className="w-15 body-chechbox" checked={permissions.homeHeadlines.read} onChange={() => setTogglePermissions("homeHeadlines", "read", "generalIndex")}></Form.Check>
                        <Form.Check className="w-15 body-chechbox" checked={permissions.homeHeadlines.create} onChange={() => setTogglePermissions("homeHeadlines", "create", "generalIndex")}></Form.Check>
                        <Form.Check className="w-15 body-chechbox" checked={permissions.homeHeadlines.update} onChange={() => setTogglePermissions("homeHeadlines", "update", "generalIndex")}></Form.Check>
                        <Form.Check className="w-15 body-chechbox" checked={permissions.homeHeadlines.delete} onChange={() => setTogglePermissions("homeHeadlines", "delete", "generalIndex")}></Form.Check>
                      </div>
                    </Accordion.Body>
                  </Accordion>
                  <Accordion className="permissions__accordion">
                    <Accordion.Header className="d-flex">
                      <strong className="w-header-content">{t('userPermission.table.users.title')}</strong>
                      <Form.Check
                        className="w-15 header-chechbox"
                        onClick={(e) => preventOpen(e)}
                        onChange={() => setToggleParentPermission("users", "all")}
                        checked = {parentMap.users.all}
                      ></Form.Check>
                      <Form.Check
                        className="w-15 header-chechbox"
                        onClick={(e) => preventOpen(e)}
                        onChange={() => setToggleParentPermission("users", "read")}
                        checked = {parentMap.users.read}
                      ></Form.Check>
                      <Form.Check
                        className="w-15 header-chechbox"
                        onClick={(e) => preventOpen(e)}
                        onChange={() => setToggleParentPermission("users", "create")}
                        checked = {parentMap.users.create}
                      ></Form.Check>
                      <Form.Check
                        className="w-15 header-chechbox"
                        onClick={(e) => preventOpen(e)}
                        onChange={() => setToggleParentPermission("users", "update")}
                        checked = {parentMap.users.update}
                      ></Form.Check>
                      <Form.Check
                        className="w-15 header-chechbox"
                        onClick={(e) => preventOpen(e)}
                        onChange={() => setToggleParentPermission("users", "delete")}
                        checked = {parentMap.users.delete}
                      ></Form.Check>
                    </Accordion.Header>
                    <Accordion.Body className="d-flex flex-column">
                      <div className="d-flex flex-row w-100">
                        <span className="b-header-content">{t('userPermission.table.users.users')}</span>
                        <span className="w-15"></span>
                        <Form.Check className="w-15 body-chechbox" checked={permissions.users.read} onChange={() => setTogglePermissions("users", "read", "users")}></Form.Check>
                        <Form.Check className="w-15 body-chechbox" checked={permissions.users.create} onChange={() => setTogglePermissions("users", "create", "users")}></Form.Check>
                        <Form.Check className="w-15 body-chechbox" checked={permissions.users.update} onChange={() => setTogglePermissions("users", "update", "users")}></Form.Check>
                        <Form.Check className="w-15 body-chechbox" checked={permissions.users.delete} onChange={() => setTogglePermissions("users", "delete", "users")}></Form.Check>
                      </div>
                      <div className="d-flex flex-row">
                        <span className="b-header-content">{t('userPermission.table.users.userPermissions')}</span>
                        <span className="w-15"></span>
                        <Form.Check className="w-15 body-chechbox" checked={permissions.userPermissions.read} onChange={() => setTogglePermissions("userPermissions", "read", "users")}></Form.Check>
                        <Form.Check className="w-15 body-chechbox" checked={permissions.userPermissions.create} onChange={() => setTogglePermissions("userPermissions", "create", "users")}></Form.Check>
                        <Form.Check className="w-15 body-chechbox" checked={permissions.userPermissions.update} onChange={() => setTogglePermissions("userPermissions", "update", "users")}></Form.Check>
                        <Form.Check className="w-15 body-chechbox" checked={permissions.userPermissions.delete} onChange={() => setTogglePermissions("userPermissions", "delete", "users")}></Form.Check>
                      </div>
                    </Accordion.Body>
                  </Accordion>
                  <Accordion className="permissions__accordion">
                    <Accordion.Header className="d-flex">
                      <strong className="w-header-content">{t('userPermission.table.stadistics.title')}</strong>
                      <Form.Check
                        className="w-15 header-chechbox"
                        onClick={(e) => preventOpen(e)}
                        onChange={() => setToggleParentPermission("stadistics", "all")}
                        checked = {parentMap.stadistics.all}
                      ></Form.Check>
                      <Form.Check
                        className="w-15 header-chechbox"
                        onClick={(e) => preventOpen(e)}
                        onChange={() => setToggleParentPermission("stadistics", "read")}
                        checked = {parentMap.stadistics.read}
                      ></Form.Check>
                      <Form.Check
                        className="w-15 header-chechbox"
                        onClick={(e) => preventOpen(e)}
                        onChange={() => setToggleParentPermission("stadistics", "create")}
                        checked = {parentMap.stadistics.create}
                      ></Form.Check>
                      <Form.Check
                        className="w-15 header-chechbox"
                        onClick={(e) => preventOpen(e)}
                        onChange={() => setToggleParentPermission("stadistics", "update")}
                        checked = {parentMap.stadistics.update}
                      ></Form.Check>
                      <Form.Check
                        className="w-15 header-chechbox"
                        onClick={(e) => preventOpen(e)}
                        onChange={() => setToggleParentPermission("stadistics", "delete")}
                        checked = {parentMap.stadistics.delete}
                      ></Form.Check>
                    </Accordion.Header>
                    <Accordion.Body className="d-flex flex-column">
                      <div className="d-flex flex-row w-100">
                        <span className="b-header-content">{t('userPermission.table.stadistics.userAnalytics')}</span>
                        <span className="w-15"></span>
                        <Form.Check className="w-15 body-chechbox" checked={permissions.analyticUsers.read} onChange={() => setTogglePermissions("analyticUsers", "read", "stadistics")}></Form.Check>
                        <Form.Check className="w-15 body-chechbox" checked={permissions.analyticUsers.create} onChange={() => setTogglePermissions("analyticUsers", "create", "stadistics")}></Form.Check>
                        <Form.Check className="w-15 body-chechbox" checked={permissions.analyticUsers.update} onChange={() => setTogglePermissions("analyticUsers", "update", "stadistics")}></Form.Check>
                        <Form.Check className="w-15 body-chechbox" checked={permissions.analyticUsers.delete} onChange={() => setTogglePermissions("analyticUsers", "delete", "stadistics")}></Form.Check>
                      </div>
                      <div className="d-flex flex-row">
                        <span className="b-header-content">{t('userPermission.table.stadistics.bookingAnalytics')}</span>
                        <span className="w-15"></span>
                        <Form.Check className="w-15 body-chechbox" checked={permissions.analyticBookings.read} onChange={() => setTogglePermissions("analyticBookings", "read", "stadistics")}></Form.Check>
                        <Form.Check className="w-15 body-chechbox" checked={permissions.analyticBookings.create} onChange={() => setTogglePermissions("analyticBookings", "create", "stadistics")}></Form.Check>
                        <Form.Check className="w-15 body-chechbox" checked={permissions.analyticBookings.update} onChange={() => setTogglePermissions("analyticBookings", "update", "stadistics")}></Form.Check>
                        <Form.Check className="w-15 body-chechbox" checked={permissions.analyticBookings.delete} onChange={() => setTogglePermissions("analyticBookings", "delete", "stadistics")}></Form.Check>
                      </div>
                      <div className="d-flex flex-row">
                        <span className="b-header-content">{t('userPermission.table.stadistics.monitor')}</span>
                        <span className="w-15"></span>
                        <Form.Check className="w-15 body-chechbox" checked={permissions.monitor.read} onChange={() => setTogglePermissions("monitor", "read", "stadistics")}></Form.Check>
                        <Form.Check className="w-15 body-chechbox" checked={permissions.monitor.create} onChange={() => setTogglePermissions("monitor", "create", "stadistics")}></Form.Check>
                        <Form.Check className="w-15 body-chechbox" checked={permissions.monitor.update} onChange={() => setTogglePermissions("monitor", "update", "stadistics")}></Form.Check>
                        <Form.Check className="w-15 body-chechbox" checked={permissions.monitor.delete} onChange={() => setTogglePermissions("monitor", "delete", "stadistics")}></Form.Check>
                      </div>
                    </Accordion.Body>
                  </Accordion>
                  <div className="position-absolute bottom-0 end-0 mb-3 me-3">
                    <Button variant="primary" type="submit" disabled={ !(userPerm.userPermissions?.create && userPerm.userPermissions?.delete && userPerm.userPermissions?.update ) }>
                    {t('general.save')}
                    </Button>
                  </div>
                </Form>
              </Card.Body>
            </Card>
          </div>
        </>
      );
}

export default UserPermissionForm;