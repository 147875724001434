import {Col, Form, FormGroup} from "react-bootstrap";

const TranslationInputLanguage = ({translations, data, onChange}) => {

    return (
        translations?.map((translation, index) => (
        <Col md="auto" key={index}>
            <FormGroup>
                <Form.Label>{translation?.language?.name}</Form.Label>
                <Form.Control type="text" value={data?.[translation.language.iso]?.value || data?.name?.[translation.language.iso]?.value || ""}
                              onChange={(e) => onChange(translation.language.iso, e.target.value, translation.language.id)}/>
            </FormGroup>
        </Col>
    )));
}

export default TranslationInputLanguage;