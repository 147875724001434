import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import { Alert, Card } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Link, useNavigate, useParams } from "react-router-dom"
import ChargerTypeForm from "../../../../components/forms/configuration/ChargerTypeForm";
import { useCallback } from "react";
import ChargerTypeService from "../../../../services/chargerTypes.service";
import { useEffect } from "react";
import BodyService from "../../../../services/body.service";
import UserPermissionService from "../../../../services/userPermission.service";

const ChargerTypeDetails = () => {
    const params = useParams();
    
    const navigate = useNavigate();
    
    const { t } = useTranslation();

    const [chargerType, setChargerType] = useState();

    const [translations, setTranslations] = useState([]);

    const [title, setTitle] = useState("");

    const [permissions, setPermissions] = useState({});

    const [success, setSuccess] = useState({
        update: false,
        email: false,
    });

    const [errors, setErrors] = useState({
        update: false,
    });

    const getChargerType = useCallback(async (id) => {
        try {
            const data = await ChargerTypeService.getById(id);                 
            setChargerType(data);
            setTitle(data.name["es"].value);
        } catch (error) {           
            navigate("/configuration/charger-type");
        }
    },[navigate]);
    
    const updateChargerType = async (data) => {
        setErrors({...errors, update: false});
        setSuccess({...success, update: false});
        
        try{
            await ChargerTypeService.update(params.id,data);
            getChargerType(params.id);
            setSuccess({...success, update: true});
        } catch (e) {
            setErrors({...errors, update: true});
        }
    };

    const getEmptyTranslations = async () => {
        const data = await BodyService.getEmptyTranslations();
        setTranslations(data);
    };
    
    const getPermissions = async () => {
        try {
            const data = await UserPermissionService.getPermissionsByConnected();
            setPermissions(data);
        } catch (error) {
            console.error(error);
        }
    }

    useEffect(() => {
        const loadData = async () => {
            await getPermissions();
        }

        loadData();
    }, []);

    useEffect(() => {
        getChargerType(params.id);
        getEmptyTranslations();
    }, [getChargerType, params.id]);

    return (
        <>
            <div className={"d-flex justify-content-between my-3"}>
                <Link to="/configuration/charger-type" className={"previous round rounded-circle text-decoration-none"}
                    title={t('general.back')}>
                    <FontAwesomeIcon icon={"chevron-left"} className={"mt-2 me-2"} />
                    <span>{t('chargerType.title')}</span>
                </Link>
            </div>
            <Alert variant={"success"} show={success.update} dismissible onClose={() => setSuccess({ ...success, update: false })}>
                {t('chargerType.success.update')}
            </Alert>
            <Alert variant={"danger"} show={errors.update} dismissible onClose={() => setErrors({ ...errors, update: false })}>
                {t('chargerType.error.update')}
            </Alert>
            <Card>
                <Card.Header className={"py-3"}>
                    <h1 className={"h3 mb-0 text-gray-800 card-title text-capitalize"}>{title}</h1>
                </Card.Header>
                <Card.Body>
                    <ChargerTypeForm onSubmit={updateChargerType} chargerType={chargerType} connector={translations} permissions={permissions} details />
                </Card.Body>
            </Card>
        </>
    )
}

export default ChargerTypeDetails;