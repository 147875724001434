import {Accordion, Button, Card, Table, Alert, FormGroup, Form, Col, Row} from "react-bootstrap";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import UserAnalyticService from "../../services/userAnalytic.service";
import DateTimePicker from "react-datetime-picker/dist/entry.nostyle";
import APP_CONF from "../../scripts/constants";
import PaginationComponent from "../../components/PaginationComponent";
import {useForm} from "react-hook-form";
import TableHeaderSortComponent from "../../components/TableHeaderSortComponent";
import UseFormatDate from "../../hooks/UseFormatDate";
import UserPermissionService from "../../services/userPermission.service";

const UserAnalytic = () => {
    const { t } = useTranslation();

    const { register, handleSubmit } = useForm({
        mode: "all",
    });
    
    const [pagination, setPagination] = useState({
        currentPage: 1,
        totalItems: 0,
        itemsPerPage: APP_CONF.ITEMS_PER_PAGE,
        numPages: 1
    });

    const generateDate = (days, endDay) => {
        var d = new Date();
        d.setDate(d.getDate() + days);
        if(endDay) {
            d.setHours(23,59,59,0); 
        }
        else {
            d.setHours(0,0,0,0); 
        }

        return d;
    }
    
    const [formData, setFormData] = useState({
        startDate: generateDate(-7, false),
        endDate: generateDate(1, true),
        search: null
    });

    const updateFormData = (target, data) => {
        setFormData({
            ...formData,
            [target]: data
        });
    }

    const [errors, setErrors] = useState({
        create: false,
        delete: false,
    });

    const [userAnalytics, setUserAnalytics] = useState([]);

    const [permissions, setPermissions] = useState({});

    const [sortColumn, setSortColumn] = useState({
        sortByColumn: null,
        orderBy: null
    });

    const getUserAnalytics = async () => {
        try {
            const data = await UserAnalyticService.getAll(pagination, sortColumn, formData)
            setUserAnalytics(data.content);
            setPagination({
                ...pagination,
                totalItems: data.totalElements,
                numPages: data.totalPages
            });
        } catch ({ response }) {
            if (response?.data?.errorCode) {
                switch (response.data.errorCode) {
                    case 13002:
                        setErrors({...errors, email: true});
                        break;
                    default:
                        setErrors({...errors, create: true});
                }
            }
        }
    }

    const getPermissions = async () => {
        try {
            const data = await UserPermissionService.getPermissionsByConnected();
            setPermissions(data);
        } catch (error) {
            console.error(error);
        }
    }

    useEffect(() => {
        getPermissions();
    }, []);

    useEffect(() => {
        getUserAnalytics();
    }, [pagination.currentPage, sortColumn, formData]);

    const filterSearch = (data) => {
        updateFormData('search', data.search);
    }

    return (
        <>
            <div className={"d-sm-flex align-items-center justify-content-between mb-4"}>
                <h1 className={"h3 mb-0 text-gray-800"}>{t('userAnalytic.title')}</h1>
            </div>
            <Card className={"shadow mb-4"}>
                <Card.Body>
                    <Form onSubmit={handleSubmit(filterSearch)}>
                        <Row>
                            <Col lg="3" className={"mb-3"}>
                                <FormGroup>
                                    <Form.Label>{t('categories.filter.search')}</Form.Label>
                                    <Form.Control type="text" {...register("search")} />
                                </FormGroup>
                            </Col>
                            <Col lg="2" className={"mb-3"}>
                                <FormGroup>
                                    <Form.Label>{t('monitor.filter.startDate')}</Form.Label>
                                    <DateTimePicker calendarIcon={null}
                                        disableClock={true}
                                        onChange={e => updateFormData('startDate', e)}
                                        value={formData?.startDate} />
                                </FormGroup>
                            </Col>
                            <Col lg="2" className={"mb-3"}>
                                <FormGroup>
                                    <Form.Label>{t('monitor.filter.endDate')}</Form.Label>
                                    <DateTimePicker calendarIcon={null}
                                        disableClock={true}
                                        onChange={e => updateFormData('endDate', e)}
                                        value={formData?.endDate} />
                                </FormGroup>
                            </Col>
                        </Row>
                        <Button type="submit" disabled={!permissions.analyticUsers?.read}>{t('users.filter.button')}</Button>
                    </Form>
                    <hr />
                    <Table responsive>
                        <thead>
                            <tr>
                                <TableHeaderSortComponent scope={"col"} text={t('userAnalytic.list.id')} column={"id"} sortColumn={sortColumn} setSortColumn={setSortColumn} />
                                <th scope="col">{t('userAnalytic.list.operation')}</th>
                                <TableHeaderSortComponent scope={"col"} text={t('userAnalytic.list.creationDate')} column={"creationDate"} sortColumn={sortColumn} setSortColumn={setSortColumn} />
                                <TableHeaderSortComponent scope={"col"} text={t('userAnalytic.list.user')} column={"userName"} sortColumn={sortColumn} setSortColumn={setSortColumn} />
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {userAnalytics.length > 0 ? userAnalytics.map((userAnalytic, index) => (
                                <tr key={index}>
                                    <td>{userAnalytic.id}</td>
                                    <td>{userAnalytic.operation}</td>
                                    <td>{userAnalytic.creationDate !== null ? UseFormatDate(userAnalytic.creationDate) : null}</td>
                                    <td>{userAnalytic.firstName} {userAnalytic.lastName}</td>
                                </tr>
                            )) :
                                <tr>
                                    <td colSpan={4}>{t('general.empty')}</td>
                                </tr>
                            }
                        </tbody>
                    </Table>
                    <PaginationComponent pagination={pagination}
                        setPagination={setPagination}
                        alwaysShown={userAnalytics.length > 0} />
                </Card.Body>
            </Card>
        </>
    );
};
export default UserAnalytic;