import React from 'react'
import { useTranslation } from 'react-i18next';
import { Accordion, Alert, Button, Card, Col, Form, FormGroup, Image, Row, Table } from 'react-bootstrap';
import { useState } from 'react';

import APP_CONF from "../../scripts/constants";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import PaginationComponent from '../../components/PaginationComponent';
import TableHeaderSortComponent from "../../components/TableHeaderSortComponent";
import { useForm } from 'react-hook-form';
import { useEffect } from 'react';

import BodyService from '../../services/body.service';
import SectionForm from '../../components/forms/SectionForm';
import SectionService from '../../services/section.service';
import UseAlertConfirm from '../../hooks/UseAlertConfirm';
import UserPermissionService from "../../services/userPermission.service";

function Sections() {

    const { t } = useTranslation();

    const [sections, setSections] = useState();

    const [languages, setLanguages] = useState();

    const [permissions, setPermissions] = useState({});

    const [filter, setFilter] = useState({
        active: true,
        search: ""
    })

    const [pagination, setPagination] = useState({
        currentPage: 1,
        totalItems: 0,
        itemsPerPage: APP_CONF.ITEMS_PER_PAGE,
        numPages: 1
    });

    const [sortColumn, setSortColumn] = useState({
        sortByColumn: null,
        orderBy: null
    });

    const [success, setSuccess] = useState({
        create: false,
        delete: false,
    });
    const [errors, setErrors] = useState({
        create: false,
        delete: false,
    });

    const { showModal } = UseAlertConfirm();

    const { register, handleSubmit } = useForm({
        mode: "all",
    });

    const getSections = async () => {
        try {
            let language;
            if ((sortColumn.sortByColumn === 'name') &&
                (sortColumn.orderBy !== null || sortColumn.orderBy !== undefined)) {
                language = "es";
            }

            const data = await SectionService.getAll(pagination, sortColumn, language, filter)
            setSections(data.content)
            setPagination({
                ...pagination,
                totalItems: data.totalElements,
                numPages: data.totalPages
            });
        } catch (error) {
            console.error(error);
        }
    }

    const createSection = async (data) => {
        setErrors({ ...errors, create: false });
        setSuccess({ ...success, create: false });

        try {
            await SectionService.create(data);
            setSuccess({ ...success, create: true });
            getSections();
        } catch (e) {
            console.error(e.response);
            setErrors({ ...errors, create: true });
            return e.response
        }
    }

    const deleteSection = async (id) => {

        showModal({
            title: t("general.delete.title"),
            body: t("general.delete.message"),
            confirmButtonVariant: "danger",
            onConfirm: async () => {
                setErrors({ ...errors, create: false });
                setSuccess({ ...success, create: false });
                try {
                    await SectionService.remove(id);
                    setSuccess({ ...success, delete: true });
                    getSections();
                } catch (e) {
                    console.error(e.response);
                    setErrors({ ...errors, delete: true });
                }
            }
        });
    }

    const getEmptyTranslations = async () => {
        const data = await BodyService.getEmptyTranslations();
        setLanguages(data);
    }

    const filterSearch = (data) => {
        let obj = data;
        if (obj.search === "") {
            obj = {
                active: data.active
            }
        }
        setFilter(obj);
    }

    const getPermissions = async () => {
        try {
            const data = await UserPermissionService.getPermissionsByConnected();
            setPermissions(data);
        } catch (error) {
            console.error(error);
        }
    }

    useEffect(() => {
        getSections()

    }, [pagination.currentPage, filter, sortColumn]);

    useEffect(() => {
        const loadData = async () => {
            await getPermissions();
            await getEmptyTranslations();
            await getSections();

        }

        loadData();
    }, [])

    return (
        <>
            <div className={"d-sm-flex align-items-center justify-content-between mb-4"}>
                <h1 className={"h3 mb-0 text-gray-800"}>{t('section.title')}</h1>
            </div>

            <Alert variant={"success"} show={success.create} dismissible onClose={() => setSuccess({ ...success, create: false })}>
                {t('section.success.create')}
            </Alert>

            <Alert variant={"danger"} show={errors.create} dismissible onClose={() => setErrors({ ...errors, create: false })}>
                {t('section.error.create')}
            </Alert>
            {permissions.sections?.read && permissions.sections?.create ? 
            <Card className={"shadow mb-4"}>
                <Accordion>
                    <Accordion.Item eventKey="0">
                        <Accordion.Header><h6 className={"m-0 fw-bold text-primary"}>{t('section.create.title')}</h6>
                        </Accordion.Header>
                        <Accordion.Body>
                            <SectionForm onSubmit={createSection} languages={languages} permissions={permissions} />
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>
            </Card>
            : null}

            <Card className={"shadow mb-4"}>
                <Card.Header className={"py-3"}>
                    <h6 className={"m-0 fw-bold text-primary"}>{t('section.table.title')}</h6>
                </Card.Header>
                <Card.Body>
                    <Form onSubmit={handleSubmit(filterSearch)}>
                        <Row>
                            <Col lg="2" className={"mb-3"}>
                                <FormGroup>
                                    <Form.Label>{t('categories.filter.active.title')}</Form.Label>
                                    <Form.Select {...register('active')} defaultValue={filter.active} aria-label="Default select example">
                                        <option value="">{t('categories.filter.active.value1')}</option>
                                        <option value={true}>{t('categories.filter.active.value2')}</option>
                                        <option value={false}>{t('categories.filter.active.value3')}</option>
                                    </Form.Select>
                                </FormGroup>
                            </Col>
                            <Col lg="3" className={"mb-3"}>
                                <FormGroup>
                                    <Form.Label>{t('users.filter.search')}</Form.Label>
                                    <Form.Control type="text" {...register("search")} />
                                </FormGroup>
                            </Col>
                        </Row>
                        <Button type="submit" className={"mt-3"} disabled={!permissions.sections?.read}>{t('categories.filter.button')}</Button>
                    </Form>
                    <hr />
                    <Table responsive>
                        <thead>
                            <tr>
                                <TableHeaderSortComponent scope={"col"} text={t('section.table.name')} column={"title"} sortColumn={sortColumn} setSortColumn={setSortColumn} />
                                <TableHeaderSortComponent scope={"col"} text={t('section.table.active')} column={"active"} sortColumn={sortColumn} setSortColumn={setSortColumn} />
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {sections?.map((section, i) =>
                                <tr key={i}>
                                    <td>
                                        <div className="d-flex flex-row flex-nowrap">
                                            {Object.values(section?.name).map((translation, i) => {
                                                if (translation.value) {
                                                    return (
                                                        <div key={i} className="me-2">
                                                            <Image src={translation.language.icon} className="me-2 flags-img-sm" />
                                                            {i + 1 === Object.values(section?.name).length ? translation.value : (translation.value === null ? "" : translation.value) + ' | '}
                                                        </div>
                                                    )
                                                } else {
                                                    return ""
                                                }
                                            }
                                            )}
                                        </div>
                                    </td>
                                    <td>{section.active ? <FontAwesomeIcon className="ms-4 text-primary" icon="eye" /> : <FontAwesomeIcon className="ms-4" icon="eye-slash" />}</td>
                                    <td className={"text-center"}>
                                        <Button variant="link" size="sm" className="ms-2" to={`/section/${section?.id}`} as={Link} disabled={!permissions.sections?.read}>
                                            <FontAwesomeIcon icon="edit" />
                                        </Button>
                                        <Button variant="link" size="sm" className="text-danger" onClick={() => deleteSection(section?.id)} disabled={!permissions.sections?.delete}>
                                            <FontAwesomeIcon icon="trash" />
                                        </Button>
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </Table>
                    <PaginationComponent pagination={pagination}
                        setPagination={setPagination}
                        alwaysShown={sections?.length > 0} />
                </Card.Body>
            </Card>
        </>
    )
}

export default Sections