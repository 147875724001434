import { Editor } from '@tinymce/tinymce-react';
import APP_CONF from '../../../scripts/constants';

const RichTextArea = ({ texts, iso, block, detail, height, onChange }) => {
	return (
		<>

			<Editor
				apiKey={APP_CONF.TINYMCE_KEY}
				onEditorChange={(evt, editor) => { detail && onChange(block, editor.getContent(), iso) }}
				init={{
					menubar: false,
					contextmenu: 'cut copy paste pastetext link',
					content_style: `
					@font-face { font-family: "norms"; src: url('/fonts/Montserrat-Regular.ttf') format('truetype') }
					@font-face { font-family: "Joane-regular"; src: url('/fonts/Joane-Regular.woff') format('truetype') }
					body { font-family: norms }
					`,
					font_family_formats: 'TTNorms=norms; Joane=Joane-regular',
					language: "es",
					language_url: "/tinymce/es.js",
					branding: false,
					statusbar: false,
					height: height
				}}
				plugins="lists autolink link"
				toolbar="undo redo | fontfamily fontsize blockformats | alignleft aligncenter alignright alignjustify | bold italic underline removeformat | bullist numlist | outdent indent | link"
				value={detail && texts ? texts?.[iso]?.[block] || "" : ""}
			/>
		</>
	);
}

export default RichTextArea