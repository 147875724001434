import { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import Select from "react-select";
import {t} from "i18next";

const appsList = [{id: 1, name: "ADMIN"}, {id: 2, name: "WEB"}];
const errorColor = "#e74a3b";

const UserAppForm = ({ onChange, apps }) => {

    const [selectedApps, setSelectedApps] = useState([]);

    const setData = (apps) => {
        setSelectedApps(apps);
        onChange(apps);
    }

    const customStyles = {
        control: (provided, _) => ({
            ...provided,
            borderColor: selectedApps.length === 0 ? errorColor : provided.borderColor, '&:hover': {
                borderColor: selectedApps.length === 0 ? errorColor : provided.borderColor
            }
        })
    }

    useEffect(() => {
        if (apps) {
            setSelectedApps(apps);
        }
    }, [apps])

    return (
        <Row>
            <Col md="6" lg="4">
                <Select options={appsList}
                        isMulti
                        classNamePrefix="select"
                        closeMenuOnSelect={false}
                        placeholder=""
                        value={selectedApps}
                        getOptionLabel={(option) => option.name}
                        getOptionValue={(option) => option.id}
                        onChange={setData}
                        styles={customStyles}
                />
                { selectedApps.length === 0 ? <div style={
                    {
                        width: "100%",
                        marginTop: "0.25rem",
                        fontSize: "0.875em",
                        color: errorColor
                    }
                }>{t('users.creation.fields.applicationsError')}</div> : null}
            </Col>
        </Row>
    );
}

export default UserAppForm;