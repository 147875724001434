import api from './api';

const getAll = (pagination, filter, sortColumn) => api.get('/charging-point/charger-type', {
    pageNum: pagination.currentPage - 1, 
    pageLength: pagination.itemsPerPage, 
    active: filter.active,
    search: filter.search,
    sortBy: sortColumn.sortByColumn,
    orderBy: sortColumn.orderBy
}).then(response => response.data);

const getList =()=>api.get('/charging-point/charger-type/list').then(response => response.data);

const create = (data) => api.post('/charging-point/charger-type', data).then(response => {
    return response.data
});

const getById = (id) => api.get(`/charging-point/charger-type/${id}`).then(response => response.data);

const update = (id, data) => api.put(`/charging-point/charger-type/${id}`, data).then(response => {
    return response.data
});

const remove = (id) => api.delete(`/charging-point/charger-type/${id}`).then(response => {
    return response.data
});

const ChargerTypeService = {
    getAll,
    getById,
    getList,
    create,
    remove,
    update
}

export default ChargerTypeService;