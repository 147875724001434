import React, { createContext, Suspense, useReducer } from 'react'
import AlertConfirm from '../components/AlertConfirm';

export const AlertConfirmContext = createContext();

const reducer = (state, pair) => ({ ...state, ...pair });

const modalState = {
            show: false,
            title: '',
            body: '',
            confirmButtonContent: '',
            confirmButtonVariant: '',
			confirmButtonIsDark: false,
            dismissButtonContent: '',
            dismissButtonVariant: '',
			dismissButtonIsDark: false,
            onConfirm: () => { },
            onDismiss: () => { }
}

export function AlertConfirmProvider(props) {
	const [state, update] = useReducer(reducer, modalState)

	return (
		<AlertConfirmContext.Provider value={{ state, update }}>
			{props.children}
			<Suspense fallback="">
				<AlertConfirm />
			</Suspense>
		</AlertConfirmContext.Provider>
	)
}
