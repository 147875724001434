import { Link, useNavigate, useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Alert, Card } from "react-bootstrap";
import GuideImageForm from "../../../components/forms/GuideImagesForm";
import { useTranslation } from "react-i18next";
import { useCallback, useEffect, useState } from "react";
import GuideImagesService from "../../../services/guideImages.service";
import UserPermissionService from "../../../services/userPermission.service";


const GuideImagesDetails = () => {

    const params = useParams();

    const navigate = useNavigate();

    const { t } = useTranslation();

    const [guideImage, setGuideImage] = useState();

    const [permissions, setPermissions] = useState({});

    const [success, setSuccess] = useState({
        update: false,
        email: false,
    });

    const [errors, setErrors] = useState({
        update: false,
    });

    const getGuideImage = useCallback(async (id) => {

        try {
            const data = await GuideImagesService.getById(id);
            setGuideImage(data);
        } catch (e) {
            navigate("/guide-images");
        }
    }, [navigate]);

    const updateGuideImage = async (data) => {
        setErrors({ ...errors, update: false });
        setSuccess({ ...success, update: false });

        try {
            await GuideImagesService.update(params.id, data);
            getGuideImage(params.id);
            setSuccess({ ...success, update: true });
        } catch (e) {
            setErrors({ ...errors, update: true });
        }
    };

    const getPermissions = async () => {
        try {
            const data = await UserPermissionService.getPermissionsByConnected();
            setPermissions(data);
        } catch (error) {
            console.error(error);
        }
    }

    useEffect(() => {
        const loadData = async () => {
            await getPermissions();
        }

        loadData();
    }, []);

    useEffect(() => {
        getGuideImage(params.id);
    }, [getGuideImage, params.id]);

    return (
        <>
            <div className={"d-flex justify-content-between my-3"}>
                <Link to="/guide-images" className={"previous round rounded-circle text-decoration-none"} title={t('general.back')}>
                    <FontAwesomeIcon icon={"chevron-left"} className={"mt-2 me-2"} />
                    <span>{t('guideImages.title')}</span>
                </Link>
            </div>
            <Alert variant={"success"} show={success.update} dismissible onClose={() => setSuccess({ ...success, update: false })}>
                {t('guideImages.success.update')}
            </Alert>
            <Alert variant={"danger"} show={errors.update} dismissible onClose={() => setErrors({ ...errors, update: false })}>
                {t('guideImages.error.update')}
            </Alert>
            <Card>
                <Card.Body>
                    <GuideImageForm onSubmit={updateGuideImage} guideImage={guideImage} title1={guideImage?.title1} title2={guideImage?.title2} url={guideImage?.url} permissions={permissions} details />
                </Card.Body>
            </Card>
        </>
    );
}

export default GuideImagesDetails;