import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect, useState } from 'react'
import { Alert, Card } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { Link, useParams } from 'react-router-dom';
// import {usePromiseTracker} from "react-promise-tracker";

import StampsForm from '../../../components/forms/StampsForm';
import BodyService from '../../../services/body.service';
import StampService from '../../../services/stamps.service';
import UserPermissionService from "../../../services/userPermission.service";
import { useCallback } from 'react';

function StampsDetails() {

    const { t } = useTranslation();
    const params = useParams();

    const [stamp, setStamp] = useState()
    const [languages, setLanguages] = useState();
    const [tracker, setTracker] = useState({
        getStamp: false
    })
    const [permissions, setPermissions] = useState({});


    const [success, setSuccess] = useState({
        update: false,
        delete: false,
    });
    const [errors, setErrors] = useState({
        update: false,
        delete: false,
    });

    const getEmptyTranslations = async () => {
        const data = await BodyService.getEmptyTranslations();
        setLanguages(data);
    }

    const getStampsdetail = useCallback(async () => {
        try {
            setTracker({...tracker, getStamp: true})
            const data = await StampService.getById(params.id)
            setStamp(data)
            setTracker({...tracker, getStamp: false})
        } catch (error) {
            console.error(error);
        }
    }, [params.id, tracker])

    const updateInterestSite = async (data) => {
        setErrors({ ...errors, update: false });
        setSuccess({ ...success, update: false });

        try {
            await StampService.update(params.id, data);
            setSuccess({...success, update: true});
        } catch (e) {
            setErrors({...errors, update: true});
        }
    };

    const getPermissions = async () => {
        try {
            const data = await UserPermissionService.getPermissionsByConnected();
            setPermissions(data);
        } catch (error) {
            console.error(error);
        }
    }

    useEffect(() => {
        const loadData = async () => {
            await getPermissions();
        }

        loadData();
    }, []);

    useEffect(() => {
        if (!stamp && !tracker.getStamp) {
            getEmptyTranslations();
            getStampsdetail();
        }
    }, [stamp, getStampsdetail, tracker.getStamp])

    return (
        <>
            <div className={"d-flex justify-content-between my-3"}>
                <Link to="/stamp" className={"previous round rounded-circle text-decoration-none"}
                    title={t('general.back')}>
                    <FontAwesomeIcon icon={"chevron-left"} className={"mt-2 me-2"} />
                    <span>{t('stamps.title')}</span>
                </Link>
            </div>

            <Alert variant={"success"} show={success.update} dismissible onClose={() => setSuccess({ ...success, update: false })}>
                {t('stamps.success.update')}
            </Alert>

            <Alert variant={"danger"} show={errors.update} dismissible onClose={() => setErrors({ ...errors, update: false })}>
                {t('stamps.error.update')}
            </Alert>

            <Card className={"shadow mb-4"}>
                <Card.Header className={"py-3"}>
                    <h6 className={"m-0 fw-bold text-primary"}>{t('stamps.update.title')}</h6>
                </Card.Header>
                <Card.Body>
                    <StampsForm onSubmit={updateInterestSite} languages={languages} stamp={stamp} permissions={permissions} details />
                </Card.Body>
            </Card >
        </>
    )
}

export default StampsDetails