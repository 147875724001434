import api from './api';

const getAll = (pagination, sortColumn, filter) => api.get('/analytic', {
    startDate: filter.startDate,
    endDate: filter.endDate,
    pageNum: pagination.currentPage - 1,
    pageLength: pagination.itemsPerPage,
    sortBy: sortColumn.sortByColumn,
    orderBy: sortColumn.orderBy,
    villaId: filter.villaId
}).then(response => {
    return response.data;
});

const getVillaData = () => api.get('/analytic/villa-data').then(response => {
    return response.data
});


const AnalyticService = {
    getAll,
    getVillaData
}

export default AnalyticService;