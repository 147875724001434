import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import { Alert, Card } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Link, useNavigate, useParams } from "react-router-dom"
import { useCallback } from "react";
import { useEffect } from "react";
import AuthorForm from "../../../components/forms/AuthorForm";
import AuthorService from "../../../services/author.service";
import BodyService from "../../../services/body.service";
import UserPermissionService from "../../../services/userPermission.service";

const AuthorDetails = () => {
    const params = useParams();

    const navigate = useNavigate();
    
    const { t } = useTranslation();

    const [author, setAuthor] = useState();

    const [translations, setTranslations] = useState([]);

    const [title, setTitle] = useState("");
    const [permissions, setPermissions] = useState({});

    const [success, setSuccess] = useState({
        update: false,
        email: false,
    });

    const [errors, setErrors] = useState({
        update: false,
    });

    const getPermissions = async () => {
        try {
            const data = await UserPermissionService.getPermissionsByConnected();
            setPermissions(data);
        } catch (error) {
            console.error(error);
        }
    }

    const getAuthor = useCallback(async (id) => {
        try {
            const data = await AuthorService.getById(id);                 
            setAuthor(data);
            setTitle(data.name);
        } catch (error) {           
            navigate("/author");
        }
    },[navigate]);

    const updateAuthor = async (data) => {
        setErrors({...errors, update: false});
        setSuccess({...success, update: false});
        
        try{
            await AuthorService.update(params.id,data);
            getAuthor(params.id);
            setSuccess({...success, update: true});
        } catch (e) {
            setErrors({...errors, update: true});
        }
    };

    const getEmptyTranslations = async () => {
        const data = await BodyService.getEmptyTranslations();
        setTranslations(data);
    };

    useEffect(() => {
        const loadData = async () => {
            await getPermissions();
        }

        loadData();
    }, []);

    useEffect(() => {
        getAuthor(params.id);
        getEmptyTranslations();
    }, [getAuthor, params.id]);

    return (
        <>
          <div className={"d-flex justify-content-between my-3"}>
                <Link to="/author" className={"previous round rounded-circle text-decoration-none"}
                    title={t('general.back')}>
                    <FontAwesomeIcon icon={"chevron-left"} className={"mt-2 me-2"} />
                    <span>{t('author.title')}</span>
                </Link>
            </div>
            <Alert variant={"success"} show={success.update} dismissible onClose={() => setSuccess({ ...success, update: false })}>
                {t('author.success.update')}
            </Alert>
            <Alert variant={"danger"} show={errors.update} dismissible onClose={() => setErrors({ ...errors, update: false })}>
                {t('author.error.update')}
            </Alert>
            <Card>
                <Card.Header className={"py-3"}>
                    <h1 className={"h3 mb-0 text-gray-800 card-title text-capitalize"}>{title}</h1>
                </Card.Header>
                <Card.Body>
                    <AuthorForm onSubmit={updateAuthor} author={author} translations={translations} permissions={permissions} details />
                </Card.Body>
            </Card>
        </>
    )
}

export default AuthorDetails;