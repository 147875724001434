import {Button, Nav, NavDropdown} from "react-bootstrap";
import {library} from "@fortawesome/fontawesome-svg-core";
import {fas} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import AuthService from "../services/auth.service";
import {useTranslation} from "react-i18next";
import {useContext} from "react";
import {AppContext} from "../context/AppContext";

const Topbar = () => {

    library.add(fas);

    const {t} = useTranslation();

    const { state, update } = useContext(AppContext);

    const logout = () => {
        AuthService.logout();
    }

    return (
        <Nav className={"navbar navbar-expand navbar-light bg-white topbar mb-4 px-3 static-top shadow"}>
            <Button variant="link" id={"sidebarToggleTop"}
                    className={"d-md-none rounded-circle mr-3"}
                    onClick={() => update({...state, collapsed: !state.collapsed})}>
                <FontAwesomeIcon icon={"bars"}/>
            </Button>

            <ul className={"navbar-nav ms-auto"}>
                <div className={"topbar-divider d-none d-sm-block"}/>

                <NavDropdown className={"nav-link"}
                             title={
                                 <div>
                                     <img className={"ms-3 img-profile rounded-circle"}
                                          src={"/img/default-avatar.jpg"}
                                          alt="User"
                                     />
                                 </div>
                             }
                             id="basic-nav-dropdown">
                    <NavDropdown.Item onClick={logout}>{t('general.sidebar.logout')}</NavDropdown.Item>
                </NavDropdown>
            </ul>
        </Nav>
    );
}

export default Topbar;
