import { t } from "i18next";
import { Button, Col, Form, FormCheck, FormGroup, Image, Row } from "react-bootstrap";
import { useForm } from "react-hook-form";
import BodyService from "../../services/body.service";
import { useEffect, useRef, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const AuthorForm = ({ onSubmit, author, details, translations, permissions }) => {

    const [image, setImage] = useState({});

    const pictureRef = useRef(null);

    const [descriptionsLanguage, setDescriptionsLanguage] = useState({});

    const setDescriptionValuesFormTextArea = (data, lang) => {

        const descriptions = { ...descriptionsLanguage };

        descriptions[lang.language.iso].value = data;

        setDescriptionsLanguage(descriptions);
    }

    const onSubmitForm = async (data) => {
        let objSend = {
            ...data,
            description: [],
            image: {}
        };

        Object.values(descriptionsLanguage).forEach((description) => {
            objSend.description.push({
                language: {
                    id: description.language.id
                },
                value: description.value,
                picture: {}
            })
        });

        setImages(objSend);

        try {
            await onSubmit(objSend);
            if (!details) {
                setDescriptionsLanguage(null);
                setImage({});
                reset();
            }
        } catch (error) {
            console.error(error)
        }

        setTimeout(function () {
            window.scrollTo(0, 0);
        }, 0);

    }

    const { register, handleSubmit, formState, reset } = useForm({
        mode: "all",
        defaultValues: {
            active: !details,
        },
    })

    const setImages = (objSend) => {
        if (image) {
            if (image.id) {
                objSend.image = {
                    id: image.id,
                    imageBase64: null,
                    imageContentType: null,
                    imageName: null,
                }
            } else {
                if (image.imageBase64 || image.imageBase64 === "") {
                    objSend.image = image;
                }
            }
        }
    }

    const setImageToBase64 = async (file) => {
        if (file) {
            const base = await BodyService.getBase64(file);
            const newImage = { ...image };
            newImage.imageBase64 = base.split(',')[1];
            newImage.imageName = file.name;
            newImage.imageContentType = file.type;
            setImage(newImage);
        }
    }

    const getBase64Src = (image) => {
        if (!image?.imageBase64 || !image?.imageContentType) {
            return false;
        }
        return `data:${image.imageContentType};base64,${image.imageBase64}`;
    }

    const removeImage = (ref) => {
        ref.current.value = "";
        let newImage = {};
        if (details) {
            if (image) {
                newImage.imageBase64 = "";
                newImage.imageContentType = "";
                newImage.imageUrl = "";
            }
        }
        setImage(newImage);
    }

    const oneLanguageIsPresent = () => {
        return descriptionsLanguage && Object.values(descriptionsLanguage).some((element) => element?.value);
    }

    const validate = () => {
        return formState.isValid && oneLanguageIsPresent();
    }

    const setLanguagesInTextArea = () => {
        let descriptionLang = {};

        translations.forEach((translation) => {
            descriptionLang[translation.language.iso] = {
                value: null,
                language: translation.language,
                iso: translation.language.iso
            }
        })

        setDescriptionsLanguage(descriptionLang);
    }

    useEffect(() => {
        setLanguagesInTextArea();

        if (details && author) {

            let descriptionLang = { ...descriptionsLanguage };

            Object.keys(author.description).forEach((key) => {
                descriptionLang[key].value = author.description[key].value
            })          

            setDescriptionsLanguage(descriptionLang);

            setImage({ imageUrl: author.image });
        }
    }, [translations, author, details])

    useEffect(() => {
        if (details) {
            reset(author);
        }
    }, [author, details, reset])

    return (
        <Form onSubmit={handleSubmit(onSubmitForm)}>
            {((!validate() && !details) || (!validate() && details && author)) && <small className='text-danger fw-bold d-block mb-2'>{t('author.form.required')}</small>}
            {((!oneLanguageIsPresent() && !details) || (!oneLanguageIsPresent() && details && author)) && <small className='text-warning fw-bold d-block mb-3'>{t('author.form.warning')}</small>}
            <Row className="mt-3">
                <Col md={3}>
                    <h5>{t('author.creation.fields.name')}<span className="text-danger fw-bold">*</span></h5>
                    <Form.Control type="text" {...register('name', { required: true })} />
                </Col>
            </Row>
            <Row className="mt-3">
                <Col md={6}>
                    <h5>{t('author.creation.fields.surname')}<span className="text-danger fw-bold">*</span></h5>
                    <Form.Control type="text" {...register('surname', { required: true })} />
                </Col>
            </Row>
            <Row className="mt-3">
                <Col md={3}>
                    <h5>{t('author.creation.fields.type.label')}<span className="text-danger fw-bold">*</span></h5>
                    <Form.Select {...register('type', { required: true })} aria-label="Default select example">
                        <option value={1}>{t('author.creation.fields.type.internal')}</option>
                        <option value={2}>{t('author.creation.fields.type.External')}</option>
                    </Form.Select>
                </Col>
            </Row>
            <Row className="mt-3">
                <Col xs={12}>
                    <h5>{t('author.creation.fields.description')}<span className="text-warning fw-bold mb-3">*</span></h5>
                    {translations && translations?.map((lang, i) =>
                    (<FormGroup key={i}>
                        <Form.Label>{lang.language.name}</Form.Label>
                        <Form.Control as="textarea" value={descriptionsLanguage?.[lang.language.iso]?.value || ''} rows={3} onChange={e => setDescriptionValuesFormTextArea(e.target.value, lang)} />
                    </FormGroup>
                    ))}
                </Col>
            </Row>
            <Row>
                <Col xs={12} className="mt-md-3">
                    <FormGroup>
                        <Form.Label>{t('author.creation.image')}</Form.Label>
                        <Row className="my-2">
                            {(image?.imageUrl || image?.imageBase64) &&
                                <Row className="my-3">
                                    <>
                                        <Col className="col-8 col-md-6 col-lg-2">
                                            <Image fluid thumbnail src={getBase64Src(image) || image?.imageUrl} />
                                        </Col>
                                    </>
                                </Row>
                            }
                            <Col md="auto">
                                <Form.Control type="file"
                                    accept="image/png, image/jpeg"
                                    onChange={(e) => setImageToBase64(e.target.files[0])}
                                    ref={pictureRef}
                                />
                            </Col>
                            <Col md="auto">
                                <Button variant="link" size="ls" onClick={() => removeImage(pictureRef)}
                                    className="border-primary">
                                    <FontAwesomeIcon icon="fas fa-trash" />
                                </Button>
                            </Col>
                        </Row>
                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col xs="12" className="mt-md-3">
                    <FormGroup>
                        <FormCheck label={t('author.creation.fields.active')} {...register("active")} />
                    </FormGroup>
                </Col>
            </Row>

            {details ? 
            <Button type="submit" className={"mt-4"} disabled={ permissions.authors?.update === false ? true : !validate() }>
                <FontAwesomeIcon icon={"save"} /> 
            </Button>
            : 
            <Button type="submit" className={"mt-4"} disabled={ permissions.authors?.create === false ? true : !validate() }>
                {t('author.creation.button')}
            </Button>
            }
            
        </Form>
    )
}

export default AuthorForm;