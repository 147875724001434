import api from './api';

const getAll = (pagination, sortColumn, language, filter) => api.get('/promotion', {
    pageNum: pagination.currentPage - 1,
    pageLength: pagination.itemsPerPage,
    search: filter.search,
    language: language,
    active: filter.active,
    sortBy: sortColumn.sortByColumn,
    orderBy: sortColumn.orderBy
}).then(response => {
    return response.data;
});

const create = (data) => api.post('/promotion', data).then(response => {
    return response.data
});

const getById = (id) => api.get(`/promotion/${id}`).then(response => {
    return response.data
});

const update = (id, data) => api.put(`/promotion/${id}`, data).then(response => {
    return response.data
});

const remove = (id) => api.delete(`/promotion/${id}`).then(response => {
    return response.data
});

const PromotionsService = {
    getAll,
    create,
    getById,
    update,
    remove
};

export default PromotionsService;