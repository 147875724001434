import React from 'react'
import { Accordion, Button, Col, Form, FormCheck, FormGroup, Image, Row } from 'react-bootstrap'
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import Select from "react-select";
import DateTimePicker from 'react-datetime-picker'
import BodyService from '../services/body.service';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Tooltip } from 'react-tooltip'
import APP_CONF from '../scripts/constants';

function PromotionForm({ onSubmit, languages, promotion, sections, permissions, detail }) {

    const { t, i18n } = useTranslation();

    const { register, handleSubmit, reset, formState } = useForm({
        mode: "all",
        defaultValues: {
            active: !detail,
        },
    });

    const [langData, setLangData] = useState();
    const [action, setAction] = useState();
    // const [sectionType, setSectionType] = useState();
    const [sectionList, setSectionList] = useState();
    const [startDate, setStartDate] = useState();
    const [endDate, setEndDate] = useState();
    const [resetFlag, setResetFlag] = useState(false);

    const [formDisabled, setFormDisabled] = useState(true);
    const [warning, setWarning] = useState({
        startDate: false,
        endDate: false
    })

    const langInputUpdate = (data, iso, target) => {
        setLangData({
            ...langData, [iso]: {
                ...langData[iso], [target]: {
                    ...langData[iso][target], "value": data
                }
            }
        });
    }

    const getBase64Src = (image) => {
        if (!image?.fileBase64 || !image?.fileContentType) {
            return false;
        }
        return `data:${image.fileContentType};base64,${image.fileBase64}`;
    }

    const setDataToBase64 = async (file, iso, id, lang) => {

        if (file) {
            const base = await BodyService.getBase64(file);
            const fileData = {};
            fileData.fileBase64 = base.split(',')[1];
            fileData.fileContentType = file.type;

            langInputUpdate(fileData, iso, `media`)

        }
    }

    const removeImg = (iso) => {
        if (iso) {
            setLangData({
                ...langData, [iso]: {
                    ...langData[iso], [`media`]: {
                        ...langData[iso][`media`], "value": ""
                    }
                }
            });

            document.getElementById(`media-${iso}`).value = null
        }
    }

    const getAction = (key) => {
        switch (key) {
            case "SESSION":
                return { title: "Sesión", value: 1 }

            case "USER":
                return { title: "Usuario", value: 2 }

            case "DEVICE":
                return { title: "Dispositivo", value: 3 }

        }
    }

    const onSubmitForm = async (data) => {

        let sectionListFormat = [];

        sectionList?.forEach((section) => {
            sectionListFormat.push(section.id)
        })

        let submitData = {
            name: data.name,
            start: startDate,
            end: endDate,
            timelapse: parseFloat(data.timelapse),
            actionType: action.value,
            active: data.active,
            // sections: sectionType === 0 ? sectionType : sectionList,
            sections: sectionListFormat,
            promotionContentRequestDTO: {
                contentTitle: [],
                contentText: [],
                contentButtonText: [],
                contentButtonUrl: [],
                contentMultimedia: []
            }
        }

        Object.values(langData).forEach((data) => {
            submitData.promotionContentRequestDTO.contentTitle.push(data.title);
            submitData.promotionContentRequestDTO.contentText.push(data.description);
            submitData.promotionContentRequestDTO.contentButtonUrl.push(data.link);
            submitData.promotionContentRequestDTO.contentButtonText.push(data.button);
            submitData.promotionContentRequestDTO.contentMultimedia.push({
                language: data.media.language,
                imageBase64: data.media.value.fileBase64 || null,
                imageContentType: data.media.value.fileContentType || null
            });
        })

        try {
            const response = await onSubmit(submitData)

            if (!detail && !response) {
                reset();
                setLangData();
                setAction();
                setSectionList([]);
                setStartDate();
                setEndDate();
                setResetFlag(resetFlag ? false : true);
            }

            setTimeout(function () {
                window.scrollTo(0, 0);
            }, 0);
        } catch (error) {
            console.error(error);
        }
    }

    const checkDisable = () => {
        let blocked = false;

        if (langData) {
            Object.values(langData).forEach((data) => { // Recorrer todos los idiomas
                if (!blocked && (!data?.title?.value || !data?.link?.value || !data?.description?.value || !data?.button?.value)) {
                    blocked = true
                }
            })

            if (!startDate || !endDate || warning.startDate || warning.endDate) {
                blocked = true
            }

            if (!action || !sectionList || sectionList?.length === 0) {
                blocked = true
            }

            if (blocked) {
                if (!formDisabled) {
                    setFormDisabled(true)
                }

                return true
            } else {
                if (formDisabled) {
                    setFormDisabled(false)
                }

                return false
            }
        }
    }

    useEffect(() => {
        if (languages?.length > 0 && !detail) {
            let langObj = {}

            languages.forEach((lang) => {
                langObj[lang.language.iso] = {
                    title: lang,
                    link: lang,
                    description: lang,
                    button: lang,
                    media: {
                        language: { id: lang.language.id },
                        value: {
                            url: null,
                            fileBase64: null,
                            fileContentType: null
                        }
                    }
                }
            })

            setLangData(langObj)
        } else if (languages?.length > 0 && detail && promotion) {
            let langObj = {}

            languages.forEach((lang) => {
                langObj[lang.language.iso] = {
                    title: {
                        language: { id: lang.language.id },
                        value: promotion?.promotionContentDTO?.contentTitle?.[lang.language.iso].value
                    },
                    link: {
                        language: { id: lang.language.id },
                        value: promotion?.promotionContentDTO?.contentButtonUrl?.[lang.language.iso].value
                    },
                    description: {
                        language: { id: lang.language.id },
                        value: promotion?.promotionContentDTO?.contentText?.[lang.language.iso].value
                    },
                    button: {
                        language: { id: lang.language.id },
                        value: promotion?.promotionContentDTO?.contentButtonText?.[lang.language.iso].value
                    },
                    media: {
                        language: { id: lang.language.id },
                        value: {
                            url: promotion?.promotionContentDTO?.contentMultimedia?.[lang.language.iso].value,
                            fileBase64: null,
                            fileContentType: null
                        }
                    }
                }
            })
            setLangData(langObj)
            promotion?.start && setStartDate(new Date(promotion?.start));
            promotion?.end && setEndDate(new Date(promotion?.end));

            let sections = [];

            promotion.sections.forEach((section) => {
                sections.push(section.id)
            })

            setSectionList(promotion.sections);
            setAction(getAction(promotion.actionType))
        }
    }, [languages, promotion, resetFlag])

    useEffect(() => {
        if (promotion && langData) {
            reset(promotion)
        }
    }, [langData, promotion])

    return (
        <Form onSubmit={handleSubmit(onSubmitForm)}>
            {((formDisabled && !detail) || (formDisabled && detail && promotion)) && <small className='text-danger fw-bold d-block mb-3'>{t('miniGuide.form.required')}</small>}
            <Accordion className='mb-3'>
                <Accordion.Item eventKey=''>
                    <Accordion.Header>
                        <h6 className='m-0 fw-bold text-primary'>{t('promotions.form.configuration.title')}{((formDisabled && !detail) || (formDisabled && detail && promotion)) && <span className="ms-1 text-danger fw-bold">*</span>}</h6>
                    </Accordion.Header>
                    <Accordion.Body>
                        <Row className='mb-4'>
                            <Col md={3}>
                                <FormGroup>
                                    <Form.Label>{t('promotions.form.configuration.name')}{((formDisabled && !detail) || (formDisabled && detail && promotion)) && <span className="ms-1 text-danger fw-bold">*</span>}</Form.Label>
                                    <Form.Control type='text'{...register('name', { required: true })} />
                                </FormGroup>
                            </Col>
                            <Col md={3}>
                                <FormGroup>
                                    <Form.Label>
                                        {t('promotions.form.configuration.start')}{((formDisabled && !detail) || (formDisabled && detail && promotion)) && <span className="ms-1 text-danger fw-bold">*</span>}

                                        {warning.startDate && (
                                            <>
                                                <FontAwesomeIcon icon={"fa-circle-exclamation"} className='text-danger ms-1' data-tooltip-id="startDateError" data-tooltip-content={t('promotions.tooltips.start')} data-tooltip-place="right" />
                                                <Tooltip id="startDateError" />
                                            </>
                                        )}
                                    </Form.Label>
                                    <DateTimePicker

                                        disableTimePicker={true}
                                        onChange={e => {
                                            if (e && e !== 0 && endDate && e?.getTime() > endDate?.getTime()) {
                                                setStartDate(endDate)
                                                setWarning({ ...warning, "startDate": true })

                                                // setTimeout(() => {
                                                //     setWarning({ ...warning, "startDate": false })
                                                // }, 10000);
                                            } else {
                                                if (e?.getTime() < endDate?.getTime()) {
                                                    setWarning({ ...warning, "startDate": false, "endDate": false })
                                                }
                                                setStartDate(e)
                                            }
                                        }}
                                        value={startDate || ''}
                                    />
                                </FormGroup>
                            </Col>
                            <Col md={3}>
                                <FormGroup>
                                    <Form.Label>
                                        {t('promotions.form.configuration.end')}{((formDisabled && !detail) || (formDisabled && detail && promotion)) && <span className="ms-1 text-danger fw-bold">*</span>}
                                        {warning.endDate && (
                                            <>
                                                <FontAwesomeIcon icon={"fa-circle-exclamation"} className='text-danger ms-1' data-tooltip-id="endDateError" data-tooltip-content={t('promotions.tooltips.end')} data-tooltip-place="right" />
                                                <Tooltip id="endDateError" />
                                            </>
                                        )}
                                    </Form.Label>
                                    <DateTimePicker
                                        disableTimePicker
                                        onChange={e => {
                                            if (e && e !== 0 && startDate && e?.getTime() < startDate?.getTime()) {
                                                setEndDate(startDate)
                                                setWarning({ ...warning, "endDate": true })
                                                // setTimeout(() => {
                                                //     setWarning({ ...warning, "endDate": false })
                                                // }, 10000);
                                            } else {
                                                if (e?.getTime() > startDate?.getTime()) {
                                                    setWarning({ ...warning, "startDate": false, "endDate": false })
                                                }
                                                setEndDate(e)
                                            }
                                        }}
                                        value={endDate || ''}
                                    />

                                </FormGroup>
                            </Col>
                            <Col md={3}>
                                <FormGroup>
                                    <Form.Label>
                                        {t('promotions.form.configuration.delay')}
                                        <FontAwesomeIcon icon={"fa-circle-question"} className='text-primary ms-1' data-tooltip-id="delayTip" data-tooltip-content={t('promotions.tooltips.delay')} data-tooltip-place="right" />
                                        <Tooltip id="delayTip" />
                                    </Form.Label>
                                    <Form.Control type='number' {...register("timelapse")} />
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row className='mb-4'>
                            <Col md={3}>
                                <Form.Label>{t('promotions.form.configuration.trigger')}{((formDisabled && !detail) || (formDisabled && detail && promotion)) && <span className="ms-1 text-danger fw-bold">*</span>}</Form.Label>
                                <Select options={[
                                    { title: "Sesión", value: 1 },
                                    { title: "Usuario", value: 2 },
                                    { title: "Dispositivo", value: 3 }
                                ]}
                                    classNamePrefix="select"
                                    placeholder={!detail ? "" : ""}
                                    getOptionLabel={(option) => option.title}
                                    getOptionValue={(option) => option.value}
                                    value={action || ''}
                                    onChange={(data) => {
                                        setAction(data)
                                    }}
                                />
                            </Col>
                            <Col md={3}>
                                <Form.Label>{t('promotions.form.configuration.sections')}{((formDisabled && !detail) || (formDisabled && detail && promotion)) && <span className="ms-1 text-danger fw-bold">*</span>}</Form.Label>
                                <Select options={sections}
                                    classNamePrefix="select"
                                    placeholder={!detail ? "" : ""}
                                    value={sectionList}
                                    getOptionLabel={(option) => option?.name?.['es']?.value}
                                    getOptionValue={(option) => option.id}
                                    // value={articleType || ''}
                                    onChange={(data) => {
                                        setSectionList(data)
                                    }}
                                    closeMenuOnSelect={false}
                                    isMulti
                                />
                            </Col>
                            <Col lg="auto" className="mt-3 d-flex align-items-end justify-content-start">
                                <FormGroup className="">
                                    <FormCheck className="form-check-input-md"
                                        label={t('articles.creation.general.active')}
                                        {...register("active")}
                                    />
                                </FormGroup>
                            </Col>
                        </Row>
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>
            {languages && languages.map((lang, i) => (
                <Accordion key={i} className="mb-3">
                    <Accordion.Item eventKey="2">
                        <Accordion.Header>
                            <h6 className={"m-0 fw-bold text-primary"}>{lang.language.name}{((formDisabled && !detail) || (formDisabled && detail && promotion)) && <span className="ms-1 text-danger fw-bold">*</span>}</h6>
                        </Accordion.Header>
                        <Accordion.Body>
                            <Row className='mb-4'>
                                <Col md={3}>
                                    <FormGroup>
                                        <Form.Label>{t('promotions.form.content.title')}{((formDisabled && !detail) || (formDisabled && detail && promotion)) && <span className="ms-1 text-danger fw-bold">*</span>}</Form.Label>
                                        <Form.Control type='text' value={langData?.[lang.language.iso]?.title?.value || ""} onChange={e => langInputUpdate(e.target.value, lang.language.iso, "title")} />
                                    </FormGroup>
                                </Col>
                                <Col md={3}>
                                    <FormGroup>
                                        <Form.Label>{t('promotions.form.content.link')}{((formDisabled && !detail) || (formDisabled && detail && promotion)) && <span className="ms-1 text-danger fw-bold">*</span>}</Form.Label>
                                        <Form.Control type='text' value={langData?.[lang.language.iso]?.link?.value || ""} onChange={e => langInputUpdate(e.target.value, lang.language.iso, "link")} />
                                    </FormGroup>
                                </Col>
                                <Col md={3}>
                                    <FormGroup>
                                        <Form.Label>{t('promotions.form.content.button')}{((formDisabled && !detail) || (formDisabled && detail && promotion)) && <span className="ms-1 text-danger fw-bold">*</span>}</Form.Label>
                                        <Form.Control type='text' value={langData?.[lang.language.iso]?.button?.value || ""} onChange={e => langInputUpdate(e.target.value, lang.language.iso, "button")} />
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row className='mb-4'>
                                <Col md={6}>
                                    <Form.Label>{t('promotions.form.content.description')}{((formDisabled && !detail) || (formDisabled && detail && promotion)) && <span className="ms-1 text-danger fw-bold">*</span>}</Form.Label>
                                    <Form.Control as={"textarea"} value={langData?.[lang.language.iso]?.description?.value || ""} type='text' onChange={e => langInputUpdate(e.target.value, lang.language.iso, "description")} rows={3} />
                                </Col>
                            </Row>
                            <Row>
                                <Col md={'auto'}>
                                    <Row>
                                        <Col md="auto" className={(langData?.[lang.language.iso]?.media?.value?.fileBase64 || langData?.[lang.language.iso]?.media?.value?.url) && "mb-3"}>
                                            <Form.Label>{t('promotions.form.content.media')}</Form.Label>
                                            <Form.Control id={`media-${lang.language.iso}`} type="file"
                                                accept="image/*, video/mp4, video/quicktime"
                                                onChange={(e) => setDataToBase64(e.target.files[0], lang.language.iso)} />
                                        </Col>
                                        {(langData?.[lang.language.iso]?.media?.value?.fileBase64 || langData?.[lang.language.iso]?.media?.value?.url) && (
                                            <Col md="auto" className={`align-self-end ${(langData?.[lang.language.iso]?.media?.value?.url || langData?.[lang.language.iso]?.media?.value) && "mb-3"}`}>
                                                <Button variant="link" size="ls" onClick={() => removeImg(lang.language.iso)}
                                                    className="border-primary">
                                                    <FontAwesomeIcon icon="fas fa-trash" />
                                                </Button>
                                            </Col>
                                        )}
                                    </Row>
                                    {(langData?.[lang.language.iso]?.media?.value?.fileBase64 || langData?.[lang.language.iso]?.media?.value?.url) && (
                                        <>
                                            {langData?.[lang.language.iso]?.media?.value?.fileContentType?.split('/')[0] === "image" || ((langData?.[lang.language.iso]?.media?.value?.url)?.includes("jpeg") || (langData?.[lang.language.iso]?.media?.value?.url)?.includes("png")) ?
                                                <Col className="col-3">
                                                    <Image fluid thumbnail src={getBase64Src(langData?.[lang.language.iso]?.media?.value) || langData?.[lang.language.iso]?.media?.value?.url}
                                                        alt="test" />
                                                </Col>
                                                :
                                                <Col className="col-7">
                                                    <video autoPlay loop muted controls alt="test" className='w-100'>
                                                        <source src={getBase64Src(langData?.[lang.language.iso]?.media?.value) || langData?.[lang.language.iso]?.media?.value?.url} type={"video/mp4"} />
                                                    </video>
                                                </Col>
                                            }
                                        </>
                                    )}
                                </Col>
                            </Row>
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>
            ))}
            {detail ? 
            <Button type="submit" className={"mt-3"} disabled={ permissions.promotions?.update === false ? true : (checkDisable() || !formState.isValid) }>
                {t('stamps.update.button')}
            </Button>
            : 
            <Button type="submit" className={"mt-3"} disabled={ permissions.promotions?.create === false ? true : (checkDisable() || !formState.isValid) }>
                {t('stamps.form.submit')}
            </Button>
            }
        </Form>
    )
}

export default PromotionForm