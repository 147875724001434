import api from './api';

const getAll = (pagination, sortColumn, language, filter) => api.get('/article', {pageNum: pagination.currentPage - 1,
    pageLength: pagination.itemsPerPage,
    search: filter.search,
    language: language,
    active: filter.active,
    sortBy: sortColumn.sortByColumn,
    orderBy: sortColumn.orderBy
}).then(response => {
    return response.data;
});

const getLazy = () => api.get(`/article/lazy`).then(response => {
    return response.data
});

const getById = (id) => api.get(`/article/${id}`).then(response => {
    return response.data
});

const create = (data) => api.post('/article', data).then(response => {
    return response.data
});

const update = (id, data) => api.put(`/article/${id}`, data).then(response => {
    return response.data
});

const remove = (id) => api.delete(`/article/${id}`).then(response => {
    return response.data
});

const downloadExcel = (language, filter) => api.getFile('/article/download-excel', {
    search: filter.search,
    language: language,
    active: filter.active
}).then(response => {
    var downloadLink = window.document.createElement('a');
    downloadLink.href = window.URL.createObjectURL(new Blob([response.data], { 
    type: 'application/octet-stream' }));
    downloadLink.download = 'Articles.xlsx';
    
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
});

const ArticleService = {
    getAll,
    getById,
    getLazy,
    create,
    update,
    remove,
    downloadExcel
};

export default ArticleService;