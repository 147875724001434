import { fas } from "@fortawesome/free-solid-svg-icons"
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Nav } from 'react-bootstrap'
import { useContext } from "react";
import { AppContext } from "../../context/AppContext";

const SidebarDropdown = ({ icon, section, text, children }) => {
    library.add(fas);

    const { state, update } = useContext(AppContext);

    return (
        <Nav.Item
            style={{ userSelect: 'none' }}>
            <div className={`${!state?.collapsed ? "nav-link" : "nav-link p-0"}`} >
                <div
                    className={`${state?.dropDown?.[section] ? "nav-link active" : "nav-link"} ${!state?.collapsed ? "p-0" : ""}`}
                    onClick={() => update({
                        ...state, "dropDown": {
                            ...state?.dropDown, [section]: !state?.dropDown?.[section]
                        },
                    })}
                >
                    <FontAwesomeIcon icon={icon} />
                    <span className={`${!state?.collapsed ? "ms-2" : ""}`}>{text}</span>
                    {!state?.collapsed && <FontAwesomeIcon icon={!state?.dropDown?.[section] ? `fa-solid fa-angle-right` : "fa-solid fa-angle-down"} className={"ms-2"} />}
                </div>
                {state?.dropDown?.[section] &&
                    <>
                        <hr className={`mb-0 dropDown-divider ${state.collapsed ? "sidebar-divider mt-0" : "mt-3"}`} />
                        <div onClick={(e) => e.stopPropagation()} className={`sidebar-drop pt-3 ${state.collapsed && "pb-3"}`}>
                            {children}
                        </div>
                    </>
                }
            </div>
        </Nav.Item>
    )
}

export default SidebarDropdown