import api from './api';

const getAll = (pagination, sortColumn, language, filter) => api.get('/charging-point', {
    pageNum: pagination.currentPage - 1,
    pageLength: pagination.itemsPerPage,
    active: filter.active,
    search: filter.search,
    language: language,
    sortBy: sortColumn.sortByColumn,
    orderBy: sortColumn.orderBy
}).then(response => {
    return response.data;
});

const getAllList = () => api.get('/charging-point/list').then(response => {
    return response.data
});

const create = (data) => api.post('/charging-point', data).then(response => {
    return response.data
});

const update = (id, data) => api.put(`/charging-point/${id}`, data).then(response => {
    return response.data
});

const getById = (id) => api.get(`/charging-point/${id}`).then(response => {
    return response.data
});

const remove = (id) => api.delete(`/charging-point/${id}`).then(response => {
    return response.data
});

const ChargingPointService = {
    getAllList,
    getAll,
    create,
    update,
    getById,
    remove
}

export default ChargingPointService;