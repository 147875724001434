import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { t } from "i18next";
import {useEffect, useState} from "react";
import { Button, Col, Form, FormCheck, FormGroup, Row } from "react-bootstrap";
import { useForm } from "react-hook-form";
import UserAppForm from "./UserAppForm";

const UserForm = ({ onSubmit, user, permissions, details }) => {

    const { register, handleSubmit, formState, reset } = useForm({
        mode: "all",
        defaultValues: {
            active: !details,
        },
    });
    const [apps, setApps] = useState([]);

    const onSubmitForm = async (data) => {
        data.applications = apps;
        await onSubmit(data);
        if (!details) {
            reset();
        }
        setTimeout(function () {
            window.scrollTo(0, 0);
        },0);
    }

    const updateApps = (data) => {
        setApps(data);
    }

    useEffect(() => {
        if (details) {
            reset(user);
            setApps(user?.applications || []);
        }
    }, [user, details, reset]);

    return (
        <Form onSubmit={handleSubmit(onSubmitForm)}>
            <Row>
                <Col md="4">
                    <FormGroup >
                        <Form.Label>{t('users.creation.fields.firstName')}</Form.Label>
                        <Form.Control type="text" {...register("firstName", { required: true })} />
                    </FormGroup>
                </Col>
                <Col md="4">
                    <FormGroup>
                        <Form.Label>{t('users.creation.fields.lastName')}</Form.Label>
                        <Form.Control type="text" {...register("lastName", { required: true })} />
                    </FormGroup>
                </Col>
                <Col md="4">
                    <FormGroup >
                        <Form.Label>{t('users.creation.fields.email')}</Form.Label>
                        <Form.Control type="email" {...register("email", {
                            required: true, pattern: {
                                value: /\S+@\S+\.\S+/,
                                message: t('users.creation.error.email')
                            }
                        })} />
                    </FormGroup>
                </Col>
                {details ?
                    <Col className="mt-md-3" >
                        <FormGroup >
                            <Form.Label>{t('users.creation.fields.applications')}</Form.Label>
                            <UserAppForm onChange={updateApps} apps={apps} />
                        </FormGroup>
                    </Col> : null}
                <Col xs="12" className="mt-md-3">
                    <FormGroup>
                        <FormCheck label={t('users.creation.fields.active')} {...register("active")} />
                    </FormGroup>
                </Col>
            </Row>
            {details ? 
            <Button type="submit" className={"mt-4"} disabled={ permissions.users?.update === false ? true : !formState.isValid }>
                <FontAwesomeIcon icon={"save"} />
            </Button>
            : 
            <Button type="submit" className={"mt-4"} disabled={ permissions.users?.create === false ? true : !formState.isValid }>
                {t('users.creation.button')}
            </Button>
            }

        </Form>
    );
}

export default UserForm;