import api from "./api";

const getAll = (pagination, sortColumn, filter) => api.get('/author', {
    pageNum: pagination.currentPage - 1,
    pageLength: pagination.itemsPerPage,
    active: filter.active,
    search: filter.search,
    sortBy: sortColumn.sortByColumn,
    orderBy: sortColumn.orderBy
}).then(response => {
    return response.data;
});

const getList = () => api.get('/author/list').then(response => response.data);

const getById = (id) => api.get(`/author/${id}`).then(response => response.data);

const create = (data) => api.post('/author', data).then(response => {
    return response.data
});

const update = (id, data) => api.put(`/author/${id}`, data).then(response => {
    return response.data
});

const remove = (id) => api.delete(`/author/${id}`).then(response => {
    return response.data
});



const AuthorService ={
    getAll,
    getById,
    getList,
    create,
    update,
    remove
}

export default AuthorService;