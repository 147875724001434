import React, {useCallback, useEffect} from "react";
import Pagination from "react-bootstrap/Pagination";
import PropTypes from "prop-types";

const PaginationComponent = ({
                                 pagination,
                                 setPagination,
                                 alwaysShown = false
                             }) => {

    const pagesCount = Math.ceil(pagination.totalItems / pagination.itemsPerPage);
    const isPaginationShown = alwaysShown ? true : pagesCount > 1;
    const isCurrentPageFirst = pagination.currentPage === 1;
    const isCurrentPageLast = pagination.currentPage === pagesCount;

    const changePage = number => {
        if (pagination.currentPage === number) return;
        setPagination({...pagination, currentPage: number});
    };

    const onPageNumberClick = pageNumber => {
        changePage(pageNumber);
    };

    const onPreviousPageClick = () => {
        changePage(pagination.currentPage - 1);
    };

    const onNextPageClick = () => {
        changePage(pagination.currentPage + 1);
    };

    const setLastPageAsCurrent = useCallback(() => {
        if (pagination.currentPage > pagesCount) {
            setPagination({...pagination, currentPage: pagesCount});
        }
    },[pagesCount, pagination, setPagination]);

    let isPageNumberOutOfRange;

    const pageNumbers = [...new Array(pagesCount)].map((_, index) => {
        const pageNumber = index + 1;
        const isPageNumberFirst = pageNumber === 1;
        const isPageNumberLast = pageNumber === pagesCount;
        const isCurrentPageWithinTwoPageNumbers =
            Math.abs(pageNumber - pagination.currentPage) <= 2;

        if (
            isPageNumberFirst ||
            isPageNumberLast ||
            isCurrentPageWithinTwoPageNumbers
        ) {
            isPageNumberOutOfRange = false;
            return (
                <Pagination.Item
                    key={pageNumber}
                    onClick={() => onPageNumberClick(pageNumber)}
                    active={pageNumber === pagination.currentPage}
                >
                    {pageNumber}
                </Pagination.Item>
            );
        }

        if (!isPageNumberOutOfRange) {
            isPageNumberOutOfRange = true;
            return <Pagination.Ellipsis key={pageNumber} className="muted"/>;
        }

        return null;
    });

    useEffect(() => {
        if (pagination.totalItems > 0) {
            setLastPageAsCurrent();
        }
    }, [pagesCount, pagination.totalItems, setLastPageAsCurrent]);

    return (
        <>
            {isPaginationShown && (
                <Pagination>
                    <Pagination.Prev onClick={onPreviousPageClick} disabled={isCurrentPageFirst}/>
                    {pageNumbers}
                    <Pagination.Next onClick={onNextPageClick} disabled={isCurrentPageLast}/>
                </Pagination>
            )}
        </>
    );
};

PaginationComponent.propTypes = {
    pagination: PropTypes.object.isRequired,
    alwaysShown: PropTypes.bool
};

export default PaginationComponent;
