import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { t } from "i18next";
import { Fragment, useEffect, useState } from "react";
import { Button, Col, Form, FormCheck, FormGroup, Image, Row } from "react-bootstrap";
import { useForm } from "react-hook-form";
import _ from "lodash";

import BodyService from "../../services/body.service";
import TranslationInput from "./fields/TranslationInput";

const GuideImageForm = ({ onSubmit, details, guideImage, title1, title2, url, permissions }) => {

    const [title1Translations, setTitle1Translations] = useState([]);
    const [title2Translations, setTitle2Translations] = useState([]);
    const [urlTranslations, setUrlTranslations] = useState([]);
    const [image, setImage] = useState({});

    const { register, handleSubmit, formState, reset } = useForm({
        mode: "all",
        defaultValues: {
            active: !details,
        },
    });

    const setImageToBase64 = async (file) => {
        if (file) {
            const base = await BodyService.getBase64(file);
            const copyImage = {};
            copyImage.imageBase64 = base.split(',')[1];
            copyImage.imageName = file.name;
            copyImage.imageContentType = file.type;
            setImage(copyImage);
        }
    }

    const getBase64Src = (image) => {
        if (!image?.imageBase64 || !image?.imageContentType) {
            return false;
        }
        return `data:${image.imageContentType};base64,${image.imageBase64}`;
    }

    const setTitle1TranslationValue = (index, value) => {

        setTitle1Translations(title1Translations.map((title1, i) => {
            if (i === index) {
                title1.value = value;
            }
            return title1;
        }));
    }

    const setTitle2TranslationValue = (index, value) => {

        setTitle2Translations(title2Translations.map((title2, i) => {
            if (i === index) {
                title2.value = value;
            }
            return title2;
        }));
    }

    const setUrlTranslationValue = (index, value) => {

        setUrlTranslations(urlTranslations.map((url, i) => {
            if (i === index) {
                url.value = value;
            }
            return url;
        }));
    }

    const onSubmitForm = async (data) => {

        data.title1 = title1Translations;
        data.title2 = title2Translations;
        data.url = urlTranslations;
        data.imageBase64 = image.imageBase64;
        data.imageContentType = image.imageContentType;

        try {
            await onSubmit(data);
            if (!details) {
                const empty = await getImageEmptyTranslations();
                setTitle1Translations(_.cloneDeep(empty))
                setTitle2Translations(_.cloneDeep(empty))
                setUrlTranslations(_.cloneDeep(empty));
                setImage({});
                reset();
            }
        } catch (error) {
            console.error(error)
        }

        setTimeout(function () {
            window.scrollTo(0, 0);
        }, 0);
    }

    const getImageEmptyTranslations = async () => {
        return await BodyService.getEmptyTranslations();
    }

    const isImageValid = () => {
        if (!details) {
            return !image.imageBase64;
        } else {
            return false;
        }
    }

    useEffect(() => {
        if (details && guideImage !== {}) {
            setTitle1Translations(title1);
            setTitle2Translations(title2);
            setUrlTranslations(url);
        }
    }, [title1, guideImage, details, title2, url]);

    useEffect(() => {
        const init = async () => {
            const empty = await getImageEmptyTranslations();
            if (details) {
                reset(guideImage);
                setTitle1Translations(title1);
                setTitle2Translations(title2);
                setImage(guideImage);
            } else {
                setTitle1Translations(_.cloneDeep(empty))
                setTitle2Translations(_.cloneDeep(empty))
                setUrlTranslations(_.cloneDeep(empty))
            }
        }

        init();
    }, [guideImage, details, reset]);

    return (
        <Form onSubmit={handleSubmit(onSubmitForm)}>
            <h4>{t('guideImages.creation.fields.images')}</h4>
            <hr />
            <h5>{t('guideImages.creation.fields.title1')}</h5>
            <Row className="my-3">
                {title1Translations?.length > 0 &&
                    <Row className="mt-3">
                        <TranslationInput translations={title1Translations} refer="name" onChange={setTitle1TranslationValue} />
                    </Row>
                }
            </Row>
            <h5>{t('guideImages.creation.fields.title2')}</h5>
            <Row className="my-3">
                {title2Translations?.length > 0 &&
                    <Row className="mt-3">
                        <TranslationInput translations={title2Translations} refer="name" onChange={setTitle2TranslationValue} />
                    </Row>
                }
            </Row>
            <Row className="mt-2">
                <h5>{t('guideImages.creation.fields.url')}</h5>
                {urlTranslations?.length > 0 &&
                    <Row className="mt-3">
                        <TranslationInput translations={urlTranslations} refer="name" onChange={setUrlTranslationValue} />
                    </Row>
                }
            </Row>
            <Row className="my-3">
                {(image?.imageUrl || image?.imageBase64) &&
                    <Col className="col-8 col-md-6 col-lg-2">
                        <Image fluid thumbnail src={getBase64Src(image) || image.imageUrl} alt={guideImage?.title1} />
                    </Col>
                }
            </Row>
            <Row className="align-content-end">
                <Col md="auto">
                    <Form.Control type="file" accept="image/png, image/jpeg" onChange={(e) => setImageToBase64(e.target.files[0])} />
                </Col>
                <Col md="auto" className="d-flex align-items-center">
                    <FormCheck label={t('guideImages.creation.fields.active')} {...register("active")} />
                </Col>
                <Col md="auto" className="d-flex align-items-center">
                    <FormCheck label={t('guideImages.creation.fields.tab')} {...register("urlNewTab")} />
                </Col>
            </Row>

            {details ? 
            <Button type="submit" className={"mt-4"} disabled={ permissions.guideImages?.update === false ? true : (!formState.isValid || isImageValid()) }>
                <FontAwesomeIcon icon={"save"} />
            </Button>
            : 
            <Button type="submit" className={"mt-4"} disabled={ permissions.guideImages?.create === false ? true : (!formState.isValid || isImageValid()) }>
                {t('guideImages.creation.button')}
            </Button>
            }

        </Form>
    );
}

export default GuideImageForm;