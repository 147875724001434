import React, { useEffect, useState } from 'react'
import { Accordion, Button, Col, Form, FormCheck, FormGroup, Image, Row } from 'react-bootstrap'
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import BodyService from '../../services/body.service';

function SectionForm({ onSubmit, section, languages, detail, permissions }) {

    const { t, i18n } = useTranslation();

    const [langData, setLangData] = useState();
    const [formDisabled, setFormDisabled] = useState(true);
    const [resetFlag, setResetFlag] = useState(false);

    const { register, handleSubmit, reset, formState } = useForm({
        mode: "all",
        defaultValues: {
            active: !detail,
        },
    });

    const onSubmitForm = async (data) => {
        let submitData = {
            active: data.active,
            name: [],
            slug: []
        }

        Object.values(langData).forEach((data) => {
            submitData.name.push(data.name);
            submitData.slug.push(data.slug);
        })

        try {
            const response = await onSubmit(submitData)

            if (!detail && !response) {
                setLangData();
                reset();
                setResetFlag(!resetFlag)
            }

            setTimeout(function () {
                window.scrollTo(0, 0);
            }, 0);
        } catch (error) {
            console.error(error);
        }
    }

    const langInputUpdate = (data, iso, target) => {
        setLangData({
            ...langData, [iso]: {
                ...langData[iso], [target]: {
                    ...langData[iso][target], "value": data
                }
            }
        });
    }

    const checkDisable = () => {
        let blocked = false;

        if (langData) {
            Object.values(langData).forEach((data) => {
                if (!blocked && (!data?.name?.value)) {
                    blocked = true
                }
            })

            if (blocked) {
                if (!formDisabled) {
                    setFormDisabled(true)
                }

                return true
            } else {
                if (formDisabled) {
                    setFormDisabled(false)
                }

                return false
            }
        }
    }

    useEffect(() => {
        if (languages?.length > 0 && !detail) {
            let langObj = {}

            languages.forEach((lang) => {
                langObj[lang.language.iso] = {
                    name: lang,
                    slug: lang,
                }
            })


            setLangData(langObj)
        } else if (languages?.length > 0 && detail && section) {
            let langObj = {}

            languages.forEach((lang) => {
                langObj[lang.language.iso] = {
                    name: {
                        language: { id: lang.language.id },
                        value: section?.name?.[lang.language.iso].value
                    },
                    slug: {
                        language: { id: lang.language.id },
                        value: section?.slug?.[lang.language.iso].value
                    },
                    isSlugEmpty: !section?.slug?.[lang.language.iso].value
                }
            })
            setLangData(langObj)
            reset(section)
        }
    }, [languages, section, resetFlag])

    return (
        <>
            <Form onSubmit={handleSubmit(onSubmitForm)}>
                {((formDisabled && !detail) || (formDisabled && detail && section)) && <small className='text-danger fw-bold d-block mb-3'>{t('miniGuide.form.required')}</small>}

                {languages && languages.map((lang, i) => (
                    <Accordion key={i} className="mb-3">
                        <Accordion.Item eventKey="2">
                            <Accordion.Header>
                                <h6 className={"m-0 fw-bold text-primary"}>{lang.language.name}{((formDisabled && !detail) || (formDisabled && detail && section)) && <span className="ms-1 text-danger fw-bold">*</span>}</h6>
                            </Accordion.Header>
                            <Accordion.Body>
                                <Row className='mb-4'>
                                    <Col md={3}>
                                        <FormGroup>
                                            <Form.Label>{t('section.form.title')}{((formDisabled && !detail) || (formDisabled && detail && section)) && <span className="ms-1 text-danger fw-bold">*</span>}</Form.Label>
                                            <Form.Control type='text' value={langData?.[lang.language.iso]?.name?.value || ""} onChange={e => langInputUpdate(e.target.value, lang.language.iso, "name")} />
                                        </FormGroup>
                                    </Col>
                                    <Col md={3}>
                                        <FormGroup>
                                            <Form.Label>{t('section.form.slug')}</Form.Label>
                                            <Form.Control type='text' value={langData?.[lang.language.iso]?.slug?.value || ""} onChange={e => langInputUpdate(BodyService.slugifyStr(e.target.value), lang.language.iso, "slug")} disabled={!langData?.[lang.language.iso]?.isSlugEmpty && detail} />
                                        </FormGroup>
                                    </Col>
                                </Row>
                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>
                ))}
                <Row>
                    <Col lg="auto" className="d-flex align-items-end justify-content-start">
                        <FormGroup className="">
                            <FormCheck className="form-check-input-md"
                                label={t('articles.creation.general.active')}
                                {...register("active")}
                            />
                        </FormGroup>
                    </Col>
                </Row>
                {detail ? 
                <Button type="submit" className={"mt-3"} disabled={ permissions.sections?.update === false ? true : checkDisable() }>
                    {t('stamps.update.button')}
                </Button>
                : 
                <Button type="submit" className={"mt-3"} disabled={ permissions.sections?.create === false ? true : checkDisable() }>
                    {t('stamps.form.submit')}
                </Button>
                }

            </Form>
        </>
    )
}

export default SectionForm