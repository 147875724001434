import api from './api';

const getAll = (pagination, sortColumn, filter) => api.get('/user', {
    pageNum: pagination.currentPage - 1,
    pageLength: pagination.itemsPerPage,
    search: filter.search,
    adminUser: filter.adminUser,
    active: filter.active,
    sortBy: sortColumn.sortByColumn,
    orderBy: sortColumn.orderBy
}).then(response => {
    return response.data;
});

const getById = (id) => api.get(`/user/${id}`).then(response => {
    return response.data
});

const create = (data) => api.post('/user', data).then(response => {
    return response.data
});

const update = (id, data) => api.put(`/user/${id}`, data).then(response => {
    return response.data
});

const downloadExcel = (filter) => api.getFile('/user/download-excel', {
    search: filter.search,
    adminUser: filter.adminUser,
    active: filter.active
}).then(response => {
    var downloadLink = window.document.createElement('a');
    downloadLink.href = window.URL.createObjectURL(new Blob([response.data], { 
    type: 'application/octet-stream' }));
    downloadLink.download = 'Users.xlsx';
    
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
});

const UserService = {
    getAll,
    getById,
    create,
    update,
    downloadExcel
};

export default UserService;