import { t } from "i18next";
import { Button, Col, Form, FormCheck, FormGroup, Row } from "react-bootstrap"
import TranslationInputLanguage from "../fields/TranslationInputLanguage";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect } from "react";
import Select from "react-select";
import RegionService from "../../../services/region.service";

const LocalityForm = ({ onSubmit, details, translations, locality, permissions }) => {

    const [localityNameValues, setLocalityNameValues] = useState();

    const [translationValues, setTranslationValues] = useState();

    const [region, setRegion] = useState();

    const [regions, setRegions] = useState([]);

    const { register, handleSubmit, formState, reset } = useForm({
        mode: "all",
        defaultValues: {
            active: !details,
        },
    });

    const setLocalityNameValuesForm = (iso, value, id) => {
        setTranslationValues({
            ...translationValues, [iso]: { value: value, langId: id, iso: iso }
        })
    };

    const onSubmitForm = async (data) => {
        let objSend = {
            name: [],
            regionId: region.id,
            active: data.active
        }

        Object.values(translationValues).forEach((name) => {
            objSend.name.push({
                language: {
                    id: name.langId
                },
                value: name.value
            })
        })

        try {
            await onSubmit(objSend);
            if (!details) {
                setTranslationValues(null);
                reset();
            }
        } catch (error) {
            console.error(error)
        }

        setTimeout(function () {
            window.scrollTo(0, 0);
        }, 0);
    };

    const getRegions = async () => {
        try {
            const data = await RegionService.getList();            
            setRegions(data);
        } catch (error) {
            console.error(error);
        }
    }

    useEffect(() => {       
        setLocalityNameValues(translations);
        getRegions();

        if (details && locality) {

            let localityLang = {}

            Object.keys(locality.name).forEach((data) => {
                localityLang[data] = {
                    value: locality.name[data].value,
                    langId: locality.name[data].language.id,
                    iso: locality.name[data].language.iso,
                }
            });

            setTranslationValues(localityLang);
        }

    }, [translations, locality, details]);

    useEffect(() => {      
        if (details) {            
            reset(locality);
            setRegion(locality?.region);
        }
    }, [locality, details, reset]);

    return (
        <Form onSubmit={handleSubmit(onSubmitForm)}>
            <Row>
                <Col xs={12} className="mb-4">
                    <h5>{t('locality.creation.fields.name')}</h5>
                    {translations?.length > 0 ?
                        <Row className="mt-3">
                            <TranslationInputLanguage translations={localityNameValues} data={translationValues} onChange={setLocalityNameValuesForm} />
                        </Row>
                        : <></>
                    }
                </Col>
            </Row>
            <Row className="mb-4">
                <Col md={3}>
                    <FormGroup>
                        <Form.Label>{t('locality.creation.region')}</Form.Label>
                        <Select options={regions}
                            classNamePrefix="select"
                            placeholder=""
                            value={region || ''}
                            getOptionLabel={(option) => option.name["es"].value}
                            getOptionValue={(option) => option.id}
                            onChange={(data) => {
                                setRegion(data)
                            }}
                        />
                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col xs="12" className="mt-md-3">
                    <FormGroup>
                        <FormCheck label={t('locality.creation.fields.active')} {...register("active")} />
                    </FormGroup>
                </Col>
            </Row>
            {details ? 
            <Button type="submit" className={"mt-4"} disabled={ permissions.localities?.update === false ? true : !formState.isValid }>
                <FontAwesomeIcon icon={"save"} />
            </Button>
            : 
            <Button type="submit" className={"mt-4"} disabled={permissions.localities?.create === false ? true : !formState.isValid }>
                {t('locality.creation.button')}
            </Button>
            }
        </Form>
    );
};

export default LocalityForm;