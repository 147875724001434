
import api from './api';

const getAll = (pagination, sortColumn, filter) => api.get('user-analytic', {
    search: filter.search,
    startDate: filter.startDate,
    endDate: filter.endDate,
    pageNum: pagination.currentPage - 1,
    pageLength: pagination.itemsPerPage,
    sortBy: sortColumn.sortByColumn,
    orderBy: sortColumn.orderBy
}).then(response => {
    return response.data;
});

const UserAnalyticService = {
    getAll
}

export default UserAnalyticService;