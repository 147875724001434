import { useState } from "react";
import { Accordion, Alert, Button, Card, Col, Form, FormGroup, Image, Row, Table } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import ProvinceForm from "../../../components/forms/configuration/ProvinceForm";
import { useEffect } from "react";
import ProvinceService from "../../../services/province.service";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import BodyService from "../../../services/body.service";
import UseAlertConfirm from "../../../hooks/UseAlertConfirm";
import APP_CONF from "../../../scripts/constants";
import { useForm } from "react-hook-form";
import PaginationComponent from "../../../components/PaginationComponent";
import TableHeaderSortComponent from "../../../components/TableHeaderSortComponent";
import UserPermissionService from "../../../services/userPermission.service";

const Province = () => {

    const { t } = useTranslation();

    const [translations, setTranslations] = useState([]);

    const [provinces, setProvinces] = useState([]);

    const { showModal } = UseAlertConfirm();

    const [pagination, setPagination] = useState({
        currentPage: 1,
        totalItems: 0,
        itemsPerPage: APP_CONF.ITEMS_PER_PAGE,
        numPages: 1
    });

    const [sortColumn, setSortColumn] = useState({
        sortByColumn: null,
        orderBy: null
    });

    const [success, setSuccess] = useState({
        create: false,
        delete: false,
    });

    const [errors, setErrors] = useState({
        create: false,
        delete: false,
    });

    const { register, handleSubmit } = useForm({
        mode: "all",
    });

    const [filter, setFilter] = useState({
        active: "",
        search: ""
    })

    const [permissions, setPermissions] = useState({});

    const createProvince = async (data) => {
        setErrors({ ...errors, create: false });
        setSuccess({ ...success, create: false });
        try {
            await ProvinceService.create(data);
            getEmptyTranslations();
            setSuccess({ ...success, create: true });
            getProvinces();
        } catch (error) {
            console.error(error.response);
            setErrors({ ...errors, create: true });
        }
    };

    const getProvinces = async () => {
        try {
            const data = await ProvinceService.getAll(pagination, filter, sortColumn);
            setProvinces(data.content);
            setPagination({
                ...pagination,
                totalItems: data.totalElements,
                numPages: data.totalPages
            });
        } catch (response) {
            if (response?.data?.errorCode) {
                switch (response.data.errorCode) {
                    case 13002:
                        setErrors({ ...errors, email: true });
                        break;
                    default:
                        setErrors({ ...errors, create: true });
                }
            }
        }
    };

    const deleteProvince = async (id) => {
        showModal({
            title: t("general.delete.title"),
            body: t("general.delete.message"),
            confirmButtonVariant: "danger",
            onConfirm: async () => {
                setErrors({ ...errors, create: false });
                setSuccess({ ...success, create: false });
                try {
                    await ProvinceService.remove(id);
                    setSuccess({ ...success, delete: true });
                    getProvinces();
                } catch (e) {
                    console.error(e.response);
                    setErrors({ ...errors, delete: true });
                }
            }
        });
    };

    const getPermissions = async () => {
        try {
            const data = await UserPermissionService.getPermissionsByConnected();
            setPermissions(data);
        } catch (error) {
            console.error(error);
        }
    }

    useEffect(() => {
        const loadData = async () => {
            await getPermissions();
            await getEmptyTranslations();
        }

        loadData();
    }, []);

    useEffect(() => {
        getProvinces();
    }, [pagination.currentPage, sortColumn, filter]);

    const getEmptyTranslations = async () => {
        const data = await BodyService.getEmptyTranslations();
        setTranslations(data);
    };

    const filterSearch = (data) => {
        setFilter({
            active: data.active,
            search: data.search,
        });
    }

    return (
        <>
            <div className={"d-sm-flex align-items-center justify-content-between mb-4"}>
                <h1 className={"h3 mb-0 text-gray-800"}>{t('province.title')}</h1>
            </div>

            <Alert variant={"danger"} show={errors.create} dismissible onClose={() => setErrors({ ...errors, create: false })}>
                {t('province.error.create')}
            </Alert>

            <Alert variant={"success"} show={success.create} dismissible onClose={() => setSuccess({ ...success, create: false })}>
                {t('province.success.create')}
            </Alert>

            <Alert variant={"success"} show={success.delete} dismissible onClose={() => setSuccess({ ...success, delete: false })}>
                {t('province.success.delete')}
            </Alert>

            <Alert variant={"danger"} show={errors.delete} dismissible onClose={() => setErrors({ ...errors, delete: false })}>
                {t('province.error.delete')}
            </Alert>
            {permissions.provinces?.read && permissions.provinces?.create ? 
            <Card className={"shadow mb-4"}>
                <Accordion>
                    <Accordion.Item eventKey="0">
                        <Accordion.Header><h6 className={"m-0 fw-bold text-primary"}>{t('province.form.title')}</h6>
                        </Accordion.Header>
                        <Accordion.Body>
                            <ProvinceForm onSubmit={createProvince} translations={translations} permissions={permissions}/>
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>
            </Card>
            : null }
            <hr />
            <Card className={"shadow mb-4"}>
                <Card.Header className={"py-3"}>
                    <h6 className={"m-0 fw-bold text-primary"}>{t('province.table.title')}</h6>
                </Card.Header>
                <Card.Body>
                    <Form onSubmit={handleSubmit(filterSearch)}>
                        <Row>
                            <Col lg="2" className={"mb-3"}>
                                <FormGroup>
                                    <Form.Label>{t('province.filter.active.title')}</Form.Label>
                                    <Form.Select {...register('active')} aria-label="Default select example">
                                        <option value="">{t('province.filter.active.value1')}</option>
                                        <option value={true}>{t('province.filter.active.value2')}</option>
                                        <option value={false}>{t('province.filter.active.value3')}</option>
                                    </Form.Select>
                                </FormGroup>
                            </Col>
                            <Col lg="3" className={"mb-3"}>
                                <FormGroup>
                                    <Form.Label>{t('province.filter.search')}</Form.Label>
                                    <Form.Control type="text" {...register("search")} />
                                </FormGroup>
                            </Col>
                        </Row>
                        <Button type="submit" className={"mt-3"} disabled={!permissions.provinces?.read}>{t('province.filter.button')}</Button>
                    </Form>
                    <hr />
                    <Table responsive>
                        <thead>
                            <tr>
                                <TableHeaderSortComponent scope={"col"} text={t('province.table.name')} column={"name"} sortColumn={sortColumn} setSortColumn={setSortColumn} />
                                <TableHeaderSortComponent scope={"col"} text={t('province.table.active')} column={"active"} sortColumn={sortColumn} setSortColumn={setSortColumn} />
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {provinces ? provinces.map((province, index) => (
                                <tr key={index}>
                                    <td>
                                        <div className="d-flex flex-row flex-nowrap">
                                            {Object.values(province.name).map((translation, i) =>
                                                <div key={i} className="me-2">
                                                    <Image src={translation.language.icon} className="me-2 flags-img-sm" />
                                                    {i + 1 === Object.values(province.name).length ? translation.value : translation.value + ' | '}
                                                </div>
                                            )}
                                        </div>
                                    </td>
                                    <td>{province?.active ? 'Sí' : 'No'}</td>
                                    <td className={"text-center"}>
                                        <Button variant="link" size="sm" className="ms-2" to={`/configuration/province/${province.id}`} as={Link} disabled={!permissions.provinces?.read}>
                                            <FontAwesomeIcon icon="edit" />
                                        </Button>
                                        <Button variant="link" size="sm" className="text-danger" onClick={() => deleteProvince(province.id)} disabled={!permissions.provinces?.delete}>
                                            <FontAwesomeIcon icon="trash" />
                                        </Button>
                                    </td>
                                </tr>
                            )) :
                                <tr>
                                    <td colSpan={4}>{t('general.empty')}</td>
                                </tr>
                            }
                        </tbody>
                    </Table>
                    <PaginationComponent pagination={pagination}
                        setPagination={setPagination}
                        alwaysShown={provinces.length > 0} />
                </Card.Body>
            </Card>
        </>
    );
};

export default Province;